/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import MasterFaqController, {
  useMasterFaqContext,
} from "../../../../controller/admin/data_master/faq";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import { AiFillPlusCircle } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import Switch from "react-switch";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import { Box } from "@chakra-ui/react";
import env from "@ludovicm67/react-dotenv";

const ModalCreateData: React.FC = () => {
  const {
    formDialog,
    formPertanyaan,
    formLoading,
    showDialog,
    jawaban,
    status_publish,
  } = useMasterFaqContext().state;
  const {
    actionFormDialog,
    setFormPertanyaan,
    setFormJawaban,
    setShowDialog,
    setStatusPublish,
  } = useMasterFaqContext();

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {formDialog} Faq
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialog(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Pertanyaan
                    </label>
                    <textarea
                      rows={5}
                      className="form-control
                      col-span-4
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={formPertanyaan}
                      onChange={(e) => setFormPertanyaan(e.target.value)}
                      placeholder="Masukkan Petunjuk Soal"
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Jawaban
                    </label>
                    <Editor
                      initialValue={`${jawaban != "" ? jawaban : ""}`}
                      apiKey={env.REACT_APP_TINYMCE_API_KEY}
                      init={{
                        width: 460,
                        height: 180,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help | image",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(a) => setFormJawaban(a)}
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Publish
                    </label>
                    <Switch
                      className="ml-2 mt-2"
                      height={20}
                      onChange={() => setStatusPublish(!status_publish)}
                      checked={status_publish}
                    />
                  </div>
                </div>

                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialog(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => actionFormDialog()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, formLoading } = useMasterFaqContext().state;
  const { setShowDialogDelete, deleteFaq } = useMasterFaqContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Faq
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteFaq()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const {
    getFaq,
    searchFaq,
    setPageRow,
    setFormDialog,
    setShowDialog,
    setUpdate,
    setShowDialogDelete,
  } = useMasterFaqContext();
  const { dataFaq, isLoading, keyword } = useMasterFaqContext().state;
  const [perPage, setPerPage] = useState(10);
  const [filterText] = React.useState("");
  const [resetPaginationToggle] = React.useState(false);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Pertanyaan",
      selector: (row) => row.pertanyaan,
    },
    {
      name: "Status Publish",
      selector: (row) => {
        return row.status_publish === 1 ? (
          <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
            Publish
          </span>
        ) : (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
            Unpublished
          </span>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <button
              className="ml-2 px-2 py-1 text-xs text-green-700 bg-green-100 hover:bg-green-200 rounded-full"
              onClick={() => {
                setFormDialog("Ubah");
                setShowDialog(true);
                setUpdate(row);
              }}
            >
              Ubah Data
            </button>
            <button
              className="ml-2 px-2 py-1 text-xs text-red-700 bg-red-100 hover:bg-red-200 rounded-full"
              onClick={() => {
                setShowDialogDelete(true);
                setUpdate(row);
              }}
            >
              Hapus Data
            </button>
          </div>
        );
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getFaq(1, "", 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page) => {
    getFaq(page, keyword, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getFaq(page, keyword, newPerPage);
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-row justify-between w-full items-center">
        <p className="font-semibold text-base">Faq</p>
        <div className="flex flex-wrap">
          <div className="input-group relative flex flex-wrap mr-2">
            <button
              className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              style={{ height: "38px" }}
            >
              <BsSearch />
            </button>
            <input
              type="search"
              className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
              placeholder="Search"
              onChange={(event) => searchFaq(event.target.value)}
            />
          </div>
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs rounded flex flex-row items-center hover:bg-green-500"
            style={{ height: "38px" }}
            onClick={() => {
              setFormDialog("Tambah");
              setShowDialog(true);
            }}
          >
            Tambah Baru <AiFillPlusCircle className="ml-3" />
          </button>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle]);

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"green"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  return (
    <SidebarWithHeader title="Master Faq">
      <Box className="app">
        <ModalCreateData />
        <ModalDelete />
        <Box as="main" style={{ padding: "0px" }}>
          <Box className="container">
            <Box bg="white" p={5} borderRadius="lg" mb={5} shadow="lg">
              <DataTable
                columns={columns}
                data={dataFaq}
                progressPending={isLoading}
                pagination
                paginationServer
                paginationTotalRows={
                  dataFaq.length > 0 ? dataFaq[0].jumlahData : 0
                }
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                progressComponent={<CustomLoader />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const MasterFaq: React.FC = () => {
  return (
    <MasterFaqController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </MasterFaqController.Provider>
  );
};

export default MasterFaq;
