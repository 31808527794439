import React, { useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "videojs-youtube";

const VideoPlayer = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady, nonActiveProgressBar = false } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video");
      videoElement.setAttribute("class", "video-js vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);

        // Disable tab navigation for all control bar elements
        const controlBar = player.controlBar.el();
        const controlBarElements = controlBar.querySelectorAll("*");
        controlBarElements.forEach((element) => {
          element.setAttribute("tabindex", "-1");
        });
      }));
    } else {
      const player = playerRef.current;

      player.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, onReady]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player={true} ref={videoRef} style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          backgroundImage: "rgb(0,0,0,0)",
          top: "0px",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      ></div>
      {nonActiveProgressBar && (
        <style>
          {`
          .vjs-progress-control {
            pointer-events: none;
          }
        `}
        </style>
      )}
    </div>
  );
};

export default VideoPlayer;
