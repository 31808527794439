export const resizeImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = document.createElement("img");
      if (typeof event.target?.result === "string") {
        img.src = event.target.result;

        img.onload = () => {
          const ImageWidth = 300;
          const ImageHeight = 450;

          const canvas = document.createElement("canvas");
          canvas.width = ImageWidth;
          canvas.height = ImageHeight;

          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(img, 0, 0, ImageWidth, ImageHeight);
            const resizedDataUrl = canvas.toDataURL(file.type);

            // Create Blob from data URL
            const blob = dataURLtoBlob(resizedDataUrl);

            const fileInfo = new File([blob], file.name, {
              lastModified: file.lastModified,
              type: file.type,
            });

            resolve(fileInfo);
          }
        };
      }
    };
    reader.readAsDataURL(file);
  });
};

const dataURLtoBlob = (dataURL) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
