import React from 'react'
import { container } from 'tsyringe'
import { KabupatenPresenter } from '../../../../../data/presenters/KabupatenPresenter'
import { Kabupaten } from "../../../../../entities/Kabupaten";

interface IState {
  isLoading: boolean
  kabupaten: Kabupaten[]
  id: number
  page: number
  keyword: string
  error: boolean
  msgError: string
}

interface InitialState {
  state: IState
  getKabupatenById: Function
  getAllKabupaten: Function
  onChangeProvinsi: Function
}

const initialState = {
  state: {
    isLoading: false,
    kabupaten: [],
    id: 0,
    page: 1,
    keyword: "",
    error: false,
    msgError: '',
  },
  getKabupatenById: () => {},
  getAllKabupaten: () => {},
  onChangeProvinsi: () => {}
}
const Context = React.createContext<InitialState>(initialState)
const { Provider: KabupatenProvider } = Context

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    kabupaten: [],
    id: 0,
    page: 1,
    keyword: "",
    error: false,
    msgError: '',
  })

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }))
  }

  // Get Data Kabupaten
  const getAllKabupaten = async () => {
    setLoading(true);

    try {
        const kabupatenPresenter = container.resolve(KabupatenPresenter);
        const get = await kabupatenPresenter.getAllKabupaten(state.page,999,state.keyword);
       
        const data = get;
  
        setState((prevstate) => ({
          ...prevstate,
          kabupaten: data,
        }));
  
        setLoading(false);
        
      } catch (error : any) {
        console.log("error data kabupaten:", error);
        setLoading(false);
      }
  };

  // Get Data Kabupaten By ID
  const getKabupatenById = async (id: number) => {
    setLoading(true);

    try {
      const kabupatenPresenter = container.resolve(KabupatenPresenter);
        const get = await kabupatenPresenter.getKabupatenById(id);
       
        const data = get;
  
        setState((prevstate) => ({
          ...prevstate,
          kabupaten: data,
        }));
  
        setLoading(false);
    } catch (error: any) {
      console.log("error data kabupaten:", error);
        setLoading(false);
    }
  }
  
  // Handle On Change Provinsi
  const onChangeProvinsi = (value) => {
    getKabupatenById(value);
  }

  return (
    <KabupatenProvider
      value={{
        state,
        getKabupatenById,
        getAllKabupaten,
        onChangeProvinsi
      }}
    >
      {children}
    </KabupatenProvider>
  )
}

export const useKabupatenContext = () => React.useContext(Context)
// eslint-disable-next-line
export default {
  useKabupatenContext,
  Provider,
}
