/**
 * The PodcastPlayerDetail component is used to display podcast details
 * and control audio playback.
 */

import React, { useState } from "react";
import {
  Slide,
  Box,
  Icon,
  Text,
  Flex,
  Image,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  Divider,
} from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import { usePodcastContext } from "../../controller/podcast";
import { ReactComponent as NextPodcastIcon } from "../../assets/svg/ic_next_podcast.svg";
import { ReactComponent as BackPodcastIcon } from "../../assets/svg/ic_back_podcast.svg";
import { BsShare } from "react-icons/bs";
import DownloadPodcast from "./DownloadPodcast";

/**
 * PodcastPlayerDetail component.
 * @component
 * @returns {React.FC} The PodcastPlayerDetail component.
 * @author Bagas Prasetyadi
 */

const PodcastPlayerDetail: React.FC = () => {
  const url = window.location.href;
  const [toogleAction, settoogleAction] = useState<boolean>(false);

  const {
    setSeeDetail,
    handlePodcastPlayer,
    renderPlayPauseIcon,
    audioRef,
    setIsPlaying,
    getTime,
    setSeekTime,
    nextPodcast,
    handleBackNextPodcast,
    handleShare,
  } = usePodcastContext();
  const { seeDetail, currentTrack, isPlaying, sliderValue, duration } =
    usePodcastContext().state;

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);

    if (audioRef?.current) {
      if (isPlaying) {
        audioRef?.current.pause();
      } else {
        audioRef?.current.play();
      }
    }
  };

  const truncateTitle = (text: string) => {
    if (text.length > 50) {
      return text.slice(0, 50) + "...";
    }
    return text;
  };

  return (
    <Slide direction="bottom" in={seeDetail} style={{ zIndex: 99 }}>
      <Box h="100vh" bg="white" overflowY="auto">
        <Box p={8}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Icon
                as={IoMdClose}
                boxSize={10}
                mr={2}
                onClick={() => {
                  setSeeDetail(false);
                  handlePodcastPlayer(true);
                }}
              />
              <Text fontSize="xl" color="black" fontWeight="semibold">
                Tutup
              </Text>
            </Box>
            <Box position="relative">
              <Icon
                as={BsThreeDots}
                boxSize={8}
                onClick={() => settoogleAction(!toogleAction)}
              />
              {currentTrack && toogleAction && (
                <Box
                  position="absolute"
                  boxShadow="sm"
                  borderRadius="md"
                  p={4}
                  top={5}
                  right={1}
                >
                  <Box mb={3}>
                    <Icon
                      as={BsShare}
                      boxSize={5}
                      color="black"
                      _hover={{ cursor: "pointer" }}
                      onClick={() => {
                        handleShare(`${url}?track=${currentTrack.id}`);
                      }}
                    />
                  </Box>
                  <DownloadPodcast
                    title={currentTrack?.title}
                    audio={currentTrack.audio}
                  />
                </Box>
              )}
            </Box>
          </Flex>

          <Flex
            my={16}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={currentTrack?.thumbnail}
              alt="thumbnail podcast"
              backgroundSize="cover"
              w="200px"
              h="200px"
              rounded="md"
              my={8}
            />
            <Box mt={2} w="80%">
              <Text fontSize="xl" fontWeight="semibold" color="black">
                {currentTrack?.title}
              </Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: currentTrack?.description ?? "",
                }}
              />
            </Box>

            <Flex mt={16} justifyContent="center" alignItems="center">
              <Icon
                as={BackPodcastIcon}
                mr={10}
                boxSize={14}
                color="black"
                onClick={() => handleBackNextPodcast("back")}
                _hover={{ cursor: "pointer" }}
              />
              <Icon
                as={renderPlayPauseIcon(currentTrack)}
                mr={10}
                boxSize={16}
                color="black"
                onClick={handlePlayPause}
                _hover={{ cursor: "pointer" }}
              />
              <Icon
                as={NextPodcastIcon}
                boxSize={14}
                color="black"
                onClick={() => handleBackNextPodcast("next")}
                _hover={{ cursor: "pointer" }}
              />
            </Flex>

            <Box display="flex" mt={8} w="90%">
              <Text fontSize="sm" mr={3}>
                {getTime(sliderValue)}
              </Text>
              <Slider
                w="full"
                aria-label="progress"
                value={sliderValue}
                min={0}
                max={duration}
                colorScheme="green"
                mr={2}
                onChange={(value) => setSeekTime(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <Text fontSize="sm">{getTime(duration)}</Text>
            </Box>
          </Flex>

          <Divider my={8} />

          <Box>
            <Text fontSize="xl" fontWeight="semibold">
              Berikutnya
            </Text>
            <Text fontSize="xl" fontWeight="semibold" color="black">
              {nextPodcast?.title}
            </Text>
            <div
              dangerouslySetInnerHTML={{
                __html: nextPodcast?.description
                  ? truncateTitle(nextPodcast?.description)
                  : "",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};

export default PodcastPlayerDetail;
