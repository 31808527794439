/**
 * A custom box upload file component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setFile - Function to set the selected file.
 * @param {"image" | "video" | "audio"  | "pdf" | "ppt} props.typeFile - Type of the file to be uploaded. Can be "image", "video", or "audio".
 */

import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Icon, Image, Input } from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
interface BoxUploadFileProps {
  setFile: Function;
  typeFile: "image" | "video" | "audio" | "pdf" | "ppt";
  defaultFile: any;
  mode?: string;
  nameFile?: string;
  isReadOnly?: boolean;
}

const MAX_FILE_SIZE_IMAGE = 2 * 1024 * 1024;
const MAX_FILE_SIZE_VIDEO = 500 * 1024 * 1024;
const MAX_FILE_SIZE_Audio = 100 * 1024 * 1024;
const MAX_FILE_SIZE_PDF = 2 * 1024 * 1024;
const MAX_FILE_SIZE_PPT = 2 * 1024 * 1024;

/**
 * BoxUploadFile component.
 * @component
 * @author Bagas Prasetyadi
 */

const BoxUploadFile = ({
  setFile,
  typeFile,
  defaultFile,
  mode = "",
  nameFile = "",
  isReadOnly = false,
}: BoxUploadFileProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isChangePPT, setIsChangePPT] = useState<boolean>(false);

  const fileTypeInfo = {
    image: {
      maxFileSize: MAX_FILE_SIZE_IMAGE,
      sizeFileText: "2MB",
      formatText: "JPG, JPEG, dan PNG",
      formatFile: [".jpg", ".jpeg", ".png"],
      htmlForLabel: "fileInputImage",
      labelType: "gambar",
    },
    video: {
      maxFileSize: MAX_FILE_SIZE_VIDEO,
      sizeFileText: "500MB",
      formatText: "Mp4",
      formatFile: ["video/*"],
      htmlForLabel: "fileInputVideo",
      labelType: "video",
    },
    audio: {
      maxFileSize: MAX_FILE_SIZE_Audio,
      sizeFileText: "100MB",
      formatText: "Mp3",
      formatFile: [".mp3"],
      htmlForLabel: "fileInputAudio",
      labelType: "audio",
    },
    pdf: {
      maxFileSize: MAX_FILE_SIZE_PDF,
      sizeFileText: "2MB",
      formatText: "PDF",
      formatFile: [".pdf"],
      htmlForLabel: "fileInputPDF",
      labelType: "pdf",
    },
    ppt: {
      maxFileSize: MAX_FILE_SIZE_PPT,
      sizeFileText: "2MB",
      formatText: "PPT",
      formatFile: [".pptx"],
      htmlForLabel: "fileInputPPT",
      labelType: "ppt",
    },
  };

  const fileInfo = fileTypeInfo[typeFile];

  const maxFileSize = fileInfo.maxFileSize;
  const sizeFileText = fileInfo.sizeFileText;
  const formatText = fileInfo.formatText;
  const formatFile = fileInfo.formatFile.join(", ");
  const htmlForLabel = fileInfo.htmlForLabel;
  const labelType = fileInfo.labelType;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (mode === "edit" && typeFile === "ppt") {
      setIsChangePPT(true);
    }

    const file = event.target.files?.[0] || null;

    if (file) {
      if (file.size > maxFileSize) {
        setError(`Ukuran file melebihi batas maksimal ${sizeFileText}.`);
        setSelectedFile(null);
        setFile(nameFile || typeFile);
        setPreview(null);
      } else {
        setError(null);
        setSelectedFile(file);
        setFile(nameFile || typeFile, file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0] || null;

    if (file) {
      if (!file.type.includes(typeFile)) {
        setError(`Format file tidak didukung. Hanya format ${formatText}.`);
        setSelectedFile(null);
        setFile(null);
        setPreview(null);
      } else if (file.size > maxFileSize) {
        setError(`Ukuran file melebihi batas maksimal ${sizeFileText}.`);
        setSelectedFile(null);
        setFile(null);
        setPreview(null);
      } else {
        setError(null);
        setSelectedFile(file);
        setFile(nameFile || typeFile, file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDelete = () => {
    setSelectedFile(null);
    setPreview(null);
    setError(null);
    setFile(nameFile || typeFile, null);
  };

  useEffect(() => {
    setPreview(defaultFile);
  }, [defaultFile]);

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      padding="4"
      textAlign="center"
      bg="#F6F5F8"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      border="1px"
      borderStyle="dashed"
      borderColor="gray.500"
    >
      {error && <Text color="red">{error}</Text>}

      <Input
        type="file"
        id={htmlForLabel}
        onChange={handleFileChange}
        accept={formatFile}
        style={{ display: "none" }}
      />

      {preview ? (
        <Box>
          <Flex justifyContent="space-between">
            {typeFile === "image" && (
              <Image
                src={preview}
                alt="Preview"
                w="full"
                maxH="300px"
                objectFit="cover"
              />
            )}

            {typeFile === "video" && (
              <video
                className="video-js"
                controls
                preload="auto"
                data-setup="{}"
                width={isReadOnly ? "100%" : "95%"}
                style={{
                  height: "100%",
                }}
              >
                <source src={preview} type="video/mp4"></source>
                <p className="vjs-no-js">
                  To view this video please enable JavaScript, and consider
                  upgrading to a web browser that
                  <a
                    href="https://videojs.com/html5-video-support/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    supports HTML5 video
                  </a>
                </p>
              </video>
            )}

            {typeFile === "audio" && (
              <audio controls style={{ width: "95%" }}>
                <source src={preview ?? ""} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}

            {typeFile === "pdf" && (
              <iframe
                title="PDF Preview"
                src={preview ?? ""}
                width={isReadOnly ? "100%" : "95%"}
                style={{
                  height: "400px",
                  border: "none",
                }}
              />
            )}

            {mode === "edit" && typeFile === "ppt" && !isChangePPT && (
              <iframe
                title="ppt review"
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${preview}`}
                width="95%"
                height="400px"
                style={{ borderRadius: "6px" }}
              />
            )}

            <Box>
              {!isReadOnly && (
                <Icon
                  as={FiTrash2}
                  color="#EB5757"
                  ml={2}
                  _hover={{ cursor: "pointer" }}
                  onClick={handleDelete}
                />
              )}
            </Box>
          </Flex>
          <Text fontWeight="semibold" mt={2}>
            {selectedFile?.name}
          </Text>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M14.9396 11.9992C14.7148 11.9995 14.4929 12.0503 14.2904 12.148C14.0879 12.2456 13.9099 12.3875 13.7696 12.5632L4.61959 23.9992H17.9996C18.3974 23.9992 18.7789 24.1572 19.0602 24.4385C19.3416 24.7198 19.4996 25.1014 19.4996 25.4992C19.4996 26.6926 19.9737 27.8372 20.8176 28.6812C21.6615 29.5251 22.8061 29.9992 23.9996 29.9992C25.1931 29.9992 26.3377 29.5251 27.1816 28.6812C28.0255 27.8372 28.4996 26.6926 28.4996 25.4992C28.4996 25.1014 28.6576 24.7198 28.9389 24.4385C29.2202 24.1572 29.6018 23.9992 29.9996 23.9992H43.3796L34.2296 12.5632C34.0893 12.3875 33.9113 12.2456 33.7088 12.148C33.5063 12.0503 33.2844 11.9995 33.0596 11.9992H14.9396ZM44.8016 26.9992H31.3496C31.0053 28.6946 30.0855 30.2189 28.746 31.3137C27.4065 32.4086 25.7296 33.0067 23.9996 33.0067C22.2696 33.0067 20.5927 32.4086 19.2532 31.3137C17.9137 30.2189 16.9939 28.6946 16.6496 26.9992H3.19759L4.15759 34.6852C4.20298 35.0484 4.37963 35.3825 4.65426 35.6245C4.92888 35.8666 5.28253 35.9998 5.64859 35.9992H42.3506C42.7161 35.9991 43.0691 35.8655 43.3431 35.6235C43.6171 35.3816 43.7933 35.0479 43.8386 34.6852L44.7986 26.9992H44.8016ZM11.4266 10.6882C11.8484 10.1609 12.3834 9.7353 12.9921 9.44292C13.6007 9.15054 14.2674 8.99888 14.9426 8.99918H33.0566C33.7318 8.99888 34.3985 9.15054 35.0071 9.44292C35.6158 9.7353 36.1508 10.1609 36.5726 10.6882L47.6726 24.5632C47.7973 24.7197 47.8894 24.8996 47.9435 25.0923C47.9976 25.285 48.0126 25.4866 47.9876 25.6852L46.8176 35.0572C46.6815 36.1458 46.1525 37.1473 45.3298 37.8732C44.5072 38.5992 43.4477 38.9996 42.3506 38.9992H5.64859C4.55146 38.9996 3.49196 38.5992 2.66934 37.8732C1.84671 37.1473 1.31763 36.1458 1.18159 35.0572L0.0115904 25.6852C-0.0129984 25.4864 0.00247168 25.2847 0.0570879 25.092C0.111704 24.8993 0.20436 24.7195 0.32959 24.5632L11.4296 10.6882H11.4266Z"
              fill="#0DBD7F"
            />
          </svg>

          <label htmlFor={htmlForLabel}>
            <Text
              fontSize="sm"
              color="black"
              mt={2}
              _hover={{ cursor: "pointer" }}
            >
              Klik atau tarik file ke area ini
            </Text>
          </label>

          <Text fontSize="sm" lineHeight="5" color="#6C757D">
            Hanya bisa satu {labelType}. Maksimal ukuran file {sizeFileText}.
            <br />
            Format {formatText}.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default BoxUploadFile;
