export class PathMateri {
  id: number
  materi_id: number
  path_materi: string
  jenis_materi: string
  metode: number

  constructor(
    id: number,
    materi_id: number,
    path_materi: string,
    jenis_materi: string,
    metode: number,
  ) {
    this.id = id
    this.materi_id = materi_id
    this.path_materi = path_materi
    this.jenis_materi = jenis_materi
    this.metode = metode
  }
}
