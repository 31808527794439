/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { BsSearch, BsFillPlusCircleFill } from "react-icons/bs";
import { AiOutlineClockCircle, AiOutlineStar } from "react-icons/ai";
import Switch from "react-switch";
import ReactPaginate from "react-paginate";
import ManajemenMateriController, {
  useManajemenMateriContext,
} from "../../../../controller/admin/manajemen_materi/index";
import { If, Then, Else } from "react-if";
import Shimmer from "react-shimmer-effect";
import { Materi } from "../../../../../../entities/Materi";
import Select from "react-select";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import { Box, Grid } from "@chakra-ui/react";

const BottomHeader: React.FC = () => {
  const {
    jenisPelatihan,
    checkAll,
    checkVideo,
    checkPDF,
    checkPPT,
    checkAudio,
  } = useManajemenMateriContext().state;
  const {
    searchMateri,
    filterJenisPelatihan,
    filterJenisMateri,
    filterSemuaJenisMateri,
  } = useManajemenMateriContext();

  let optionsJenisPelatihan;

  if (jenisPelatihan.length > 0) {
    optionsJenisPelatihan = jenisPelatihan.map((item) => ({
      value: item.id,
      label: item.nama_pelatihan,
    }));
  } else {
    optionsJenisPelatihan = [];
  }

  return (
    <div>
      <div className="w-full bg-white flex flex-wrap px-5 py-2">
        <div
          className="mr-4 mt-2 flex flex-wrap justify-between border border-gray-300 rounded px-2 py-2"
          style={{ width: "177px" }}
        >
          <a>Semua</a>
          <input
            type="checkbox"
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-gren-600 checked:border-gren-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            onChange={() => filterSemuaJenisMateri(!checkAll)}
          />
        </div>
        <div
          className="mr-4 mt-2 flex flex-wrap justify-between border border-gray-300 rounded px-2 py-2"
          style={{ width: "177px" }}
        >
          <a>Video</a>
          <input
            type="checkbox"
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-gren-600 checked:border-gren-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            onChange={(e) => filterJenisMateri(!checkVideo, "video")}
            checked={checkVideo}
          />
        </div>
        <div
          className="mr-4 mt-2 flex flex-wrap justify-between border border-gray-300 rounded px-2 py-2"
          style={{ width: "177px" }}
        >
          <a>PDF</a>
          <input
            type="checkbox"
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-gren-600 checked:border-gren-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            onChange={(e) => filterJenisMateri(!checkPDF, "pdf")}
            checked={checkPDF}
          />
        </div>
        <div
          className="mr-4 mt-2 flex flex-wrap justify-between border border-gray-300 rounded px-2 py-2"
          style={{ width: "177px" }}
        >
          <a>PPT</a>
          <input
            type="checkbox"
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-gren-600 checked:border-gren-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            onChange={(e) => filterJenisMateri(!checkPPT, "ppt")}
            checked={checkPPT}
          />
        </div>
        <div
          className="mt-2 flex flex-wrap justify-between border border-gray-300 rounded px-2 py-2"
          style={{ width: "177px" }}
        >
          <a>Audio</a>
          <input
            type="checkbox"
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-gren-600 checked:border-gren-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            onChange={(e) => filterJenisMateri(!checkAudio, "audio")}
            checked={checkAudio}
          />
        </div>
      </div>
      <hr />
      <div className="w-full bg-white grid grid-cols-1 lg:grid-cols-2">
        <div className="flex flex-wrap items-center ">
          <div className="ml-5 w-full grid grid-cols-2 gap-4">
            <Select
              className="w-full"
              options={optionsJenisPelatihan}
              onChange={(val: any) => filterJenisPelatihan(val.value)}
              placeholder="Nama Pelatihan (Jenis Pelatihan)"
            />

            {/* Clear Filter */}
            <button
              onClick={() => filterJenisPelatihan("")}
              className="px-4 py-2.5 rounded border border-green-300 text-green-500 font-semibold text-xs flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease hover:bg-green-600 hover:text-white"
            >
              Clear Filter
            </button>
          </div>
        </div>
        <div className="flex flex-wrap items-center mr-5 ml-5 justify-self-end">
          <div className="input-group relative flex flex-wrap mt-6 mr-2">
            <button
              className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              style={{ height: "38px" }}
            >
              <BsSearch />
            </button>
            <input
              type="search"
              className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
              placeholder="Search"
              onChange={(e) => searchMateri(e.target.value)}
            />
          </div>
          <a
            href="/admin/tambah/manajemen_materi"
            className="flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
          >
            Tambah Baru
            <BsFillPlusCircleFill
              className="ml-2"
              style={{ marginTop: "2px" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const Pagination = () => {
  const { setPage } = useManajemenMateriContext();
  const { jumlahPage } = useManajemenMateriContext().state;

  let endFix =
    jumlahPage > Number(jumlahPage.toFixed())
      ? Number(jumlahPage.toFixed()) + 1
      : Number(jumlahPage.toFixed());

  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  return (
    <If condition={endFix > 1}>
      <Then>
        <div className="flex justify-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Selanjutnya"
            activeClassName="active"
            containerClassName="pagination"
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            pageCount={
              jumlahPage > Number(jumlahPage.toFixed())
                ? Number(jumlahPage.toFixed()) + 1
                : Number(jumlahPage.toFixed())
            }
            previousLabel="Sebelumnya"
          />
        </div>
      </Then>
      <Else>
        <></>
      </Else>
    </If>
  );
};

const ItemMateri = ({ materi }: { materi: Materi }) => {
  return (
    <div className="sm:col-span-3 md:col-span-2 lg:col-span-1  w-full">
      <div
        className="rounded-lg bg-white hover:shadow-lg transition-all duration-300"
        style={{ height: "250px" }}
      >
        <div className="relative">
          <img
            className="z-10 rounded-t-lg w-full"
            src={materi.path_thumbnail}
            alt=""
            style={{ height: "119px", objectFit: "cover" }}
          />
        </div>
        <div className="px-6 pb-6 pt-3">
          <a
            className="text-gray-900 text-base font-medium mb-2"
            href={`/admin/detail/manajemen_materi/${materi.id}`}
          >
            {materi.judul_materi.length > 30
              ? materi.judul_materi.substring(0, 30) + "..."
              : materi.judul_materi}
          </a>
          {/* Count Tanggal Ditambahkan */}
          <div className="flex flex-rows mt-2">
            <AiOutlineClockCircle
              className="mr-3 text-md"
              style={{ marginTop: "4px" }}
            />
            <p className="text-sm">{materi.tanggal_pembuatan}</p>
          </div>

          {/* Deskripsi */}
          {/* <p className="mt-3 text-xs">{parse(materi.uraian_deskripsi)}</p> */}

          {/* Preview & Stars */}
          <div className="flex justify-between mt-3">
            <div className="flex flex-rows mt-3n">
              <div className="flex flex-rows">
                <AiOutlineStar className="mr-2 text-sm text-yellow-400 " />
                <p className="text-xs">{materi.rating}</p>
              </div>
            </div>
            <div>
              <Switch
                className="ml-2"
                height={20}
                onChange={() => {}}
                checked={materi.status_publish == 1 ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  const { getMateri, getJenisPelatihan } = useManajemenMateriContext();
  const { materi, isLoading } = useManajemenMateriContext().state;

  useEffect(() => {
    getMateri(1, "");
    getJenisPelatihan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarWithHeader title="Manajemen Materi">
      <Box className="app">
        <Box as="main" style={{ padding: "0px" }}>
          <Box mb={4}>
            <BottomHeader />
          </Box>
          <Box className="container" mb={10}>
            <Grid
              templateColumns={{
                base: "1fr",
                sm: "repeat(1, 1fr)",
                md: "repeat(4, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={4}
            >
              <If condition={isLoading}>
                <Then>
                  {[1, 2, 3, 4, 5, 6].map((i) => {
                    return (
                      <Shimmer key={i}>
                        <Box
                          pl={2}
                          mt={2}
                          w="full"
                          borderRadius="lg"
                          mx={3}
                          h="100px"
                        ></Box>
                      </Shimmer>
                    );
                  })}
                </Then>
                <Else>
                  {materi.map((m: Materi) => {
                    return <ItemMateri materi={m} />;
                  })}
                </Else>
              </If>
            </Grid>
            <Pagination />
          </Box>
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const ManajemenMateri: React.FC = () => {
  return (
    <ManajemenMateriController.Provider>
      <WrapperMain />
    </ManajemenMateriController.Provider>
  );
};

export default ManajemenMateri;
