import { injectable } from "tsyringe";
import { LandingDataApiRepository } from "../../app/repository/api/LandingDataRepository";

@injectable()
export class LandingDataPresenter {
  private repository: LandingDataApiRepository;
  constructor(repository: LandingDataApiRepository) {
    this.repository = repository;
  }

  public getAllDataLanding(): Promise<any> {
    return this.repository.getAllDataLanding();
  }
}
