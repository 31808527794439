export class Dashboard {
  peserta_pertahun: any;
  total_pelatihan: number;
  total_pelatihan_on_progress: number;
  total_pelatihan_finish: number;
  total_pengguna: number;
  total_pengguna_online: number;
  total_peserta: number;
  total_peserta_lulus: number;
  total_peserta_tidak_lulus: number;
  total_peserta_predikat_sangat_kompeten: number;
  total_peserta_predikat_kompeten: number;
  total_peserta_predikat_cukup_kompeten: number;
  total_peserta_predikat_tidak_lulus: number;
  peserta_perjenis_pelatihan: any;
  pengguna_aktif_perhari: any;
  total_asn: number;
  total_non_asn: number;

  constructor(
    peserta_pertahun: any,
    total_pelatihan: number,
    total_pelatihan_on_progress: number,
    total_pelatihan_finish: number,
    total_pengguna: number,
    total_pengguna_online: number,
    total_peserta: number,
    total_peserta_lulus: number,
    total_peserta_tidak_lulus: number,
    total_peserta_predikat_sangat_kompeten: number,
    total_peserta_predikat_kompeten: number,
    total_peserta_predikat_cukup_kompeten: number,
    total_peserta_predikat_tidak_lulus: number,
    peserta_perjenis_pelatihan: any,
    pengguna_aktif_perhari: any,
    total_asn: number,
    total_non_asn
  ) {
    this.peserta_pertahun = peserta_pertahun;
    this.total_pelatihan = total_pelatihan;
    this.total_pelatihan_on_progress = total_pelatihan_on_progress;
    this.total_pelatihan_finish = total_pelatihan_finish;
    this.total_pengguna = total_pengguna;
    this.total_pengguna_online = total_pengguna_online;
    this.total_peserta = total_peserta;
    this.total_peserta_lulus = total_peserta_lulus;
    this.total_peserta_tidak_lulus = total_peserta_tidak_lulus;
    this.total_peserta_predikat_sangat_kompeten =
      total_peserta_predikat_sangat_kompeten;
    this.total_peserta_predikat_kompeten = total_peserta_predikat_kompeten;
    this.total_peserta_predikat_cukup_kompeten =
      total_peserta_predikat_cukup_kompeten;
    this.total_peserta_predikat_tidak_lulus =
      total_peserta_predikat_tidak_lulus;
    this.peserta_perjenis_pelatihan = peserta_perjenis_pelatihan;
    this.pengguna_aktif_perhari = pengguna_aktif_perhari;
    this.total_asn = total_asn;
    this.total_non_asn = total_non_asn;
  }
}

export class PesertaPertahun {
  tahun: string;
  jumlah_peserta: number;

  constructor(tahun: string, jumlah_peserta: number) {
    this.tahun = tahun;
    this.jumlah_peserta = jumlah_peserta;
  }
}
