import { useToast } from "@chakra-ui/react";
import React, { createContext, useState, useContext } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface IState {
  state: {
    url: string;
    isLoading: boolean;
    listData: [];
    search: string;
    page: number;
    perPage: number;
    totalListData: number;
    isLoadingDownloadRapot: { [key: string]: boolean };
  };
  dispatch: {
    getRiwayatRapot: Function;
    setRiwayatRapotState: Function;
    downloadRiwayatRapot: Function;
  };
}

const initialState: IState = {
  state: {
    url: "/management_content/pengetahuan_saya/list/raport/downloaded",
    isLoading: false,
    listData: [],
    search: "",
    page: 1,
    perPage: 10,
    totalListData: 0,
    isLoadingDownloadRapot: {},
  },
  dispatch: {
    getRiwayatRapot: () => {},
    setRiwayatRapotState: () => {},
    downloadRiwayatRapot: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: PengetahuanRiwayatRapotProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const toast = useToast();

  const { url, search, page, perPage } = state.state;

  const setRiwayatRapotState = (key: keyof IState["state"], value: any) => {
    setState((prev) => ({ ...prev, state: { ...prev.state, [key]: value } }));
  };

  const getRiwayatRapot = async () => {
    setRiwayatRapotState("isLoading", true);

    try {
      const response = await axiosInstance.get(url, {
        params: {
          search,
          page,
          per_page: perPage,
        },
      });

      setRiwayatRapotState("listData", response.data.data?.data);
      setRiwayatRapotState("totalListData", response.data.data?.total);
    } catch (error) {
      throw error;
    } finally {
      setRiwayatRapotState("isLoading", false);
    }
  };

  const downloadRiwayatRapot = async (urlDownload: string, rowId: string) => {
    setRiwayatRapotState("isLoadingDownloadRapot", {
      ...state.state.isLoadingDownloadRapot,
      [rowId]: true,
    });

    const startIndex = urlDownload.indexOf("management_content");
    const urlDownloadUpdated = urlDownload.substring(startIndex);

    try {
      const response = await axiosInstance.get(urlDownloadUpdated, {
        responseType: "blob",
      });

      // Construct the filename from the content-disposition header
      const filename = response.headers["content-disposition"];

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", filename);

      document.body.appendChild(link);

      link.click();

      link.parentNode?.removeChild(link);

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw error;
    } finally {
      setRiwayatRapotState("isLoadingDownloadRapot", {
        ...state.state.isLoadingDownloadRapot,
        [rowId]: false,
      });
    }
  };

  return (
    <PengetahuanRiwayatRapotProvider
      value={{
        state: state.state,
        dispatch: {
          getRiwayatRapot,
          setRiwayatRapotState,
          downloadRiwayatRapot,
        },
      }}
    >
      {children}
    </PengetahuanRiwayatRapotProvider>
  );
};

export const usePengetahuanRiwayatRapotContext = () => useContext(Context);
const PengetahuanRiwayatRapotContext = {
  usePengetahuanRiwayatRapotContext,
  Provider,
};

export default PengetahuanRiwayatRapotContext;
