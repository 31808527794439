import { useToast } from "@chakra-ui/react";
import React, { createContext, useState, useContext } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface IState {
  state: {
    url: string;
    isLoadingDownload: boolean;
    fileAssesment: File | null;
    isLoadingUpload: boolean;
    isOpenModalUpload: boolean;
    modeModalAssessment: "Upload" | "Edit";
  };
  dispatch: {
    downloadAssesment: Function;
    setPengetahuanAsessmentState: Function;
    uploadAssessment: Function;
  };
}

const initialState: IState = {
  state: {
    url: "/management_content/pengetahuan_saya/assessment/",
    isLoadingDownload: false,
    fileAssesment: null,
    isLoadingUpload: false,
    isOpenModalUpload: false,
    modeModalAssessment: "Upload",
  },
  dispatch: {
    downloadAssesment: () => {},
    setPengetahuanAsessmentState: () => {},
    uploadAssessment: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: PengetahuanAsessmentProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const toast = useToast();

  const { url, fileAssesment, modeModalAssessment } = state.state;

  const setPengetahuanAsessmentState = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prev) => ({ ...prev, state: { ...prev.state, [key]: value } }));
  };

  const setDateDownload = async (pengetahuanId) => {
    const formData = new FormData();
    formData.append("pengetahuan_id", pengetahuanId);

    try {
      const response = await axiosInstance.post(
        url + "setTanggalDownload",
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const downloadAssesment = async (
    pengetahuanId: string,
    title,
    downloadDate,
    type
  ) => {
    setPengetahuanAsessmentState("isLoadingDownload", true);

    try {
      if (!downloadDate && type === "asynchronized") {
        await setDateDownload(pengetahuanId);
      }

      const response = await axiosInstance.get(url + pengetahuanId, {
        responseType: "blob", // Ini penting untuk mendapatkan response sebagai Blob
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = urlBlob;

      let fileName = `${title}.zip`; // Default filename

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw error;
    } finally {
      setPengetahuanAsessmentState("isLoadingDownload", false);
    }
  };

  const uploadAssessment = async (pengetahuanId: string) => {
    setPengetahuanAsessmentState("isLoadingUpload", true);

    const formData = new FormData();
    formData.append("pengetahuan_id", pengetahuanId);
    formData.append("path_file_assessment", fileAssesment as File);

    const urlUpload =
      modeModalAssessment === "Upload" ? url + "upload" : url + "update";

    try {
      const response = await axiosInstance.post(urlUpload, formData);

      setPengetahuanAsessmentState("isOpenModalUpload", false);
      setPengetahuanAsessmentState("fileAssesment", null);

      return response.data;
    } catch (error) {
      toast({
        title:
          error?.response?.data?.message ?? "Error saat mengupload assessment",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      throw error;
    } finally {
      setPengetahuanAsessmentState("isLoadingUpload", false);
    }
  };

  const dispatch = {
    downloadAssesment,
    setPengetahuanAsessmentState,
    uploadAssessment,
  };
  return (
    <PengetahuanAsessmentProvider value={{ state: state.state, dispatch }}>
      {children}
    </PengetahuanAsessmentProvider>
  );
};

export const usePengetahuanAssesmentContext = () => useContext(Context);

const PengetahuanAssesmentContext = {
  usePengetahuanAssesmentContext,
  Provider,
};

export default PengetahuanAssesmentContext;
