import {
  Flex,
  Button,
  Box,
  Grid,
  GridItem,
  Text,
  Input,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import {
  AiOutlineArrowLeft,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineSave,
} from "react-icons/ai";

import { useHistory, useParams } from "react-router-dom";
import BoxDetailAssesment from "../../../../../components/Admin/ModalPengetahuan/BoxDetailAssesment";
import BoxDetailQuiz from "../../../../../components/Admin/ModalPengetahuan/BoxDetailQuiz";
import SidebarWithHeader from "../../../../../components/Admin/SidebarWithHeader";
import ManajemenPengetahuanKontenPemateriController, {
  OptionType,
  useManajemenPengetahuanKontenPemateriContext,
} from "../../../../../controller/admin/manajemen_pengetahuan/konten-pemateri";
import YoutubeContect, {
  useYoutubeContext,
} from "../../../../../controller/youtube/index";
import BoxUploadFile from "../../../../../components/Admin/UploadFile/BoxUploadFile";
import KelolaKontenManajemenPelatihanContext, {
  useKelolaKontenManajemenPelatihanContext,
} from "../../../../../controller/admin/manajemen_pelatihan/kelola_konten";
import ModalDetailLatihanQuiz from "../../../../../components/Admin/ModalPengetahuan/ModalDetailLatihanQuiz";
import ModalAssesment from "../../../../../components/Admin/ModalPengetahuan/ModalAssesment";
import ModalLatihanQuiz from "../../../../../components/Admin/ModalPengetahuan/ModalLatihanQuiz";
import { MdOutlineChangeCircle } from "react-icons/md";
import Loader from "../../../../../components/Loader";
import { extractYouTubeVideoId } from "../../../../../../misc/extractYouTubeVideoId";

/**
 * The `DetailKontenPemateri` component displays the details of the speaker content
 * managed within the knowledge management system. This component provides
 * features to view, edit, and save content details, including video settings,
 * speaker management, and quiz/assessment options.
 *
 * @component
 * @returns {React.ReactElement} The detail view of the speaker content.
 * @author Bagas Prasetyadi
 */
const DetailKontenPemateri = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const { id_pemateri } = useParams() as { id_pemateri: string };

  const {
    hasAssesment,
    hasQuiz,
    mode,
    title,
    category,
    listCategory,
    youtubeVideoSelected,
    pdf,
    listVideoTautan,
    videoTautan,
    urlTautan,
    isLoadingData,
    speakerName,
    listSpeakers,
    speakerSelected,
    isAddingNewSpeaker,
    isLoadingPost,
    linkStreaming,
    startDate,
    startTime,
  } = useManajemenPengetahuanKontenPemateriContext().state;
  const {
    setPengetahuanKontenPemateri: setState,
    getDetailKontenPemateri,
    postData,
  } = useManajemenPengetahuanKontenPemateriContext().disptach;

  const { listVideo, isLoading: isLoadingListVideo } = useYoutubeContext().state;
  const { getListVideo } = useYoutubeContext();

  const listOptionVideoYoutube: any = listVideo.map((item) => ({
    value: item?.videoId,
    label: item?.title,
  }));

  const [tabIndex, setTabIndex] = useState(0);
  const [showModalDetailQuiz, setShowModalDetailQuiz] = useState(false);
  const [showModalLatihan, setShowModalLatihan] = useState(false);
  const [showModalAssesment, setShowModalAssesment] = useState(false);
  const [defaultVideoTautan, setdefaultVideoTautan] = useState<null | OptionType>(listVideoTautan[1])

  const isVideoSelected = (videoTautan?.value === 'video' || defaultVideoTautan?.value === 'video');
  const isTautanSelected = (videoTautan?.value === 'tautan' || defaultVideoTautan?.value === 'tautan');
  const isNotTautan = videoTautan?.value !== 'tautan';

  const isSelectedInList = listOptionVideoYoutube.some(
    (option) => option.value === youtubeVideoSelected
  );

  useEffect(() => {
    if(isSelectedInList) {
      setdefaultVideoTautan(listVideoTautan[0])
    }
  }, [isSelectedInList, listVideoTautan])

  useEffect(() => {
    getDetailKontenPemateri(id_pemateri);
    getListVideo();
  }, []);

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        padding: "2px 8px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 100,
      };
    },
  };

  return (
    <SidebarWithHeader spacing={false}>
      <Box m={4} pb={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Button
            leftIcon={<AiOutlineArrowLeft />}
            color="black"
            bg="white"
            _hover={{ bg: "white" }}
            _active={{ bg: "white" }}
            onClick={() => history.push("/admin/manajemen_pengetahuan/" + id)}
          >
            Kembali
          </Button>
          <Stack direction="row" spacing={2}>
            <Button
              bg="#F2994A"
              color="white"
              _hover={{ bg: "#F2994A" }}
              _active={{ bg: "#F2994A" }}
              isLoading={isLoadingData || isLoadingListVideo}
              loadingText="Mengambil Data..."
              rightIcon={
                mode === "edit" ? (
                  <AiOutlineEye color="white" size={16} />
                ) : (
                  <AiOutlineEdit color="white" size={16} />
                )
              }
              onClick={() => {
                if (mode === "edit") {
                  setState("mode", "view");
                }
                if (mode === "view") {
                  setState("mode", "edit");

                  if (typeof youtubeVideoSelected === "string") {
                    const videoSelected = listOptionVideoYoutube.find(
                      (item) => item.value === youtubeVideoSelected
                    );

                    setState("youtubeVideoSelected", videoSelected);
                  }
                }
              }}
            >
              {mode === "view" ? "Edit" : "View"}
            </Button>
            <Button
              colorScheme="green"
              rightIcon={<AiOutlineSave color="white" size={16} />}
              isLoading={isLoadingPost}
              spinnerPlacement="start"
              loadingText="Menyimpan..."
              onClick={() => postData(id, id_pemateri)}
              isDisabled={mode === "view" || isLoadingData}
            >
              Simpan
            </Button>
          </Stack>
        </Flex>
        <Box mt={4} bg="white" p={6} borderRadius="md">
          {isLoadingData ? (
            <Loader />
          ) : (
            <Grid templateColumns="repeat(8, 1fr)" gap={4}>
              {/* Nama Pemateri */}
              <GridItem colSpan={2}>
                <Text fontWeight="semibold" fontSize="sm">
                  Nama Pemateri
                </Text>
              </GridItem>
              <GridItem
                colSpan={6}
                display="flex"
                justifyContent="space-between"
              >
                <Box w="92%">
                  {isAddingNewSpeaker ? (
                    <Input
                      type="text"
                      value={speakerName}
                      placeholder="Masukkan Nama Pemateri"
                      onChange={(e) => setState("speakerName", e.target.value)}
                    />
                  ) : (
                    <Select
                      styles={colourStyles}
                      options={listSpeakers}
                      value={speakerSelected}
                      onChange={(e) => setState("speakerSelected", e)}
                      placeholder="Pilih Pemateri"
                      isDisabled={mode === "view"}
                    />
                  )}
                </Box>
                {}
                <IconButton
                  icon={<MdOutlineChangeCircle size={20} />}
                  aria-label="Switch Materi Option"
                  onClick={() =>
                    setState("isAddingNewSpeaker", !isAddingNewSpeaker)
                  }
                  colorScheme="blue"
                  isDisabled={mode === "view"}
                >
                  {isAddingNewSpeaker ? "Pilih Pemateri" : "Input Manual"}
                </IconButton>
              </GridItem>

              {/* Judul Materi */}
              <GridItem colSpan={2}>
                <Text fontWeight="semibold" fontSize="sm">
                  Judul Materi
                </Text>
              </GridItem>
              <GridItem colSpan={6}>
                <Input
                  value={title}
                  fontSize="sm"
                  readOnly={mode === "view"}
                  onChange={(e) => setState("title", e.target.value)}
                />
              </GridItem>

              {category ? (
                <>
                  <GridItem colSpan={2}>
                    <Text fontWeight="semibold" fontSize="sm">
                      Tipe File
                    </Text>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Select
                      placeholder="Pilih Tipe File"
                      options={listCategory}
                      isClearable
                      onChange={(e) => {
                        setState("category", e)
                        setState("pdf", null)
                      }}
                      value={category}
                      styles={colourStyles}
                      isDisabled={mode === "view"}
                    />
                  </GridItem>

                  {category?.value === "video" && (
                    <>
                      {mode === "edit" && (
                        <>
                          <GridItem colSpan={2}>
                            <Text fontWeight="semibold" fontSize="sm">
                              Pilih Video/Tautan
                            </Text>
                          </GridItem>
                          <GridItem colSpan={6}>
                            <Select
                              placeholder="Pilih Video/Tautan"
                              options={listVideoTautan}
                              isClearable
                              onChange={(e) => {
                                setState("videoTautan", e)
                                setState("youtubeVideoSelected", null)
                                setState("urlTautan", "")
                                setdefaultVideoTautan(null)
                              }
                              }
                              value={videoTautan || defaultVideoTautan}
                              styles={colourStyles}
                            />
                          </GridItem>

                          {isVideoSelected && isNotTautan && (
                            <>
                              <GridItem colSpan={2}>
                                <Text fontWeight="semibold" fontSize="sm">
                                  Pilih Video
                                </Text>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <Select
                                  placeholder="Pilih Video"
                                  options={listOptionVideoYoutube}
                                  isClearable
                                  onChange={(e) => setState("youtubeVideoSelected", e)}
                                  value={youtubeVideoSelected}
                                  styles={colourStyles}
                                />
                              </GridItem>
                            </>
                          )}

                          {isTautanSelected && (
                            <>
                              <GridItem colSpan={2}>
                                <Text fontWeight="semibold" fontSize="sm">
                                  Tautan Video
                                </Text>
                              </GridItem>
                              <GridItem colSpan={6}>
                                <Input
                                  type="text"
                                  value={urlTautan}
                                  placeholder="Masukkan Tautan Video Pembelajaran"
                                  onChange={(e) =>
                                    setState("urlTautan", e.target.value)
                                  }
                                />
                              </GridItem>
                            </>
                          )}
                        </>
                      )}

                      {isVideoSelected && youtubeVideoSelected && (
                        <>
                          <GridItem colSpan={2}>
                            <Text fontWeight="semibold" fontSize="sm">
                              Video
                            </Text>
                          </GridItem>
                          <GridItem colSpan={6}>
                            <iframe
                              title="youtube video"
                              src={`https://www.youtube.com/embed/${
                                typeof youtubeVideoSelected === "string"
                                  ? youtubeVideoSelected
                                  : youtubeVideoSelected.value
                              }`}
                              allowFullScreen
                              style={{ width: "100%", height: "350px" }}
                            />
                          </GridItem>
                        </>
                      )}
                      {isTautanSelected && urlTautan && extractYouTubeVideoId(urlTautan) && (
                        <>
                          <GridItem colSpan={2}></GridItem>
                          <GridItem colSpan={6}>
                            <iframe
                              title="youtube video"
                              src={`https://www.youtube.com/embed/${extractYouTubeVideoId(urlTautan)}`}
                              allowFullScreen
                              style={{ width: "100%", height: "300px" }}
                            />
                          </GridItem>
                        </>
                      )}
                    </>
                  )}

                  {category?.value === "pdf" && (
                    <>
                      <GridItem colSpan={2}>
                        <Text fontWeight="semibold" fontSize="sm">
                          Upload PDF
                        </Text>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <BoxUploadFile
                          setFile={setState}
                          typeFile="pdf"
                          defaultFile={pdf}
                        />
                      </GridItem>
                    </>
                  )}
                </>
              ) : (
                <>
                  <GridItem colSpan={2}>
                    <Text fontWeight="semibold" fontSize="sm">
                      Tanggal
                    </Text>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Input
                      type="date"
                      value={startDate}
                      onChange={(e) => setState("startDate", e.target.value)}
                      readOnly={mode === "view"}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text fontWeight="semibold" fontSize="sm">
                      Waktu
                    </Text>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Input
                      type="time"
                      value={startTime}
                      onChange={(e) => setState("startTime", e.target.value)}
                      readOnly={mode === "view"}
                    />
                  </GridItem>
                  {/* Detail Konten */}
                  <GridItem colSpan={2}>
                    <Text fontWeight="semibold" fontSize="sm">
                      Detail Konten
                    </Text>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Input
                      value={linkStreaming}
                      onChange={(e) =>
                        setState("linkStreaming", e.target.value)
                      }
                      readOnly={mode === "view"}
                    />
                  </GridItem>
                </>
              )}

              <GridItem colSpan={2}>
                <Text fontWeight="semibold" fontSize="sm">
                  Tambah Quiz/Assignment
                </Text>
              </GridItem>

              <GridItem
                colSpan={6}
                display="flex"
                textAlign="center"
                justifyContent="space-between"
              >
                <Box
                  w="49%"
                  py={2}
                  bgColor="green.100"
                  borderColor="green"
                  borderWidth="1px"
                  borderRadius="lg"
                >
                  <label className="container text-green-500">
                    Quiz
                    <input
                      type="checkbox"
                      className="ml-2"
                      checked={hasQuiz}
                      disabled={mode === "view"}
                      onChange={(e) => setState("hasQuiz", e.target.checked)}
                    />
                  </label>
                </Box>
                <Box
                  w="49%"
                  py={2}
                  bgColor="orange.100"
                  borderColor="orange"
                  borderWidth="1px"
                  borderRadius="lg"
                >
                  <label className="container text-orange-500">
                    Assignment
                    <input
                      type="checkbox"
                      className="ml-2"
                      checked={hasAssesment}
                      disabled={mode === "view"}
                      onChange={(e) =>
                        setState("hasAssesment", e.target.checked)
                      }
                    />
                  </label>
                </Box>
              </GridItem>

              {(hasQuiz || hasAssesment) && (
                <>
                  <GridItem colSpan={2}></GridItem>
                  <GridItem colSpan={6}>
                    <Tabs
                      variant="line"
                      colorScheme="green"
                      onChange={(index) => setTabIndex(index)}
                      index={tabIndex}
                    >
                      <TabList w="fit-content">
                        {hasQuiz && <Tab>Quiz</Tab>}
                        {hasAssesment && <Tab>Assignment</Tab>}
                      </TabList>

                      <TabPanels>
                        {hasQuiz && (
                          <TabPanel px={0}>
                            <Box
                              border="1px"
                              borderColor="gray.500"
                              borderRadius="md"
                              p={4}
                            >
                              <BoxDetailQuiz
                                setShowModalDetailQuiz={setShowModalDetailQuiz}
                                setShowModalLatihan={setShowModalLatihan}
                                isReadOnly={mode === "view"}
                              />
                            </Box>
                          </TabPanel>
                        )}

                        {hasAssesment && (
                          <TabPanel px={0}>
                            <Box
                              border="1px"
                              borderColor="gray.500"
                              borderRadius="md"
                              p={4}
                            >
                              <BoxDetailAssesment
                                setShowModalAssesment={setShowModalAssesment}
                                isReadOnly={mode === "view"}
                              />
                            </Box>
                          </TabPanel>
                        )}
                      </TabPanels>
                    </Tabs>
                  </GridItem>
                </>
              )}
            </Grid>
          )}
        </Box>
      </Box>
      <ModalDetailLatihanQuiz
        setShowModalDetailQuiz={setShowModalDetailQuiz}
        showModalDetailQuiz={showModalDetailQuiz}
      />
      <ModalAssesment
        setShowModalAssesment={setShowModalAssesment}
        showModalAssesment={showModalAssesment}
      />
      <ModalLatihanQuiz
        setShowModalLatihan={setShowModalLatihan}
        showModalLatihan={showModalLatihan}
      />
    </SidebarWithHeader>
  );
};

const DetailKontenPemateriWrapper = () => {
  return (
    <ManajemenPengetahuanKontenPemateriController>
      <YoutubeContect.Provider>
        <KelolaKontenManajemenPelatihanContext.Provider>
          <DetailKontenPemateri />
        </KelolaKontenManajemenPelatihanContext.Provider>
      </YoutubeContect.Provider>
    </ManajemenPengetahuanKontenPemateriController>
  );
};

export default DetailKontenPemateriWrapper;
