import {
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ModalComponentProps extends ModalProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  modalTitle?: string;
  closeButtonText?: string;
  isSecondaryButton: boolean;
  secondaryButtonText?: string;
  secondaryColorScheme?: string;
  secondaryBackground?: string;
  handleAction: Function;
  isLoading: boolean;
  loadingText: string;
}

const ModalChakraComponent = ({
  onOpen,
  isOpen,
  onClose,
  children,
  modalTitle,
  closeButtonText,
  isSecondaryButton,
  secondaryButtonText,
  secondaryColorScheme,
  secondaryBackground,
  handleAction,
  isLoading,
  loadingText,
  ...rest
}: ModalComponentProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            {closeButtonText ?? "Tutup"}
          </Button>
          {isSecondaryButton ? (
            <Button
              isLoading={isLoading}
              loadingText={loadingText}
              colorScheme={secondaryColorScheme ?? "whatsapp"}
              background={secondaryBackground ?? "GreenPrimary.500"}
              onClick={() => handleAction()}
            >
              {secondaryButtonText}
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalChakraComponent;
