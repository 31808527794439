import { injectable } from "tsyringe";
import { SproutVideoApiRepository } from "../../app/repository/api/SproutVideoApiRepository";
import { SproutVideo } from "../../entities/SproutVideo";

@injectable()
export class SproutVideoPresenter {
  private repository: SproutVideoApiRepository;
  constructor(repository: SproutVideoApiRepository) {
    this.repository = repository;
  }

  public getAllSproutVideo(): Promise<SproutVideo[]> {
    return this.repository.getAllVideo();
  }
}
