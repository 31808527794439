import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Icon,
  Stack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { useKontibutorPengajuanContext } from "../../controller/kontributor/pengajuan";

/**
 * A modal that displays a unique number for contributors to check the status
 * of their submission. The modal is centered and has a size of "xl". The modal
 * has a close button at the top right with a red background color. The modal
 * body displays a text about the unique number and a button to copy the
 * number to the clipboard. The modal also displays a text about how to check
 * the status of the submission.
 * @return {JSX.Element} The JSX element representing the modal.
 * @author Bagas Prasetyadi
 */

const ModalNomorPengajuan = () => {
  const { isOpenModalNomorPengajuan, nomorPengajuan } =
    useKontibutorPengajuanContext().state;
  const { setKontibutorPengajuanState: setState } =
    useKontibutorPengajuanContext().dispatch;

  const toast = useToast();

  const onClose = () => {
    setState("isOpenModalNomorPengajuan", false);
  };

  return (
    <Modal
      isOpen={isOpenModalNomorPengajuan}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="semibold" color="black">
          Nomor Status Pengajuan
        </ModalHeader>
        <ModalCloseButton
          bg="#F67070"
          color="white"
          _hover={{ bg: "#DC2626" }}
          _active={{ bg: "#DC2626" }}
          w="30px"
          h="30px"
          mt={1}
        />
        <ModalBody textAlign="center" pb={4}>
          <Text fontSize="sm">
            Pengajuan Anda saat ini sedang dalam proses review. Silakan gunakan
            nomor kode berikut untuk mengecek status pengajuan Anda:
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            my={2}
            bg="#F3F3F3"
            w="200px"
            rounded="md"
            mx="auto"
          >
            <Stack direction="row" alignItems="center">
              <Text fontSize="lg" fontWeight="bold" p={2} color="#0DBD7F">
                {nomorPengajuan}
              </Text>
              <Icon
                as={MdOutlineContentCopy}
                color="#343330"
                boxSize={4}
                cursor="pointer"
                onClick={() => {
                  toast({
                    title: "Berhasil Menyalin Nomor Pengajuan",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                  });
                  navigator.clipboard.writeText(nomorPengajuan);
                }}
              />
            </Stack>
          </Flex>
          <Text fontSize="sm">
            Anda dapat memeriksa apakah pengajuan Anda sedang diproses,
            diterima, atau ditolak dengan memasukkan nomor kode ini di halaman
            depan menu “Kontributor”.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalNomorPengajuan;
