import { Box, Flex, Icon, Stack, Text, Button } from "@chakra-ui/react";
import React from "react";
import { IoDocumentTextOutline } from "react-icons/all";
import { MdOutlineQuiz } from "react-icons/md";
import { PengetahuanMateri } from "../../../types/pengetahuan";
import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";

type Props = {
  item: PengetahuanMateri;
};

/**
 * BoxPemateri component is used to display each speaker's info in knowledge detail page.
 * It takes a single prop `item` which is an object containing the speaker's info.
 * The component displays the speaker's name, title, and type of quiz/assesment they have.
 * If the speaker has a synchronized quiz, it displays a button to follow the quiz.
 * When the button is clicked, it opens a modal to confirm the action.
 * @author Bagas Prasetyadi
 */

const BoxPemateri = ({ item }: Props) => {
  const { pengetahuanItem } = usePengetahuanDetailContext().state;
  const { setPengetahuanDetailState } = usePengetahuanDetailContext();

  const onOpen = () => {
    setPengetahuanDetailState("speakerId", item.id);
    setPengetahuanDetailState("isOpenModalConfirmation", true);
  };
  return (
    <Box
      rounded="md"
      border="1px"
      borderColor="gray.200"
      p={3}
      mt={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Stack direction="row" spacing={1}>
          <Text fontWeight="semibold" fontSize="md">
            {item?.nama_pemateri} |
          </Text>
          <Text fontSize="md">Pemateri</Text>
        </Stack>
        <Text fontSize="sm" mt={2}>
          {item.judul}
        </Text>
        {item.tanggal_mulai && (
          <Text fontSize="xs" mt={2}>
            {item.tanggal_mulai} - {item.waktu_mulai}
          </Text>
        )}
        <Stack direction="row" mt={2} spacing={2}>
          {item.has_quiz && (
            <Flex alignItems="center">
              <Icon as={MdOutlineQuiz} boxSize={5} color="green.500" mr={1} />
              <Text>Quiz</Text>
            </Flex>
          )}
          {item.has_assessment && (
            <Flex alignItems="center">
              <Icon
                as={IoDocumentTextOutline}
                boxSize={5}
                color="green.500"
                mr={1}
              />
              <Text>Assignment</Text>
            </Flex>
          )}
        </Stack>
      </Box>
      <Box>
        {pengetahuanItem?.jenis_pengetahuan === "synchronized" && (
          <Button
            bg="orange.300"
            color="white"
            _hover={{ bg: "orange.400" }}
            _active={{ bg: "orange.400" }}
            w="100px"
            onClick={() => onOpen()}
          >
            Ikuti
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BoxPemateri;
