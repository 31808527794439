/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Wrapper } from "../../../components/Wrapper";
import PrettyRating from "pretty-rating-react";
import { ReactComponent as CalendarIconYellow } from "../../../assets/svg/ic_calendar_yellow.svg";
import { ReactComponent as TimeIconYellow } from "../../../assets/svg/ic_time_yellow.svg";
import { ReactComponent as RateFullIcon } from "../../../assets/svg/ic_full_rate.svg";
import ImgUsers from "../../../assets/png/img_users.png";
import If, { Else } from "if-else-react";
import DetailPelatihanController, {
  useDetailPelatihanContext,
} from "../../../controller/pelatihan/detail";
import ReviewPelatihanController, {
  useReviewPelatihanContext,
} from "../../../controller/review_pelatihan";
import PelatihanSayaController, {
  usePelatihanSayaContext,
} from "../../../controller/registered/pelatihan_saya";
import SertifikatController, {
  useSertifikatContext,
} from "../../../controller/sertifikat/index";
import { useParams } from "react-router-dom";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { ReviewPelatihan } from "../../../../../entities/ReviewPelatihan";
import {
  ModulPelatihan,
  SubModulPelatihan,
} from "../../../../../entities/Pelatihan";
import { Modal } from "../../../components/Modal";
import ReactPaginate from "react-paginate";
import parse from "html-react-parser";
import ReactStars from "react-rating-stars-component";
import { toast, ToastContainer } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { TbFileCertificate, FaExternalLinkAlt } from "react-icons/all";
import { useIsAuthenticated } from "react-auth-kit";
import JoyrideComponent from "../../../components/global/Joyride";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Placement } from "react-joyride";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";

const colors = {
  star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
};

const HeaderBanner: React.FC = () => {
  const { pelatihan, isLoading } = useDetailPelatihanContext().state;

  const { storePelatihanSaya, setShowModalRegisterPelatihan } =
    usePelatihanSayaContext();
  const { isLoading: isLoadingStorePelatihanSaya, currentTime } =
    usePelatihanSayaContext().state;

  const { reviewPelatihan } = useReviewPelatihanContext().state;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleDaftar = (e) => {
    e.preventDefault();
    if (pelatihan != null) {
      storePelatihanSaya(
        pelatihan.id,
        pelatihan.waktu_mulai_pendaftaran,
        pelatihan.waktu_selesai_pendaftaran
      );
    }
  };

  const currentDate = currentTime;

  const isAuthenticated = useIsAuthenticated();

  return (
    <Box maxW={{ base: "42rem", lg: "80rem" }} mx="auto" px={8} w="full">
      <Box py={10} bg="#059669" mt={6} rounded="lg">
        <If condition={isLoading}>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={5}
          >
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <Box borderRadius="lg" ml={10} mr={{ base: 0, md: 10 }}>
                <Skeleton height={228} />
              </Box>
            </GridItem>
            <GridItem colSpan={3} mr={6} ml={6}>
              <Skeleton count={7} height={20} style={{ marginTop: "11px" }} />
            </GridItem>
          </Grid>
        </If>

        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap={5}
          display={isLoading ? "none" : "grid"}
        >
          <GridItem
            colSpan={{
              base: 3,
              md: 2,
            }}
          >
            <Image
              src={pelatihan?.path_thumbnail}
              mx={{ base: 4, md: 8 }}
              borderRadius="md"
              h={{ base: "180px", md: "228px" }}
              maxW="full"
              objectFit="cover"
              w="90%"
            />
          </GridItem>
          <GridItem colSpan={3} mr={6} ml={6}>
            <Heading as="h2" fontSize="2xl" color="white" fontWeight="semibold">
              {pelatihan?.judul_pelatihan}
            </Heading>
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap={4}
              mt={4}
              alignItems="center"
            >
              <GridItem colSpan={{ base: 6, md: 3 }}>
                <Box
                  bg="white"
                  fontSize="xs"
                  p={2}
                  rounded="full"
                  textAlign="center"
                >
                  <Text color="green.400">{pelatihan?.penyelenggara}</Text>
                </Box>
              </GridItem>
              <GridItem colSpan={{ base: 6, md: 3 }}>
                <Flex
                  ml={{ base: 0, md: 2 }}
                  textAlign="right"
                  fontSize="sm"
                  alignItems="center"
                >
                  <PrettyRating
                    value={pelatihan != null ? pelatihan.rating : 0}
                    colors={colors.star}
                  />
                  <Text color="white" ml={2} fontWeight="semibold">
                    {pelatihan?.rating}
                  </Text>
                  <Text color="white" ml={2} fontWeight="semibold">
                    (
                    {reviewPelatihan.length > 0
                      ? reviewPelatihan[0].jumlahDataReview
                      : 0}{" "}
                    Reviews)
                  </Text>
                </Flex>
              </GridItem>
            </Grid>

            <Flex mt={4} direction={{ base: "column", md: "row" }}>
              <Flex>
                <CalendarIconYellow />
                <Text color="white" ml={2} fontSize="base" fontWeight="bold">
                  {moment(pelatihan?.waktu_mulai).format("LL")} s.d{" "}
                  {moment(pelatihan?.waktu_selesai).format("LL")}
                </Text>
              </Flex>
              <Flex mt={{ base: 4, md: 0 }}>
                <TimeIconYellow className="md:ml-3" />
                <Text
                  as="b"
                  color="white"
                  ml={2}
                  fontSize="base"
                  fontWeight="bold"
                >
                  {pelatihan?.jp} JP
                </Text>
              </Flex>
            </Flex>
            <Flex
              justifyContent={isAuthenticated() ? "space-between" : "start"}
              alignItems="center"
              mt={4}
            >
              <If condition={isAuthenticated() !== false}>
                {/* Button Mulai Belajar */}
                <button
                  className={`bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-5 rounded-md ${
                    pelatihan != null &&
                    pelatihan.status_pelatihan == "Sedang Mengikuti"
                      ? ""
                      : "hidden"
                  }`}
                  disabled={
                    pelatihan != null &&
                    moment(currentDate).isBefore(pelatihan.waktu_mulai)
                  }
                  onClick={() => setShowModalRegisterPelatihan(true)}
                >
                  {pelatihan != null &&
                  moment(currentDate).isBefore(pelatihan.waktu_mulai)
                    ? "Anda Telah Terdaftar"
                    : "Mulai Belajar"}
                </button>

                {/* Button Daftar */}
                <button
                  className={`bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-5 rounded-md daftar-pelatihan ${
                    pelatihan != null &&
                    pelatihan.status_pelatihan == "Belum Mengikuti"
                      ? ""
                      : "hidden"
                  }`}
                  onClick={(e) => handleDaftar(e)}
                  disabled={isLoadingStorePelatihanSaya ? true : false}
                >
                  <If condition={isLoadingStorePelatihanSaya}>
                    <div className="text-center">
                      <svg
                        role="status"
                        className="inline w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                    <Else />
                    Daftar Pelatihan
                  </If>
                </button>

                {/* Button Lihat Materi */}
                <a
                  href={`/detail_pelatihan_saya/${pelatihan?.uuid}`}
                  className={`bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-5 rounded-md ${
                    pelatihan != null &&
                    pelatihan.status_pelatihan == "Selesai Dipelajari"
                      ? ""
                      : "hidden"
                  }`}
                >
                  Lihat Materi Pelatihan
                </a>
                <Else />
                <a
                  href="/login"
                  className="bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-5 rounded-md"
                >
                  Daftar Sekarang
                </a>
              </If>
              {!isMobile && <GuideDetailPelatihan />}
            </Flex>
            {isMobile && (
              <Box mt={4}>
                <GuideDetailPelatihan />
              </Box>
            )}
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

const ItemSubModul = ({
  sub_modul,
  idx,
  numbering,
}: {
  sub_modul: SubModulPelatihan;
  idx: number;
  numbering: number;
}) => {
  const number_second = idx + 1;

  return (
    <div className="flex items-center mb-3 mt-3">
      <input
        id="default-checkbox"
        type="checkbox"
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        disabled
      />
      <label
        htmlFor="default-checkbox"
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 text-justify"
      >
        {numbering}.{number_second} {sub_modul.judul}
      </label>
    </div>
  );
};

const ItemModul = ({ modul, idx }: { modul: ModulPelatihan; idx: number }) => {
  const numbering = idx + 1;

  return (
    <div className="accordion-item bg-white mt-3">
      <h2 className="accordion-header mb-0" id={`section${modul.id}`}>
        <button
          className="
            accordion-button
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-sm text-gray-800 text-left
            bg-gray-100
            rounded-full
            transition
            focus:outline-none
          "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${modul.id}`}
          aria-expanded="true"
          aria-controls={`collapse${modul.id}`}
        >
          <span className="font-semibold">
            Section {numbering} :{" "}
            <span className="font-normal">{modul.judul_modul}</span>
          </span>
        </button>
      </h2>
      <div
        id={`collapse${modul.id}`}
        className={
          numbering === 1
            ? "accordion-collapse collapse show"
            : "accordion-collapse collapse"
        }
        aria-labelledby={`section${modul.id}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body py-4 px-4">
          {modul.sub_modul.map((v, idx) => {
            return (
              <ItemSubModul
                sub_modul={v}
                idx={idx}
                key={idx}
                numbering={numbering}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PelatihanModul: React.FC = () => {
  const { pelatihan } = useDetailPelatihanContext().state;

  return (
    <>
      <div className="container mt-0 md:mt-4 mb-4 md:mb-0 p-5 bg-white rounded-lg modul-pelatihan">
        <h2 className="font-semibold text-base mb-4">Modul Pelatihan</h2>
        <div className="accordion" id="accordionExample">
          {pelatihan != null && pelatihan.modulPelatihan.length > 0 ? (
            pelatihan.modulPelatihan.map((v, idx) => {
              return <ItemModul modul={v} idx={idx} key={idx} />;
            })
          ) : (
            <p className="text-center">Modul Kosong</p>
          )}
        </div>
      </div>
    </>
  );
};

const ModalSertifikat = ({
  pelatihan_id,
  nama_pelatihan,
}: {
  pelatihan_id: number;
  nama_pelatihan: string;
}) => {
  // Call Controller Sertifikat
  const { linkPDF, isLoadingDownload, isLoadingGet, showModal } =
    useSertifikatContext().state;

  const { downloadSertifikat, setShowModal } = useSertifikatContext();

  const handleDownloadSertifikat = () => {
    downloadSertifikat(pelatihan_id, nama_pelatihan);
  };

  return (
    <>
      {showModal ? (
        <>
          {/* background modal */}
          <div
            className="fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto inset-0 h-modal md:h-full justify-center items-center flex bg-gray-800 opacity-50"
            aria-modal="true"
            role="dialog"
            tabIndex={-1}
            style={{ zIndex: 999999 }}
          ></div>

          {/* Modal */}
          <div
            className="flex justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
            style={{ zIndex: 999999 }}
          >
            <div className="relative w-full h-full max-w-7xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Sertifikat Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowModal(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-5">
                  <If condition={isLoadingGet}>
                    <div className="text-center mx-20 my-20">
                      <svg
                        role="status"
                        className="inline w-20 h-20 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                    <Else />
                    <iframe
                      src={`${linkPDF}`}
                      width={"100%"}
                      height={"600"}
                      style={{ borderRadius: "6px" }}
                    />
                  </If>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b">
                  <button
                    type="button"
                    className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"
                    onClick={handleDownloadSertifikat}
                  >
                    <If condition={isLoadingDownload}>
                      <div className="text-center">
                        <svg
                          role="status"
                          className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                      <Else />
                      Download
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const DetailItem: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };
  const urlSearchParams = new URLSearchParams(window.location.search);
  const action = urlSearchParams.get("action");

  const [reviewTab, setReviewTab] = useState(true);

  // Get Pelatihan
  const { pelatihan } = useDetailPelatihanContext().state;

  // Get Review Pelatihan
  const { reviewPelatihan } = useReviewPelatihanContext().state;

  const { setShowModalReviewPelatihan } = usePelatihanSayaContext();

  const activeTab = (e) => {
    const id = e.currentTarget.id;

    switch (id) {
      case "review-tab":
        setReviewTab(true);
        break;

      case "diskusi-tab":
        setReviewTab(false);
        break;

      default:
        break;
    }
  };

  // Call Sertifikat Controller
  const { getSertifikat, setShowModal } = useSertifikatContext();

  const handleLihatSertifikat = () => {
    getSertifikat(pelatihan?.id);
    setShowModal(true);
  };

  useEffect(() => {
    if (action === "download-certificate") {
      handleLihatSertifikat();
    }
  }, [action]);

  return (
    <>
      <div className="max-w-2xl mx-auto lg:max-w-7xl lg:px-4 px-8 w-full">
        {/* If Pelatihan Selesai Dipelajari */}
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 ${
            pelatihan != null &&
            pelatihan.status_pelatihan == "Selesai Dipelajari"
              ? ""
              : "hidden"
          }`}
        >
          <div className="col-span-2">
            <div className="container mt-4 p-5 bg-white rounded-md">
              {(pelatihan != null &&
                pelatihan?.predikat_peserta != null &&
                pelatihan?.predikat_peserta.includes("Tidak")) ||
              pelatihan?.predikat_peserta === null ? (
                <>
                  <TbFileCertificate
                    className="text-green-600 mb-3"
                    fontSize={"25px"}
                  />
                  <h2 className="font-semibold text-base">
                    Maaf Anda Tidak Lulus Pelatihan Ini
                  </h2>
                  <p className="text-justify mt-2">
                    Anda memperoleh nilai keseluruhan{" "}
                    <b>{pelatihan != null && pelatihan.nilai_akhir}</b> pada{" "}
                    <b>{pelatihan != null && pelatihan.judul_pelatihan}</b>.
                    <br />
                    Terus belajar lebih giat kembali dan kembali daftarkan diri
                    anda di pelatihan mendatang.
                  </p>
                </>
              ) : (
                <>
                  <TbFileCertificate
                    className="text-green-600 mb-3"
                    fontSize={"25px"}
                  />
                  <h2 className="font-semibold text-base">
                    Selamat Anda Lulus Pelatihan Ini
                  </h2>
                  <p className="text-justify mt-2">
                    Anda memperoleh nilai keseluruhan{" "}
                    <b>{pelatihan != null && pelatihan.nilai_akhir}</b> pada{" "}
                    <b>{pelatihan != null && pelatihan.judul_pelatihan}</b>.
                    <br />
                    Terus belajar dan praktikkan ilmu yang telah anda pelajari
                    di pelatihan ini agar lebih bermanfaat.
                  </p>
                </>
              )}
              <div
                className={`flex flex-row mt-4 ${
                  pelatihan != null &&
                  pelatihan.predikat_peserta != null &&
                  pelatihan.predikat_peserta.includes("Tidak")
                    ? "hidden"
                    : ""
                }`}
              >
                <button
                  className="bg-green-500 hover:bg-green-600 text-white py-2 px-5 rounded-md"
                  onClick={handleLihatSertifikat}
                  hidden={
                    pelatihan != null && pelatihan?.status_tte != "2"
                      ? true
                      : false
                  }
                >
                  Lihat Sertifikat
                </button>
                <button
                  className="bg-green-500 hover:bg-green-600 text-white py-2 px-5 rounded-md"
                  disabled
                  hidden={
                    pelatihan != null && pelatihan?.status_tte == "2"
                      ? true
                      : false
                  }
                >
                  Menunggu Proses TTE
                </button>
                <button
                  className={`border-2 border-green-500 hover:bg-green-600 text-green-500 hover:text-white py-2 px-5 rounded-md ml-3 ${
                    pelatihan?.status_review == 2 ? "hidden" : null
                  }`}
                  data-modal-placement="center-center"
                  onClick={() => setShowModalReviewPelatihan(true)}
                >
                  Berikan Review
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Informasi Umum, Persyaratan, Review Tab Dan Diskusi Tab */}
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5">
          <div className="col-span-3 md:col-span-3">
            <div className="container mt-4 p-5 bg-white rounded-md">
              <h2 className="font-semibold text-base">Informasi Umum</h2>
              <div className="text-justify mt-2">
                {parse(pelatihan != null ? pelatihan?.informasi_umum : "")}
              </div>
            </div>

            <div className="container  p-5 bg-white rounded-md mt-5">
              <h2 className="font-semibold text-base">Persyaratan</h2>
              <div className="text-justify mt-2">
                {parse(pelatihan != null ? pelatihan?.persyaratan : "")}
              </div>
            </div>

            <Box
              mt={4}
              p={5}
              mb={{ base: 0, md: 12 }}
              backgroundColor="#FFFFFF"
              borderRadius="10px"
            >
              {/* Tabs */}
              <ul className="hidden text-sm font-medium text-center text-gray-500 rounded-md divide-x divide-gray-200 shadow sm:flex w-4/12">
                <li className="w-full">
                  <button
                    className={`inline-block p-2 w-full rounded-md border border-gray  transition-colors duration-300 ${
                      reviewTab
                        ? "bg-gray-700 text-white"
                        : "bg-white text-gray-700"
                    }`}
                    id="review-tab"
                    onClick={(e) => activeTab(e)}
                  >
                    Reviews (
                    {reviewPelatihan.length > 0
                      ? reviewPelatihan[0].jumlahDataReview
                      : 0}
                    )
                  </button>
                </li>
              </ul>
              {/* Menu */}
              <div className="mt-5">
                <If condition={reviewTab}>
                  <Reviews />
                  <Else />
                  <Diskusi />
                </If>
              </div>
            </Box>
          </div>
          <GridItem colSpan={{ base: 3, md: 2 }}>
            <PelatihanModul />
          </GridItem>
        </div>
      </div>

      {/* ModalSertifikat */}
      <ModalSertifikat
        pelatihan_id={pelatihan != null ? pelatihan.id : 0}
        nama_pelatihan={pelatihan != null ? pelatihan.judul_pelatihan : ""}
      />
    </>
  );
};

const GuideDetailPelatihan = () => {
  const steps = [
    {
      content: (
        <p>
          Materi dan bahan ajar yang akan anda peroleh ketika mengikuti
          pelatihan ini
        </p>
      ),
      placement: "bottom" as Placement,
      target: ".modul-pelatihan",
      disableBeacon: true,
    },
    {
      content: (
        <p>
          Klik disini jika anda memenuhi syarat dan tertarik pada pelatihan ini
        </p>
      ),
      placement: "bottom" as Placement,
      target: ".daftar-pelatihan",
      disableBeacon: true,
    },
  ];

  return <JoyrideComponent steps={steps} textColor="#fff" />;
};

const Pagination = () => {
  const { setPage } = useReviewPelatihanContext();
  const { jumlahPage } = useReviewPelatihanContext().state;

  var endFix =
    jumlahPage > Number(jumlahPage.toFixed())
      ? Number(jumlahPage.toFixed()) + 1
      : Number(jumlahPage.toFixed());

  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  return (
    <If condition={endFix > 1}>
      <div className="flex justify-center mt-5">
        <ReactPaginate
          breakLabel="..."
          nextLabel="Selanjutnya"
          activeClassName="active"
          containerClassName="pagination"
          onPageChange={handlePageClick}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          pageCount={
            jumlahPage > Number(jumlahPage.toFixed())
              ? Number(jumlahPage.toFixed()) + 1
              : Number(jumlahPage.toFixed())
          }
          previousLabel="Sebelumnya"
        />
      </div>
      <Else />
      <></>
    </If>
  );
};

const ModalReviewImage = () => {
  const {
    setShowModal,
    getDetailReviewPelatihan,
    setPathFotoReview,
    clearFormReviewDetail,
  } = useReviewPelatihanContext();
  const {
    showModal,
    reviewId,
    isLoadingReviewDetail,
    detailReview,
    pathFotoReview,
  } = useReviewPelatihanContext().state;

  useEffect(() => {
    if (reviewId != 0) {
      getDetailReviewPelatihan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return (
    <>
      {showModal ? (
        <>
          {/* Background Modal */}
          <div
            className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto inset-0 h-modal md:h-full justify-center items-center flex bg-gray-800 opacity-50"
            aria-modal="true"
            role="dialog"
            tabIndex={-1}
          ></div>

          {/* Modal */}
          <div className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto inset-0 h-modal md:h-full justify-center items-center flex">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Review
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => {
                      setShowModal(false);
                      clearFormReviewDetail();
                    }}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className="p-6 space-y-6 -mt-5 overflow-y-auto"
                  style={{ height: "600px" }}
                >
                  <div className="grid grid-cols-4 gap-5">
                    <div className="col-span-3">
                      <If condition={isLoadingReviewDetail}>
                        <Skeleton
                          style={{
                            width: "100%",
                            height: "400px",
                            borderRadius: "10px",
                          }}
                        />
                        <Else />
                        <img
                          src={pathFotoReview}
                          alt="imgtest"
                          style={{ width: "100%" }}
                          className="rounded-lg"
                        />
                      </If>
                    </div>
                    <div className="col-span-1">
                      <If condition={isLoadingReviewDetail}>
                        <Skeleton
                          style={{
                            width: "100px",
                            height: "60px",
                            borderRadius: "10px",
                            marginBottom: "10px",
                          }}
                          count={4}
                        />
                        <Else />
                        {detailReview != null &&
                          detailReview.path_foto_komentar_pelatihan.length >
                            0 &&
                          detailReview.path_foto_komentar_pelatihan.map(
                            (val) => {
                              return (
                                <button
                                  onClick={() => setPathFotoReview(val.label)}
                                >
                                  <img
                                    src={val.label}
                                    alt={val.label}
                                    className="w-35 h-20 rounded-lg mb-5 hover:shadow-lg"
                                  />
                                </button>
                              );
                            }
                          )}
                      </If>
                    </div>
                  </div>

                  <div className="mt-10">
                    <div className="flex flex-row">
                      {detailReview != null && (
                        <>
                          <img
                            src={detailReview.path_foto}
                            alt={detailReview.path_foto}
                            className="rounded-full w-10 h-10"
                          />
                          <div className="flex flex-col ml-3">
                            <p className="font-medium text-sm">
                              {detailReview.name}
                            </p>
                            <div className="flex flex-row items-center">
                              <div className="text-sm mr-2">
                                <If condition={detailReview.rating > 0}>
                                  <PrettyRating
                                    value={detailReview.rating}
                                    colors={colors.star}
                                  />
                                </If>
                              </div>
                              <p className="text-xs text-gray-500">
                                {moment(detailReview.created_at).format(
                                  "DD MMMM YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="mt-5">
                      {detailReview != null && (
                        <>
                          <p className="text-md">{detailReview.komentar}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ItemReviewes = ({ review }: { review: ReviewPelatihan }) => {
  const { setShowModal, setReviewId, setIndexSelectedPathFotoReview } =
    useReviewPelatihanContext();

  return (
    <div
      className="container mt-4 p-5"
      style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
    >
      <div className="grid md:grid-cols-9 sm:grid-cols-3 gap-5">
        <div className="col-span-1 md:col-span-1 sm:grid-cols-1 ml-1 mr-1">
          <img
            src={review.path_foto_user}
            className="rounded-full"
            style={{ width: "50px", height: "45px" }}
          />
        </div>
        <div className="col-span-3 md:col-span-3 sm:grid-cols-1 ml-1 mr-1">
          <p className="font-medium text-sm">{review.name}</p>
          <div className="grid grid-cols-2 items-center">
            <div className="text-sm">
              <If condition={review.rating > 0}>
                <PrettyRating value={review.rating} colors={colors.star} />
              </If>
            </div>
            <p className="text-xs text-gray-500">
              {moment(review.created_at).format("DD MMMM YYYY")}
            </p>
          </div>
        </div>
        <div className="col-span-5 md:col-span-5 sm:grid-cols-1 ml-1 mr-1">
          <p className="text-xs text-gray-500 mb-3 text-justify">
            {review.komentar}
          </p>
          <If condition={review.path_foto_komentar_materi.length > 0}>
            <div className="flex flex-row mb-2">
              {review.path_foto_komentar_materi.map((p, key) => {
                return (
                  <button
                    key={key}
                    onClick={() => {
                      setIndexSelectedPathFotoReview(key);
                      setReviewId(p.review_pelatihan_id);
                      setShowModal(true);
                    }}
                  >
                    <img
                      className="object-cover h-20 w-35 mr-3 mb-3 rounded-md hover:shadow-lg"
                      src={p.label}
                      alt={p.label}
                    ></img>
                  </button>
                );
              })}
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

const Reviews: React.FC = () => {
  const { setRating, setFoto } = useReviewPelatihanContext();
  const {
    reviewPelatihan,
    isLoadingReview,
    rating,
    foto,
    countReviewFoto,
    countReview,
  } = useReviewPelatihanContext().state;
  const { pelatihan } = useDetailPelatihanContext().state;
  var buttonAll = "btn-review";
  var buttonDenganFoto = "btn-review";
  var buttonBintang1 = "btn-review";
  var buttonBintang2 = "btn-review";
  var buttonBintang3 = "btn-review";
  var buttonBintang4 = "btn-review";
  var buttonBintang5 = "btn-review";
  if (rating == "") {
    if (foto) {
      buttonDenganFoto = "btn-review-active";
      buttonAll = "btn-review";
    } else {
      buttonAll = "btn-review-active";
      buttonDenganFoto = "btn-review";
    }
  } else if (rating == "1") {
    buttonBintang1 = "btn-review-active";
  } else if (rating == "2") {
    buttonBintang2 = "btn-review-active";
  } else if (rating == "3") {
    buttonBintang3 = "btn-review-active";
  } else if (rating == "4") {
    buttonBintang4 = "btn-review-active";
  } else if (rating == "5") {
    buttonBintang5 = "btn-review-active";
  }

  return (
    <>
      <div className="grid md:grid-cols-6 sm:grid-cols-2 gap-5">
        <div
          className="col-span-6 md:col-span-2 sm:grid-cols-2 ml-1 mr-1"
          style={{
            backgroundColor: "rgba(255, 194, 113, 0.1)",
            borderRadius: "10px",
          }}
        >
          <div
            className="text-center font-semibold mt-4"
            style={{ fontSize: "50px" }}
          >
            {pelatihan?.rating.toFixed(1)}
          </div>
          <div className="text-center text-lg">
            <PrettyRating
              value={Number(pelatihan?.rating)}
              colors={colors.star}
            />
          </div>
          <p className="text-center mb-5 mt-1" style={{ color: "#7A8990" }}>
            {reviewPelatihan.length > 0
              ? reviewPelatihan[0].jumlahDataReview
              : 0}{" "}
            Reviews
          </p>
        </div>
        <div
          className="col-span-6 md:col-span-4 sm:grid-cols-2  ml-1 mr"
          style={{
            backgroundColor: "rgba(255, 194, 113, 0.1)",
            borderRadius: "10px",
          }}
        >
          <div className="p-5">
            <div className="grid grid-cols-9">
              <div className="col-span-2" style={{ color: "#7A8990" }}>
                Excellent
              </div>
              <div className="col-span-6">
                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                  <div
                    className="bg-yellow-400 h-1.5 rounded-full"
                    style={{
                      width: `${
                        reviewPelatihan.length > 0
                          ? reviewPelatihan[0].reviews.excelent_review
                          : 0
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-span-1 ml-2" style={{ color: "#7A8990" }}>
                {Math.round(
                  reviewPelatihan.length > 0
                    ? reviewPelatihan[0].reviews.excelent_review
                    : 0
                )}
                %
              </div>
            </div>
            <div className="grid grid-cols-9 mt-2">
              <div className="col-span-2" style={{ color: "#7A8990" }}>
                Very Good
              </div>
              <div className="col-span-6">
                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                  <div
                    className="bg-yellow-400 h-1.5 rounded-full"
                    style={{
                      width: `${
                        reviewPelatihan.length > 0
                          ? reviewPelatihan[0].reviews.very_good_review
                          : 0
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-span-1 ml-2" style={{ color: "#7A8990" }}>
                {Math.round(
                  reviewPelatihan.length > 0
                    ? reviewPelatihan[0].reviews.very_good_review
                    : 0
                )}
                %
              </div>
            </div>
            <div className="grid grid-cols-9 mt-2">
              <div className="col-span-2" style={{ color: "#7A8990" }}>
                Average
              </div>
              <div className="col-span-6">
                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                  <div
                    className="bg-yellow-400 h-1.5 rounded-full"
                    style={{
                      width: `${
                        reviewPelatihan.length > 0
                          ? reviewPelatihan[0].reviews.averange_review
                          : 0
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-span-1 ml-2" style={{ color: "#7A8990" }}>
                {Math.round(
                  reviewPelatihan.length > 0
                    ? reviewPelatihan[0].reviews.averange_review
                    : 0
                )}
                %
              </div>
            </div>
            <div className="grid grid-cols-9 mt-2">
              <div className="col-span-2" style={{ color: "#7A8990" }}>
                Poor
              </div>
              <div className="col-span-6">
                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                  <div
                    className="bg-yellow-400 h-1.5 rounded-full"
                    style={{
                      width: `${
                        reviewPelatihan.length > 0
                          ? reviewPelatihan[0].reviews.poor_review
                          : 0
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-span-1 ml-2" style={{ color: "#7A8990" }}>
                {Math.round(
                  reviewPelatihan.length > 0
                    ? reviewPelatihan[0].reviews.poor_review
                    : 0
                )}
                %
              </div>
            </div>
            <div className="grid grid-cols-9 mt-2">
              <div className="col-span-2" style={{ color: "#7A8990" }}>
                Terrible
              </div>
              <div className="col-span-6">
                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mt-2">
                  <div
                    className="bg-yellow-400 h-1.5 rounded-full"
                    style={{
                      width: `${
                        reviewPelatihan.length > 0
                          ? reviewPelatihan[0].reviews.terrible_review
                          : 0
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-span-1 ml-2" style={{ color: "#7A8990" }}>
                {Math.round(
                  reviewPelatihan.length > 0
                    ? reviewPelatihan[0].reviews.terrible_review
                    : 0
                )}
                %
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-wrap">
        <button
          className={`py-2 px-4 ${buttonAll} mr-2 mt-2`}
          onClick={() => setRating("")}
        >
          Semua ({countReview})
        </button>
        <button
          className={`py-2 px-4 ${buttonDenganFoto} mr-2 mt-2`}
          onClick={() => setFoto()}
        >
          Dengan Foto ({countReviewFoto})
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang5} mr-2 mt-2`}
          onClick={() => setRating("5")}
        >
          <RateFullIcon className="mr-1" />5
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang4} mr-2 mt-2`}
          onClick={() => setRating("4")}
        >
          <RateFullIcon className="mr-1" />4
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang3} mr-2 mt-2`}
          onClick={() => setRating("3")}
        >
          <RateFullIcon className="mr-1" />3
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang2} mr-2 mt-2`}
          onClick={() => setRating("2")}
        >
          <RateFullIcon className="mr-1" />2
        </button>
        <button
          className={`py-2 px-4 ${buttonBintang1} mr-2 mt-2`}
          onClick={() => setRating("1")}
        >
          <RateFullIcon className="mr-1" />1
        </button>
      </div>

      <If condition={isLoadingReview}>
        <div
          className="container mt-4 p-5"
          style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
        >
          <div className="grid md:grid-cols-9 sm:grid-cols-3 gap-5">
            <div className="col-span-1 md:col-span-1 sm:grid-cols-1 ml-1 mr-1">
              <Skeleton width={50} height={50} />
            </div>
            <div className="col-span-8 md:col-span-8 sm:grid-cols-1 ml-1 mr-1">
              <p className="font-medium text-base">
                <Skeleton count={2} />
              </p>
            </div>
          </div>
          <p className="mt-4">
            <Skeleton count={2} />
          </p>
        </div>
        <Else />
        {reviewPelatihan.length > 0 ? (
          reviewPelatihan.map((v: ReviewPelatihan, key) => (
            <ItemReviewes review={v} key={key} />
          ))
        ) : (
          <p className="text-center text-md mt-8">Tidak ada review!</p>
        )}
      </If>
      <Pagination />
    </>
  );
};

const ItemsDiskusi: React.FC = () => {
  return (
    <div
      className="container mt-4 p-5"
      style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
    >
      <div className="grid md:grid-cols-9 sm:grid-cols-3 gap-5">
        <div className="col-span-1 md:col-span-1 sm:grid-cols-1 ml-1 mr-1">
          <img src={ImgUsers} alt="card_cover" style={{ width: "50px" }} />
        </div>
        <div className="col-span-8 md:col-span-8 sm:grid-cols-1 ml-1 mr-1">
          <p className="font-medium text-base">Muhammad Arsyad Abdullah</p>
          <div className="beetween-container">
            <p className="text-sm">14 Jam</p>
            <div className="text-right text-sm">
              <button className="py-2 px-5 border border-gray-300 text-md rounded-md text-gray-600 hover:bg-gray-600 hover:text-white md:py-3 md:text-md md:px-5 -mt-10">
                Ikuti Diskusi
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="mt-4">komentar</p>
    </div>
  );
};

const Diskusi: React.FC = () => {
  return (
    <>
      <div className="container">
        <div className="bg-green-100 p-3 rounded-md">
          <div className="flex flex- justify-between">
            <div className="flex">
              <p className="text-md font-semibold mt-3 pl-3">
                Ada yang perlu didiskusikan?
              </p>
            </div>
            <div className="flex">
              <a
                href="/login"
                className="py-2 px-5 border border-transparent text-md rounded-md text-white bg-green-500 hover:bg-green-600 md:py-3 md:text-md md:px-5"
              >
                Tulis Pertanyaan
              </a>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 rounded-md mt-5">
          <ItemsDiskusi />
        </div>
      </div>
    </>
  );
};

const WrapperMain: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  // File Type
  const fileType = ["png", "jpg", "jpeg"];

  // Get Data Pelatihan
  const { getDetailPelatihan } = useDetailPelatihanContext();

  // Get pelatihan saya
  const {
    getCurrentDateServer,
    setShowModalRegisterPelatihan,
    setShowModalReviewPelatihan,
  } = usePelatihanSayaContext();
  const { showModalRegisterPelatihan, showModalReviewPelatihan } =
    usePelatihanSayaContext().state;

  // Get Data Review
  const {
    getReviewPelatihan,
    storeReviewPelatihan,
    setKomentar,
    setRatingInput,
    setPathFotoReviewPelatihan,
    setFormEvaluasiEvidence,
  } = useReviewPelatihanContext();

  useEffect(() => {
    const fetchData = async () => {
      await getCurrentDateServer();
      const detailPelatihan = await getDetailPelatihan(id);

      if (detailPelatihan) {
        getReviewPelatihan(1, "", detailPelatihan.id, false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pelatihan, isLoading } = useDetailPelatihanContext().state;

  const { currentTime } = usePelatihanSayaContext().state;

  const handleChangeFilePendukung = (file: File) => {
    setPathFotoReviewPelatihan(file);
  };

  const handleEvidenceFormEvaluasi = (file: File) => {
    setFormEvaluasiEvidence(file);
  };

  const currentDate = currentTime;

  const handleJoinPelatihan = (e) => {
    e.preventDefault();
    if (pelatihan != null) {
      if (
        moment(currentDate).isAfter(pelatihan.waktu_mulai) &&
        moment(currentDate).isBefore(pelatihan.waktu_selesai)
      ) {
        window.location.href = "/detail_pelatihan_saya/" + id;
      } else if (moment(currentDate).isAfter(pelatihan.waktu_selesai)) {
        toast(
          "Mohon Maaf, Pelatihan yang anda ikuti sudah melewati batas waktu pelaksanaan"
        );
      }
    }
  };

  return (
    <Wrapper>
      <HeaderBanner />

      <DetailItem />

      {/* Modal Gabung Pelatihan */}
      {showModalRegisterPelatihan && (
        <Modal id_modal="modalGabungPelatihan">
          <div className="flex items-start justify-between p-4 rounded-t">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Mulai Pelatihan
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              style={{ color: "white" }}
              onClick={() => setShowModalRegisterPelatihan(false)}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6 -mt-5">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Apakah anda yakin ingin memulai pelatihan?
            </p>
          </div>
          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              type="button"
              className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
              onClick={(e) => handleJoinPelatihan(e)}
            >
              Yakin
            </button>
            <button
              type="button"
              onClick={() => setShowModalRegisterPelatihan(false)}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Tidak
            </button>
          </div>
        </Modal>
      )}

      {/* Modal Review */}
      {showModalReviewPelatihan && (
        <Modal id_modal="modalReview">
          <div className="flex items-start justify-between p-4 rounded-t">
            <h3 className="text-xl font-semibold text-gray-900">
              Review Pelatihan
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              style={{ color: "white" }}
              onClick={() => setShowModalReviewPelatihan(false)}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6 -mt-5">
            <div
              className="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg"
              role="alert"
            >
              <table>
                <tbody>
                  <tr>
                    <td className="font-semibold text-base text-gray-700">
                      Judul Pelatihan
                    </td>
                    <td className="font-semibold text-base text-gray-700 px-1">
                      :
                    </td>
                    <td>
                      <p className="text-base text-gray-700">
                        {pelatihan?.judul_pelatihan}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold text-base text-gray-700">
                      Penyelenggara Pelatihan
                    </td>
                    <td className="font-semibold text-base text-gray-700 px-1">
                      :
                    </td>
                    <td>
                      <p className="text-base text-gray-700">
                        {pelatihan?.penyelenggara}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-center">
              <ReactStars
                count={5}
                size={40}
                activeColor="#ffd700"
                onChange={(e) => setRatingInput(e)}
              />
            </div>
            <div className="mt-2">
              <label className="block mb-2 text-sm">File Pendukung</label>
              <FileUploader
                multiple={true}
                handleChange={handleChangeFilePendukung}
                name="file"
                types={fileType}
                style={{ width: "100%" }}
              />
            </div>
            <div className="mt-2">
              <label className="block mb-2 text-sm">Testimoni</label>
              <textarea
                className="form-control block
              w-full
              px-3
              py-3
              text-sm
              font-normal
              text-gray-700
              bg-gray-100 bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
                rows={5}
                onChange={(e) => setKomentar(e.target.value)}
              />
            </div>
            <div className="mt-2">
              <label className="block mb-2 text-sm">Form evaluasi</label>
              <Link
                href="https://docs.google.com/forms/d/e/1FAIpQLSe0M73gmy2YOPloLVP8icq_gQ36kV2IerIbJD-VCw2euF7wEw/viewform"
                isExternal
                color="#0658c2"
              >
                Klik disini <Icon as={FaExternalLinkAlt} mx="2px" />
              </Link>
            </div>
            <div className="mt-2">
              <label className="block mb-2 text-sm">
                Upload bukti sudah mengisi form evaluasi
              </label>
              <FileUploader
                handleChange={handleEvidenceFormEvaluasi}
                name="file"
                types={fileType}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="items-center p-6 space-x-2 rounded-b">
            <button
              type="button"
              className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"
              onClick={() => storeReviewPelatihan(pelatihan?.id)}
            >
              <If condition={isLoading}>
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
                <Else />
                Kirim Review
              </If>
            </button>
          </div>
        </Modal>
      )}

      {/* Modal Review Image */}
      <ModalReviewImage />
    </Wrapper>
  );
};

const PelatihanDetails: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const statusPengisian = GetLocalStorage({ name: "status_pengisian" });

  useEffect(() => {
    if (isAuthenticated() !== false && statusPengisian !== "true") {
      window.location.href = "/profile";
    }
  }, []);

  return (
    <DetailPelatihanController.Provider>
      <ReviewPelatihanController.Provider>
        <PelatihanSayaController.Provider>
          <SertifikatController.Provider>
            <ToastContainer
              position="top-center"
              style={{ width: "60%", marginTop: "5%" }}
            />
            <WrapperMain />
          </SertifikatController.Provider>
        </PelatihanSayaController.Provider>
      </ReviewPelatihanController.Provider>
    </DetailPelatihanController.Provider>
  );
};

export default PelatihanDetails;
