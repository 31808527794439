import { injectable } from 'tsyringe'
import { UjianApiRepository } from '../../app/repository/api/UjianApiRepository'
import { Exam } from '../../entities/Exam'

@injectable()
export class UjianPresenter {
  private repository: UjianApiRepository
  constructor(repository: UjianApiRepository) {
    this.repository = repository
  }

  public getUjian(sub_modul_pelatihan_id: number): Promise<Exam> {
    return this.repository.getUjian(sub_modul_pelatihan_id)
  }

  public startUjian(sub_modul_pelatihan_id: number): Promise<any> {
    return this.repository.startUjian(sub_modul_pelatihan_id)
  }

  public finishUjian(sub_modul_pelatihan_id: number): Promise<any> {
    return this.repository.finishUjian(sub_modul_pelatihan_id)
  }

  public answerUjian(soal_id: number, id_jawaban_peserta: number, jawaban_peserta: string): Promise<any> {
    return this.repository.answerUjian(soal_id, id_jawaban_peserta, jawaban_peserta)
  }

  public updateWaktu(sub_modul_pelatihan_id: number): Promise<any> {
    return this.repository.updateWaktu(sub_modul_pelatihan_id)
  }
}
