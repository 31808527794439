import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaSearch } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import Loader from "../../../components/Loader";
import ManajemenKontributorPengetahuanController, {
  useManajemenKontributorPengetahuanContext,
} from "../../../controller/admin/manajemen_kontributor_pengetahuan";

/**
 * ManajemenKontributorPengetahuan component.
 *
 * This component is used to manage contributors of knowledge.
 * It displays a list of contributors with their details and allows filtering by keyword.
 *
 * @return {JSX.Element} The JSX element representing the component.
 * @author Bagas Prasetyadi
 */

const ManajemenKontributorPengetahuan = () => {
  const { listData, keyword, isLoading, totalListData, perPage, page, status } =
    useManajemenKontributorPengetahuanContext().state;
  const { fetchData, setManajemenKontributorPengetahuanState: setState } =
    useManajemenKontributorPengetahuanContext().dispatch;

  const history = useHistory();

  const getStatusColor = (status) => {
    switch (status) {
      case "diajukan":
        return "blue";
      case "diterima":
        return "green";
      case "ditolak":
        return "red";
      default:
        return "gray";
    }
  };

  const columns: any = [
    {
      name: "NO.",
      selector: (row, index) => (page - 1) * perPage + (index + 1),
      width: "80px",
    },
    {
      name: "NAMA",
      selector: (row) => row?.nama_lengkap,
      sortable: true,
      // width: "0px",
      wrap: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "DOMISILI",
      selector: (row) => row?.nama_kabupaten ?? "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "STATUS",
      selector: (row) => {
        return (
          <Badge colorScheme={getStatusColor(row?.status)}>{row?.status}</Badge>
        );
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "TANGGAL DAFTAR",
      selector: (row) => row?.created_at,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "AKSI",
      cell: (row, index) => (
        <Flex alignItems="center">
          <Button
            size="sm"
            colorScheme="blue"
            leftIcon={<Icon as={FiEye} />}
            h="30px"
            onClick={() => {
              history.push(
                `/admin/manajemen_kontributor_pengetahuan/verifikator/${row?.nomor_pengajuan}`
              );
            }}
          >
            Lihat
          </Button>
        </Flex>
      ),
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  const listStatus = [
    { value: "diajukan", label: "Diajukan" },
    { value: "diterima", label: "Diterima" },
    { value: "ditolak", label: "Ditolak" },
  ];

  const handlePerRowsChange = (newPerPage) => {
    setState("page", 1);
    setState("perPage", newPerPage);
  };

  const handlePageChange = (page) => {
    setState("page", page);
  };

  useEffect(() => {
    fetchData();
  }, [keyword, page, perPage, status]);
  return (
    <SidebarWithHeader spacing={false} title="Kontributor Pengetahuan">
      <Box bg="white" p={4} rounded="xl" m={4}>
        <Flex justifyContent="flex-end">
          <Select
            options={listStatus}
            placeholder="Filter Pengajuan"
            onChange={(e) => setState("status", e?.value)}
            className="w-60"
            isClearable
          />
          <InputGroup w="250px" mt={{ base: 4, md: 0 }} ml={2}>
            <InputLeftElement pointerEvents="none">
              <FaSearch />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Cari Disini..."
              onChange={(e) => {
                setState("keyword", e.target.value);
                setState("page", 1);
              }}
            />
          </InputGroup>
        </Flex>
        <Box mt={4}>
          <DataTable
            columns={columns}
            data={listData}
            customStyles={customStyles}
            pagination
            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            paginationPerPage={perPage}
            paginationServer
            paginationTotalRows={listData.length > 0 ? totalListData : 0}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={isLoading}
            progressComponent={<Loader />}
            noDataComponent="Data tidak ditemukan"
            responsive
          />
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const ManajemenKontributorPengetahuanWrapper = () => {
  return (
    <ManajemenKontributorPengetahuanController>
      <ManajemenKontributorPengetahuan />
    </ManajemenKontributorPengetahuanController>
  );
};

export default ManajemenKontributorPengetahuanWrapper;
