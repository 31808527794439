/**
 * UserProfile Component displays the user profile information and notifications dropdown.
 *
 * @component
 * @example
 * return (
 *   <UserProfile />
 * )
 */

import React, { useState } from "react";
import { useAuthUser, useSignOut } from "react-auth-kit";
import moment from "moment";
import If from "if-else-react";

import UserController, {
  useUserContext,
} from "../../controller/registered/profile";
import { useNotificationsContext } from "../../controller/notifications";
import { GetAuthData } from "../../../misc/GetAuthData";
import {
  clearLocalStorageExcept,
  GetLocalStorage,
} from "../../../misc/UseLocalStorage";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Image,
  Text,
  List,
  ListItem,
  Link,
  UnorderedList,
  Icon,
} from "@chakra-ui/react";
import { FaBell, FaCommentAlt } from "react-icons/fa";

/**
 * UserProfile component.
 *
 * @returns {React.FC} The UserProfile component.
 * @author Bagas Prasetyadi
 */

const UserProfile: React.FC = () => {
  const { foto_user } = useUserContext().state;
  const { notifData } = useNotificationsContext().state;
  const notifStatus = notifData.filter((data) => data.data.is_read === false);
  const { getNotifications, readAllNotifications } = useNotificationsContext();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const signOut = useSignOut();
  const history = useHistory();

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const onLogout = () => {
    closeDropdown();
    if (signOut()) {
      const keysToKeep = [
        "logoNavbar",
        "logoFooterLef",
        "logoFooterCenter",
        "contact",
        "copyright",
        "urlFacebook",
        "urlInstagram",
        "urlTwitter",
        "urlYoutube",
      ];

      clearLocalStorageExcept(keysToKeep);
      history.push(`/login`);
    }
  };

  // use auth data
  const auth = useAuthUser();

  React.useEffect(() => {
    if (auth() !== null) {
      getNotifications(auth()?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UserController.Provider>
      <Box>
        <Flex flexDirection="row" position="relative" mr={4}>
          {/* User Account */}
          <Box position="relative" className="user-profile">
            <Button
              id="dropdownDefault"
              data-dropdown-toggle="dropdown"
              bg="white"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {GetAuthData() !== null ? (
                foto_user ? (
                  <Image
                    src={foto_user}
                    alt={`gambar_profile_${
                      GetAuthData() != null ? GetAuthData()?.username : "-"
                    }`}
                    rounded="full"
                    boxSize="40px"
                  />
                ) : (
                  <Image
                    src={
                      GetLocalStorage({ name: "path_photo" }) !== "null"
                        ? GetLocalStorage({ name: "path_photo" })
                        : `https://ui-avatars.com/api/?name=${
                            GetAuthData()?.username
                          }&background=007E39&color=fff`
                    }
                    alt={`gambar_profile_${
                      GetAuthData() != null ? GetAuthData()?.username : "-"
                    }`}
                    rounded="full"
                    boxSize="40px"
                  />
                )
              ) : (
                ""
              )}

              <Text fontSize="sm" ml={2}>
                {GetAuthData() != null ? GetAuthData()?.username : "-"}
              </Text>
            </Button>
            <Box
              id="dropdown"
              zIndex={10}
              w={44}
              bg="white"
              rounded="lg"
              dividecolor="gray-100"
              boxShadow="md"
              position="absolute"
              visibility={showDropdown ? "visible" : "hidden"}
            >
              <List
                py="1"
                fontSize="sm"
                color="gray.700"
                bg="white"
                dividecolor="gray.100"
                shadow={["none", "none", "sm"]}
                rounded="lg"
              >
                <ListItem>
                  <Link
                    href="/profile"
                    display="block"
                    py="2"
                    px="4"
                    fontWeight="normal"
                    fontSize="sm"
                    _hover={{
                      bg: "gray.100",
                      dark: { bg: "gray.600", color: "white" },
                    }}
                    _focus={{ outline: "none" }}
                  >
                    Profil
                  </Link>
                </ListItem>
                <ListItem>
                  <Button
                    onClick={onLogout}
                    display="block"
                    py="2"
                    px="4"
                    width="full"
                    bg="white"
                    fontWeight="normal"
                    fontSize="sm"
                    _hover={{
                      bg: "gray.100",
                      dark: { bg: "gray.600", color: "white" },
                    }}
                    textAlign="left"
                  >
                    Keluar
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Box>

          {/* Notifications */}
          <Box position="relative">
            <Button
              id="dropdownDefaultNotifications"
              data-dropdown-toggle="dropdownNotifications"
              bg="white"
              onClick={() => setShowNotification(!showNotification)}
            >
              <If condition={notifStatus.length > 0}>
                <Flex h="2px" w="2px" pos="absolute">
                  <Flex
                    animate={{
                      bg: "red.400",
                      opacity: 0.75,
                    }}
                    transition="all 1s"
                    rounded="full"
                    bg="red.400"
                    opacity={0.75}
                    w="2px"
                    h="2px"
                    animation="ping 1s cubic-bezier(0, 0, 0.2, 1) infinite"
                  />
                  <Flex
                    bg="red.500"
                    rounded="full"
                    w="2px"
                    h="2px"
                    position="relative"
                  />
                </Flex>
              </If>

              <Icon as={FaBell} color="#007E39" marginRight="5px" boxSize={4} />
            </Button>
            <Box
              id="dropdownNotifications"
              position="absolute"
              zIndex="10"
              width="96"
              bg="white"
              rounded="lg"
              shadow="md"
              visibility={showNotification ? "visible" : "hidden"}
            >
              <Flex
                flexDirection="row"
                p="4"
                justify="space-between"
                alignItems="center"
                shadow="md"
              >
                <Text fontSize="lg" fontWeight="semibold">
                  Notifikasi
                </Text>
                <Button
                  size="xs"
                  colorScheme="blue"
                  variant="link"
                  onClick={() => readAllNotifications()}
                >
                  Tandai semua telah dibaca
                </Button>
              </Flex>
              <UnorderedList
                fontSize="sm"
                color="gray.700"
                borderBottomWidth="1px"
                borderColor="gray.200"
                listStyleType="none"
                ml={0}
              >
                {notifData.length > 0 ? (
                  notifData.map((val, idx) => {
                    const timestampDate = new Date(
                      val.data.timestamp.seconds * 1000
                    );
                    const timestamp = moment(timestampDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    );

                    return (
                      <ListItem
                        key={idx}
                        borderBottomWidth="1px"
                        bg={
                          val.data.is_read === false ? "green.100" : "gray.100"
                        }
                        _hover={{
                          bg:
                            val.data.is_read === false
                              ? "green.200"
                              : "gray.200",
                        }}
                        py={4}
                      >
                        <Button
                          flexDirection="row"
                          alignItems="center"
                          width="full"
                        >
                          {/* Icon */}
                          <Flex
                            bg="green.400"
                            rounded="full"
                            h="10"
                            w="12"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon as={FaCommentAlt} color="white" />
                          </Flex>

                          <Flex flexDir="column" ml="3" w="full">
                            {/* Info Judul */}
                            <Text
                              fontSize="sm"
                              fontWeight="semibold"
                              textAlign="left"
                            >
                              Pesan Terbaru Dari {val.data.nama_user}
                            </Text>

                            <Flex justify="space-between" mt="1">
                              {/* About Content Message */}
                              <Text fontSize="xs" color="gray.400">
                                {val.data.content.length > 20
                                  ? val.data.content.substring(0, 20) + "..."
                                  : val.data.content}
                              </Text>

                              {/* About Hours */}
                              <Text fontSize="xs" color="gray.400">
                                {moment(timestamp).startOf("minutes").fromNow()}
                              </Text>
                            </Flex>
                          </Flex>
                        </Button>
                      </ListItem>
                    );
                  })
                ) : (
                  <Text textAlign="center" p="5">
                    Belum Ada Notifikasi
                  </Text>
                )}
              </UnorderedList>
            </Box>
          </Box>
        </Flex>
      </Box>
      {/* Dropdown Notifications */}
    </UserController.Provider>
  );
};

export default UserProfile;
