import { AxiosResponse } from "axios";
import { SettingPenilaian } from "../../entities/SettingPenilaian";

export class SettingPenilaianDataMapper {
  public convertSettingPenlaianDataFromApi(
    result: AxiosResponse<any>
  ): SettingPenilaian[] {
    const { data } = result.data;

    let settingPenilaian: SettingPenilaian[] = [];

    data.map((sp: any) => {
      return settingPenilaian.push(
        new SettingPenilaian(
          sp.id,
          sp.jenis_pelatihan_id,
          sp.nilai_bawah,
          sp.nilai_atas,
          sp.predikat,
          sp.created_at,
          sp.updated_at
        )
      );
    });

    return settingPenilaian;
  }

  public convertDetailSettingPenlaianDataFromApi(
    result: AxiosResponse<any>
  ): SettingPenilaian {
    const { data } = result.data;

    let settingPenilaian: SettingPenilaian;

    settingPenilaian = new SettingPenilaian(
      data.id,
      data.jenis_pelatihan_id,
      data.nilai_bawah,
      data.nilai_atas,
      data.predikat,
      data.created_at,
      data.updated_at
    );

    return settingPenilaian;
  }
}
