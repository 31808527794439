import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
  Image,
  Link,
  Alert,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosCloseCircle } from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import ManajemenKontributorPengetahuanController, {
  useManajemenKontributorPengetahuanContext,
} from "../../../../controller/admin/manajemen_kontributor_pengetahuan";
import ManajemenPengetahuanContext, {
  useManajemenPengetahuanContext,
} from "../../../../controller/admin/manajemen_pengetahuan";
import FilePdf from "../../../../assets/svg/file-pdf.svg";
import FileWord from "../../../../assets/svg/file-word.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../../../../components/Loader";
import ModalVerification from "../../../../components/Admin/Kontributor/ModalVerification";

/**
 * A component that displays a grid item with a label and a value.
 *
 * @param {string} label - The label to be displayed.
 * @param {string} value - The value to be displayed.
 * @return {JSX.Element} The JSX element representing the grid item.
 */
const GridItemDetail = ({ label, value }) => (
  <>
    <GridItem colSpan={1}>
      <Text color="#3C4952" fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
    </GridItem>
    <GridItem colSpan={6}>
      <Text color="black" fontSize="sm">
        : {value ?? "-"}
      </Text>
    </GridItem>
  </>
);

/**
 * A component that displays a grid item with a file.
 *
 * @param {string} label - The label to be displayed.
 * @param {string} fileName - The name of the file.
 * @param {string} file - The URL of the file.
 * @return {JSX.Element} The JSX element representing the grid item with a file.
 */
const GridItemFile = ({ label, fileName, file }) => {
  const [isIframeVisible, setIsIframeVisible] = useState(false); // State untuk mengelola visibilitas iframe
  const fileExtension = fileName?.split(".").pop().toLowerCase();
  const fileIcon = fileExtension === "pdf" ? FilePdf : FileWord;

  const toggleIframeVisibility = () => {
    setIsIframeVisible(!isIframeVisible);
  };

  return (
    <>
      {file && (
        <>
          <GridItem colSpan={1}>
            <Text color="#3C4952 " fontSize="sm" fontWeight="semibold">
              {label}
            </Text>
          </GridItem>
          <GridItem colSpan={6}>
            <Box rounded="lg" p={2} border="1px solid #E7E7E7">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={fileExtension === "pdf" && isIframeVisible ? 2 : 0}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Image w="24px" h="24px" src={fileIcon} alt="file icon" />
                  <Text fontSize="sm">{fileName}</Text>
                </Stack>
                {fileExtension === "pdf" ? (
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    onClick={toggleIframeVisibility}
                    _hover={{ cursor: "pointer" }}
                  >
                    <Icon as={isIframeVisible ? FaEyeSlash : FaEye} />
                    <Text>{isIframeVisible ? "Sembunyikan" : "Lihat"}</Text>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    _hover={{ cursor: "pointer" }}
                  >
                    <Link href={file} isExternal fontSize="xs">
                      Unduh
                    </Link>
                  </Stack>
                )}
              </Flex>
              {fileExtension === "pdf" && isIframeVisible && (
                <iframe src={file} width="100%" height="400px" />
              )}
            </Box>
          </GridItem>
        </>
      )}
    </>
  );
};

/**
 * A component that handles the verification of a knowledge contributor.
 * It displays the contributor's biodata and knowledge content, and allows for verification.
 *
 * @return {JSX.Element} The JSX element representing the verification page.
 * @author Bagas Prasetyadi
 */
const VerifikatorKontributorPengetahuan = () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const { kontributorItem: item, isLoadingDetail } =
    useManajemenKontributorPengetahuanContext().state;
  const {
    getDetailKontributorPengetahuan,
    setManajemenKontributorPengetahuanState: setState,
  } = useManajemenKontributorPengetahuanContext().dispatch;

  const [tabIndexMenu, setTabIndexMenu] = useState(0);
  const [showButtonAction, setShowButtonAction] = useState(false);

  const showRevisiButton = !item?.has_publish && item?.status === "ditolak";
  const showTolakTerimaButton =
    !item?.has_publish && item?.status === "diajukan";

  const handleOpenModalVerification = (status: string) => {
    setState("statusVerification", status);
    setState("isOpenModal", true);
  };

  useEffect(() => {
    getDetailKontributorPengetahuan(id);
  }, []);

  return (
    <SidebarWithHeader
      spacing={false}
      title="Verifikasi Kontributor Pengetahuan"
    >
      <Box
        p={4}
        bg="#EDF2F7"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h2" fontSize="md" fontWeight="bold" lineHeight="normal">
            {item?.nama_lengkap}
          </Heading>
          {showRevisiButton && !showButtonAction && (
            <Button
              colorScheme="orange"
              onClick={() => setShowButtonAction(true)}
            >
              Revisi
            </Button>
          )}
          {(showTolakTerimaButton || showButtonAction) && (
            <Stack direction="row" spacing={3}>
              <Button
                colorScheme="red"
                onClick={() => handleOpenModalVerification("ditolak")}
              >
                Tolak
              </Button>
              <Button
                colorScheme="green"
                onClick={() => handleOpenModalVerification("diterima")}
              >
                Terima
              </Button>
            </Stack>
          )}
        </Flex>
      </Box>
      <Box mt={4} ml={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Text
            fontSize="sm"
            color="#7A8990"
            onClick={() => history.goBack()}
            _hover={{
              cursor: "pointer",
              color: "green.600",
            }}
          >
            Kontributor Pengetahuan
          </Text>
          <Icon as={IoIosArrowForward} color="#7A8990" />
          <Text fontSize="sm" color="green.600" fontWeight="semibold">
            Verifikasi Pengetahuan
          </Text>
        </Stack>
      </Box>
      <Box mt={4}>
        {isLoadingDetail ? (
          <Loader />
        ) : (
          <Box mx={4}>
            {item?.status === "ditolak" && (
              <Alert
                bg="#FEF3F3"
                shadow="sm"
                mb={4}
                rounded="md"
                display="flex"
                alignItems="start"
              >
                <Icon
                  color="red"
                  as={IoIosCloseCircle}
                  boxSize={5}
                  mr={1}
                  mt="2px"
                />
                <Stack direction="column" color="#BC1C21">
                  <AlertTitle fontWeight="normal">
                    Pengajuan Kontribusi Ditolak
                  </AlertTitle>
                  <AlertDescription
                    display="flex"
                    sx={{
                      marginTop: "0px !important",
                      lineHeight: "20px",
                    }}
                  >
                    <Text fontSize="sm" fontWeight="semibold" mr={1}>
                      Alasan DItolak:
                    </Text>
                    <Text fontSize="sm" fontWeight="normal">
                      {item?.alasan}
                    </Text>
                  </AlertDescription>
                </Stack>
              </Alert>
            )}
            <Tabs
              variant="unstyled"
              color="black"
              index={tabIndexMenu}
              onChange={(index) => setTabIndexMenu(index)}
            >
              <TabList bg="transparent" borderTopRadius="lg">
                <Tab
                  bg={tabIndexMenu === 0 ? "white" : "#E5E7EB"}
                  roundedTop="lg"
                  mr={2}
                >
                  Biodata
                </Tab>
                <Tab
                  bg={tabIndexMenu === 1 ? "white" : "#E5E7EB"}
                  roundedTop="lg"
                >
                  Konten Pengetahuan
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0} pb={5}>
                  <Grid
                    templateColumns="repeat(7, 1fr)"
                    py={8}
                    gap={4}
                    px={4}
                    bg="white"
                  >
                    <GridItemDetail
                      label="Nama Lengkap"
                      value={item?.nama_lengkap}
                    />
                    <GridItemDetail label="NIK" value={item?.nik} />
                    <GridItemDetail label="NIP" value={item?.nip} />
                    <GridItemDetail label="Email" value={item?.email} />
                    <GridItemDetail
                      label="No. HP"
                      value={item?.nomor_telepon}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel p={0}>
                  <Grid
                    templateColumns="repeat(7, 1fr)"
                    py={8}
                    gap={4}
                    px={4}
                    bg="white"
                  >
                    <GridItemDetail label="Judul" value={item?.judul} />
                    <GridItemDetail label="Kategori" value="Lainnya" />
                    <GridItemDetail label="Bidang" value={item?.bidang} />
                    <GridItemFile
                      label="Dokumen Publikasi"
                      fileName={item?.nama_file_dokumen}
                      file={item?.file_dokumen}
                    />
                    <GridItemFile
                      label="Naskah Publikasi"
                      fileName={item?.nama_file_naskah}
                      file={item?.file_naskah}
                    />

                    {item?.link_video && (
                      <>
                        <GridItem colSpan={1}>
                          <Text
                            color="#3C4952"
                            fontWeight="semibold"
                            fontSize="sm"
                          >
                            Link Kontribusi
                          </Text>
                        </GridItem>
                        <GridItem colSpan={6}>
                          <Link
                            color="blue"
                            href={item?.link_video}
                            isExternal
                            fontStyle="italic"
                          >
                            : {item?.link_video}
                          </Link>
                        </GridItem>
                      </>
                    )}
                    <GridItemDetail
                      label="Deskripsi Singkat"
                      value={item?.deskripsi}
                    />
                  </Grid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Box>

      <ModalVerification />
    </SidebarWithHeader>
  );
};

const VerifikatorKontributorPengetahuanWrapper = () => {
  return (
    <ManajemenPengetahuanContext.Provider>
      <ManajemenKontributorPengetahuanController>
        <VerifikatorKontributorPengetahuan />
      </ManajemenKontributorPengetahuanController>
    </ManajemenPengetahuanContext.Provider>
  );
};

export default VerifikatorKontributorPengetahuanWrapper;
