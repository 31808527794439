import React from "react";
import { container } from "tsyringe";
import { YoutubePresenter } from "../../../../../data/presenters/YoutubePresenter";
import { Youtube } from "../../../../../entities/Youtube";

interface IState {
  isLoading: boolean;
  listVideo: Youtube[];
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  getListVideo: Function;
}

const initialState = {
  state: {
    isLoading: false,
    listVideo: [],
    error: false,
    msgError: "",
  },
  getListVideo: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: YoutubeProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    listVideo: [],
    error: false,
    msgError: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const getListVideo = async () => {
    setLoading(true);

    try {
      const youtubePresenter = container.resolve(YoutubePresenter);
      const get = await youtubePresenter.getAllYoutubeVideo();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listVideo: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get list video youtube:", error);
      setLoading(false);
    }
  };

  return (
    <YoutubeProvider
      value={{
        state,
        getListVideo,
      }}
    >
      {children}
    </YoutubeProvider>
  );
};

export const useYoutubeContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useYoutubeContext,
  Provider,
};
