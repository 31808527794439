/**
 * This component is used to display a count card on a particular page.
 */

import React, { ReactElement } from "react";
import CountUp from "react-countup";
import { Box, Text, Grid, GridItem, Button, Container } from "@chakra-ui/react";
import { useLandingDataContext } from "../../controller/public/landing_data";
import { ReactComponent as IcBook } from "../../assets/svg/ic_book.svg";
import { ReactComponent as IcHat } from "../../assets/svg/ic_hat.svg";
import { ReactComponent as IcPerson } from "../../assets/svg/ic_person.svg";
import { ReactComponent as IcPersons } from "../../assets/svg/ic_persons.svg";

/**
 * CountingSection component.
 *
 * @returns {React.FC} The CountingSection component.
 * @author Bagas Prasetyadi
 */

/**
 * CountingCard component.
 *
 * @typedef {Object} CountingCardProps
 * @property {string} title - The title of the counting card.
 * @property {number} data - The data value of the counting card.
 * @property {React.ReactElement} icon - The icon element of the counting card.
 */

interface CountingCardProps {
  title: string;
  data: number;
  icon: ReactElement;
}

const CountingCard = ({ title, data, icon }: CountingCardProps) => {
  return (
    <Box
      bg="white"
      rounded="lg"
      overflow="hidden"
      p={6}
      boxShadow="lg"
      w="full"
      h="full"
    >
      <Text fontSize="lg">{title}</Text>
      <Text fontSize="4xl" fontWeight="bold" color="#007E39">
        <CountUp end={data ?? 0} duration={3} />
      </Text>
      <Box display="flex" justifyContent="end">
        {icon}
      </Box>
    </Box>
  );
};

const CountingSection: React.FC = () => {
  const { landingData } = useLandingDataContext().state;

  return (
    <Box bgGradient="linear(white 0%,  green.50 90%)">
      <Container maxW="8xl" py={{ base: 8, md: 20 }} w="full">
        <Grid
          gridTemplateColumns={{
            lg: "repeat(2, 1fr)",
            base: "repeat(1, 1fr)",
          }}
          gap={{ base: 4, md: 8 }}
        >
          <GridItem colSpan={{ base: 2, lg: 1 }} mt={{ base: 0, md: 8 }} mb={8}>
            <Box>
              <Text
                as="h1"
                fontWeight="semibold"
                fontSize={{ base: "2xl", md: "2.5rem" }}
              >
                Menjadi pintar dengan <br /> PINTAR sekarang
              </Text>
              <Text fontSize="md" mt={6}>
                berikut adalah data PINTAR (Pusat Informasi <br /> pelatihan &
                pembelajaran) Kementerian Agama RI
              </Text>
              <Box mt={6}>
                <Button
                  as="a"
                  href="/login"
                  mx="auto"
                  py={6}
                  fontSize="md"
                  fontWeight="medium"
                  rounded="md"
                  bg="green.700"
                  color="white"
                  _hover={{ bg: "green.800" }}
                  _active={{ bg: "green.800" }}
                >
                  Yuk Mulai Belajar
                </Button>
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 2, lg: 1 }}>
            <Grid
              gridTemplateColumns={{
                base: "repeat(1, 1fr)",
                lg: "repeat(2, 1fr)",
              }}
              gap={6}
            >
              {/* Data Pelatihan */}
              <CountingCard
                title="Pelatihan"
                data={landingData?.count_pelatihan ?? 0}
                icon={<IcHat />}
              />

              {/* Data Peserta */}
              <CountingCard
                title="Peserta"
                data={landingData?.count_peserta ?? 0}
                icon={<IcPersons />}
              />

              {/* Data Modul Belajar */}
              <CountingCard
                title="Modul Belajar"
                data={landingData?.count_materi ?? 0}
                icon={<IcBook />}
              />

              {/* Data Pengguna Aktif */}
              <CountingCard
                title="Pengguna Aktif"
                data={landingData?.count_active_user ?? 0}
                icon={<IcPerson />}
              />
            </Grid>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default CountingSection;
