import { injectable } from 'tsyringe'
import { PathMateriApiRepository } from '../../app/repository/api/PathMateriApiRepository'
import { PathMateri } from '../../entities/PathMateri'

@injectable()
export class PathMateriPresenter {
  private repository: PathMateriApiRepository
  constructor(repository: PathMateriApiRepository) {
    this.repository = repository
  }

  /**
   *
   * @param materi_id
   * @param jenis_materi
   * @returns
   */
  public getAllPathMateri(
    materi_id: number,
    jenis_materi: string,
  ): Promise<PathMateri[]> {
    return this.repository.getAllPathMateri(materi_id, jenis_materi)
  }

  /**
   *
   * @param materi_id
   * @param path_materi
   * @param jenis_materi
   * @param metode
   * @returns
   */
  public postPathMateri(
    materi_id: number,
    path_materi: any,
    jenis_materi: string,
    metode: any,
  ): Promise<any> {
    return this.repository.postPathMateri(
      materi_id,
      path_materi,
      jenis_materi,
      metode,
    )
  }

  /**
   *
   * @param pathMateriId
   * @param path_materi
   * @param jenis_materi
   * @param metode
   * @returns
   */
  public putPathMateri(
    pathMateriId: number,
    path_materi: any,
    jenis_materi: string,
    metode: any,
  ): Promise<any> {
    return this.repository.putPathMateri(
      pathMateriId,
      path_materi,
      jenis_materi,
      metode,
    )
  }

  /**
   *
   * @param pathMateriId
   * @returns
   */
  public deletePathMateri(pathMateriId: number): Promise<any> {
    return this.repository.deletePathMateri(pathMateriId)
  }
}
