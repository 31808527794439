import { Badge } from "@chakra-ui/react";
import React from "react";

/**
 * A badge component to display a category label with customizable color.
 * @param {Object} props - Component properties.
 * @param {string} props.category - The category label (e.g., "pdf", "ppt", "audio", "video").
 * @returns {JSX.Element} The CategoryBadge component displaying the category label with the specified color.
 * @author Bagas Prasetyadi
 */

const CategoryBadge = ({ category }: { category: string }) => {
  let badgeColor;
  switch (category) {
    case "pdf":
      badgeColor = "red";
      break;
    case "ppt":
      badgeColor = "orange";
      break;
    case "audio":
      badgeColor = "green";
      break;
    case "video":
      badgeColor = "blue";
      break;
    default:
      badgeColor = "red";
      break;
  }

  return (
    <Badge colorScheme={badgeColor} variant="solid" fontSize="0.8em">
      {category}
    </Badge>
  );
};

export default CategoryBadge;
