import React from "react";
import ReactPaginate from "react-paginate";

/**
 * Pagination component for rendering pagination controls.
 * Uses the ReactPaginate library to display pagination UI.
 *
 * @component
 * @param {object} props - Component props.
 * @param {Function} props.handleChange - A function to be called when a page is changed.
 * @param {number} props.page - The current active page.
 * @param {number} props.totalPage - The total number of pages.
 * @returns {JSX.Element} Pagination component JSX element.
 * @author Bagas Prasetyadi
 */

interface PaginationProps {
  handleChange: Function;
  page: number;
  totalPage: number;
}


const Pagination = ({ handleChange, totalPage, page }: PaginationProps) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="Selanjutnya"
      activeClassName="active"
      containerClassName="pagination"
      onPageChange={(e) => handleChange(e)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      pageCount={totalPage}
      previousLabel="Sebelumnya"
      forcePage={page - 1}
    />
  );
};

export default Pagination;
