export class Pelatihan {
  id: number;
  path_thumbnail: string;
  sub_menu_jenis_pelatihan_id: number;
  jenis_pelatihan_id: number;
  judul_pelatihan: string;
  waktu_mulai: string;
  waktu_selesai: string;
  waktu_mulai_pendaftaran: string;
  waktu_selesai_pendaftaran: string;
  penyelenggara: string;
  informasi_umum: string;
  kompetensi_teknis: string;
  persyaratan: string;
  angkatan: string;
  created_at: string;
  updated_at: string;
  views: string;
  rating: number;
  tanggal_pelatihan: string;
  count_reviewer: number;
  count_peserta: number;
  count_diskusi: number;
  jp: number;
  jumlahData: number;
  status_pelatihan: string;
  sub_menu_jenis_pelatihan: string;
  modulPelatihan: ModulPelatihan[];
  subModulPelatihan: SubModulPelatihan[];
  count_review_excellent: number;
  count_review_verry_good: number;
  count_review_average: number;
  count_review_poor: number;
  count_review_terrible: number;
  predikat_peserta?: string | null;
  status_tte: string;
  status_publish: number;
  status_review: number;
  nilai_akhir: number;
  is_ppkb: number;
  materi_inti: string;
  event_id: string;
  is_for_guru_madrasah: number;
  jenis_penilaian_id: number;
  uuid: string;

  constructor(
    id: number,
    path_thumbnail: string,
    sub_menu_jenis_pelatihan_id: number,
    jenis_pelatihan_id: number,
    judul_pelatihan: string,
    waktu_mulai: string,
    waktu_selesai: string,
    waktu_mulai_pendaftaran: string,
    waktu_selesai_pendaftaran: string,
    penyelenggara: string,
    informasi_umum: string,
    kompetensi_teknis: string,
    persyaratan: string,
    angkatan: string,
    created_at: string,
    updated_at: string,
    views: string,
    rating: number,
    tanggal_pelatihan: string,
    count_reviewer: number,
    count_peserta: number,
    count_diskusi: number,
    jp: number,
    jumlahData: number,
    status_pelatihan: string,
    sub_menu_jenis_pelatihan: string,
    modulPelatihan: ModulPelatihan[],
    subModulPelatihan: SubModulPelatihan[],
    count_review_excellent: number,
    count_review_verry_good: number,
    count_review_average: number,
    count_review_poor: number,
    count_review_terrible: number,
    predikat_peserta: string,
    status_tte: string,
    status_publish: number,
    status_review: number,
    nilai_akhir: number,
    is_ppkb: number,
    materi_inti: string,
    event_id: string,
    is_for_guru_madrasah: number,
    jenis_penilaian_id: number,
    uuid: string
  ) {
    this.id = id;
    this.path_thumbnail = path_thumbnail;
    this.sub_menu_jenis_pelatihan_id = sub_menu_jenis_pelatihan_id;
    this.jenis_pelatihan_id = jenis_pelatihan_id;
    this.judul_pelatihan = judul_pelatihan;
    this.waktu_mulai = waktu_mulai;
    this.waktu_selesai = waktu_selesai;
    this.waktu_mulai_pendaftaran = waktu_mulai_pendaftaran;
    this.waktu_selesai_pendaftaran = waktu_selesai_pendaftaran;
    this.penyelenggara = penyelenggara;
    this.informasi_umum = informasi_umum;
    this.kompetensi_teknis = kompetensi_teknis;
    this.persyaratan = persyaratan;
    this.angkatan = angkatan;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.views = views;
    this.rating = rating;
    this.tanggal_pelatihan = tanggal_pelatihan;
    this.count_reviewer = count_reviewer;
    this.count_peserta = count_peserta;
    this.count_diskusi = count_diskusi;
    this.jp = jp;
    this.jumlahData = jumlahData;
    this.count_peserta = count_peserta;
    this.status_pelatihan = status_pelatihan;
    this.sub_menu_jenis_pelatihan = sub_menu_jenis_pelatihan;
    this.modulPelatihan = modulPelatihan;
    this.subModulPelatihan = subModulPelatihan;
    this.count_review_excellent = count_review_excellent;
    this.count_review_verry_good = count_review_verry_good;
    this.count_review_average = count_review_average;
    this.count_review_poor = count_review_poor;
    this.count_review_terrible = count_review_terrible;
    this.predikat_peserta = predikat_peserta;
    this.status_tte = status_tte;
    this.status_publish = status_publish;
    this.status_review = status_review;
    this.nilai_akhir = nilai_akhir;
    this.is_ppkb = is_ppkb;
    this.materi_inti = materi_inti;
    this.event_id = event_id;
    this.is_for_guru_madrasah = is_for_guru_madrasah;
    this.jenis_penilaian_id = jenis_penilaian_id;
    this.uuid = uuid;
  }
}

export class ModulPelatihan {
  id: number;
  pelatihan_id: number;
  judul_modul: string;
  sub_modul: SubModulPelatihan[];
  created_at: string;
  updated_at: string;
  status_pembelajaran: string;

  constructor(
    id: number,
    pelatihan_id: number,
    judul_modul: string,
    sub_modul: SubModulPelatihan[],
    created_at: string,
    updated_at: string,
    status_pembelajaran: string
  ) {
    this.id = id;
    this.pelatihan_id = pelatihan_id;
    this.judul_modul = judul_modul;
    this.sub_modul = sub_modul;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.status_pembelajaran = status_pembelajaran;
  }
}

export class SubModulPelatihan {
  id: number;
  modul_pelatihan_id: number;
  paket_soal_id: number;
  materi_id: number;
  judul: string;
  jenis_ujian: string;
  jenis_sub_modul: string;
  passing_grade: string;
  durasi_latihan: string;
  m_final_project: any;
  m_materi: any;
  m_latihan: any;
  m_paket_soal: any;
  status_pembelajaran: string;
  ada_latihan: number;
  created_at: string;
  updated_at: string;
  waktu_pengumpulan_final_project: string;
  limit_soal: number;
  has_completed_video: boolean;
  t_sub_modul_pelatihan_id: number;

  constructor(
    id: number,
    modul_pelatihan_id: number,
    paket_soal_id: number,
    materi_id: number,
    judul: string,
    jenis_ujian: string,
    jenis_sub_modul: string,
    passing_grade: string,
    durasi_latihan: string,
    m_final_project: any,
    m_materi: any,
    m_latihan: any,
    m_paket_soal: any,
    status_pembelajaran: string,
    ada_latihan: number,
    created_at: string,
    updated_at: string,
    waktu_pengumpulan_final_project: string,
    limit_soal: number,
    has_completed_video: boolean,
    t_sub_modul_pelatihan_id: number
  ) {
    this.id = id;
    this.modul_pelatihan_id = modul_pelatihan_id;
    this.paket_soal_id = paket_soal_id;
    this.materi_id = materi_id;
    this.judul = judul;
    this.jenis_ujian = jenis_ujian;
    this.jenis_sub_modul = jenis_sub_modul;
    this.passing_grade = passing_grade;
    this.durasi_latihan = durasi_latihan;
    this.m_final_project = m_final_project;
    this.m_materi = m_materi;
    this.m_latihan = m_latihan;
    this.m_paket_soal = m_paket_soal;
    this.status_pembelajaran = status_pembelajaran;
    this.ada_latihan = ada_latihan;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.waktu_pengumpulan_final_project = waktu_pengumpulan_final_project;
    this.limit_soal = limit_soal;
    this.has_completed_video = has_completed_video;
    this.t_sub_modul_pelatihan_id = t_sub_modul_pelatihan_id;
  }
}
