import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  Image,
  Flex,
  Text,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Wrapper } from "../../../components/Wrapper";
import EventPintarPublicContext, {
  useEventPintarPublicContext,
} from "../../../controller/public/event_pintar";
import { AiOutlineClockCircle, IoLocationOutline } from "react-icons/all";
import moment from "moment";

/**
 * Component for displaying event card items.
 * @param {Object} props - Component properties.
 * @param {Object} props.item - Event item data.
 * @author Bagas Prasetyadi
 */

const CardItem = ({ item }) => {
  const descriptionLength = useBreakpointValue({
    base: 30,
    md: 60,
  });

  const sliceHTML = (html, maxLength) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const textContent = doc.body.textContent || "";
    const threeDots = textContent.length > maxLength ? "..." : "";

    return textContent.slice(0, maxLength) + threeDots;
  };

  const formatDate = (dateString) => {
    const dateObject = moment(dateString, "YYYY-MM-DD HH:mm:ss");
    const formattedDate = dateObject.format("DD MMM YYYY");

    return formattedDate;
  };

  return (
    <Box
      h="200px"
      w="full"
      display="flex"
      rounded="lg"
      border="1px"
      borderColor="gray.400"
      _hover={{ cursor: "pointer" }}
      onClick={() => {
        window.location.href = `/detail/event-pintar/${item.id}`;
      }}
    >
      <Image
        src={item.image}
        objectFit="cover"
        h="full"
        w="40%"
        mr={2}
        borderLeftRadius="md"
      />
      <Box w="full" p={2}>
        <Text fontSize={{ base: "md", md: "lg" }} fontWeight="semibold" mb={4}>
          {item.title}
        </Text>
        <Flex mb={2} alignItems="center">
          <Icon as={AiOutlineClockCircle} boxSize={4} mr={2} />
          <Text fontSize="sm">Dimulai pada {formatDate(item.created_at)}</Text>
        </Flex>
        <Text fontSize="sm" color="#7A8990" mb={4}>
          {sliceHTML(item.description, descriptionLength)}
        </Text>
        <Flex alignItems="center">
          <Icon as={IoLocationOutline} mr={2} />
          <Text fontSize="sm">{item.location}</Text>
        </Flex>
      </Box>
    </Box>
  );
};

/**
 * Component for displaying the detail page of an event.
 */

const DetailEventPintar = () => {
  const { data, loadingData, loadingDetail, listData } =
    useEventPintarPublicContext().state;
  const { getDetailEventPintar, getEventPintar } =
    useEventPintarPublicContext();

  const filterListData = listData
    .filter((item) => {
      return item.id.toString() !== window.location.pathname.split("/", 4)[3];
    })
    .splice(0, 3);

  useEffect(() => {
    getEventPintar();
    getDetailEventPintar(window.location.pathname.split("/", 4)[3]);
  }, []);

  return (
    <Box m={{ base: 8, md: 12 }}>
      <Grid templateColumns="repeat(3, 1fr)" gap={8}>
        {loadingDetail ? (
          <GridItem colSpan={{ base: 3, md: 2 }}>
            <Skeleton h="600px" w="full" mb={4} />
            <Skeleton h="300px" w="full" />
          </GridItem>
        ) : (
          <GridItem colSpan={{ base: 3, md: 2 }}>
            <Image
              src={data?.image}
              w="full"
              maxH="600px"
              objectFit="cover"
              mb={4}
              rounded="lg"
            />

            <Box rounded="lg" w="full" p={4} bg="white" mt={4}>
              <Text fontSize="lg" fontWeight="semibold" mr={2}>
                {data?.title}
              </Text>

              <Flex alignItems="center" mt={2}>
                <Icon as={AiOutlineClockCircle} boxSize={4} mr={2} />
                <Text fontSize="sm">
                  {moment(data?.created_at, "YYYY-MM-DD HH:mm:ss").fromNow()}
                </Text>
              </Flex>
              <Box
                mt={4}
                as="div"
                dangerouslySetInnerHTML={{
                  __html: data?.description || "",
                }}
              ></Box>
            </Box>
          </GridItem>
        )}
        {loadingData ? (
          <GridItem colSpan={{ base: 3, md: 1 }}>
            <Skeleton h="300px" w="full" mb={4} />
            <Skeleton h="300px" w="full" mb={4} />
            <Skeleton h="300px" w="full" mb={4} />
          </GridItem>
        ) : (
          <GridItem colSpan={{ base: 3, md: 1 }}>
            <Box rounded="lg" p={4} bg="white">
              <Text fontSize="lg" fontWeight="semibold" mb={4}>
                Event Lainnya
              </Text>
              {filterListData.length &&
                filterListData.map((item, i) => (
                  <Box mb={filterListData.length === i + 1 ? 0 : 4}>
                    <CardItem item={item} />
                  </Box>
                ))}
            </Box>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};

const DetailEventPintarMain = () => {
  return (
    <Wrapper>
      <EventPintarPublicContext.Provider>
        <DetailEventPintar />
      </EventPintarPublicContext.Provider>
    </Wrapper>
  );
};

export default DetailEventPintarMain;
