import { KelurahanRepositoryInterface } from "../../../data/contracts/Kelurahan";
import { KelurahanDataMapper } from "../../../data/mappers/Kelurahan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Kelurahan } from "../../../entities/Kelurahan";

export class KelurahanApiRepository implements KelurahanRepositoryInterface {
  private apiService: ApiService;
  private mapper: KelurahanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: KelurahanDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllKelurahan(
    page: number,
    rows: number,
    keyword: string
  ): Promise<Kelurahan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.kelurahan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers
    );
    return this.mapper.convertKelurahanDataFromApi(dataResponse);
  }

  public async getKelurahanById(id: number): Promise<Kelurahan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.kelurahan() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertKelurahanDataFromApi(dataResponse);
  }

  public async createKelurahan(
    kecamatan_id: string,
    nama_kelurahan: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.wilayahKelurahan(),
      {
        kecamatan_id: kecamatan_id,
        nama_kelurahan: nama_kelurahan,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async updateKelurahan(
    id: number,
    kecamatan_id: string,
    nama_kelurahan: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.wilayahKelurahan() + "/" + id,
      {
        kecamatan_id: kecamatan_id,
        nama_kelurahan: nama_kelurahan,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async deleteKelurahan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.wilayahKelurahan() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
