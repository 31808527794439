/* eslint-disable eqeqeq */
import { AxiosResponse } from "axios";
import { GetLocalStorage } from "../../app/misc/UseLocalStorage";
import { Kabupaten } from "../../entities/Kabupaten";

export class KabupatenDataMapper {
  public convertKabupatenDataFromApi(result: AxiosResponse<any>): Kabupaten[] {
    const { data } = result.data;

    let kabupaten: Kabupaten[] = [];

    data.map((u: any) => {
      return kabupaten.push(
        new Kabupaten(
          u.id,
          u.provinsi_id,
          u.nama_kabupaten,
          u.created_at,
          u.updated_at,
          GetLocalStorage({ name: "level" }) == "superadmin"
            ? result.data.pagination.total_rows
            : ""
        )
      );
    });

    return kabupaten;
  }
}
