import { Exam, MSoal } from "../../entities/Exam";
import { AxiosResponse } from "axios";

export class ExamDataMapper {
  public convertListExamData(result: AxiosResponse<any>): Exam {
    const { data } = result.data;

    let m_soal: any = [];

    data.soal.map((u: any) => {
      // Push MSoal
      return m_soal.push(
        new MSoal(
          u.id,
          u.paket_soal_id,
          u.soal,
          u.id_jawaban_latihan,
          u.jawaban_peserta,
          u.created_at,
          u.updated_at,
          u.m_jawaban_soal
        )
      );
    });

    let exam: Exam = new Exam(
      data.latihan.judul,
      data.latihan.durasi_latihan,
      data.latihan.passing_grade,
      data.latihan.sisa_waktu,
      data.latihan.nilai,
      data.latihan.status,
      m_soal,
      data.latihan.sequence,
      data.latihan.total_sequence
    );

    return exam;
  }
}
