/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  FiUsers,
  FiCalendar,
  FiClock,
  FiTrash,
  FiEdit,
  FiSearch,
  TbCertificate,
  IoDuplicateOutline,
  FiDownload,
  FaFileSignature,
  MdOutlineCalculate,
  BiCheckCircle,
} from "react-icons/all";
import PrettyRating from "pretty-rating-react";
import DetailPelatihanDataPelatihanController, {
  useDetailPelatihanDataPelatihanContext,
} from "../../../../../controller/admin/manajemen_pelatihan/detail/data_pelatihan";
import DetailPelatihanKontenPelatihanController, {
  useDetailPelatihanKontenPelatihanContext,
} from "../../../../../controller/admin/manajemen_pelatihan/detail/konten_pelatihan";
import DetailPelatihanDaftarPesertaController, {
  useDetailPelatihanDaftarPesertaContext,
} from "../../../../../controller/admin/manajemen_pelatihan/detail/daftar_peserta";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import moment from "moment";
import parse from "html-react-parser";
import SidebarWithHeader from "../../../../../components/Admin/SidebarWithHeader";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Tag,
  Text,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Stack,
  Skeleton,
  Spinner,
  InputGroup,
  InputLeftAddon,
  Input,
  useDisclosure,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Tooltip,
} from "@chakra-ui/react";
import CountUp from "react-countup";
import DataTable from "react-data-table-component";
import { isAfter, parseISO } from "date-fns";
import ModalChakraComponent from "../../../../../components/ModalChakra";
import ContentPelatihan from "../../../../../components/Admin/ContentPelatihan/ContentPelatihan";
import ModalSertifikat from "../../../../../components/Admin/DaftarPeserta/ModalSertifikat";
import ManajemenSertifikatController, {
  useManajemenSertifikatContext,
} from "../../../../../controller/admin/manajemen_sertifikat";
import Select from "react-select";
import MasterTTEContext, {
  useMasterTTEContext,
} from "../../../../../controller/admin/data_master/tte";
import { GetAuthData } from "../../../../../../misc/GetAuthData";
import Loader from "../../../../../components/Loader";
import Pusher from "pusher-js";
import env from "@ludovicm67/react-dotenv";

const colors = {
  star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
};

const HeaderBanner: React.FC = () => {
  const { pelatihan, isLoading, isLoadingDuplicate } =
    useDetailPelatihanDataPelatihanContext().state;

  const { setSelectedId, setShowDialogDelete, setModalDuplicate } =
    useDetailPelatihanDataPelatihanContext();

  const level = GetAuthData()?.level;

  return (
    <Box borderRadius="md" background="GreenPrimary.500" boxShadow="lg">
      {isLoading ? (
        <Stack direction="column" p={7}>
          <Box>
            <Skeleton height="250px" borderRadius="md" />
          </Box>
          <Stack>
            <Skeleton height="40px" borderRadius="md" />
            <Skeleton height="40px" borderRadius="md" />
          </Stack>
        </Stack>
      ) : (
        <Grid
          templateColumns={{
            lg: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
          gap={7}
          p={7}
        >
          <GridItem zIndex={1}>
            <Box
              rounded={"lg"}
              _groupHover={{
                _after: {
                  filter: "blur(20px)",
                },
              }}
            >
              <Image
                rounded={"lg"}
                width="500px"
                h="300px"
                objectFit={"cover"}
                src={pelatihan?.path_thumbnail}
              />
            </Box>
          </GridItem>
          <GridItem>
            <Heading
              fontSize={"2xl"}
              fontFamily={"body"}
              fontWeight={700}
              color="white"
            >
              {pelatihan?.judul_pelatihan}
            </Heading>
            <Tag colorScheme="whatsapp" px={4} py={2} my={4}>
              {pelatihan?.penyelenggara}
            </Tag>

            <Flex
              alignItems="center"
              color="white"
              fontSize={18}
              fontWeight={600}
              mt={2}
            >
              <Icon as={FiUsers} mr={2} color={"gold"} />
              <CountUp end={pelatihan != null ? pelatihan.count_peserta : 0} />
              <Text ml={1} fontSize={18}>
                Peserta
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              color="white"
              fontSize={18}
              fontWeight={600}
              mt={2}
            >
              <Icon as={FiCalendar} mr={2} color={"gold"} />
              <Text fontSize={18}>
                {moment(pelatihan?.waktu_mulai).format("LL")} s.d{" "}
                {moment(pelatihan?.waktu_selesai).format("LL")}
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              color="white"
              fontSize={18}
              fontWeight={600}
              mt={2}
            >
              <Icon as={FiClock} mr={2} color={"gold"} />
              <Text fontSize={18}>{pelatihan?.jp} JP</Text>
            </Flex>

            <Flex
              alignItems="center"
              color="white"
              fontSize={18}
              fontWeight={600}
              mt={4}
            >
              <Box mr={2}>
                <PrettyRating
                  value={pelatihan != null ? pelatihan.rating : 0}
                  colors={colors.star}
                />
              </Box>
              <CountUp end={pelatihan != null ? pelatihan.rating : 0} />
              <Text ml={1} fontSize={18}>
                (
                <CountUp
                  end={pelatihan != null ? pelatihan.count_reviewer : 0}
                />{" "}
                Reviews)
              </Text>
            </Flex>

            {(level === "superadmin" || level === "admin") && (
              <Flex mt={4}>
                <Button
                  colorScheme="red"
                  color="white"
                  mr={4}
                  fontSize={16}
                  onClick={() => {
                    setSelectedId(pelatihan?.id);
                    setShowDialogDelete(true);
                  }}
                >
                  <Flex>
                    Hapus
                    <Icon as={FiTrash} ml={2} />
                  </Flex>
                </Button>
                <Button
                  as="a"
                  href={`/admin/ubah/manajemen_pelatihan/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  colorScheme="blue"
                  color="white"
                  fontSize={16}
                  mr={4}
                >
                  <Flex>
                    Edit
                    <Icon as={FiEdit} ml={2} />
                  </Flex>
                </Button>
                <Button
                  colorScheme="whatsapp"
                  fontSize={16}
                  rightIcon={<IoDuplicateOutline />}
                  isLoading={isLoadingDuplicate}
                  onClick={() => {
                    setModalDuplicate(true);
                  }}
                >
                  Duplikat
                </Button>
              </Flex>
            )}
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

const DetailPelatihan: React.FC = () => {
  const { pelatihan } = useDetailPelatihanDataPelatihanContext().state;

  return (
    <Box>
      <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" mb={1} w="20%">
          Jenis Pelatihan
        </Heading>
        <Text fontSize="md" w="80%">
          : {pelatihan?.sub_menu_jenis_pelatihan}
        </Text>
      </Flex>
      <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Judul Pelatihan
        </Heading>
        <Text fontSize="md" w="80%">
          : {pelatihan?.judul_pelatihan}
        </Text>
      </Flex>
      <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Angkatan
        </Heading>
        <Text fontSize="md" w="80%">
          : {pelatihan?.angkatan}
        </Text>
      </Flex>
      <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Waktu Mulai Pelaksanaan Pelatihan
        </Heading>
        <Text fontSize="md" w="80%">
          : {moment(pelatihan?.waktu_mulai).format("LL")} s.d{" "}
          {moment(pelatihan?.waktu_selesai).format("LL")}
        </Text>
      </Flex>
      <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Waktu Mulai Pendaftaran Pelatihan
        </Heading>
        <Text fontSize="md" w="80%">
          : {moment(pelatihan?.waktu_mulai_pendaftaran).format("LL")} s.d{" "}
          {moment(pelatihan?.waktu_selesai_pendaftaran).format("LL")}
        </Text>
      </Flex>
      <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Penyelenggara Pelatihan
        </Heading>
        <Text fontSize="md" w="80%">
          : {pelatihan?.penyelenggara}
        </Text>
      </Flex>
      {/* <Flex mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Link Grup Telegram Pelatihan
        </Heading>
        <Text fontSize="md" w="80%">
          : {pelatihan?.link_qr_pelatihan ?? "-"}
        </Text>
      </Flex> */}
      <Flex direction="column" mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Informasi Umum Pelatihan
        </Heading>
        <Box fontSize="md">
          {parse(
            pelatihan?.informasi_umum != null ? pelatihan.informasi_umum : "-"
          )}
        </Box>
      </Flex>
      <Flex direction="column" mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Kompetensi Teknis Pelatihan
        </Heading>
        <Box fontSize="md">
          {parse(
            pelatihan?.kompetensi_teknis != null
              ? pelatihan.kompetensi_teknis
              : "-"
          )}
        </Box>
      </Flex>
      <Flex direction="column" mb={4}>
        <Heading fontSize="md" fontWeight="semibold" w="20%" mb={1}>
          Syarat Pelatihan
        </Heading>
        <Box fontSize="md">
          {parse(pelatihan?.persyaratan != null ? pelatihan.persyaratan : "-")}
        </Box>
      </Flex>
    </Box>
  );
};

const DaftarPeserta: React.FC = () => {
  const {
    search,
    setPageRow,
    getDaftarPeserta,
    setShowDialogDeletePeserta,
    setPelatihanPesertaId,
    setShowSertifikat,
    setPathSertifikat,
    deletePesertaPelatihan,
    downloadPeserta,
    setPage,
    calculatePelatihanValue,
    genereateExcel,
  } = useDetailPelatihanDaftarPesertaContext();

  const {
    pelatihanSaya,
    isLoading,
    keyword,
    isLoadingDeletePeserta,
    isLoadingDownloadPeserta,
    page,
    row: rows,
    loadingRowIdCalculatePelatihan,
    isLoadingGenerateExcel,
    pelatihan_id,
  } = useDetailPelatihanDaftarPesertaContext().state;

  const {
    setPelatihanId,
    setShowDialog,
    setTteAction,
    setUserId,
    setTteUlang,
  } = useManajemenSertifikatContext();

  const level = GetAuthData()?.level;

  const [perPage, setPerPage] = useState(10);
  const [filterText] = React.useState("");
  const [resetPaginationToggle] = React.useState(false);
  const [generateLatihanId, setgenerateLatihanId] = useState<string[]>([])

  const paginationRowsPerPageOptions = [10, 20, 30, 50, 100];

  const {
    onOpen: onOpenDeletePesertaModal,
    onClose: onCloseDeletePesertaModal,
    isOpen: isOpenDeletePesertaModal,
  } = useDisclosure();

  const pusherApiKey = env.REACT_APP_PUSHER_API_KEY || "";
  const pusherCluster = env.REACT_APP_PUSHER_CLUSTER || "";

  useEffect(() => {
    const pusher = new Pusher(pusherApiKey, {
      cluster: pusherCluster,
    });
    const channel = pusher.subscribe("job-channel");

    channel.bind("job-completed", function (data) {
      setgenerateLatihanId((prevState) => [...prevState, data.pelatihan_id]);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [pusherApiKey, pusherCluster]);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => (page - 1) * rows + (index + 1),
      grow: 0,
    },
    {
      name: "Nama Lengkap",
      selector: (row) => row.peserta.name,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "NIP / NIK",
      selector: (row) => row.peserta.nip || row.peserta.nik,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Nilai",
      selector: (row) => row.nilai_akhir,
      sortable: true,
    },
    {
      name: "Predikat",
      selector: (row) => (row.predikat != null ? row.predikat : "-"),
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Modul Selesai",
      selector: (row) => row.modul_selesai,
      sortable: true,
      wrap: true,
    },
    {
      name: "Modul Total",
      selector: (row) => row.modul_belum,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status_pelatihan,
      sortable: true,
      wrap: true,
    },
    ...(level === "superadmin" || level === "admin"
      ? [
          {
            name: "Action",
            selector: (row) => {
              return (
                <Box display="flex" flexDirection="column" my={3}>
                  {/* Delete Button */}
                  <Button
                    colorScheme="red"
                    leftIcon={<FiTrash />}
                    onClick={() => {
                      onOpenDeletePesertaModal();
                      setPelatihanPesertaId(row.id);
                    }}
                  >
                    Hapus Peserta
                  </Button>

                  {/* Download Sertifikat */}
                  <If condition={row.path_sertifikat != null}>
                    <Button
                      mt={3}
                      colorScheme="blue"
                      leftIcon={<TbCertificate />}
                      onClick={() => {
                        setPathSertifikat(row.path_sertifikat);
                        setShowSertifikat(true);
                      }}
                    >
                      Lihat Sertifikat
                    </Button>
                  </If>

                  {row.path_sertifikat === null ? (
                    <Tooltip
                      label="TTE ini akan men-generate sertifikat peserta yang dipilih"
                      placement="left"
                    >
                      {/* Button TTE Standard */}
                      <Button
                        mt={3}
                        colorScheme="green"
                        leftIcon={<FaFileSignature />}
                        onClick={() => {
                          setPelatihanId(row.pelatihan_id);
                          setUserId(row.user_id);
                          setShowDialog(true);
                          setTteAction("standard");
                          setTteUlang(false);
                        }}
                      >
                        TTE Sertifikat
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      label="TTE ini akan men-generate ulang sertifikat peserta yang dipilih"
                      placement="left"
                    >
                      {/* Button TTE Ulang */}
                      <Button
                        mt={3}
                        colorScheme="gray"
                        leftIcon={<FaFileSignature />}
                        onClick={() => {
                          setPelatihanId(row.pelatihan_id);
                          setUserId(row.user_id);
                          setShowDialog(true);
                          setTteUlang(true);
                          setTteAction("standard");
                        }}
                      >
                        Regenerate Sertifikat
                      </Button>
                    </Tooltip>
                  )}

                  {/* Button Hitung Ulang Nilai Pelatihan */}

                  {row.status_tte === "1" &&
                    row.status_pelatihan === "selesai dipelajari" && (
                      <Button
                        mt={3}
                        colorScheme="yellow"
                        leftIcon={<MdOutlineCalculate />}
                        isLoading={loadingRowIdCalculatePelatihan === row.id}
                        onClick={() => {
                          calculatePelatihanValue(row.id);
                        }}
                      >
                        Hitung Ulang Nilai
                      </Button>
                    )}
                </Box>
              );
            },
            sortable: true,
            width: "200px",
            wrap: true,
          },
        ]
      : []),
  ];

  const handlePageChange = (page) => {
    setPage(page);
    getDaftarPeserta(page, keyword, perPage, pelatihan_id);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getDaftarPeserta(page, keyword, newPerPage, pelatihan_id);
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <Flex>
        <InputGroup size="md" mb={5} w={{ base: "full", md: "fit-content" }}>
          <InputLeftAddon children={<FiSearch />} background="white" />
          <Input
            placeholder="Cari Peserta..."
            background="white"
            onChange={(event) => search(event.target.value, pelatihan_id)}
          />
        </InputGroup>
        {(level === "superadmin" || level === "admin") && (
          <Stack direction="row" spacing={3} ml={3}>
            <Box position="relative">
              {generateLatihanId.includes(pelatihan_id) && (
                <Box position="absolute" bottom="0px" left="0" width="100%">
                  <Text
                    color="green.400"
                    fontSize="xs"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    gridGap={0.5}
                  >
                    <BiCheckCircle size={16} /> Generate Telah Selesai
                  </Text>
                </Box>
              )}
              <Button
                colorScheme="blue"
                leftIcon={<FiDownload />}
                fontSize="sm"
                onClick={() => genereateExcel()}
                isLoading={isLoadingGenerateExcel}
                loadingText="Generate Excel..."
              >
                Generate Excel
              </Button>
            </Box>
            <Button
              colorScheme="green"
              leftIcon={<FiDownload />}
              fontSize="sm"
              onClick={() => downloadPeserta()}
              isLoading={isLoadingDownloadPeserta}
              loadingText="Download..."
            >
              Download Excel
            </Button>
          </Stack>
        )}
      </Flex>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, resetPaginationToggle, isLoadingGenerateExcel, isLoadingDownloadPeserta, generateLatihanId]);

  const CustomLoader = () => (
    <Box p={24}>
      <Spinner />
    </Box>
  );

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  return (
    <>
      {/* Modal Delete */}
      <ModalChakraComponent
        onOpen={onOpenDeletePesertaModal}
        isOpen={isOpenDeletePesertaModal}
        onClose={onCloseDeletePesertaModal}
        modalTitle="Hapus Peserta"
        isSecondaryButton={true}
        secondaryButtonText="Hapus"
        secondaryColorScheme="red"
        secondaryBackground="red"
        handleAction={deletePesertaPelatihan}
        isLoading={isLoadingDeletePeserta}
        loadingText="Menghapus"
      >
        <Text>Apakah anda yakin ingin menghapus peserta ini?</Text>
      </ModalChakraComponent>

      <DataTable
        columns={columns}
        data={pelatihanSaya}
        progressPending={isLoading}
        pagination
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationServer
        paginationTotalRows={
          pelatihanSaya.length > 0 ? pelatihanSaya[0].jumlahData : 0
        }
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        progressComponent={<CustomLoader />}
      />
    </>
  );
};

const Penilaian = () => {
  const { modulPelatihan } = useDetailPelatihanKontenPelatihanContext().state;
  const { pelatihan } = useDetailPelatihanDataPelatihanContext().state;

  return (
    <Box>
      <Heading as="h4" p={5} fontSize="lg">
        Bobot Penilaian
      </Heading>
      <Divider mb={2} />
      {modulPelatihan.length
        ? modulPelatihan.map((item, i) => {
            return (
              <Box px={8} py={2} key={item.id}>
                <Text fontSize="md" fontWeight="semibold">
                  Section {i + 1}. {item.judul_modul}
                </Text>

                <Box mx={4}>
                  {item.subModulPelatihan.map((sm) => {
                    return (
                      <Grid
                        templateColumns={{
                          md: "repeat(11, 1fr)",
                          sm: "repeat(3, 1fr)",
                        }}
                        gap={5}
                        mt={2}
                        key={sm.id}
                      >
                        <GridItem colSpan={{ md: 2, sm: 1 }}>
                          <Text
                            as="label"
                            htmlFor="judul"
                            display="inline-block"
                            py={2}
                            color="gray.700"
                            fontWeight="md"
                            className="form-label"
                          >
                            {sm.judul}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={{ md: 5, sm: 1 }}>
                          <Input
                            type="number"
                            readOnly
                            defaultValue={
                              (sm.ada_latihan === 0 && sm.jenis_ujian === "") ||
                              pelatihan?.jenis_penilaian_id === 2
                                ? 0
                                : sm.bobot_penilaian
                            }
                            placeholder="Tidak Ada Nilai"
                            className="form-control"
                            bg="white"
                            border="1px solid"
                            borderColor="gray.300"
                            rounded="md"
                            transition="ease-in-out"
                            m={0}
                            _focus={{
                              color: "gray.700",
                              bg: "white",
                              borderColor: "blue.600",
                              outline: "none",
                            }}
                          />
                        </GridItem>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
            );
          })
        : null}
    </Box>
  );
};

const TabsMenu: React.FC = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const action = urlSearchParams.get("action") ?? "";

  const { isLoading } = useDetailPelatihanDataPelatihanContext().state;

  let initialTabIndex = 0;
  if (action === "get-pretest") {
    initialTabIndex = 3; // Indeks tab "Daftar Peserta"
  }

  return (
    <Box background="white" p={4} borderRadius="md" mt={4}>
      {isLoading ? (
        <Loader />
      ) : (
        <Tabs variant="unstyled" isLazy defaultIndex={initialTabIndex}>
          <TabList justifyContent="space-between">
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              }}
              gap={{ base: 2, md: 0 }}
            >
              <Tab
                _selected={{ color: "white", bg: "green.500", fontWeight: 700 }}
                borderRadius={{ base: "md", md: "none" }}
                borderTopLeftRadius={{ base: "md", md: "md" }}
                borderBottomLeftRadius={{ base: "md", md: "md" }}
                background="gray.200"
                color="gray.500"
              >
                Data Pelatihan
              </Tab>
              <Tab
                _selected={{ color: "white", bg: "green.500", fontWeight: 700 }}
                borderRadius={{ base: "md", md: "none" }}
                background="gray.200"
                color="gray.500"
              >
                Konten Pelatihan
              </Tab>
              <Tab
                _selected={{ color: "white", bg: "green.500", fontWeight: 700 }}
                borderRadius={{ base: "md", md: "none" }}
                background="gray.200"
                color="gray.500"
              >
                Penilaian
              </Tab>
              <Tab
                _selected={{ color: "white", bg: "green.500", fontWeight: 700 }}
                borderRadius={{ base: "md", md: "none" }}
                borderTopRightRadius={{ base: "md", md: "md" }}
                borderBottomRightRadius={{ base: "md", md: "md" }}
                background="gray.200"
                color="gray.500"
              >
                Daftar Peserta
              </Tab>
            </Grid>
          </TabList>
          <TabPanels p={0} mt={5}>
            <TabPanel p={0}>
              <DetailPelatihan />
            </TabPanel>
            <TabPanel p={0}>
              <ContentPelatihan />
            </TabPanel>
            <TabPanel p={0}>
              <Penilaian />
            </TabPanel>
            <TabPanel p={0}>
              <DaftarPeserta />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, isLoadingDelete } =
    useDetailPelatihanDataPelatihanContext().state;
  const { setShowDialogDelete, deletePelatihan } =
    useDetailPelatihanDataPelatihanContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deletePelatihan()}
                  >
                    <If condition={isLoadingDelete}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDuplicate = () => {
  const { duplicatePelatihan, setModalDuplicate } =
    useDetailPelatihanDataPelatihanContext();

  const { isLoadingDuplicate, isOpenModalDuplicate, pelatihan } =
    useDetailPelatihanDataPelatihanContext().state;

  const onCLoseModalDuplicate = () => {
    setModalDuplicate(false);
  };

  return (
    <Modal
      isOpen={isOpenModalDuplicate}
      onClose={onCLoseModalDuplicate}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="semibold" color="black">
          Duplikat Pelatihan
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Apakah anda yakin ingin menduplikasi data ini</ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onCLoseModalDuplicate}>
            BATAL
          </Button>
          <Button
            colorScheme="whatsapp"
            isLoading={isLoadingDuplicate}
            onClick={() => duplicatePelatihan(pelatihan?.id)}
          >
            YAKIN
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ModalTTESertifikat: React.FC = () => {
  const { setShowDialog, tteSertifikat, setAccountTTESelected } =
    useManajemenSertifikatContext();
  const { showDialog, isLoading, tteAction } =
    useManajemenSertifikatContext().state;

  const { listDataOption: listAccountTTEOption } = useMasterTTEContext().state;

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    TTE Sertifikat Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialog(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  {/* Alert */}
                  <div
                    id="alert-4"
                    className="flex p-4 mb-4 bg-yellow-100 rounded-lg"
                    role="alert"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-5 h-5 text-yellow-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-3">
                      <p className="text-md font-semibold text-yellow-700">
                        Tanda Tangan Elektronik
                      </p>
                      <p className="mt-2 text-red-700">
                        <b>PASSPHRASE</b> adalah password yang di-set ketika
                        membuat kunci tanda tangan digital Kementrian Agama
                        bersama Badan Siber dan Sandi Nasional (BSSN) Jika kunci
                        salah, maka proses penandatanganan digital akan{" "}
                        <b>GAGAL</b>
                      </p>
                      {tteAction === "urgent" && (
                        <p className="mt-2 text-red-700">
                          *TTE ini digunakan untuk seluruh sertifikat pelatihan
                          termasuk yang tidak lulus
                        </p>
                      )}
                    </div>
                    <button
                      type="button"
                      className="ml-auto -mx-1.5 -my-1.5 bg-yellow-100 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex h-8 w-8 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300"
                      data-dismiss-target="#alert-4"
                      aria-label="Close"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Form */}
                  <div>
                    {/* Akun TTE */}
                    <div>
                      <label className="text-sm font-medium text-gray-900">
                        Pilih Akun TTE
                      </label>
                      <div className="mt-3">
                        <Select
                          placeholder="Pilih Akun TTE"
                          options={listAccountTTEOption}
                          isClearable
                          onChange={(selectedOption) =>
                            setAccountTTESelected(selectedOption)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialog(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => tteSertifikat()}
                  >
                    <If condition={isLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Tanda Tangani Sertifikat Secara Digital</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  // Detail Data Pelatihan Context
  const { getDetailPelatihan } = useDetailPelatihanDataPelatihanContext();

  // Detail Konten Pelatihan Context
  const { getModulPelatihan, getPaketSoal, setPelatihan } =
    useDetailPelatihanKontenPelatihanContext();

  // Detail Daftar Peserta Pelatihan Context
  const { getDaftarPeserta } = useDetailPelatihanDaftarPesertaContext();
  const { pelatihan } = useDetailPelatihanDaftarPesertaContext().state;

  // Master TTE Context
  const { fetchData: getListAccountTTE } = useMasterTTEContext();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const userNik = urlSearchParams.get("userNik") ?? "";
  const pelatihanId = window.location.pathname.split("/", 6)[5];

  useEffect(() => {
    getDetailPelatihan(pelatihanId).then((data) => {
      setPelatihan(data);
      getModulPelatihan(data.id);

      getPaketSoal();
      getListAccountTTE();

      if (userNik) {
        getDaftarPeserta(1, userNik, 10, data.id);
      } else {
        getDaftarPeserta(1, "", 10, data.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarWithHeader>
      <ModalDelete />
      <ModalSertifikat
        nama_pelatihan={pelatihan != null ? pelatihan.judul_pelatihan : ""}
      />
      <ModalDuplicate />
      <HeaderBanner />
      <TabsMenu />
      <ModalTTESertifikat />
    </SidebarWithHeader>
  );
};

const DetailDataPelatihanManajemenPelatihan: React.FC = () => {
  return (
    <DetailPelatihanDataPelatihanController.Provider>
      <DetailPelatihanKontenPelatihanController.Provider>
        <DetailPelatihanDaftarPesertaController.Provider>
          <ManajemenSertifikatController.Provider>
            <MasterTTEContext.Provider>
              <ToastContainer
                position="top-center"
                style={{ width: "60%", marginTop: "5%" }}
              />
              <WrapperMain />
            </MasterTTEContext.Provider>
          </ManajemenSertifikatController.Provider>
        </DetailPelatihanDaftarPesertaController.Provider>
      </DetailPelatihanKontenPelatihanController.Provider>
    </DetailPelatihanDataPelatihanController.Provider>
  );
};

export default DetailDataPelatihanManajemenPelatihan;
