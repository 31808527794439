export class LogTTE {
  total: number;
  diproses: number;
  berhasil: number;
  gagal: number;
  judul_pelatihan: string;
  jumlah_data: number;
  created_at: string;
  updated_at: string;
  waktu_mulai: string;
  waktu_selesai: string;

  constructor(
    total: number,
    diproses: number,
    berhasil: number,
    gagal: number,
    judul_pelatihan: string,
    jumlah_data: number,
    created_at: string,
    updated_at: string,
    waktu_mulai: string,
    waktu_selesai: string
  ) {
    this.total = total;
    this.diproses = diproses;
    this.berhasil = berhasil;
    this.gagal = gagal;
    this.judul_pelatihan = judul_pelatihan;
    this.jumlah_data = jumlah_data;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.waktu_mulai = waktu_mulai;
    this.waktu_selesai = waktu_selesai;
  }
}
