import { DependencyContainer } from "tsyringe";
import { AuthDataMapper } from "../../data/mappers/Auth";
import { PaketSoalDataMapper } from "../../data/mappers/PaketSoal";
import { SoalDataMapper } from "../../data/mappers/Soal";
import { KategoriPaketSoal } from "../../entities/KategoriPaketSoal";
import { MateriDataMapper } from "../../data/mappers/Materi";
import { UsersDataMapper } from "../../data/mappers/Users";
import { PelatihanDataMapper } from "../../data/mappers/Pelatihan";
import { FaqsDataMapper } from "../../data/mappers/Faq";
import { ProvinsiDataMapper } from "../../data/mappers/Provinsi";
import { KabupatenDataMapper } from "../../data/mappers/Kabupaten";
import { ModulPelatihanDataMapper } from "../../data/mappers/ModulPelatihan";
import { KecamatanDataMapper } from "../../data/mappers/Kecamatan";
import { KelurahanDataMapper } from "../../data/mappers/Kelurahan";
import { MenuJenisPelatihanDataMapper } from "../../data/mappers/MenuJenisPelatihan";
import { JenisPelatihanDataMapper } from "../../data/mappers/JenisPelatihan";
import { ReviewPelatihanDataMapper } from "../../data/mappers/ReviewPelatihan";
import { SettingPenilaianDataMapper } from "../../data/mappers/SettingPenilaian";
import { QuizDataMapper } from "../../data/mappers/Quiz";
import { PelatihanSayaDataMapper } from "../../data/mappers/PelatihanSaya";
import { ReviewMateriDataMapper } from "../../data/mappers/ReviewMateri";
import { SliderDataMapper } from "../../data/mappers/Slider";
import { ExamDataMapper } from "../../data/mappers/Exam";
import { LogLatihanDataMapper } from "../../data/mappers/LogLatihan";
import { SproutVideoDataMapper } from "../../data/mappers/SproutVideo";
import { FinalProjectDataMapper } from "../../data/mappers/FinalProject";
import { HelpdeskBantuanDataMapper } from "../../data/mappers/HelpdeskBantuan";
import { UjianDataMapper } from "../../data/mappers/Ujian";
import { DashboardDataMapper } from "../../data/mappers/Dashboard";
import { JawabanSoalDataMapper } from "../../data/mappers/JawabanSoal";
import { PathMateriDataMapper } from "../../data/mappers/PathMateri";
import { DashboardRekapitulasiDataMapper } from "../../data/mappers/DashboardRekapitulasi";
import { LogTTEDataMapper } from "../../data/mappers/LogTTE";
import { YoutubeDataMapper } from "../../data/mappers/Youtube";
import { SimdiklatDataMapper } from "../../data/mappers/Simdiklat";

export class MapperModule {
  public static init(container: DependencyContainer) {
    /**
     * [register description]
     *
     * @param   {[type]}  AuthDataMapper  [AuthDataMapper description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<AuthDataMapper>(AuthDataMapper, {
      useClass: AuthDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PaketSoalDataMapper  [PaketSoalDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<PaketSoalDataMapper>(PaketSoalDataMapper, {
      useClass: PaketSoalDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SoalDataMapper  [SoalDataMapper description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<SoalDataMapper>(SoalDataMapper, {
      useClass: SoalDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KategoriPaketSoal  [KategoriPaketSoal description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<KategoriPaketSoal>(KategoriPaketSoal, {
      useClass: KategoriPaketSoal,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  MateriDataMapper  [MateriDataMapper description]
     *
     * @return  {[type]}                    [return description]
     */
    container.register<MateriDataMapper>(MateriDataMapper, {
      useClass: MateriDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  UsersDataMapper  [UsersDataMapper description]
     *
     * @return  {[type]}                   [return description]
     */
    container.register<UsersDataMapper>(UsersDataMapper, {
      useClass: UsersDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PelatihanDataMapper  [PelatihanDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<PelatihanDataMapper>(PelatihanDataMapper, {
      useClass: PelatihanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  FaqsDataMapper  [FaqsDataMapper description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<FaqsDataMapper>(FaqsDataMapper, {
      useClass: FaqsDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  UjianDataMapper  [UjianDataMapper description]
     *
     * @return  {[type]}                   [return description]
     */
    container.register<UjianDataMapper>(UjianDataMapper, {
      useClass: UjianDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  DashboardDataMapper  [DashboardDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<DashboardDataMapper>(DashboardDataMapper, {
      useClass: DashboardDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  JawabanSoalDataMapper  [JawabanSoalDataMapper description]
     *
     * @return  {[type]}                         [return description]
     */
    container.register<JawabanSoalDataMapper>(JawabanSoalDataMapper, {
      useClass: JawabanSoalDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SproutVideoDataMapper  [SproutVideoDataMapper description]
     *
     * @return  {[type]}                         [return description]
     */
    container.register<SproutVideoDataMapper>(SproutVideoDataMapper, {
      useClass: SproutVideoDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ProvinsiDataMapper  [ProvinsiDataMapper description]
     *
     * @return  {[type]}                      [return description]
     */
    container.register<ProvinsiDataMapper>(ProvinsiDataMapper, {
      useClass: ProvinsiDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KabupatenDataMapper  [KabupatenDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<KabupatenDataMapper>(KabupatenDataMapper, {
      useClass: KabupatenDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KecamatanDataMapper  [KecamatanDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<KecamatanDataMapper>(KecamatanDataMapper, {
      useClass: KecamatanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ReviewPelatihanDataMapper  [ReviewPelatihanDataMapper description]
     *
     * @return  {[type]}                             [return description]
     */
    container.register<ReviewPelatihanDataMapper>(ReviewPelatihanDataMapper, {
      useClass: ReviewPelatihanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  KelurahanDataMapper  [KelurahanDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<KelurahanDataMapper>(KelurahanDataMapper, {
      useClass: KelurahanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ModulPelatihanDataMapper  [ModulPelatihanDataMapper description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<ModulPelatihanDataMapper>(ModulPelatihanDataMapper, {
      useClass: ModulPelatihanDataMapper,
    });

    /**
     * [register description]
     *
     * @return  {[type]}  [return description]
     */
    container.register<MenuJenisPelatihanDataMapper>(
      MenuJenisPelatihanDataMapper,
      {
        useClass: MenuJenisPelatihanDataMapper,
      }
    );

    /**
     * [register description]
     *
     * @param   {[type]}  ExamDataMapper  [ExamDataMapper description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<ExamDataMapper>(ExamDataMapper, {
      useClass: ExamDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  LogLatihanDataMapper  [LogLatihanDataMapper description]
     *
     * @return  {[type]}                        [return description]
     */
    container.register<LogLatihanDataMapper>(LogLatihanDataMapper, {
      useClass: LogLatihanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  JenisPelatihanDataMapper  [JenisPelatihanDataMapper description]
     *
     * @return  {[type]}                            [return description]
     */
    container.register<JenisPelatihanDataMapper>(JenisPelatihanDataMapper, {
      useClass: JenisPelatihanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SettingPenilaianDataMapper  [SettingPenilaianDataMapper description]
     *
     * @return  {[type]}                              [return description]
     */
    container.register<SettingPenilaianDataMapper>(SettingPenilaianDataMapper, {
      useClass: SettingPenilaianDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PelatihanSayaDataMapper  [PelatihanSayaDataMapper description]
     *
     * @return  {[type]}                           [return description]
     */
    container.register<PelatihanSayaDataMapper>(PelatihanSayaDataMapper, {
      useClass: PelatihanSayaDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  QuizDataMapper  [QuizDataMapper description]
     *
     * @return  {[type]}                  [return description]
     */
    container.register<QuizDataMapper>(QuizDataMapper, {
      useClass: QuizDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  ReviewMateriDataMapper  [ReviewMateriDataMapper description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<ReviewMateriDataMapper>(ReviewMateriDataMapper, {
      useClass: ReviewMateriDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SliderDataMapper  [SliderDataMapper description]
     *
     * @return  {[type]}                    [return description]
     */
    container.register<SliderDataMapper>(SliderDataMapper, {
      useClass: SliderDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  FinalProjectDataMapper  [FinalProjectDataMapper description]
     *
     * @return  {[type]}                          [return description]
     */
    container.register<FinalProjectDataMapper>(FinalProjectDataMapper, {
      useClass: FinalProjectDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  HelpdeskBantuanDataMapper  [HelpdeskBantuanDataMapper description]
     *
     * @return  {[type]}                             [return description]
     */
    container.register<HelpdeskBantuanDataMapper>(HelpdeskBantuanDataMapper, {
      useClass: HelpdeskBantuanDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  PathMateriDataMapper  [PathMateriDataMapper description]
     *
     * @return  {[type]}                        [return description]
     */
    container.register<PathMateriDataMapper>(PathMateriDataMapper, {
      useClass: PathMateriDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  DashboardRekapitulasiDataMapper  [DashboardRekapitulasiDataMapper description]
     *
     * @return  {[type]}                                   [return description]
     */
    container.register<DashboardRekapitulasiDataMapper>(
      DashboardRekapitulasiDataMapper,
      {
        useClass: DashboardRekapitulasiDataMapper,
      }
    );

    /**
     * [register description]
     *
     * @param   {[type]}  LogTTEDataMapper  [LogTTEDataMapper description]
     *
     * @return  {[type]}                    [return description]
     */
    container.register<LogTTEDataMapper>(LogTTEDataMapper, {
      useClass: LogTTEDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  YoutubeDataMapper  [YoutubeDataMapper description]
     *
     * @return  {[type]}                     [return description]
     */
    container.register<YoutubeDataMapper>(YoutubeDataMapper, {
      useClass: YoutubeDataMapper,
    });

    /**
     * [register description]
     *
     * @param   {[type]}  SimdiklatDataMapper  [SimdiklatDataMapper description]
     *
     * @return  {[type]}                       [return description]
     */
    container.register<SimdiklatDataMapper>(SimdiklatDataMapper, {
      useClass: SimdiklatDataMapper,
    });
  }
}
