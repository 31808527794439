import { AxiosResponse } from "axios";
import { PathMateri } from "../../entities/PathMateri";

export class PathMateriDataMapper {
  /**
   *
   * @param result pathMateri
   * @returns
   */
  public convertListPathMateri(result: AxiosResponse<any>): PathMateri[] {
    const { data } = result.data;

    let pathMateri: PathMateri[] = [];

    data.map((p: any) => {
      return pathMateri.push(
        new PathMateri(
          p.id,
          p.materi_id,
          p.path_materi,
          p.jenis_materi,
          p.metode
        )
      );
    });

    return pathMateri;
  }
}
