export class Kecamatan {
  id: number
  kabupaten_id: number
  nama_kecamatan: string
  created_at: string
  updated_at: string
  jumlahData : number

  constructor(
    id: number,
    kabupaten_id: number,
    nama_kecamatan: string,
    created_at: string,
    updated_at: string,
    jumlahData : number
  ) {
    this.id = id
    this.kabupaten_id = kabupaten_id
    this.nama_kecamatan = nama_kecamatan
    this.created_at = created_at
    this.updated_at = updated_at
    this.jumlahData = jumlahData
  }
}