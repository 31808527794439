import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Skeleton,
  Container,
  Img,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PelatihanImage from "../../assets/webp/img_pelatihan.webp";
import KnowledgeImage from "../../assets/webp/img_pengetahuan.webp";
import KlinikPengetahuanImage from "../../assets/webp/img_klinik_pengetahuan.webp";

// Komponen untuk CardItem
interface CardItemProps {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
  navigatePath: string;
}

const CardItem: React.FC<CardItemProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  navigatePath,
}) => {
  const history = useHistory();

  const handleNavigate = () => {
    history.push(navigatePath);
    window.scrollTo(0, 0);
  };

  return (
    <GridItem
      colSpan={1}
      rounded="lg"
      overflow="hidden"
      shadow="lg"
      my={4}
      display="flex"
      flexDirection="column"
    >
      <Img
        w="full"
        src={imageSrc}
        fallback={<Skeleton h="full" w="full" />}
        alt={imageAlt}
        objectFit="cover"
        mb={{ base: 0, md: 4 }}
        h={{ base: "auto", md: "290px" }}
      />
      <Flex direction="column" justifyContent="space-between" flex="1">
        <Box px={6} py={4}>
          <Text fontWeight="semibold" fontSize="xl" mb={2} color="black">
            {title}
          </Text>
          <Text fontSize="sm" color="black">
            {description}
          </Text>
        </Box>
        <Box px={6} pb={4}>
          <Button
            type="button"
            color="white"
            bg="green.700"
            rounded="md"
            p={2}
            w="full"
            _hover={{ bg: "green.800" }}
            _active={{ bg: "green.800" }}
            onClick={handleNavigate}
          >
            Selengkapnya
          </Button>
        </Box>
      </Flex>
    </GridItem>
  );
};

const TrainingKnowledge: React.FC = () => {
  return (
    <Box bg="white">
      <Container maxW="8xl" my={{ base: 8, md: 12 }}>
        <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="semibold">
          Pelatihan dan Pengetahuan
        </Text>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          gap={4}
        >
          <CardItem
            imageSrc={PelatihanImage}
            imageAlt="Gambar Pelatihan"
            title="Pelatihan"
            description="Ikuti Pelatihan untuk tingkatkan kompetensi anda"
            navigatePath="/pelatihan"
          />
          <CardItem
            imageSrc={KlinikPengetahuanImage}
            imageAlt="Gambar Klinik Pengetahuan"
            title="Klinik Pengetahuan"
            description="Ikuti materi Pengetahuan untuk tingkatkan kualifikasi anda"
            navigatePath="/klinik-pengetahuan"
          />
          <CardItem
            imageSrc={KnowledgeImage}
            imageAlt="Gambar Berbagi Pengetahuan"
            title="Berbagi Pengetahuan"
            description="Ikuti materi Pengetahuan untuk tingkatkan kualifikasi anda"
            navigatePath="/daftar-pengetahuan"
          />
        </Grid>
      </Container>
    </Box>
  );
};

export default TrainingKnowledge;
