import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface IState {
  state: {
    url: string;
    isLoading: boolean;
    listData: [];
    page: number;
    perPage: number;
    search: string;
    isLoadingDownload: { [key: string]: boolean };
  };
  dispatch: {
    setPengetahuanDaftarPeserta: Function;
    fetchData: Function;
    downloadFileAssessment: Function;
  };
}

const initialState: IState = {
  state: {
    url: "management_content/pengetahuan/peserta/",
    isLoading: false,
    listData: [],
    isLoadingDownload: {},
    page: 1,
    perPage: 10,
    search: "",
  },
  dispatch: {
    setPengetahuanDaftarPeserta: () => {},
    fetchData: () => {},
    downloadFileAssessment: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: ManajemenPengetahuanDaftarPesertaProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const { url, page, perPage, search } = state.state;
  const setPengetahuanDaftarPeserta = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async (id: string) => {
    setPengetahuanDaftarPeserta("isLoading", true);

    try {
      const response = await axiosInstance.get(url + id, {
        params: {
          page,
          per_page: perPage,
          keyword: search,
        },
      });

      setPengetahuanDaftarPeserta("listData", response.data.data?.data);
      setPengetahuanDaftarPeserta("isLoading", false);
    } catch (error) {
      setPengetahuanDaftarPeserta("isLoading", false);
    }
  };

  const downloadFileAssessment = async (
    id: string,
    userId: string,
    fileName: string,
    rowId: string
  ) => {
    setPengetahuanDaftarPeserta("isLoadingDownload", {
      ...state.state.isLoadingDownload,
      [rowId]: true,
    });

    try {
      const response = await axiosInstance.get(
        `management_content/pengetahuan/peserta/${id}/${userId}/file/download`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", `${fileName}`);

      document.body.appendChild(link);

      link.click();

      link.parentNode?.removeChild(link);

      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw error;
    } finally {
      setPengetahuanDaftarPeserta("isLoadingDownload", true);
    }
  };

  return (
    <ManajemenPengetahuanDaftarPesertaProvider
      value={{
        state: state.state,
        dispatch: {
          setPengetahuanDaftarPeserta,
          fetchData,
          downloadFileAssessment,
        },
      }}
    >
      {children}
    </ManajemenPengetahuanDaftarPesertaProvider>
  );
};

export const useManajemenPengetahuanDaftarPesertaContext = () =>
  useContext(Context);

const ManajemenPengetahuanDaftarPesertaController = {
  useManajemenPengetahuanDaftarPesertaContext,
  Provider,
};

export default ManajemenPengetahuanDaftarPesertaController;
