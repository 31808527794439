export class SproutVideo {
  id: number
  security_token: number
  title: string

  constructor(
    id: number,
    security_token: number,
    title: string
  ) {
    this.id = id
    this.security_token = security_token
    this.title = title
  }
}