import { YoutubeRepositoryInterface } from "../../../data/contracts/Youtube";
import { YoutubeDataMapper } from "../../../data/mappers/Youtube";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Youtube } from "../../../entities/Youtube";
import axios from "axios";
import env from "@ludovicm67/react-dotenv";

export class YoutubeApiRepository implements YoutubeRepositoryInterface {
  private apiService: ApiService;
  private mapper: YoutubeDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: YoutubeDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllVideo(): Promise<Youtube[]> {
    let pageToken = null;
    let playlistItems = [];
    let numResultsFetched = 0;
    const MAX_RESULTS_TOTAL = 500;

    while (numResultsFetched < MAX_RESULTS_TOTAL) {
      let requestUrl =
        this.endpoints.youtubeDataAPI() +
        `/playlistItems?playlistId=PLdnzMUBrRAF37JxyVQymV7AzHOOkNHhgo&part=snippet&key=${env.REACT_APP_YOUTUBE_API_KEY}&fields=nextPageToken, items(snippet(title),%20snippet(resourceId(videoId)))&maxResults=50`;

      if (pageToken) {
        requestUrl += `&pageToken=${pageToken}`;
      }

      const response = await axios.get(requestUrl);
      playlistItems = playlistItems.concat(response.data.items);

      numResultsFetched += response.data.items.length;
      pageToken = response.data.nextPageToken;

      if (!pageToken) {
        break;
      }
    }

    return this.mapper.convertYoutubeDataFromApi(playlistItems);
  }
}
