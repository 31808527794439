import { injectable } from "tsyringe";
import { SimdiklatApiRepository } from "../../app/repository/api/SimdiklatApiRepository";
import {
  AgamaMasterSimdiklat,
  JabatanMasterSimdiklat,
  PangkatMasterSimdiklat,
  PelatihanSimdiklat,
  StatusKawinMasterSimdiklat,
  StatusPegawaiMasterSimdiklat,
} from "../../entities/Simdiklat";
import { LogTTE } from "../../entities/LogTTE";

@injectable()
export class SimdiklatPresenter {
  private repository: SimdiklatApiRepository;
  constructor(repository: SimdiklatApiRepository) {
    this.repository = repository;
  }

  /**
   *
   * @param page
   * @param limit
   * @returns
   */
  public getPelatihan(
    page: number,
    limit: number
  ): Promise<PelatihanSimdiklat[]> {
    return this.repository.getPelatihan(page, limit);
  }

  /**
   *
   * @param id
   * @returns
   */
  public getPelatihanById(id: number): Promise<PelatihanSimdiklat> {
    return this.repository.getPelatihanById(id);
  }

  /**
   *
   * @returns
   */
  public getMasterPangkat(): Promise<PangkatMasterSimdiklat[]> {
    return this.repository.getMasterPangkat();
  }

  /**
   *
   * @returns
   */
  public getMasterJabatan(): Promise<JabatanMasterSimdiklat[]> {
    return this.repository.getMasterJabatan();
  }

  /**
   *
   * @returns
   */
  public getMasterAgama(): Promise<AgamaMasterSimdiklat[]> {
    return this.repository.getMasterAgama();
  }

  /**
   *
   * @returns
   */
  public getMasterStatusKawin(): Promise<StatusKawinMasterSimdiklat[]> {
    return this.repository.getMasterStatusKawin();
  }

  /**
   *
   * @returns
   */
  public getMasterStatusPegawai(): Promise<StatusPegawaiMasterSimdiklat[]> {
    return this.repository.getMasterStatusPegawai();
  }

  /**
   *
   * @returns
   */
  public logDataAlumniSimdiklat(page: number, rows: number): Promise<LogTTE[]> {
    return this.repository.logDataAlumniSimdiklat(page, rows);
  }

  /**
   *
   * @param pelatihan_id
   * @returns
   */
  public kirimDataAlumniSimdiklat(pelatihan_id: number): Promise<any> {
    return this.repository.kirimDataAlumniSimdiklat(pelatihan_id);
  }
}
