/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { AiOutlinePlus, AiFillPlusCircle } from "react-icons/all";
import MasterJenisPelatihanController, {
  useMasterJenisPelatihanContext,
} from "../../../../controller/admin/data_master/jenis_pelatihan";
import SimdiklatController, {
  useSimdiklatContext,
} from "../../../../controller/simdiklat";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import { ToastContainer } from "react-toastify";
import { BsSearch, BsFillTrashFill, FaEdit } from "react-icons/all";
import Select from "react-select";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import {
  Box,
  Grid,
  Link,
  Button,
  Text,
  List,
  ListItem,
  GridItem,
  IconButton,
} from "@chakra-ui/react";

const ModalCreateData: React.FC = () => {
  const {
    formId,
    formDialog,
    formMenuJenisPelatihan,
    formMenuJenisPelatihanId,
    formSubMenuJenisPelatihan,
    formLoading,
    showDialog,
    typeMenu,
    menuJenisPelatihan,
  } = useMasterJenisPelatihanContext().state;
  const {
    actionFormDialog,
    setFormMenuJenisPelatihan,
    setFormMenuJenisPelatihanId,
    setFormSubMenuJenisPelatihan,
    setShowDialog,
    setTypeMenu,
    setShowDialogDelete,
    setActiveMenu,
  } = useMasterJenisPelatihanContext();

  let optionsMenuJenisPelatihan;
  let valueMenuJenisPelatihan;

  if (menuJenisPelatihan.length > 0) {
    optionsMenuJenisPelatihan = menuJenisPelatihan.map((item) => ({
      value: item.id,
      label: item.menu_jenis_pelatihan,
    }));
    if (formMenuJenisPelatihanId != 0) {
      menuJenisPelatihan.map((item) => {
        if (item.id == formMenuJenisPelatihanId) {
          valueMenuJenisPelatihan = {
            value: item.id,
            label: item.menu_jenis_pelatihan,
          };
        }

        return valueMenuJenisPelatihan;
      });
    }
  } else {
    optionsMenuJenisPelatihan = [];
  }

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {formDialog} {typeMenu == "menu" ? "Menu" : "Sub Menu"}{" "}
                    Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialog(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <If condition={formDialog == "Tambah"}>
                    <Then>
                      <div className="form-group grid grid-cols-6 gap-4">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Jenis Menu
                        </label>
                        <div className="flex flex-wrap mt-1 col-span-4">
                          <div className="form-check">
                            <input
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                              type="checkbox"
                              checked={typeMenu == "menu" ? true : false}
                              onClick={() => {
                                setTypeMenu("menu");
                              }}
                            />
                            <label className="form-check-label inline-block text-gray-800">
                              Menu
                            </label>
                          </div>
                          <div className="form-check ml-3">
                            <input
                              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                              type="checkbox"
                              checked={typeMenu != "menu" ? true : false}
                              onClick={() => {
                                setTypeMenu("sub_menu");
                              }}
                            />
                            <label className="form-check-label inline-block text-gray-800">
                              Sub Menu
                            </label>
                          </div>
                        </div>
                      </div>
                    </Then>
                  </If>

                  <If condition={typeMenu != "menu"}>
                    <Then>
                      <div className="form-group grid grid-cols-6 gap-4">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Pilih Menu Jenis Pelatihan
                        </label>
                        <div className="col-span-4">
                          <Select
                            options={optionsMenuJenisPelatihan}
                            value={valueMenuJenisPelatihan}
                            onChange={(val: any) =>
                              setFormMenuJenisPelatihanId(val.value)
                            }
                            placeholder="Pilih Menu Jenis Pelatihan"
                          />
                        </div>
                      </div>
                      <div className="form-group grid grid-cols-6 gap-4">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Sub Menu Jenis Pelatihan
                        </label>
                        <input
                          type="text"
                          className="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        col-span-4
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                          placeholder="Masukkan Sub Menu Jenis Pelatihan"
                          onChange={(event) =>
                            setFormSubMenuJenisPelatihan(event.target.value)
                          }
                          value={formSubMenuJenisPelatihan}
                        />
                      </div>
                    </Then>
                  </If>

                  <If condition={typeMenu == "menu"}>
                    <Then>
                      <div className="form-group grid grid-cols-6 gap-4">
                        <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                          Menu Jenis Pelatihan
                        </label>
                        <input
                          type="text"
                          className="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        col-span-4
                        "
                          placeholder="Masukkan Menu Jenis Pelatihan"
                          onChange={(event) =>
                            setFormMenuJenisPelatihan(event.target.value)
                          }
                          value={formMenuJenisPelatihan}
                        />
                      </div>
                    </Then>
                  </If>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialog(false)}
                  >
                    Batal
                  </button>
                  <If condition={formDialog == "Ubah"}>
                    <Then>
                      <button
                        type="button"
                        className="inline-block px-6 py-2.5 bg-red-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-500 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                        onClick={() => {
                          setShowDialog(false);
                          setShowDialogDelete(true);
                        }}
                      >
                        Hapus
                      </button>

                      <If condition={typeMenu != "menu"}>
                        <Then>
                          <button
                            type="button"
                            className="inline-block px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                            onClick={() => {
                              setShowDialog(false);
                              setActiveMenu(formMenuJenisPelatihanId, formId);
                            }}
                          >
                            Set Filter
                          </button>
                        </Then>
                      </If>
                    </Then>
                  </If>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => actionFormDialog()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, formLoading } =
    useMasterJenisPelatihanContext().state;
  const { setShowDialogDelete, deleteMenuJenisPelatihan } =
    useMasterJenisPelatihanContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Data
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteMenuJenisPelatihan()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

interface ModalCreateDataJenisPelatihanProps {
  titlePelatihan: string;
  disableMetodePenambahan: boolean;
}
const ModalCreateDataJenisPelatihan: React.FC<
  ModalCreateDataJenisPelatihanProps
> = ({ titlePelatihan, disableMetodePenambahan }) => {
  // use master jenis pelatihan context

  const {
    formDialog,
    formSubMenuJenisPelatihanId,
    formKode,
    formNamaPelatihan,
    formKompetensi,
    formJP,
    formKeterangan,
    formLoading,
    showDialogJenisPelatihan,
    subMenuJenisPelatihan,
    metodePenambahan,
  } = useMasterJenisPelatihanContext().state;
  const {
    actionFormDialogJenisPelatihan,
    setFormSubMenuJenisPelatihanId,
    setFormKode,
    setFormNamaPelatihan,
    setFormKompetensi,
    setFormJP,
    setFormKeterangan,
    setShowDialogJenisPelatihan,
    setMetodePenambahan,
  } = useMasterJenisPelatihanContext();

  // use simdiklat context
  const { listPelatihanSimdiklat } = useSimdiklatContext().state;

  let optionsSubMenuJenisPelatihan;
  let valueSubMenuJenisPelatihan;

  if (subMenuJenisPelatihan.length > 0) {
    optionsSubMenuJenisPelatihan = subMenuJenisPelatihan.map((item) => ({
      value: item.id,
      label: item.judul_menu,
    }));
    if (formSubMenuJenisPelatihanId != 0) {
      subMenuJenisPelatihan.map((item) => {
        if (item.id == formSubMenuJenisPelatihanId) {
          valueSubMenuJenisPelatihan = {
            value: item.id,
            label: item.sub_menu_jenis_pelatihan,
          };
        }

        return valueSubMenuJenisPelatihan;
      });
    }
  } else {
    optionsSubMenuJenisPelatihan = [];
  }

  let optionsSimdiklatPelatihan;

  if (listPelatihanSimdiklat.length > 0) {
    optionsSimdiklatPelatihan = listPelatihanSimdiklat.map((item) => ({
      value: item.id,
      label: item.nama_diklat_nama,
    }));
  } else {
    optionsSimdiklatPelatihan = [];
  }

  const optionsMetodePenambahan = [
    { value: 1, label: "Buat Pelatihan Baru" },
    { value: 2, label: "Ambil Data Pelatihan Dari Simdiklat" },
  ];

  return (
    <>
      {showDialogJenisPelatihan ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {formDialog} Jenis Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogJenisPelatihan(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Pilih Menu Jenis Pelatihan
                    </label>
                    <div className="col-span-4">
                      <Select
                        options={optionsSubMenuJenisPelatihan}
                        value={valueSubMenuJenisPelatihan}
                        onChange={(val: any) =>
                          setFormSubMenuJenisPelatihanId(val.value)
                        }
                        placeholder="Pilih Menu Jenis Pelatihan"
                      />
                    </div>
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Pilih Metode Penambahan
                    </label>
                    <div className="col-span-4">
                      <Select
                        options={optionsMetodePenambahan}
                        value={optionsMetodePenambahan.filter(
                          (val) => val.value === metodePenambahan
                        )}
                        onChange={(val: any) => setMetodePenambahan(val.value)}
                        placeholder="Pilih Metode Penambahan"
                        isDisabled={disableMetodePenambahan}
                      />
                    </div>
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Nama Pelatihan
                    </label>
                    <If condition={metodePenambahan === 1}>
                      <Then>
                        <input
                          type="text"
                          className="
                        form-control
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        col-span-4
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                          disabled={titlePelatihan ? true : false}
                          placeholder="Masukkan Nama Pelatihan"
                          onChange={(event) =>
                            setFormNamaPelatihan(event.target.value)
                          }
                          value={formNamaPelatihan}
                        />
                      </Then>
                      <Else>
                        <div className="col-span-4">
                          <Select
                            placeholder="Pilih Nama Pelatihan"
                            options={optionsSimdiklatPelatihan}
                            onChange={(event) => {
                              setFormNamaPelatihan(event.label);
                              setFormKode(event.value);
                            }}
                            value={optionsSimdiklatPelatihan.filter(
                              (val) => val.label === formNamaPelatihan
                            )}
                          />
                        </div>
                      </Else>
                    </If>
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Kode Pelatihan
                    </label>
                    <If condition={metodePenambahan === 1}>
                      <Then>
                        <input
                          type="text"
                          className="
                        form-control
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        col-span-4
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                          placeholder="Masukkan Kode Pelatihan"
                          onChange={(event) => setFormKode(event.target.value)}
                          value={formKode}
                        />
                      </Then>
                      <Else>
                        <input
                          type="text"
                          className="
                        form-control
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-300 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        col-span-4
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                          disabled={true}
                          placeholder="Masukkan Kode Pelatihan"
                          value={formKode}
                        />
                      </Else>
                    </If>
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Kompetensi
                    </label>
                    <textarea
                      rows={5}
                      className="form-control
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          col-span-4
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={formKompetensi}
                      onChange={(e) => setFormKompetensi(e.target.value)}
                      placeholder="Masukkan Kompetensi"
                    />
                  </div>

                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Jam Pelatihan
                    </label>
                    <input
                      type="text"
                      className="
                        form-control
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        col-span-4
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                      placeholder="Masukkan Menu Jam Pelatihan"
                      onChange={(event) => setFormJP(event.target.value)}
                      value={formJP}
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Keterangan
                    </label>
                    <textarea
                      rows={5}
                      className="form-control
                          block
                          w-full
                          px-3
                          col-span-4
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={formKeterangan}
                      onChange={(e) => setFormKeterangan(e.target.value)}
                      placeholder="Masukkan Form Keterangan"
                    />
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogJenisPelatihan(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => actionFormDialogJenisPelatihan()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDeleteJenisPelatihan: React.FC = () => {
  const { showDialogDeleteJenisPelatihan, formLoading } =
    useMasterJenisPelatihanContext().state;
  const { setShowDialogDeleteJenisPelatihan, deleteJenisPelatihan } =
    useMasterJenisPelatihanContext();

  return (
    <>
      {showDialogDeleteJenisPelatihan ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Jenis Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDeleteJenisPelatihan(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDeleteJenisPelatihan(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteJenisPelatihan()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalShowSettingPenilian: React.FC = () => {
  const { showModalSettingPenilaian, settingPeniaian, selectJenisPelatihanId } =
    useMasterJenisPelatihanContext().state;
  const {
    setShowModalSettingPenilaian,
    setShowDialogDeleteSettingPenilaian,
    clearForm,
    setShowDialogFormSettingPenilaian,
    setFormDialog,
    setUpdateSettingPenilaian,
    setFormId,
  } = useMasterJenisPelatihanContext();

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Nilai Bawah",
      selector: (row) => row.nilai_bawah,
      sortable: true,
    },
    {
      name: "Nilai Atas",
      selector: (row) => row.nilai_atas,
      sortable: true,
    },
    {
      name: "Predikat",
      selector: (row) => row.predikat,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => {
        return (
          <div>
            <button
              className="ml-2 px-2 py-1 text-base bg-white rounded-lg"
              onClick={() => {
                setUpdateSettingPenilaian(row);
              }}
            >
              <FaEdit style={{ color: "#F2994A" }} />
            </button>
            <button
              className="ml-2 px-2 py-1 text-base bg-white rounded-lg"
              onClick={() => {
                setFormId(row.id);
                setShowDialogDeleteSettingPenilaian(true);
              }}
            >
              <BsFillTrashFill style={{ color: "#EB5757" }} />
            </button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  return (
    <>
      {showModalSettingPenilaian ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Setting Nilai
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() =>
                      setShowModalSettingPenilaian(
                        false,
                        selectJenisPelatihanId
                      )
                    }
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs rounded float-right mb-2"
                    style={{ height: "38px" }}
                    onClick={() => {
                      clearForm();
                      setShowDialogFormSettingPenilaian(true);
                      setFormDialog("Tambah");
                    }}
                  >
                    Tambah Baru
                  </button>
                  <DataTable
                    columns={columns}
                    data={settingPeniaian}
                    customStyles={customStyles}
                  />
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() =>
                      setShowModalSettingPenilaian(
                        false,
                        selectJenisPelatihanId
                      )
                    }
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDialogFormSettingPenilaian: React.FC = () => {
  const {
    formDialog,
    formLoading,
    formNilaiBawah,
    formNilaiAtas,
    formPredikat,
    showDialogFormSettingPenilaian,
  } = useMasterJenisPelatihanContext().state;
  const {
    actionFormSettingPenilaian,
    setShowDialogFormSettingPenilaian,
    setFormNilaiBawah,
    setFormNilaiAtas,
    setFormPredikat,
  } = useMasterJenisPelatihanContext();

  return (
    <>
      {showDialogFormSettingPenilaian ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {formDialog} Setting Nilai
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogFormSettingPenilaian(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Nilai Bawah
                    </label>
                    <input
                      className="form-control
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          col-span-4
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={formNilaiBawah}
                      onChange={(e) => setFormNilaiBawah(e.target.value)}
                      placeholder="Masukkan Nilai Bawah"
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Nilai Atas
                    </label>
                    <input
                      className="form-control
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          col-span-4
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={formNilaiAtas}
                      onChange={(e) => setFormNilaiAtas(e.target.value)}
                      placeholder="Masukkan Nilai Atas"
                    />
                  </div>
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Predikat
                    </label>
                    <select
                      className="form-control
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          col-span-4
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => setFormPredikat(e.target.value)}
                    >
                      <option selected>Pilih Predikat</option>
                      <option
                        value="Lulus, Sangat Kompeten"
                        selected={
                          formPredikat == "Lulus, Sangat Kompeten"
                            ? true
                            : false
                        }
                      >
                        Lulus, Sangat Kompeten
                      </option>
                      <option
                        value="Lulus, Kompeten"
                        selected={
                          formPredikat == "Lulus, Kompeten" ? true : false
                        }
                      >
                        Lulus, Kompeten
                      </option>
                      <option
                        value="Lulus, Cukup Kompeten"
                        selected={
                          formPredikat == "Lulus, Cukup Kompeten" ? true : false
                        }
                      >
                        Lulus, Cukup Kompeten
                      </option>
                      <option
                        value="Tidak Lulus"
                        selected={formPredikat == "Tidak Lulus" ? true : false}
                      >
                        Tidak Lulus
                      </option>
                    </select>
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogFormSettingPenilaian(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => actionFormSettingPenilaian()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ModalDeleteSettingNilai: React.FC = () => {
  const { showDialogDeletteSettingPenilaian, formLoading } =
    useMasterJenisPelatihanContext().state;
  const { setShowDialogDeleteSettingPenilaian, deleteSettingPenilaian } =
    useMasterJenisPelatihanContext();

  return (
    <>
      {showDialogDeletteSettingPenilaian ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Setting Nilai
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDeleteSettingPenilaian(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDeleteSettingPenilaian(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deleteSettingPenilaian()}
                  >
                    <If condition={formLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  // use master jenis pelatihan context
  const {
    getMenuJenisPelatihan,
    getSubMenuJenisPelatihan,
    setShowDialog,
    setFormDialog,
    setUpdate,
    getJenisPelatihan,
    setPageRow,
    searchJenisPelatihan,
    setShowDialogJenisPelatihan,
    setUpdateJenisPelatihan,
    setShowDialogDeleteJenisPelatihan,
    clearForm,
    setActiveMenu,
    setShowModalSettingPenilaian,
    setFormNamaPelatihan,
    setFormKode,
    setMetodePenambahan,
  } = useMasterJenisPelatihanContext();
  const {
    menuJenisPelatihan,
    keyword,
    jenisPelatihan,
    isLoading,
    subMenuJenisPelatihanIdActive,
    MenuJenisPelatihanIdActive,
  } = useMasterJenisPelatihanContext().state;

  // use simdiklat context
  const { getListPelatihanSimdiklat } = useSimdiklatContext();

  // use state
  const [perPage, setPerPage] = useState(10);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const titlePelatihan = urlSearchParams.get("titlePelatihan");

  useEffect(() => {
    getMenuJenisPelatihan();
    getSubMenuJenisPelatihan();
    getJenisPelatihan(1, "", 10);
    getListPelatihanSimdiklat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (titlePelatihan) {
      setShowDialogJenisPelatihan(true);
      setFormDialog("Tambah");
      setFormNamaPelatihan(titlePelatihan);
      setMetodePenambahan(1);
    }
  }, [titlePelatihan]);

  const handlePageChange = (page) => {
    getJenisPelatihan(page, keyword, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getJenisPelatihan(page, keyword, newPerPage);
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Kode",
      selector: (row: any) => row.kode,
      wrap: true,
    },
    {
      name: "Nama Pelatihan",
      selector: (row) => row.nama_pelatihan,
      wrap: true,
    },
    {
      name: "Kompetensi",
      selector: (row) => row.kompetensi,
      wrap: true,
    },
    {
      name: "Keterangan",
      selector: (row) => row.keterangan,
    },
    {
      name: "Penilaian",
      selector: (row) => {
        return (
          <div>
            <button
              className="ml-2 px-2 py-1 text-xs bg-green-100 text-green-700 rounded-full hover:bg-green-200"
              onClick={() => {
                setShowModalSettingPenilaian(true, row.id);
              }}
            >
              Setting Nilai
            </button>
          </div>
        );
      },
    },
    {
      name: "",
      selector: (row) => {
        return (
          <div>
            <button
              className="ml-2 px-2 py-1 text-base bg-white rounded-lg"
              onClick={() => {
                setUpdateJenisPelatihan(row);
              }}
            >
              <FaEdit style={{ color: "#F2994A" }} />
            </button>
            <button
              className="ml-2 px-2 py-1 text-base bg-white rounded-lg"
              onClick={() => {
                setShowDialogDeleteJenisPelatihan(true, row.id);
              }}
            >
              <BsFillTrashFill style={{ color: "#EB5757" }} />
            </button>
          </div>
        );
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex flex-row justify-between w-full items-center">
        <p className="font-semibold text-base">Nama Pelatihan</p>
        <div className="flex flex-wrap">
          <div className="input-group relative flex flex-wrap mr-2">
            <button
              className="btn px-3 py-1 border border-gray-300 text-[#212529] text-lg leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              style={{ height: "38px" }}
            >
              <BsSearch />
            </button>
            <input
              type="search"
              className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded focus:ring-0 focus:border-gray-300"
              placeholder="Cari Disini"
              onChange={(event) =>
                searchJenisPelatihan(
                  event.target.value,
                  subMenuJenisPelatihanIdActive
                )
              }
            />
          </div>
          <button
            type="button"
            className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs rounded flex flex-row items-center hover:bg-green-500"
            style={{ height: "38px" }}
            onClick={() => {
              clearForm();
              setShowDialogJenisPelatihan(true);
              setFormDialog("Tambah");
            }}
          >
            Tambah Baru <AiFillPlusCircle className="ml-3" />
          </button>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, subMenuJenisPelatihanIdActive]);

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"green"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  return (
    <SidebarWithHeader title="Master Jenis Pelatihan">
      <Box className="app">
        <ModalCreateData />
        <ModalDelete />
        <ModalCreateDataJenisPelatihan
          titlePelatihan={titlePelatihan ?? ""}
          disableMetodePenambahan={titlePelatihan ? true : false}
        />
        <ModalDeleteJenisPelatihan />
        <ModalShowSettingPenilian />
        <ModalDialogFormSettingPenilaian />
        <ModalDeleteSettingNilai />

        <Box as="main" style={{ padding: "0px" }}>
          <Box className="container">
            <Box bg="white" borderRadius="lg" mb={5} shadow="lg">
              <Grid
                templateColumns={{
                  md: "repeat(11, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <GridItem
                  colSpan={{ md: 2, sm: 1 }}
                  pt={2}
                  borderRight="2px"
                  borderColor="gray.200"
                >
                  <Box display="flex" justifyContent="space-between" pt={3}>
                    <Link fontWeight="semibold" ml={5}>
                      Jenis Pelatihan
                    </Link>
                    <Button
                      type="button"
                      mr={3}
                      px={1}
                      h="20px"
                      bg="green.600"
                      color="white"
                      fontWeight="semibold"
                      fontSize="xs"
                      leading="tight"
                      rounded="full"
                      shadow="md"
                      _hover={{ bg: "green.700" }}
                      onClick={() => {
                        setShowDialog(true);
                        setFormDialog("Tambah");
                      }}
                    >
                      <AiOutlinePlus />
                    </Button>
                  </Box>
                  <Box mt={6} ml={5} overflowY="auto" h="550px">
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color={
                        subMenuJenisPelatihanIdActive == ""
                          ? "#0DBD7F"
                          : "black"
                      }
                      cursor="pointer"
                      onClick={() => setActiveMenu("", "")}
                    >
                      Semua Jenis Pelatihan
                    </Text>
                    <If condition={menuJenisPelatihan.length > 0}>
                      <Then>
                        {menuJenisPelatihan.map((m) => {
                          return (
                            <>
                              <Text
                                fontWeight="semibold"
                                mt={3}
                                cursor="pointer"
                                color={
                                  MenuJenisPelatihanIdActive == m.id.toString()
                                    ? "#0DBD7F"
                                    : "black"
                                }
                                onClick={() => {
                                  setUpdate(m, "menu");
                                }}
                                style={{
                                  color:
                                    MenuJenisPelatihanIdActive ==
                                    m.id.toString()
                                      ? "#0DBD7F"
                                      : "black",
                                }}
                              >
                                {m.menu_jenis_pelatihan}
                              </Text>
                              <List ml={5} mt={3} cursor="pointer">
                                {m.subMenuJenisPelatihan.map((sm) => (
                                  <ListItem
                                    key={sm.id}
                                    mt={2}
                                    onClick={() => {
                                      setUpdate(sm, "sub_menu");
                                    }}
                                    color={
                                      subMenuJenisPelatihanIdActive ===
                                      sm.id.toString()
                                        ? "#0DBD7F"
                                        : "black"
                                    }
                                  >
                                    {sm.sub_menu_jenis_pelatihan}
                                  </ListItem>
                                ))}
                              </List>
                            </>
                          );
                        })}
                      </Then>
                    </If>
                  </Box>
                </GridItem>

                <GridItem colSpan={{ base: 1, sm: 1, md: 9 }}>
                  <Box p={5}>
                    <DataTable
                      columns={columns}
                      data={jenisPelatihan}
                      progressPending={isLoading}
                      pagination
                      paginationServer
                      paginationTotalRows={
                        jenisPelatihan.length > 0
                          ? jenisPelatihan[0].jumlahData
                          : 0
                      }
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      customStyles={customStyles}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      progressComponent={<CustomLoader />}
                    />
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const MasterJenisPelatihan: React.FC = () => {
  return (
    <MasterJenisPelatihanController.Provider>
      <SimdiklatController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <WrapperMain />
      </SimdiklatController.Provider>
    </MasterJenisPelatihanController.Provider>
  );
};

export default MasterJenisPelatihan;
