export class KategoriPaketSoal{
    id: number;
    kategori_paket_soal: string;
    created_at: string;
    updated_at: string;
    jumlahData : number;
    constructor(
        id: number,
        kategori_paket_soal: string,
        created_at: string,
        updated_at: string,
        jumlahData : number
    ) {
      this.id = id;
      this.kategori_paket_soal = kategori_paket_soal;
      this.created_at = created_at;
      this.updated_at = updated_at;
      this.jumlahData = jumlahData;
    }
  }