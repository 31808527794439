import React from "react";
import { container } from "tsyringe";
import { LandingDataPresenter } from "../../../../../data/presenters/LandingDataPresenter";
import { LandingData } from "../../../../../entities/LandingData";

interface IState {
  isLoading: boolean;
  landingData: LandingData | null;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  getLandingData: Function;
}

const initialState = {
  state: {
    isLoading: false,
    landingData: null,
    error: false,
    msgError: "",
  },
  getLandingData: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: LandingDataProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    landingData: null,
    error: false,
    msgError: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  // Get Data Landing
  const getLandingData = async () => {
    setLoading(true);

    try {
      const landingDataPresenter = container.resolve(LandingDataPresenter);
      const get = await landingDataPresenter.getAllDataLanding();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        landingData: data.data.data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data landing:", error);
      setLoading(false);
    }
  };

  return (
    <LandingDataProvider
      value={{
        state,
        getLandingData,
      }}
    >
      {children}
    </LandingDataProvider>
  );
};

export const useLandingDataContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useLandingDataContext,
  Provider,
};
