export class LogLatihan {
  total_jawaban_benar: number;
  total_soal: number;
  nilai: number;
  passing_grade: number;
  status: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;

  constructor(
    total_jawaban_benar: number,
    total_soal: number,
    nilai: number,
    passing_grade: number,
    status: string,
    created_at: string,
    updated_at: string,
    jumlahData: number
  ) {
    this.total_jawaban_benar = total_jawaban_benar;
    this.total_soal = total_soal;
    this.nilai = nilai;
    this.passing_grade = passing_grade;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
  }
}
