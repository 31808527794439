import React from "react";
import Carousel from "react-multi-carousel";
import {
  Box,
  Image,
  Grid,
  GridItem,
  Text,
  Skeleton,
  Container,
} from "@chakra-ui/react";
import CustomDot from "./CustomDot";
import { useInfografisPublicContext } from "../../controller/public/infografis";
import { useInfografisContext } from "../../controller/admin/landing_page/infografis";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

interface InfografisProps {
  accessFor: string;
}

const Infografis = ({ accessFor }: InfografisProps) => {
  const { infografisData: dataPublic, loadingData: loadingPublic } =
    useInfografisPublicContext().state;

  const { infografisData: dataPrivate, loadingData: loadingPrivate } =
    useInfografisContext().state;

  const data = accessFor === "public" ? dataPublic : dataPrivate;
  const loadingData = accessFor === "public" ? loadingPublic : loadingPrivate;

  return (
    <Box bg="white">
      <Container maxW="8xl" my={{ base: 8, md: 20 }}>
        <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="semibold">
          Infografis
        </Text>

        {!data.length ? (
          <Box mx="auto" textAlign="center" my={20}>
            <Text fontSize="xl" color="gray.400">
              Belum Ada Infografis
            </Text>
          </Box>
        ) : (
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(10, 1fr)" }}
            gap={4}
            pt={{ base: 0, md: 4 }}
            h={{ base: "230px", md: "500px" }}
          >
            {loadingData ? (
              <Box display="flex" flexDirection="column" w="full">
                <Skeleton
                  height="200px"
                  width={{ base: "300px", md: "1000px" }}
                  mb={4}
                />
                <Skeleton height="200px" width="full" />
              </Box>
            ) : (
              <>
                <GridItem rowSpan={2} colSpan={7} h="full">
                  <Carousel
                    responsive={responsive}
                    ssr={false}
                    arrows={false}
                    showDots={true}
                    slidesToSlide={1}
                    focusOnSelect
                    infinite
                    autoPlay
                    autoPlaySpeed={5000}
                    customDot={<CustomDot />}
                    className="h-full rounded-lg"
                  >
                    {data.map((item) => (
                      <Box
                        key={item?.id}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        h="full"
                        w="full"
                      >
                        <Image
                          src={item?.image}
                          loading="lazy"
                          alt="Gambar Infografis"
                          w="full"
                          h={{ base: "200px", md: "500px" }}
                          objectFit="cover"
                        />
                      </Box>
                    ))}
                  </Carousel>
                </GridItem>
                <GridItem
                  colSpan={{ base: 0, md: 3 }}
                  rowSpan={1}
                  display={{ base: "none", md: "block" }}
                  h="full"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    h="full"
                    w="full"
                  >
                    <Image
                      src={data[0]?.image}
                      loading="lazy"
                      alt="Gambar Infografis"
                      h="full"
                      w="full"
                      borderRadius="lg"
                      objectFit="cover"
                    />
                  </Box>
                </GridItem>
                <GridItem
                  colSpan={{ base: 0, md: 3 }}
                  rowSpan={1}
                  display={{ base: "none", md: "block" }}
                  maxH="full"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    h="full"
                    w="full"
                    objectFit="cover"
                  >
                    <Image
                      src={data[1]?.image ?? data[0]?.image}
                      loading="lazy"
                      alt="Gambar Infografis"
                      h="full"
                      w="full"
                      borderRadius="lg"
                    />
                  </Box>
                </GridItem>
              </>
            )}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Infografis;
