import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { useManajemenKontributorPengetahuanContext } from "../../../controller/admin/manajemen_kontributor_pengetahuan";

/**
 * A modal dialog for accepting or rejecting a contributor application.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */
const ModalVerification = () => {
  const { isLoadingVerification, statusVerification, isOpenModal } =
    useManajemenKontributorPengetahuanContext().state;
  const {
    setManajemenKontributorPengetahuanState: setState,
    handleVerification,
  } = useManajemenKontributorPengetahuanContext().dispatch;
  const cancelRef = React.useRef(null);

  const labelTitleModal =
    statusVerification === "diterima" ? "Terima" : "Tolak";

  const formattedStatus =
    statusVerification.charAt(0).toUpperCase() + statusVerification.slice(1);

  const onClose = () => {
    setState("isOpenModal", false);
  };

  return (
    <AlertDialog
      isOpen={isOpenModal}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {labelTitleModal} Pengajuan Kontribusi
          </AlertDialogHeader>

          <AlertDialogBody>
            <Flex justifyContent="space-between" alignItems="start">
              <Text w="30%" fontWeight="semibold">
                Alasan {formattedStatus}
              </Text>
              <Textarea
                placeholder="Alasan"
                onChange={(e) => setState("message", e.target.value)}
              />
            </Flex>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Batal
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleVerification()}
              ml={3}
              isLoading={isLoadingVerification}
            >
              SImpan
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ModalVerification;
