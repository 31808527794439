import { SproutVideoRepositoryInterface } from "../../../data/contracts/SproutVideo";
import { SproutVideoDataMapper } from "../../../data/mappers/SproutVideo";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { SproutVideo } from "../../../entities/SproutVideo";

export class SproutVideoApiRepository
  implements SproutVideoRepositoryInterface
{
  private apiService: ApiService;
  private mapper: SproutVideoDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: SproutVideoDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllVideo(): Promise<SproutVideo[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.sproutVideo() + "/videos",
      {},
      null,
      headers
    );
    return this.mapper.convertSproutVideoDataFromApi(dataResponse);
  }
}
