/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { FaqPresenter } from "../../../../../../data/presenters/FaqPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dataFaq } from "../../../../../../entities/Faq";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalFaq: number;
  dataFaq: dataFaq[];
  formId: number;
  formDialog: string;
  formPertanyaan: string;
  formJawaban: string;
  jawaban: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  status_publish: boolean;
}

interface InitialState {
  state: IState;
  getFaq: Function;
  setPage: Function;
  setPageRow: Function;
  searchFaq: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormPertanyaan: Function;
  setFormJawaban: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deleteFaq: Function;
  setStatusPublish: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalFaq: 1,
    dataFaq: [],
    formDialog: "",
    formId: 0,
    formPertanyaan: "",
    formJawaban: "",
    formLoading: false,
    showDialog: false,
    jawaban: "",
    showDialogDelete: false,
    status_publish: false,
  },
  getFaq: () => {},
  searchFaq: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormPertanyaan: () => {},
  setFormJawaban: () => {},
  setShowDialog: () => {},
  deleteFaq: () => {},
  setShowDialogDelete: () => {},
  setStatusPublish: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: MasterFaqProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalFaq: 0,
    dataFaq: [],
    formDialog: "",
    formId: 0,
    formPertanyaan: "",
    formJawaban: "",
    formLoading: false,
    showDialog: false,
    jawaban: "",
    showDialogDelete: false,
    status_publish: false,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setStatusPublish = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      status_publish: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getFaq = async (pagee: number, keyword: string, row: number) => {
    setLoading(true);

    try {
      const faqPresenter = container.resolve(FaqPresenter);
      const getAllFaq = await faqPresenter.getAllFaq(pagee, row, keyword);

      const data = getAllFaq;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        dataFaq: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error when fetch faq :", error);
      toast.error("Ada Kesalahan Saat Mengambil Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const searchFaq = (value: string) => {
    setLoading(true);
    getFaq(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: dataFaq) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formPertanyaan: value.pertanyaan,
      formJawaban: value.jawaban,
      jawaban: value.jawaban,
      status_publish: value.status_publish === 1 ? true : false,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formPertanyaan == "") {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const faqPresenter = container.resolve(FaqPresenter);
      if (state.formDialog == "Tambah") {
        const action = await faqPresenter.storeFaq(
          state.formPertanyaan,
          state.formJawaban,
          state.status_publish
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getFaq(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await faqPresenter.updateFaq(
          state.formId,
          state.formPertanyaan,
          state.formJawaban,
          state.status_publish
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getFaq(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error when start action faq :", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormPertanyaan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPertanyaan: value,
    }));
  };

  const setFormJawaban = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJawaban: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formPertanyaan: "",
      formJawaban: "",
    }));
  };

  const deleteFaq = async () => {
    setFormLoading(true);

    try {
      const faqPresenter = container.resolve(FaqPresenter);
      const action = await faqPresenter.deleteFaq(state.formId);
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getFaq(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error when delete faq :", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  return (
    <MasterFaqProvider
      value={{
        state,
        getFaq,
        setPage,
        setPageRow,
        searchFaq,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormPertanyaan,
        setFormJawaban,
        setShowDialog,
        deleteFaq,
        setShowDialogDelete,
        setStatusPublish,
      }}
    >
      {children}
    </MasterFaqProvider>
  );
};

export const useMasterFaqContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterFaqContext,
  Provider,
};
