import { AxiosResponse } from "axios";
import { KategoriPengetahuan } from "../../entities/KategoriPengetahuan";

export class KategoriPengetahuanDataMapper {
  public convertListKategoriPengetahuanDataFromApi(
    result: AxiosResponse<any>
  ): KategoriPengetahuan[] {
    const { data } = result.data;

    let kategoriPengetahuan: KategoriPengetahuan[] = [];

    data.map((d: any) => {
      return kategoriPengetahuan.push(
        new KategoriPengetahuan(
          d.id,
          d.nama,
          d.created_at,
          d.updated_at,
          result.data.pagination.total_rows,
          d.path_icon
        )
      );
    });

    return kategoriPengetahuan;
  }
}
