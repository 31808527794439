export class ModulPelatihan{
    id: number;
    pelatihan_id: number;
    judul_modul: string;
    created_at: string;
    updated_at: string;
    subModulPelatihan : SubModulPelatihan[];
    sequence : number | null;
    
    constructor(
        id: number,
        pelatihan_id: number,
        judul_modul: string,
        created_at: string,
        updated_at: string,
        subModulPelatihan : SubModulPelatihan[],
        sequence : number | null
    ) {
      this.id = id;
      this.pelatihan_id = pelatihan_id;
      this.judul_modul = judul_modul;
      this.created_at = created_at;
      this.updated_at = updated_at;
      this.subModulPelatihan = subModulPelatihan;
      this.sequence = sequence;
    }
  }


  export class SubModulPelatihan{
    id: number;
    modul_pelatihan_id: number;
    jenis_sub_modul: string;
    materi_id: any;
    materi: any;
    jenis_ujian: string;
    judul: string;
    paket_soal_id : number;
    created_at: string;
    updated_at: string;
    bobot_penilaian : string;
    ada_latihan : number;
    m_latihan : Latihan | null;
    durasi_latihan : number;
    passing_grade : number;
    sequence : number | null;
    limit_soal : number | null;
    
    constructor(
        id: number,
        modul_pelatihan_id: number,
        jenis_sub_modul: string,
        materi_id: number,
        materi: any,
        jenis_ujian: string,
        judul: string,
        paket_soal_id : number,
        created_at: string,
        updated_at: string,
        bobot_penilaian : string,
        ada_latihan : number,
        m_latihan : Latihan | null,
        durasi_latihan : number,
        passing_grade : number,
        sequence : number | null,
        limit_soal : number | null
    ) {
      this.id = id;
      this.modul_pelatihan_id = modul_pelatihan_id;
      this.jenis_sub_modul = jenis_sub_modul;
      this.materi_id = materi_id;
      this.materi = materi;
      this.jenis_ujian = jenis_ujian;
      this.judul = judul;
      this.paket_soal_id = paket_soal_id;
      this.created_at = created_at;
      this.updated_at = updated_at;
      this.bobot_penilaian = bobot_penilaian;
      this.ada_latihan = ada_latihan;
      this.m_latihan = m_latihan;
      this.durasi_latihan = durasi_latihan;
      this.passing_grade = passing_grade;
      this.sequence = sequence;
      this.limit_soal = limit_soal;
    }
  }


  export class Latihan{
    id: number;
    sub_modul_pelatihan_id: number;
    materi_id: any;
    paket_soal_id : number;
    durasi_latihan : number;
    passing_grade : number;
    sequence: string;
    
    constructor(
        id: number,
        sub_modul_pelatihan_id: number,
        materi_id: number,
        paket_soal_id : number,
        durasi_latihan : number,
        passing_grade : number,
        sequence: string
    ) {
      this.id = id;
      this.sub_modul_pelatihan_id = sub_modul_pelatihan_id;
      this.materi_id = materi_id;
      this.paket_soal_id = paket_soal_id;
      this.durasi_latihan = durasi_latihan;
      this.passing_grade = passing_grade;
      this.sequence = sequence;
    }
  }


