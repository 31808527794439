export class DashboardRekapitulasi {
  judul_pelatihan: string;
  jp: string;
  waktu_mulai: string;
  waktu_selesai: string;
  count_peserta: number;
  nilai_tertinggi: number;
  nilai_terendah: number;
  nilai_rata_rata_keseluruhan: number;
  nilai_rata_rata_lulus: number;
  predikat: any;
  jumlah_data: number;
  jumlah_peserta: number;
  id: number;
  persentase_tte: number;
  update_status_pelatihan: string;

  constructor(
    judul_pelatihan: string,
    jp: string,
    waktu_mulai: string,
    waktu_selesai: string,
    count_peserta: number,
    nilai_tertinggi: number,
    nilai_terendah: number,
    nilai_rata_rata_keseluruhan: number,
    nilai_rata_rata_lulus: number,
    predikat: any,
    jumlah_data: number,
    jumlah_peserta: number,
    id: number,
    persentase_tte: number,
    update_status_pelatihan: string
  ) {
    this.judul_pelatihan = judul_pelatihan;
    this.jp = jp;
    this.waktu_mulai = waktu_mulai;
    this.waktu_selesai = waktu_selesai;
    this.count_peserta = count_peserta;
    this.nilai_tertinggi = nilai_tertinggi;
    this.nilai_terendah = nilai_terendah;
    this.nilai_rata_rata_keseluruhan = nilai_rata_rata_keseluruhan;
    this.nilai_rata_rata_lulus = nilai_rata_rata_lulus;
    this.predikat = predikat;
    this.jumlah_data = jumlah_data;
    this.jumlah_peserta = jumlah_peserta;
    this.id = id;
    this.persentase_tte = persentase_tte;
    this.update_status_pelatihan = update_status_pelatihan;
  }
}
