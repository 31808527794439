import { AxiosResponse } from "axios";
import { JawabanSoal } from "../../entities/JawabanSoal";

export class JawabanSoalDataMapper {
  public convertDetailJawabanSoalDataFromApi(
    result: AxiosResponse<any>
  ): JawabanSoal[] {
    const { data } = result.data;

    let jawaban: JawabanSoal[] = [];

    data.map((j: any) => {
      return jawaban.push(
        new JawabanSoal(j.id, j.soal_id, j.pilihan, j.jawaban_benar)
      );
    });

    return jawaban;
  }
}
