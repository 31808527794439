import { injectable } from "tsyringe";
import { JawabanSoalApiRepository } from "../../app/repository/api/JawabanSoalApiRepository";
import { JawabanSoal } from "../../entities/JawabanSoal";

@injectable()
export class JawabanSoalPresenter {
  private repository: JawabanSoalApiRepository;
  constructor(repository: JawabanSoalApiRepository) {
    this.repository = repository;
  }

  public getJawabanSoalBySoalId(soal_id: number): Promise<JawabanSoal[]> {
    return this.repository.getJawabanSoalBySoalId(soal_id);
  }

  public storeJawabanSoal(
    soal_id: number, 
    pilihan: string, 
    jawaban_benar: boolean
  ): Promise<any> {
    return this.repository.storeJawabanSoal(
      soal_id,
      pilihan,
      jawaban_benar,
    );
  }

  public updateJawabanSoal(
    jawaban_soal_id: number, 
    pilihan: string, 
    jawaban_benar: boolean
  ): Promise<any> {
    return this.repository.updateJawabanSoal(
      jawaban_soal_id,
      pilihan,
      jawaban_benar,  
    );
  }

  public deleteJawabanSoal(jawaban_soal_id: number): Promise<any> {
    return this.repository.deleteJawabanSoal(jawaban_soal_id);
  }

}
