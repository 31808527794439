import { DashboardRekapitulasi } from "../../entities/DashboardRekapitulasi";
import { AxiosResponse } from "axios";

export class DashboardRekapitulasiDataMapper {
  public convertListDataDasboardRekapitulasi(
    result: AxiosResponse<any>
  ): DashboardRekapitulasi[] {
    const { data } = result.data;

    let dataRekaps: DashboardRekapitulasi[] = [];

    data.map((val: any) => {
      return dataRekaps.push(
        new DashboardRekapitulasi(
          val.judul_pelatihan,
          val.jp,
          val.waktu_mulai,
          val.waktu_selesai,
          val.count_peserta,
          val.nilai_tertinggi,
          val.nilai_terendah,
          val.nilai_rata_rata_keseluruhan,
          val.nilai_rata_rata_lulus,
          val.predikat,
          result.data.pagination.total_rows,
          val.count_peserta,
          val.id,
          val.persentase_tte,
          val.update_status_pelatihan
        )
      );
    });

    return dataRekaps;
  }
}
