export class KategoriPengetahuan {
  id: string;
  nama: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;
  path_icon: string;
  constructor(
    id: string,
    nama: string,
    created_at: string,
    updated_at: string,
    jumlahData: number,
    path_icon: string
  ) {
    this.id = id;
    this.nama = nama;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
    this.path_icon = path_icon;
  }
}
