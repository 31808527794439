/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { MateriPresenter } from "../../../../../../data/presenters/MateriPresenter";
import { PathMateriPresenter } from "../../../../../../data/presenters/PathMateriPresenter";
import { Materi } from "../../../../../../entities/Materi";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import { ReviewMateriPresenter } from "../../../../../../data/presenters/ReviewMateriPresenter";
import { ReviewMateri } from "../../../../../../entities/ReviewMateri";
import { PathMateri } from "../../../../../../entities/PathMateri";

interface IState {
  isLoading: boolean;
  isLoadingReview: boolean;
  materi: Materi | null;
  pathMateri: PathMateri[] | null;
  showDialogDelete: boolean;
  selectedId: number;
  isLoadingDelete: boolean;
  jumlahPage: number;
  page: number;
  rating: string;
  foto: boolean;
  reviewMateri: ReviewMateri[];
  indexJenisMateri: number;
}

interface InitialState {
  state: IState;
  getDetailMateri: Function;
  setShowDialogDelete: Function;
  setSelectedId: Function;
  deleteMateri: Function;
  setPage: Function;
  setRating: Function;
  setFoto: Function;
  getReviewMateri: Function;
  setIndexJenisMateri: Function;
}

const initialState = {
  state: {
    isLoading: true,
    isLoadingReview: true,
    materi: null,
    pathMateri: null,
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDelete: false,
    jumlahPage: 0,
    page: 1,
    rating: "",
    foto: false,
    reviewMateri: [],
    indexJenisMateri: 0,
  },
  getDetailMateri: () => {},
  setShowDialogDelete: () => {},
  setSelectedId: () => {},
  deleteMateri: () => {},
  setPage: () => {},
  setRating: () => {},
  setFoto: () => {},
  getReviewMateri: () => {},
  setIndexJenisMateri: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenMateriDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    isLoadingReview: true,
    isLoadingDelete: false,
    materi: null,
    pathMateri: null,
    showDialogDelete: false,
    selectedId: 0,
    jumlahPage: 0,
    page: 1,
    rating: "",
    foto: false,
    reviewMateri: [],
    indexJenisMateri: 0,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingReview = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingReview: value,
    }));
  };

  const setLoadingDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const getDetailMateri = async (id: number) => {
    setLoading(true);
    setLoadingReview(true);

    try {
      // Get Materi
      const materiPresenter = container.resolve(MateriPresenter);
      const getDetail = await materiPresenter.getDetailMateri(id);
      const data = getDetail;

      // Get Path Materi
      const pathMateriPresenter = container.resolve(PathMateriPresenter);
      const get = await pathMateriPresenter.getAllPathMateri(id, "");
      const response = get;

      setState((prevstate) => ({
        ...prevstate,
        materi: data,
        pathMateri: response,
      }));

      getReviewMateri(1, "", data.id, state.foto);

      setLoading(false);
    } catch (error: any) {
      console.log("error get materi:", error);
      setLoading(false);
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setSelectedId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedId: value,
    }));
  };

  const setIndexJenisMateri = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexJenisMateri: value,
    }));
  };

  const deleteMateri = async () => {
    setLoadingDelete(true);

    try {
      const materiPresenter = container.resolve(MateriPresenter);
      const deleteMateri = await materiPresenter.deleteMateri(state.selectedId);

      const data = deleteMateri;

      if (data.data.code == 200) {
        toast.success("Berhasil Menghapus Data Materi.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_materi`);
        window.location.reload();
      } else {
        toast.error("Gagal Menghapus Data Materi.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingDelete(false);
      setShowDialogDelete(false);
    } catch (error: any) {
      setLoadingDelete(false);
    }
  };

  const setPage = (value: number) => {
    setLoadingReview(true);
    getReviewMateri(
      value + 1,
      state.rating,
      Number(state.materi?.id),
      state.foto
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setRating = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      reviewMateri: [],
    }));
    setLoadingReview(true);

    getReviewMateri(1, value, Number(state.materi?.id), false);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      rating: value,
      foto: false,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getReviewMateri = async (
    pagee: number,
    rating: string,
    materi_id: number,
    foto: boolean
  ) => {
    try {
      const reviewMateriPresenter = container.resolve(ReviewMateriPresenter);
      const getAllReviewMateri = await reviewMateriPresenter.getReviewMateri(
        pagee,
        10,
        "",
        materi_id,
        rating,
        foto
      );

      const data = getAllReviewMateri;

      setJumlahPage(data[0].jumlahDataReview / 10);

      setState((prevstate) => ({
        ...prevstate,
        reviewMateri: data,
      }));

      setLoadingReview(false);
    } catch (error: any) {
      console.log("error Review Materi:", error);
      setLoadingReview(false);
    }
  };

  const setFoto = () => {
    setState((prevstate) => ({
      ...prevstate,
      reviewMateri: [],
    }));
    setLoadingReview(true);
    getReviewMateri(1, "", Number(state.materi?.id), true);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      rating: "",
      foto: true,
    }));
  };

  return (
    <ManajemenMateriDetailProvider
      value={{
        state,
        getDetailMateri,
        setShowDialogDelete,
        setSelectedId,
        deleteMateri,
        setPage,
        setRating,
        setFoto,
        getReviewMateri,
        setIndexJenisMateri,
      }}
    >
      {children}
    </ManajemenMateriDetailProvider>
  );
};

export const useManajemenMateriDetailContext = () => React.useContext(Context);

export default {
  useManajemenMateriDetailContext,
  Provider,
};
