import { AxiosResponse } from "axios";
import { PathFotoKomentar, ReviewMateri } from "../../entities/ReviewMateri";
import { Users } from "../../entities/User";

export class ReviewMateriDataMapper {
  public convertListReviewMateriDataFromApi(
    result: AxiosResponse<any>
  ): ReviewMateri[] {
    const { data } = result.data;

    let review: ReviewMateri[] = [];

    data.map((d: any) => {
      let pathFoto: PathFotoKomentar[] = [];

      d.path_foto_komentar_materi.map((p: any) => {
        return pathFoto.push(new PathFotoKomentar(p.id, p.label));
      });

      let user: Users = new Users(
        d.user[0].id,
        d.user[0].name,
        d.user[0].email,
        d.user[0].nik,
        d.user[0].nip,
        d.user[0].phone_number,
        d.user[0].level,
        d.user[0].email_verified_at,
        d.user[0].remember_token,
        d.user[0].path_foto,
        d.user[0].created_at,
        d.user[0].updated_at,
        0
      );

      return review.push(
        new ReviewMateri(
          d.id,
          d.user_id,
          d.materi_id,
          d.komentar,
          d.rating,
          d.created_at,
          d.updated_at,
          user,
          result.data.pagination.total_rows,
          pathFoto
        )
      );
    });

    return review;
  }
}
