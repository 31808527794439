import React, { useEffect, useState } from "react";
import {
  Flex,
  Heading,
  Box,
  Button,
  Text,
  Input,
  Grid,
  GridItem,
  Switch,
  Icon,
  Image,
  Link,
  Divider,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import ManajemenIdentitasController, {
  useManajemenIdentitasContext,
} from "../../../../controller/admin/landing_page/manajemen_identitas";
import { ReactComponent as InstagramIcon } from "../../../../assets/svg/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../../assets/svg/twitter_logo.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/svg/youtube.svg";
import Loader from "../../../../components/Loader";

/**
 * Manajemen Identitas is a component that allows users to manage layout like navbar and footer.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */

interface FileUploadProps {
  onChange?: Function;
  defaultImage: string | null | undefined;
}

const FileUpload: React.FC<FileUploadProps> = ({ onChange, defaultImage }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);

    if (onChange && file) {
      onChange(file);

      // Create a URL for the selected file
      const fileUrl = URL.createObjectURL(file);
      setImageUrl(fileUrl);
    }
  };

  useEffect(() => {
    if (defaultImage) setImageUrl(defaultImage);
  }, [defaultImage]);

  return (
    <Box>
      <Input type="file" accept="image/*" onChange={handleFileChange} mb="2" />

      {imageUrl && (
        <Box mt="2">
          <Text fontWeight="bold">Preview:</Text>
          <Image
            src={imageUrl}
            alt="Preview"
            boxSize="150px"
            objectFit="cover"
          />
        </Box>
      )}
    </Box>
  );
};

const ManajemenIdentitas = () => {
  const {
    setManajemenIdentitasState: setState,
    setLogoNavbar,
    setLogoFooterLeft,
    setLogoFooterCenter,
    fetchData,
    postData,
  } = useManajemenIdentitasContext();
  const state = useManajemenIdentitasContext().state;

  const {
    logoNavbarUrl,
    logoFooterLeftUrl,
    logoFooterCenterUrl,
    contact,
    urlFacebook,
    urlInstagram,
    urlTwitter,
    urlYoutube,
    copyright,
    loadingAction,
    loadingData,
    isPreview,
  } = state;

  useEffect(() => {
    fetchData();
  }, []);

  const PreviewComponent = () => {
    return (
      <Box>
        <Box>
          <Text fontSize="md" mb={2}>
            Navbar
          </Text>
          <Box bg="white" display={{ base: "none", md: "block" }}>
            <Flex
              justify="space-between"
              align="center"
              h={20}
              px={{ base: 8, md: 12 }}
            >
              <Flex align="center" className="gap-4">
                <Button
                  type="button"
                  color="white"
                  bg="green.700"
                  _hover={{ bg: "green.800" }}
                  _active={{ bg: "green.800" }}
                  fontSize="sm"
                  px={4}
                  rounded="lg"
                >
                  Masuk
                </Button>
                <Text fontSize="md" display="inline-block">
                  Pelatihan
                </Text>
                <Text fontSize="md" display="inline-block">
                  Pengetahuan
                </Text>
                <Text fontSize="md" display="inline-block">
                  Podcast
                </Text>
              </Flex>

              <Flex position="relative">
                <Box
                  position="absolute"
                  insetY={0}
                  left={0}
                  pl={3}
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={AiOutlineSearch} h={5} w={5} />
                </Box>
                <form>
                  <Input
                    type="text"
                    width="300px"
                    fontSize="md"
                    variant="outline"
                    borderColor="green.700"
                    borderRadius="2xl"
                    height={10}
                    pl={10}
                    placeholder="Cari Pelatihan"
                  />
                </form>
              </Flex>

              <Flex gap={4} align="center">
                {logoNavbarUrl ? (
                  <Image src={logoNavbarUrl} height={10} />
                ) : (
                  <Text fontSize="sm">Logo Navbar</Text>
                )}
              </Flex>
            </Flex>
          </Box>
        </Box>
        <Box mt={4}>
          <Text fontSize="md" mb={2}>
            Footer
          </Text>
          <Box bg="green.700" w="full" py={8} px={{ base: 8, md: 12 }}>
            <Box
              justifyContent="space-between"
              mt={{ md: 4 }}
              alignItems="start"
              flexDirection={{ base: "column", md: "row" }}
              display={{ base: "none", md: "flex" }}
            >
              <Box w="300px">
                <Flex alignItems="start" justifyContent="start" h="full">
                  {logoFooterLeftUrl ? (
                    <Image src={logoFooterLeftUrl} w="auto" h="3rem" />
                  ) : (
                    <Text fontSize="sm" color="white">
                      Logo Footer Kiri
                    </Text>
                  )}
                </Flex>
              </Box>
              <Box w={{ base: "300px", md: "450px" }} h="3rem">
                <Flex
                  alignItems="center"
                  justifyContent="start"
                  h="full"
                  flexDirection="column"
                >
                  {logoFooterCenterUrl ? (
                    <Image
                      w="full"
                      h="full"
                      src={logoFooterCenterUrl}
                      objectFit="contain"
                    />
                  ) : (
                    <Text fontSize="sm" color="white">
                      Logo Footer Tengah
                    </Text>
                  )}
                </Flex>
              </Box>
              <Box w="300px" display="flex" justifyContent={{ md: "flex-end" }}>
                <Box>
                  <Text fontWeight="semibold" color="white" fontSize="md">
                    Kontak
                  </Text>
                  <Text fontWeight="normal" color="white" fontSize="md">
                    {contact}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Flex
              justifyContent={{ base: "start", md: "center" }}
              mt={{ base: "2rem", md: "3rem" }}
            >
              <Link mr={{ base: 8, md: 2 }} href={urlFacebook} isExternal>
                <Icon as={FacebookIcon} boxSize={6} />
              </Link>
              <Link mr={{ base: 8, md: 2 }} href={urlInstagram} isExternal>
                <Icon as={InstagramIcon} boxSize={6} />
              </Link>
              <Link mr={{ base: 8, md: 2 }} href={urlYoutube} isExternal>
                <Icon as={YoutubeIcon} boxSize={8} />
              </Link>
              <Link mr={{ base: 8, md: 2 }} href={urlTwitter} isExternal>
                <Icon as={TwitterIcon} boxSize={6} />
              </Link>
            </Flex>
            <Divider my={4} />
            <Box display="flex" justifyContent="center" alignItems="center">
              <Text color="white" fontSize={{ base: "11px", md: "sm" }}>
                © {copyright}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="#EDF2F7"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              Manajemen Identitas
            </Heading>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Switch
              defaultChecked={isPreview}
              colorScheme="green"
              mr={2}
              mt={1}
              onChange={(e) => setState("isPreview", !isPreview)}
            />
            <Text fontWeight="semibold" fontSize="sm">
              Preview
            </Text>
            <Button
              isLoading={loadingAction}
              colorScheme="green"
              ml={3}
              onClick={() => {
                postData();
              }}
            >
              Simpan
            </Button>
          </Flex>
        </Flex>
      </Box>

      <Box m={4} px={4} py={8} boxShadow="lg" borderRadius="xl" bg="white">
        {loadingData ? (
          <Loader />
        ) : isPreview ? (
          <PreviewComponent />
        ) : (
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* Logo Navbar */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Logo Navbar
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <FileUpload
                onChange={setLogoNavbar}
                defaultImage={logoNavbarUrl}
              />
            </GridItem>

            {/* Logo Footer Kiri */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Logo Footer Kiri
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <FileUpload
                onChange={setLogoFooterLeft}
                defaultImage={logoFooterLeftUrl}
              />
            </GridItem>

            {/* Logo Footer Tengah */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Logo Footer Tengah
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <FileUpload
                onChange={setLogoFooterCenter}
                defaultImage={logoFooterCenterUrl}
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Kontak
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={contact}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setState("contact", e.target.value)}
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Url Facebook
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={urlFacebook}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setState("urlFacebook", e.target.value)}
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Url Instagram
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={urlInstagram}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setState("urlInstagram", e.target.value)}
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Url Youtube
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={urlYoutube}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setState("urlYoutube", e.target.value)}
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Url Twitter
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={urlTwitter}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setState("urlTwitter", e.target.value)}
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Copyright
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={copyright}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setState("copyright", e.target.value)}
              />
            </GridItem>
          </Grid>
        )}
      </Box>
    </SidebarWithHeader>
  );
};

const ManajemenIdentitasMain: React.FC = () => {
  return (
    <ManajemenIdentitasController.Provider>
      <ManajemenIdentitas />
    </ManajemenIdentitasController.Provider>
  );
};

export default ManajemenIdentitasMain;
