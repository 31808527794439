import {
  Box,
  Divider,
  Heading,
  Text,
  Grid,
  GridItem,
  Input,
  Stack,
  Icon,
  Button,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoStar } from "react-icons/io5";
import { RiMedal2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from "../../../components/Loader";
import { Wrapper } from "../../../components/Wrapper";
import UserContext, {
  useUserContext,
} from "../../../controller/registered/profile";
import PengetahuanContext, {
  usePengetahuanContext,
} from "../../../controller/pengetahuan/index";

/**
 * PreviewRapot component to display the preview of knowledge report.
 *
 * @component
 * @example
 * @author Bagas Prasetyadi
 * return (
 *   <PreviewRapot />
 * )
 */

const PreviewRapot = () => {
  const history = useHistory();

  const { getPengetahuanSaya, setState, getDetailJP } = usePengetahuanContext();
  const {
    listPengetahuanSaya,
    isLoadingPengetahuanSaya,
    totalPengetahuanSaya,
    perPagePengetahuanSaya,
    pagePengetahuanSaya,
    dataJP,
  } = usePengetahuanContext().state;
  const { getDetailUser } = useUserContext();
  const { name, nik, tempat_lahir, tanggal_lahir, jabatan, instansi } =
    useUserContext().state;

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };
  const columns: any = [
    {
      name: "NO.",
      selector: (row, index) =>
        (pagePengetahuanSaya - 1) * perPagePengetahuanSaya + (index + 1),
      width: "80px",
    },
    {
      name: "NAMA PENGETAHUAN",
      selector: (row) => row?.pengetahuan?.title ?? "",
      sortable: true,
      wrap: true,
    },
    {
      name: "TANGGAL MENGIKUTI",
      selector: (row) =>
        row?.created_at
          ? moment(row?.tanggal_mulai).format("dddd, DD MMMM YYYY")
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "POINT",
      selector: (row) => row?.pengetahuan?.review_count ?? "",
      cell: (row) => {
        return (
          <Stack direction="row">
            <Icon as={IoStar} color="#FFD95F" boxSize={4} />
            <Text
              color="black"
              fontWeight="semibold"
              fontSize="sm"
              sx={{ marginLeft: "3px !important" }}
            >
              / 1 Bintang
            </Text>
          </Stack>
        );
      },
      wrap: true,
    },
  ];

  const ttl = `${tempat_lahir}, ${moment(tanggal_lahir).format(
    "DD MMMM YYYY"
  )}`;

  const handlePageChange = (page) => {
    setState((prevState) => ({ ...prevState, pagePengetahuanSaya: page }));
  };

  const handlePerRowsChange = (newPerPage) => {
    setState((prevState) => ({
      ...prevState,
      pagePengetahuanSaya: 1,
      perPagePengetahuanSaya: newPerPage,
    }));
  };

  useEffect(() => {
    getDetailUser();
    getDetailJP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPengetahuanSaya("selesai", "0");
  }, [pagePengetahuanSaya, perPagePengetahuanSaya]);
  return (
    <Wrapper>
      <Box my={10} mx={{ base: 4, md: "auto" }}>
        <Button
          leftIcon={<AiOutlineArrowLeft />}
          color="black"
          bg="white"
          mb={4}
          _hover={{ bg: "white" }}
          _active={{ bg: "white" }}
          onClick={() => history.push("/")}
        >
          Kembali
        </Button>
        <Box bg="white" p={4} rounded="lg" w={{ base: "auto", md: "6xl" }}>
          <Heading
            as="h2"
            color="#4B5563"
            fontSize={{ base: "lg", md: "xl" }}
            fontWeight="normal"
          >
            Rapot Pengetahuan
          </Heading>
          <Divider my={4} />
          <Grid templateColumns="repeat(8, 1fr)" gap={3}>
            {/* Nama */}
            <GridItem colSpan={2}>
              <Text fontSize="sm">Nama</Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Input value={name} />
            </GridItem>

            {/* NIK */}
            <GridItem colSpan={2}>
              <Text fontSize="sm">NIK</Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Input value={nik} />
            </GridItem>

            {/* TTL */}
            <GridItem colSpan={2}>
              <Text fontSize="sm">Tempat dan Tanggal Lahir</Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Input value={ttl} />
            </GridItem>

            <GridItem colSpan={2}>
              <Text fontSize="sm">Jabatan</Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Input value={jabatan} />
            </GridItem>

            <GridItem colSpan={2}>
              <Text fontSize="sm">Instansi/Unit Kerja</Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Input value={instansi} />
            </GridItem>
          </Grid>

          <Divider my={4} />
          <Heading as="h3" color="#9CA3AF" fontSize="md" fontWeight="semibold">
            Perhitungan Rapot
          </Heading>

          <Box mt={4} maxW="full">
            <DataTable
              columns={columns}
              data={listPengetahuanSaya}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
              paginationPerPage={perPagePengetahuanSaya}
              paginationServer
              paginationTotalRows={
                listPengetahuanSaya.length > 0 ? totalPengetahuanSaya : 0
              }
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={isLoadingPengetahuanSaya}
              progressComponent={<Loader />}
              noDataComponent="Data tidak ditemukan"
              responsive
            />
          </Box>

          <Divider my={4} />

          <Heading as="h3" color="#9CA3AF" fontSize="md" fontWeight="semibold">
            Akumulasi JP
          </Heading>
          <Text color="#1F2937" fonstSize="xs" mt={2}>
            *Catatan: 1 JP = 5 Bintang (dihitung dari bintang yang dihasilkan
            disetiap pengetahuan yang sudah selesai)
          </Text>
          <Stack mt={2} direction="row" spacing={4} w="full">
            <Box
              rounded="md"
              p={4}
              bg="#F9FAFB"
              w="full"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Box bg="white" p={2} rounded="md" display="inline-block">
                  <Icon as={IoStar} color="#FFD95F" boxSize={5} />
                </Box>
                <Text color="#1F2937" fontSize="sm" mt={1}>
                  Total Bintang
                </Text>
              </Box>
              <Box>
                <Text color="#0DBD7F" fontSize="2xl" fontWeight="semibold">
                  {dataJP?.point}
                </Text>
              </Box>
            </Box>
            <Box
              rounded="md"
              p={4}
              bg="#F9FAFB"
              w="full"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Box bg="white" p={2} rounded="md" display="inline-block">
                  <Icon as={RiMedal2Line} color="#FFD95F" boxSize={5} />
                </Box>
                <Text color="#1F2937" fontSize="sm" mt={1}>
                  Akumulasi JP
                </Text>
              </Box>
              <Box>
                <Text color="#0DBD7F" fontSize="2xl" fontWeight="semibold">
                  {dataJP?.jp}
                </Text>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Wrapper>
  );
};

const PreviewRapotWrapper = () => {
  return (
    <PengetahuanContext.Provider>
      <UserContext.Provider>
        <PreviewRapot />
      </UserContext.Provider>
    </PengetahuanContext.Provider>
  );
};

export default PreviewRapotWrapper;
