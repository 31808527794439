import { injectable } from "tsyringe";
import { MenuJenisPelatihanApiRepository } from "../../app/repository/api/MenuJenisPelatihanApiRepository";
import { MenuJenisPelatihan, SubMenuJenisPelatihan } from "../../entities/MenuJenisPelatihan";

@injectable()
export class MenuJenisPelatihanPresenter {
  private repository: MenuJenisPelatihanApiRepository;
  constructor(repository: MenuJenisPelatihanApiRepository) {
    this.repository = repository;
  }

  public getAllMenuJenisPelatihan(
    page: number,
    rows: number , 
    keyword : string
  ): Promise<MenuJenisPelatihan[]> {
    return this.repository.getAllMenuJenisPelatihan(page,rows,keyword);
  }

  public getDetailMenuJenisPelatihan(
    id: number
  ): Promise<MenuJenisPelatihan> {
    return this.repository.getDetailMenuJenisPelatihan(id);
  }


  public storeMenuJenisPelatihan(
    menu_jenis_pelatihan : string
    ): Promise<any> {
    return this.repository.storeMenuJenisPelatihan(
        menu_jenis_pelatihan
    );
  }

  public updateMenuJenisPelatihan(
    id : number,
    menu_jenis_pelatihan : string
    ): Promise<any> {
    return this.repository.updateMenuJenisPelatihan(
        id,
        menu_jenis_pelatihan
    );
  }

  public deleteMenuJenisPelatihan(id: number): Promise<any> {
    return this.repository.deleteMenuJenisPelatihan(id);
  }

  public getAllSubMenuJenisPelatihan(
    page: number,
    rows: number , 
    keyword : string
  ): Promise<SubMenuJenisPelatihan[]> {
    return this.repository.getAllSubMenuJenisPelatihan(page,rows,keyword);
  }

  public getDetailSubMenuJenisPelatihan(
    id: number
  ): Promise<SubMenuJenisPelatihan> {
    return this.repository.getDetailSubMenuJenisPelatihan(id);
  }


  public storeSubMenuJenisPelatihan(
    menu_jenis_pelatihan_id : number,
    sub_menu_jenis_pelatihan: string ,
    ): Promise<any> {
    return this.repository.storeSubMenuJenisPelatihan(
        menu_jenis_pelatihan_id,
        sub_menu_jenis_pelatihan
    );
  }

  public updateSubMenuJenisPelatihan(
    id : number,
    menu_jenis_pelatihan_id : number,
    sub_menu_jenis_pelatihan: string ,
    ): Promise<any> {
    return this.repository.updateSubMenuJenisPelatihan(
        id,
        menu_jenis_pelatihan_id,
        sub_menu_jenis_pelatihan
    );
  }

  public deleteSubMenuJenisPelatihan(id: number): Promise<any> {
    return this.repository.deleteSubMenuJenisPelatihan(id);
  }

}
