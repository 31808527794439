/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React from "react";
import { Wrapper } from "../../../components/Wrapper";
import RegisterController from "../../../controller/auth/register";
import { ToastContainer } from "react-toastify";
import RegisterForm from "../../../components/Register/RegisterForm";

const WrapperMain: React.FC = () => {
  return (
    <Wrapper>
      {/* Content */}
      <RegisterForm />
    </Wrapper>
  );
};

const Register: React.FC = () => {
  return (
    <RegisterController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </RegisterController.Provider>
  );
};

export default Register;
