import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../../components/Loader";
import { Wrapper } from "../../../components/Wrapper";
import { FcDocument } from "react-icons/fc";
import { FiDownload } from "react-icons/fi";
import PengetahuanRiwayatRapotContext, {
  usePengetahuanRiwayatRapotContext,
} from "../../../controller/pengetahuan/riwayat-rapot";
import { FaSearch } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import history from "../../../../../history";
import { format, parseISO } from "date-fns";
import { id } from "date-fns/locale";

/**
 * RiwayatRapotPengetahuan component to display the history of downloaded reports.
 *
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 * @author Bagas Prasetyadi
 */

const RiwayatRapotPengetahuan = () => {
  const {
    listData,
    isLoading,
    page,
    perPage,
    search,
    totalListData,
    isLoadingDownloadRapot,
  } = usePengetahuanRiwayatRapotContext().state;
  const { getRiwayatRapot, setRiwayatRapotState, downloadRiwayatRapot } =
    usePengetahuanRiwayatRapotContext().dispatch;

  const parsedDate = (originalDate: string) => {
    return parseISO(originalDate.replace(" ", "T"));
  };

  const columns: any = [
    {
      name: "NO.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "DETAIL",
      selector: (row) => {
        return (
          <Flex alignItems="center">
            <Icon as={FcDocument} mr={2} boxSize="30px" />
            <Text>{row?.nama_file ?? "-"}</Text>
          </Flex>
        );
      },
      sortable: true,
      width: "400px",
      wrap: true,
    },
    {
      name: "TANGGAL CONVERT",
      selector: (row) =>
        row?.tanggal_convert
          ? format(parsedDate(row?.tanggal_convert), "dd MMMM yyyy - HH:mm", {
              locale: id,
            })
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "AKSI",
      cell: (row, index) => (
        <Flex alignItems="center">
          <Button
            size="sm"
            colorScheme="orange"
            leftIcon={<Icon as={FiDownload} />}
            onClick={() => downloadRiwayatRapot(row?.url_download, index)}
            h="30px"
            isLoading={isLoadingDownloadRapot[index] || false}
          >
            Download
          </Button>
        </Flex>
      ),
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getRiwayatRapot();
  }, [search, page, perPage]);

  const handlePerRowsChange = (newPerPage) => {
    setRiwayatRapotState("page", 1);
    setRiwayatRapotState("perPage", newPerPage);
  };

  const handlePageChange = (page) => {
    setRiwayatRapotState("page", page);
  };

  return (
    <Box mx="auto" my={12} w="6xl">
      <Box bg="white" rounded="md" p={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="flex-start" alignItems="center">
            <IconButton
              aria-label="Previous Page"
              icon={<AiOutlineArrowLeft />}
              fontSize="md"
              mr={2}
              variant="ghost"
              onClick={() => history.goBack()}
            />
            <Heading as="h2" fontSize="xl" fontWeight="normal">
              Riwayat Convert Rapot Pengetahuan
            </Heading>
          </Flex>
          <InputGroup w="250px">
            <InputLeftElement pointerEvents="none">
              <FaSearch />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Cari Disini..."
              onChange={(e) => {
                setRiwayatRapotState("search", e.target.value);
                setRiwayatRapotState("page", 1);
              }}
              _focus={{
                ring: "1",
                ringColor: "green.700",
                borderColor: "green.700",
              }}
            />
          </InputGroup>
        </Flex>

        <Box mt={4}>
          <DataTable
            columns={columns}
            data={listData}
            customStyles={customStyles}
            pagination
            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            paginationPerPage={perPage}
            paginationServer
            paginationTotalRows={listData.length > 0 ? totalListData : 0}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={isLoading}
            progressComponent={<Loader />}
            noDataComponent="Data tidak ditemukan"
          />
        </Box>
      </Box>
    </Box>
  );
};

const WrapperMain = () => {
  return (
    <Wrapper>
      <RiwayatRapotPengetahuan />
    </Wrapper>
  );
};

const RiwayatRapotPengetahuanPage = () => {
  return (
    <PengetahuanRiwayatRapotContext.Provider>
      <WrapperMain />
    </PengetahuanRiwayatRapotContext.Provider>
  );
};

export default RiwayatRapotPengetahuanPage;
