export class Quiz {
    id: number
    sub_modul_pelatihan_id: number
    materi_id : number
    waktu_ke : string
    pertanyaan : string
    created_at: string
    updated_at: string
    JawabanQuiz : JawabanQuiz[]
  
    constructor(
      id: number,
      sub_modul_pelatihan_id: number,
      materi_id : number,
      waktu_ke : string,
      pertanyaan : string,
      created_at: string,
      updated_at: string,
      JawabanQuiz : JawabanQuiz[]
    ) {
      this.id = id
      this.sub_modul_pelatihan_id = sub_modul_pelatihan_id
      this.materi_id = materi_id
      this.waktu_ke = waktu_ke
      this.pertanyaan = pertanyaan
      this.created_at = created_at
      this.updated_at = updated_at
      this.JawabanQuiz = JawabanQuiz
    }
  }

  export class JawabanQuiz {
    id: number
    quiz_id: number
    pilihan : string
    jawaban_benar : number
    created_at: string
    updated_at: string
  
    constructor(
        id: number,
        quiz_id: number,
        pilihan : string,
        jawaban_benar : number,
        created_at: string,
        updated_at: string
    ) {
      this.id = id
      this.quiz_id = quiz_id
      this.pilihan = pilihan
      this.jawaban_benar = jawaban_benar
      this.created_at = created_at
      this.updated_at = updated_at
    }
  }