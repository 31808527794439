import React, { createContext, useState, useContext, useRef } from "react";
import { BsPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import {
  IoVolumeMute,
  IoVolumeHigh,
  IoVolumeLow,
  IoVolumeMedium,
} from "react-icons/io5";
import { useToast } from "@chakra-ui/react";
import axiosInstance from "../../../api/axiosInstance";

/**
 * Interface for podcast data
 */

interface PodcastItem {
  id: number;
  title: string;
  description: string;
  audio: string;
  duration: string;
  category: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
  thumbnail: string;
}

/**
 * Interface for podcast state and functions
 */
interface IState {
  state: {
    isPlaying: boolean;
    currentTrack: null | PodcastItem;
    showPodcastPlayer: boolean;
    isRepeat: boolean;
    volume: number;
    currentTime: number;
    duration: number;
    sliderValue: number;
    seekTime: number;
    seeDetail: boolean;
    listData: PodcastItem[];
    loadingData: boolean;
    url: string;
    currentId: null | number;
    showOptionIcon: boolean;
    optionIconSelected: number;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
    keyword: string;
    listCategorySelected: string[];
    urlToCopy: string;
  };
  selectedPodcast: PodcastItem | null;
  nextPodcast: PodcastItem | null;
  setIsPlaying: Function;
  setCurrentTrack: Function;
  handlePodcastPlayer: Function;
  renderPlayPauseIcon: Function;
  audioRef: React.RefObject<HTMLAudioElement> | null;
  setIsRepeat: Function;
  setVolume: Function;
  handleLoadedMetadata: Function;
  handleTimeUpdate: Function;
  handleSliderChange: Function;
  formatTime: Function;
  setSeekTime: Function;
  renderVolumeIcon: Function;
  togglePlayPause: Function;
  getTime: Function;
  setSeeDetail: Function;
  handleBackNextPodcast: Function;
  fetchData: Function;
  handleDownloadAudio: Function;
  handleShare: Function;
  setPodcastState: Function;
  getDetailPodcast: Function;
}

const initialState: IState = {
  state: {
    isPlaying: false,
    currentTrack: null,
    showPodcastPlayer: false,
    isRepeat: false,
    volume: 50,
    currentTime: 0,
    duration: 0,
    sliderValue: 0,
    seekTime: 0,
    seeDetail: false,
    listData: [],
    loadingData: true,
    url: "public/podcast",
    currentId: null,
    showOptionIcon: false,
    optionIconSelected: 0,
    pagination: null,
    page: 1,
    perPage: 3,
    keyword: "",
    listCategorySelected: [""],
    urlToCopy: "",
  },
  selectedPodcast: null,
  nextPodcast: null,
  audioRef: null,
  setIsPlaying: () => {},
  setCurrentTrack: () => {},
  handlePodcastPlayer: () => {},
  renderPlayPauseIcon: () => {},
  setIsRepeat: () => {},
  setVolume: () => {},
  handleLoadedMetadata: () => {},
  handleTimeUpdate: () => {},
  handleSliderChange: () => {},
  formatTime: () => {},
  setSeekTime: () => {},
  renderVolumeIcon: () => {},
  togglePlayPause: () => {},
  getTime: () => {},
  setSeeDetail: () => {},
  handleBackNextPodcast: () => {},
  fetchData: () => {},
  handleDownloadAudio: () => {},
  handleShare: () => {},
  setPodcastState: () => {},
  getDetailPodcast: () => {},
};

const Context = createContext<IState>(initialState);
const { Provider: PodcastProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const audioRef = useRef<HTMLAudioElement>(null);
  const toast = useToast();
  const { url, listData, keyword, page, perPage, listCategorySelected } =
    state.state;

  /**
   * Sets the value of a specific key in the podcast state
   * @param {keyof IState["state"]} key - The key to set the value for
   * @param {any} value - The new value
   */

  const setPodcastState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async () => {
    const joinCategory = listCategorySelected.join(",");

    setPodcastState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: "1",
          category: joinCategory,
          keyword: keyword,
          rows: perPage,
          page: page,
        },
      });

      setPodcastState("listData", response.data?.data);
      setPodcastState("pagination", response.data?.pagination);
      setPodcastState("loadingData", false);
    } catch (error) {
      setPodcastState("loadingData", false);
      throw error;
    }
  };

  const getDetailPodcast = async (id) => {
    setPodcastState("loadingData", true);
    try {
      const response = await axiosInstance.get(`${url}/${id}`, {
        params: {
          status_publish: "1",
        },
      });

      const newListData = new Array(response.data?.data);

      setPodcastState("listData", newListData);
      setPodcastState("loadingData", false);
    } catch (error) {
      setPodcastState("loadingData", false);
      throw error;
    }
  };

  const setIsPlaying: React.Dispatch<React.SetStateAction<boolean>> = (
    value
  ) => {
    setPodcastState("isPlaying", value);
  };

  const setCurrentTrack: React.Dispatch<
    React.SetStateAction<PodcastItem | null>
  > = (track) => {
    setPodcastState("currentTrack", track);
  };

  const setIsRepeat: React.Dispatch<React.SetStateAction<boolean>> = (
    value
  ) => {
    setPodcastState("isRepeat", value);
  };

  const setVolume: React.Dispatch<React.SetStateAction<number>> = (value) => {
    setPodcastState("volume", value);
  };

  const setDuration: React.Dispatch<React.SetStateAction<any>> = (value) => {
    setPodcastState("duration", value);
  };

  const setCurrentTime: React.Dispatch<React.SetStateAction<number>> = (
    value
  ) => {
    setPodcastState("currentTime", value);
  };

  const setSliderValue: React.Dispatch<React.SetStateAction<number>> = (
    value
  ) => {
    setPodcastState("sliderValue", value);
  };

  const setSeekTime: React.Dispatch<React.SetStateAction<number>> = (value) => {
    setPodcastState("seekTime", value);
  };

  const setSeeDetail: React.Dispatch<React.SetStateAction<boolean>> = (
    value
  ) => {
    setPodcastState("seeDetail", value);
  };

  const handlePodcastPlayer = (value) => {
    if (state.state.isPlaying) {
      setPodcastState("showPodcastPlayer", value);
      const podcastSlider = document.getElementById(
        "slider-thumb-podcast-slider"
      );
      podcastSlider?.blur();
    }
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current?.duration);
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef?.current?.currentTime;
    if (currentTime) {
      setCurrentTime(currentTime);
      setSliderValue((currentTime / state.state.duration) * 100); // Menghitung posisi slider berdasarkan waktu terkini
    }
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const renderPlayPauseIcon = (track: PodcastItem) => {
    if (state.state.isPlaying) {
      if (track.audio === state.state.currentTrack?.audio) {
        return BsFillPauseCircleFill;
      } else {
        return BsPlayCircleFill;
      }
    }
    return BsPlayCircleFill;
  };

  const renderVolumeIcon = (volume) => {
    if (volume === 0) {
      return IoVolumeMute;
    }

    if (volume >= 1 && volume < 30) {
      return IoVolumeLow;
    }

    if (volume >= 30 && volume < 70) {
      return IoVolumeMedium;
    }

    if (volume >= 70) {
      return IoVolumeHigh;
    }
  };

  const togglePlayPause = (track) => {
    setCurrentTrack(track);

    setPodcastState("currentId", track.iid);

    if (state.state.currentTrack !== track) {
      setIsPlaying(true);
    } else {
      setIsPlaying(!state.state.isPlaying);
    }

    if (audioRef?.current) {
      if (state.state.isPlaying) {
        audioRef?.current.pause();
      } else {
        audioRef?.current.play();
      }
    }
  };

  const getTime = (time) =>
    `${Math.floor(time / 60)}:${`0${Math.floor(time % 60)}`.slice(-2)}`;

  const selectedPodcast = listData.filter(
    (item) => item.audio === state.state.currentTrack?.audio
  )[0];

  const lastIndexTrack = listData.findIndex(
    (item) => item.audio === state.state.currentTrack?.audio
  );

  const nextPodcast =
    listData.length === lastIndexTrack + 1
      ? listData[0]
      : listData[lastIndexTrack + 1];

  const handleBackNextPodcast = (type: "back" | "next") => {
    const lastIndexTrack = listData.findIndex(
      (item) => item.audio === state.state.currentTrack?.audio
    );

    const isFirstTrack = state.state.currentTrack?.audio === listData[0]?.audio;

    if (!state.state.isPlaying) {
      setIsPlaying(true);
    }

    if (audioRef?.current) {
      if (type === "back") {
        if (isFirstTrack) {
          audioRef.current.currentTime = 0;
          return;
        }

        const newTrack = listData[lastIndexTrack - 1];

        setCurrentTrack(newTrack);
      } else {
        let newTrack;

        if (listData.length === lastIndexTrack + 1) {
          newTrack = listData[0];
        } else {
          newTrack = listData[lastIndexTrack + 1];
        }

        setCurrentTrack(newTrack);
      }
    }
  };

  const handleDownloadAudio = (titlePodcast, audioPodcast) => {
    const newTab = window.open();

    const link = document.createElement("a");
    link.href = audioPodcast;
    link.download = `${titlePodcast}.mp3`;

    if (newTab) {
      newTab.document.body.appendChild(link);

      link.click();
    }
  };
  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    toast({
      title: "Tautan  di salin ke papan klip.",
      status: "success",
      duration: 5000,
    });
  };
  return (
    <PodcastProvider
      value={{
        state: state.state,
        setIsPlaying,
        setCurrentTrack,
        handlePodcastPlayer,
        renderPlayPauseIcon,
        setIsRepeat,
        setVolume,
        audioRef,
        handleLoadedMetadata,
        handleSliderChange,
        handleTimeUpdate,
        formatTime,
        setSeekTime,
        renderVolumeIcon,
        togglePlayPause,
        getTime,
        setSeeDetail,
        selectedPodcast,
        nextPodcast,
        handleBackNextPodcast,
        fetchData,
        handleDownloadAudio,
        handleShare,
        setPodcastState,
        getDetailPodcast,
      }}
    >
      {children}
    </PodcastProvider>
  );
};

export const usePodcastContext = () => useContext(Context);

const PodcastContext = {
  usePodcastContext,
  Provider,
};

export default PodcastContext;
