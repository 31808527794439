import { AxiosResponse } from "axios";
import { LogTTE } from "../../entities/LogTTE";

export class LogTTEDataMapper {
  public convertListLogTTEData(result: AxiosResponse<any>): LogTTE[] {
    const { data } = result.data;

    let logTTE: LogTTE[] = [];

    data.map((d: any) => {
      return logTTE.push(
        new LogTTE(
          d.total,
          d.diproses,
          d.berhasil,
          d.gagal,
          d.judul_pelatihan,
          result.data.pagination.total_rows,
          d.created_at,
          d.updated_at,
          d.waktu_mulai,
          d.waktu_selesai
        )
      );
    });

    return logTTE;
  }
}
