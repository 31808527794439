import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiOutlinePlus } from "react-icons/ai";
import { FaEye, FaSearch } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { useManajemenPengetahuanContext } from "../../../controller/admin/manajemen_pengetahuan";
import {
  OptionType,
  useManajemenPengetahuanKontenPemateriContext,
} from "../../../controller/admin/manajemen_pengetahuan/konten-pemateri";
import Loader from "../../Loader";

/**
 * The KontenPemateri component displays a table of speakers' data within a knowledge management system.
 * This component handles various functionalities, including pagination, search, and data management of the speakers.
 *
 * @component
 * @returns {React.ReactElement} - Returns the KontenPemateri component.
 * @author Bagas Prasetyadi
 */

const KontenPemateri = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const { pengetahuanItem } = useManajemenPengetahuanContext().state;
  const {
    listData,
    listSpeakers,
    keyword,
    totalListData,
    page,
    perPage,
    isLoadingData,
  } = useManajemenPengetahuanKontenPemateriContext().state;
  const { setPengetahuanKontenPemateri: setState, fetchData } =
    useManajemenPengetahuanKontenPemateriContext().disptach;

  const getSpeaker = (id: number) => {
    return (
      listSpeakers.find((speaker: OptionType) => Number(speaker.value) === id)
        ?.label ?? ""
    );
  };

  const handlePerRowsChange = (newPerPage) => {
    setState("page", 1);
    setState("perPage", newPerPage);
  };

  const handlePageChange = (page) => {
    setState("page", page);
  };

  const columns = [
    {
      name: "NO.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "NAMA",
      selector: (row) => getSpeaker(row?.pemateri_id),
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "JUDUL MATERI",
      selector: (row) => row?.judul,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    ...(pengetahuanItem?.jenis_pengetahuan === "synchronized"
      ? [
          {
            name: "WAKTU",
            sortable: true,
            cell: (row) => {
              return (
                <Text>
                  {row.tanggal_mulai} - {row.waktu_mulai}
                </Text>
              );
            },
            wrap: true,
            width: "230px",
          },
        ]
      : []),
    {
      name: "QUIZ",
      selector: (row) => row.has_quiz,
      sortable: true,
      cell: (row) => {
        const textColor = row?.has_quiz ? "green" : "gray";
        const textLabel = row?.has_quiz ? "Tersedia" : "Tidak Tersedia";
        const textWeight = row?.has_quiz ? "semibold" : "normal";
        return (
          <Text color={textColor} fontWeight={textWeight}>
            {textLabel}
          </Text>
        );
      },
      wrap: true,
    },
    {
      name: "ASSIGNMENT",
      selector: (row) => row?.has_assessment,
      sortable: true,
      cell: (row) => {
        const textColor = row?.has_assessment ? "green" : "gray";
        const textLabel = row?.has_assessment ? "Tersedia" : "Tidak Tersedia";
        const textWeight = row?.has_assessment ? "semibold" : "normal";
        return (
          <Text color={textColor} fontWeight={textWeight}>
            {textLabel}
          </Text>
        );
      },
      wrap: true,
    },
    {
      name: "AKSI",
      cell: (row, index) => {
        return (
          <Button
            colorScheme="blue"
            h="35px"
            leftIcon={<Icon as={FaEye} />}
            onClick={() =>
              history.push(
                `/admin/manajemen_pengetahuan/${pengetahuanItem?.id}/konten_pemateri/${row?.id}`
              )
            }
          >
            Lihat
          </Button>
        );
      },
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    fetchData(id);
  }, [keyword, page, perPage]);

  return (
    <Box bg="white" p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Button
          colorScheme="blue"
          leftIcon={<Icon as={AiOutlinePlus} />}
          onClick={() => setState("isOpenModal", true)}
        >
          Tambah Pemateri
        </Button>
        <Stack direction="row" spacing={2}>
          <InputGroup w="250px" mt={{ base: 4, md: 0 }}>
            <InputLeftElement pointerEvents="none">
              <FaSearch />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Cari Disini..."
              onChange={(e) => {
                setState("keyword", e.target.value);
                setState("page", 1);
              }}
              _focus={{
                ring: "1",
                ringColor: "green.700",
                borderColor: "green.700",
              }}
            />
          </InputGroup>
          <Button
            colorScheme="green"
            rightIcon={<Icon as={FiDownload} />}
            ml={3}
          >
            Download
          </Button>
        </Stack>
      </Flex>
      <DataTable
        data={listData}
        columns={columns}
        customStyles={customStyles}
        progressPending={isLoadingData}
        progressComponent={<Loader />}
        noDataComponent="Data tidak ditemukan"
        pagination
        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={listData.length > 0 ? totalListData : 0}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </Box>
  );
};

export default KontenPemateri;
