import {
  Text,
  Flex,
  Divider,
  Grid,
  GridItem,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useManajemenPengetahuanKontenPemateriContext } from "../../../controller/admin/manajemen_pengetahuan/konten-pemateri";
import MultiplePDFUpload from "../UploadFile/MultipleUploadFile";

type Props = {
  setShowModalAssesment: Function;
  isReadOnly?: boolean;
};

/**
 * Renders a box component that displays the details of an assessment.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setShowModalAssesment - A function to set the visibility of the assessment modal.
 * @return {JSX.Element} The rendered box component.
 * @author Bagas Prasetyadi
 */

const BoxDetailAssesment = ({
  setShowModalAssesment,
  isReadOnly = false,
}: Props) => {
  const { pathFileAssesment, titleAssesment, descriptionAssesment, linkDrive } =
    useManajemenPengetahuanKontenPemateriContext().state;
  const { setPengetahuanKontenPemateri: setState } =
    useManajemenPengetahuanKontenPemateriContext().disptach;

  const setPathFilesAssesment = (files) => {
    setState("pathFileAssesment", files);
  };

  return (
    <>
      <Flex alignItems="center">
        <Text fontWeight="semibold" fontSize="md" mr={3}>
          Detail Assignment
        </Text>
      </Flex>
      <Divider my={4} />

      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
        <GridItem colSpan={1}>Judul</GridItem>
        <GridItem colSpan={5}>
          <Input
            fontSize="sm"
            value={titleAssesment}
            readOnly={isReadOnly}
            onChange={(e) => setState("titleAssesment", e.target.value)}
          />
        </GridItem>
        <GridItem colSpan={1}>Deskripsi</GridItem>
        <GridItem colSpan={5}>
          <Textarea
            fontSize="sm"
            value={descriptionAssesment}
            readOnly={isReadOnly}
            onChange={(e) => setState("descriptionAssesment", e.target.value)}
          />
        </GridItem>
        <GridItem colSpan={1}>Bahan Tugas</GridItem>
        <GridItem colSpan={5}>
          <MultiplePDFUpload
            defaultFiles={pathFileAssesment}
            setFiles={setPathFilesAssesment}
          />
        </GridItem>

        {/* Link Assesment */}
        <GridItem colSpan={1}>Link Assignment</GridItem>
        <GridItem colSpan={5}>
          <Input
            fontSize="sm"
            value={linkDrive}
            readOnly={isReadOnly}
            onChange={(e) => setState("linkDrive", e.target.value)}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default BoxDetailAssesment;
