import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Alert,
  Text,
  Box,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ReactStars from "react-rating-stars-component";
import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";

/**
 * ModalReviewPengetahuan component allows users to give reviews for a specific knowledge item.
 * It includes a rating system and a textarea for comments.
 *
 * @component
 * @example
 * @author Bagas Prasetyadi
 * return (
 *   <ModalReviewPengetahuan />
 * )
 */

const ModalReviewPengetahuan = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { handlePostReview, setReviewState, getReviewData, fetchData } =
    usePengetahuanDetailContext();
  const { review, pengetahuanItem } = usePengetahuanDetailContext().state;

  const postReview = async () => {
    setReviewState("isLoadingPost", true);

    const id = window.location.pathname.split("/")[3];

    if (review.rating === 0) {
      toast({
        title: "Rating harus diisi",
        status: "error",
        isClosable: true,
        position: "top-right",
      });

      setReviewState("isLoadingPost", false);
      return;
    }

    try {
      const response = await handlePostReview(id);
      toast({
        title: response?.data?.message,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
      fetchData(id);
      getReviewData(id);
    } catch (error) {
      toast({
        title: `Sedang ada kesalahan pada sistem`,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setReviewState("isLoadingPost", false);
    }
  };

  return (
    <>
      <Button colorScheme="green" variant="outline" onClick={onOpen}>
        Beri Review
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" textAlign="center" color="red.400">
            Berikan Review
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert
              status="warning"
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <Text fontSize="sm" fontWeight="semibold">
                {pengetahuanItem?.title}
              </Text>
              <Text fontSize="xs" mt={2}>
                {pengetahuanItem?.nama_penyelenggara ?? ""}
              </Text>
            </Alert>

            <Box
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={3}
            >
              <ReactStars
                count={5}
                size={40}
                activeColor="#ffd700"
                onChange={(e) => setReviewState("rating", e ? e : 0)}
              />
            </Box>
            <Box>
              <Text mt={2} mb={1} fontWeight="semibold" fontSize="sm">
                Komentar
              </Text>
              <Textarea
                value={review?.comment}
                onChange={(e) => setReviewState("comment", e.target.value)}
                rows={6}
                placeholder="Tulis Komentar disini"
                size="sm"
                bg="#f2f2f2"
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              bg={"orange.300"}
              color="white"
              w="full"
              _hover={{ bg: "orange.400" }}
              _active={{ bg: "orange.400" }}
              isLoading={review.isLoadingPost}
              onClick={postReview}
            >
              Kirim Review
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalReviewPengetahuan;
