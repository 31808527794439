import { ReviewPelatihanRepositoryInterface } from "../../../data/contracts/ReviewPelatihan";
import { ReviewPelatihanDataMapper } from "../../../data/mappers/ReviewPelatihan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ReviewPelatihan } from "../../../entities/ReviewPelatihan";
import { GetLocalStorage } from "../../misc/UseLocalStorage";

export class ReviewPelatihanApiRepository
  implements ReviewPelatihanRepositoryInterface
{
  private apiService: ApiService;
  private mapper: ReviewPelatihanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: ReviewPelatihanDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getReviewPelatihan(
    page: number,
    rows: number,
    keyword: string,
    pelatihan_id: number,
    rating: string,
    foto: boolean
  ): Promise<ReviewPelatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.reviewPelatihan()
        : "public/" + this.endpoints.reviewPelatihan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        pelatihan_id: pelatihan_id,
        rating: rating,
        foto: foto,
      },
      null,
      headers
    );
    return this.mapper.convertListReviewPelatihanDataFromApi(dataResponse);
  }

  public async storeReviewPelatihan(
    pelatihan_id: number,
    komentar: string,
    path_foto_review_pelatihan: any[],
    rating: number,
    screenshot_form_evaluasi: File
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("pelatihan_id", pelatihan_id.toString());
    formData.append("komentar", komentar);
    path_foto_review_pelatihan.forEach((v) =>
      formData.append("path_foto_review_pelatihan[]", v)
    );
    formData.append("rating", rating.toString());
    formData.append("screenshot_form_evaluasi", screenshot_form_evaluasi);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.reviewPelatihan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async getDetailReviewPelatihan(review_id: number): Promise<any> {
    const headers = new Map<string, string>();
    const dataResponse = await this.apiService.invoke(
      "get",
      "public/" + this.endpoints.reviewPelatihan() + `/${review_id}`,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
