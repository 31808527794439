import React from "react";
import { container } from "tsyringe";
import { FaqPresenter } from "../../../../../data/presenters/FaqPresenter";
import { dataFaq } from "../../../../../entities/Faq";

interface IState {
  isLoading: boolean;
  faq: dataFaq[];
  page: number;
  keyword: string;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  getFaqsData: Function;
  setKeyword: Function;
  searchFaq: Function;
}

const initialState = {
  state: {
    isLoading: false,
    faq: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  },
  getFaqsData: () => {},
  setKeyword: () => {},
  searchFaq: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: FaqProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    faq: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setKeyword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  /**
   *
   * @param page
   * @param keyword
   */
  const getFaqsData = async (page: number, keyword: string) => {
    setLoading(true);

    try {
      const faqPresenter = container.resolve(FaqPresenter);
      const get = await faqPresenter.getAllFaqPublic(page, 10, keyword);

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        faq: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data faq:", error);
      setLoading(false);
    }
  };

  const searchFaq = () => {
    setState((prevstate) => ({
      ...prevstate,
      faq: [],
    }));
    setLoading(true);
    getFaqsData(1, state.keyword);
  };

  return (
    <FaqProvider
      value={{
        state,
        getFaqsData,
        setKeyword,
        searchFaq,
      }}
    >
      {children}
    </FaqProvider>
  );
};

export const useFaqContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useFaqContext,
  Provider,
};
