import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSignIn } from "react-auth-kit";
import history from "../../../../../history";
import env from "@ludovicm67/react-dotenv";
import { SetLocalStorage } from "../../../../misc/UseLocalStorage";

const qs = require("qs");

interface State {
  isLoading: boolean;
  isError: boolean;
  error: string;
  isLoggedIn: boolean;
  state: string;
  name: string;
  email: string;
  code: string;
  accessToken: string;
  refreshToken: string;
}
interface ContextType {
  state: State;
  handleDoLogin: () => void;
  checkSession: () => void;
  switchToken: (code) => void;
}

const initialState: State = {
  isLoading: false,
  isError: false,
  error: "",
  isLoggedIn: false,
  state: "",
  name: "",
  email: "",
  code: localStorage.getItem("code") || "",
  accessToken: localStorage.getItem("accessToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
};

const Context = React.createContext<ContextType>({
  state: initialState,
  handleDoLogin: () => {},
  checkSession: () => {},
  switchToken: () => {},
});
const { Provider: SsoSimpatikaProvider } = Context;

const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const signIn = useSignIn();
  const location = useLocation();
  const [state, setState] = React.useState<State>(initialState);

  const queryParams = new URLSearchParams(location.search);

  const handleDoLogin = () => {
    window.location.href = `${env.REACT_APP_SIMPATIKA_URL}oauth/authorize?client_id=${env.REACT_APP_SIMPATIKA_CLIENT_ID}&client_secret=${env.REACT_APP_SIMPATIKA_CLIENT_SECRET}&redirect_uri=${env.REACT_APP_SIMPATIKA_REDIRECT_URI_1}&response_type=code&scope=read`;
  };

  const handleGetAccessToken = (code: string) => {
    return new Promise((resolve, reject) => {
      let data = qs.stringify({
        code: code,
        client_id: env.REACT_APP_SIMPATIKA_CLIENT_ID,
        client_secret: env.REACT_APP_SIMPATIKA_CLIENT_SECRET,
        grant_type: "authorization_code",
        redirect_uri: env.REACT_APP_SIMPATIKA_REDIRECT_URI_2,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${env.REACT_APP_SIMPATIKA_URL}oauth/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const { access_token, refresh_token, expires_in } = response.data;
          const expiryTime = new Date().getTime() + expires_in * 1000;

          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          localStorage.setItem("expiryTime", expiryTime.toString());
          setState((prevState) => ({
            ...prevState,
            accessToken: access_token,
            refreshToken: refresh_token,
          }));
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleRefreshToken = () => {
    return new Promise((resolve, reject) => {
      if (!state.refreshToken) {
        return reject(new Error("No refresh token available"));
      }

      let data = qs.stringify({
        refresh_token: state.refreshToken,
        client_id: env.REACT_APP_SIMPATIKA_CLIENT_ID,
        client_secret: env.REACT_APP_SIMPATIKA_CLIENT_SECRET,
        grant_type: "refresh_token",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${env.REACT_APP_SIMPATIKA_URL}oauth/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const { access_token, refresh_token, expires_in } = response.data;
          const expiryTime = new Date().getTime() + expires_in * 1000;

          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          localStorage.setItem("expiryTime", expiryTime.toString());
          setState((prevState) => ({
            ...prevState,
            accessToken: access_token,
            refreshToken: refresh_token,
          }));
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleGetProfileSimpatika = (accessToken: string) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${env.REACT_APP_SIMPATIKA_URL}oauth/profile`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response: any) => {
          setState((prevState) => ({
            ...prevState,
            state: "authenticated",
            isLoggedIn: true,
            isLoading: false,
            isError: false,
            error: "",
            accessToken: accessToken,
            refreshToken: state.refreshToken,
            email: response?.data?.email,
            name: response?.data?.nama,
          }));
          switchToken(accessToken);
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            state: "error",
            isLoggedIn: false,
            isLoading: false,
            isError: true,
            error: error.message,
          }));
          reject(error);
        });
    });
  };

  const checkSession = () => {
    if (state.accessToken) {
      const expiryTime = localStorage.getItem("expiryTime");
      const currentTime = new Date().getTime();

      if (expiryTime && currentTime > parseInt(expiryTime)) {
        handleRefreshToken()
          .then((response: any) => {
            handleGetProfileSimpatika(response.access_token);
          })
          .catch((error) => {
            setState((prevState) => ({
              ...prevState,
              state: "error",
              isLoggedIn: false,
              isLoading: false,
              isError: true,
              error: error.message,
            }));
          });
      } else {
        handleGetProfileSimpatika(state.accessToken);
      }
    }
  };

  React.useEffect(() => {
    if (location.pathname === "/") {
      const oauthCode = queryParams.get("code");

      switchToken(oauthCode);
    }
  }, [location.search]);

  const getMe = async (token: string) => {
    const baseUrl = env.REACT_APP_BE_PROD_BASE_URL;

    const res = await axios.get(baseUrl + "auth/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = res.data;

    return data;
  };

  const switchToken = async (code) => {

    try {
      const response = await axios.post(
        `${env.REACT_APP_BE_PROD_BASE_URL}simpatika/switch-token`,
        {
          st_code: code,
        }
      );

      const { data } = response?.data;

      const user = {
        id: data.id,
        username: data?.name,
        nik: data?.nik,
        email: data?.email,
        level: data?.level,
        jenis_user: data?.jenis_user,
      };

      const responseMe = await getMe(data?.access_token);

      // set foto profile
      SetLocalStorage({
        name: "path_photo",
        value: responseMe?.path_foto,
      });

      // set level
      SetLocalStorage({ name: "level", value: responseMe.level });

      // set status pengisian
      SetLocalStorage({
        name: "status_pengisian",
        value: responseMe?.m_peserta?.status_pengisian,
      });

      // return;
      if (
        signIn({
          token: data?.access_token,
          expiresIn: data?.expires_in,
          tokenType: "Bearer",
          authState: user,
        })
      ) {
        history.replace("/");
      }
    } catch (error) {
      const { data, message } = error.response.data;
      if (message?.toLowerCase() === "user not found") {
        window.location.href = `/register?name=${state.name}&email=${
          state.email
        }&nik=${data?.data?.nik}&autoFill=${true}`;
      }
    }
  };

  const contextValue: ContextType = {
    state,
    handleDoLogin,
    checkSession,
    switchToken,
  };

  return (
    <SsoSimpatikaProvider value={contextValue}>{children}</SsoSimpatikaProvider>
  );
};

export const useSsoSimpatikaContext = () => React.useContext(Context);

export default {
  useSsoSimpatikaContext,
  Provider,
};
