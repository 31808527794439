import React from "react";
import { container } from "tsyringe";
import { ProvinsiPresenter } from "../../../../../data/presenters/ProvinsiPresenter";
import { Provinsi } from "../../../../../entities/Provinsi";

interface IState {
  isLoading: boolean;
  provinsi: Provinsi[];
  page: number;
  keyword: string;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  getAllProvinsi: Function;
}

const initialState = {
  state: {
    isLoading: false,
    provinsi: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  },
  getAllProvinsi: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ProvinsiProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    provinsi: [],
    page: 1,
    keyword: "",
    error: false,
    msgError: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  // Get Data Provinsi
  const getAllProvinsi = async () => {
    setLoading(true);

    try {
      const provinsiPresenter = container.resolve(ProvinsiPresenter);
      const get = await provinsiPresenter.getAllProvinsi(
        state.page,
        999,
        state.keyword
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        provinsi: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error data provinsi:", error);
      setLoading(false);
    }
  };

  return (
    <ProvinsiProvider
      value={{
        state,
        getAllProvinsi,
      }}
    >
      {children}
    </ProvinsiProvider>
  );
};

export const useProvinsiContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useProvinsiContext,
  Provider,
};
