import React from "react";
import { container } from "tsyringe";
import { AuthPresenter } from "../../../../../data/presenters/AuthPresenter";
import history from "../../../../../history";
import { toast } from "react-toastify";

interface IState {
  isLoading: boolean;
  email: string;
  error: boolean;
  msgError: string;
}

interface InitialState {
  state: IState;
  setEmail: Function;
  handleResetPassword: Function;
}

const initialState = {
  state: {
    isLoading: false,
    email: "",
    error: false,
    msgError: "",
  },
  setEmail: () => {},
  handleResetPassword: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ForgotPasswordProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    email: "",
    error: false,
    msgError: "",
  });

  /**
   *
   * @param value
   * @returns
   */
  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  /**
   *
   * @param value
   * @returns
   */
  const setEmail = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      email: value,
    }));
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const authPresenter = container.resolve(AuthPresenter);
      await authPresenter.requestResetPassword(state.email);

      toast.success(
        "Berhasil meminta request untuk reset password, Silahkan cek email anda.",
        {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        }
      );
      setLoading(false);
      history.replace("/login");
      window.location.reload();
    } catch (error: any) {
      console.log("error when send request reset password :", error.message);
      toast.error(
        "Error saat mengirim request reset password :" + error.message,
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        }
      );
      setLoading(false);
      return;
    }
  };

  return (
    <ForgotPasswordProvider
      value={{
        state,
        setEmail,
        handleResetPassword,
      }}
    >
      {children}
    </ForgotPasswordProvider>
  );
};

export const useForgotPasswordContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useForgotPasswordContext,
  Provider,
};
