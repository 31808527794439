import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { usePengetahuanAssesmentContext } from "../../../controller/pengetahuan/assesment";
import { usePengetahuanDetailContext } from "../../../controller/pengetahuan/detail";
import BoxUploadFile from "../UploadFile/BoxUploadFile";

/**
 * Renders a modal for uploading an assessment, including handling the upload process.
 *
 * @return {void} No return value
 * @author Bagas Prasetyadi
 */
const ModalUploadAssesment = () => {
  const {
    fileAssesment,
    isLoadingUpload,
    isOpenModalUpload,
    modeModalAssessment,
  } = usePengetahuanAssesmentContext().state;
  const { setPengetahuanAsessmentState, uploadAssessment } =
    usePengetahuanAssesmentContext().dispatch;
  const { fetchData } = usePengetahuanDetailContext();

  const pengetahuanId = window.location.pathname.split("/", 4)[3];

  const onCLose = () => {
    setPengetahuanAsessmentState("isOpenModalUpload", false);
    setPengetahuanAsessmentState("fileAssesment", null);
  };

  const handleUpload = async () => {
    const response = await uploadAssessment(pengetahuanId);
    if (response.code === 200) {
      fetchData(pengetahuanId);
    }
  };

  return (
    <Modal isOpen={isOpenModalUpload} onClose={onCLose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modeModalAssessment} Assessment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BoxUploadFile
            setFile={setPengetahuanAsessmentState}
            typeFile="pdf"
            defaultFile={fileAssesment}
            nameFile="fileAssesment"
          />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => handleUpload()}
            isLoading={isLoadingUpload}
          >
            Upload File
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUploadAssesment;
