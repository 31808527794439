/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Switch from "react-switch";
import ManajemenSoalUpdateController, {
  useManajemenSoalUpdateContext,
} from "../../../../controller/admin/manajemen_soal/update";
import ManajemenSoalDetailController, {
  useManajemenSoalDetailContext,
} from "../../../../controller/admin/manajemen_soal/detail";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import AWS from "aws-sdk";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import env from "@ludovicm67/react-dotenv";

const BottomHeader: React.FC = () => {
  const { updateSoal } = useManajemenSoalUpdateContext();
  const { isLoading } = useManajemenSoalUpdateContext().state;
  const { paketSoal } = useManajemenSoalDetailContext().state;

  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="px-6 py-3 items-center">
        <a href="#">Ubah Soal {paketSoal?.jenis_soal}</a>
      </div>
      <div className="px-6 py-2 flex relative">
        <button
          type="button"
          className="px-6 py-2 bg-green-500 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 "
          onClick={() => {
            updateSoal(
              window.location.pathname.split("/", 5)[4],
              window.location.pathname.split("/", 6)[5]
            );
          }}
        >
          <If condition={isLoading}>
            <Then>
              <ReactLoading
                type={"bars"}
                color={"white"}
                height={15}
                width={20}
              />
            </Then>
            <Else>Simpan</Else>
          </If>
        </button>
      </div>
    </div>
  );
};

const ItemOpsi = ({ opsi }: { opsi: Array<string> }): JSX.Element => {
  const { setPilihan, setJawabanBenar, deleteJawaban, setJawabanBenarArray } =
    useManajemenSoalUpdateContext();
  const { jawabanArray, jawabanBener, jawabanBenerArray } =
    useManajemenSoalUpdateContext().state;
  const [jawaban, setJawaban] = React.useState(`${jawabanBener}`);
  const { paketSoal } = useManajemenSoalDetailContext().state;

  const onChangeJawabanBenar = (val, idx) => {
    if (paketSoal?.jenis_soal == "Pilihan Ganda Kompleks") {
      setJawabanBenarArray(val);
    } else {
      setJawaban(val);
    }
    setJawabanBenar(idx, paketSoal?.jenis_soal);
  };

  const checkJawabanBenar = (val) => {
    const checkValue = jawabanBenerArray.find((v) => v == val);

    if (checkValue) {
      return true;
    } else {
      return false;
    }
  };

  var data: JSX.Element[] = [];
  opsi.map((pg, idx) =>
    data.push(
      <div className="mt-10">
        <div className="flex justify-between mb-2">
          <div className="flex flex-row">
            {paketSoal?.jenis_soal == "Pilihan Ganda Kompleks" ? (
              <>
                <a>Jawaban {checkJawabanBenar(pg) ? "Benar" : "Salah"}</a>
                <Switch
                  className="ml-2"
                  height={20}
                  onChange={() => {
                    onChangeJawabanBenar(pg, idx);
                  }}
                  checked={checkJawabanBenar(pg) ? true : false}
                />
              </>
            ) : (
              <>
                <a>Jawaban {jawaban == pg ? "Benar" : "Salah"}</a>
                <Switch
                  className="ml-2"
                  height={20}
                  onChange={() => {
                    onChangeJawabanBenar(pg, idx);
                  }}
                  checked={jawaban == pg ? true : false}
                />
              </>
            )}
          </div>
          <div className="flex flex-row">
            <button
              onClick={() => deleteJawaban(idx)}
              type="button"
              className="px-3 py-2 bg-red-500 text-white font-semibold text-md leading-tight rounded shadow-md hover:bg-red-700"
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
        <Editor
          apiKey={env.REACT_APP_TINYMCE_API_KEY}
          initialValue={`${
            jawabanArray.length > 0 ? jawabanArray[idx].value : ""
          }`}
          init={{
            height: 180,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help | image",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            images_upload_handler: async function (
              blobInfo: any,
              success: any,
              failure: any
            ) {
              const S3_BUCKET = "pusdiklat-lms";
              const REGION = "sgp1";
              const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

              const s3Credentials = new AWS.Credentials({
                accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                secretAccessKey: "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
              });

              const myBucket = new AWS.S3({
                params: { Bucket: S3_BUCKET },
                endpoint: ENDPOINT,
                region: REGION,
                credentials: s3Credentials,
              });

              function makeid(length) {
                var result = "";
                var characters =
                  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                  result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                  );
                }
                return result;
              }

              try {
                const nameFile =
                  makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                const params = {
                  ACL: "public-read",
                  Body: blobInfo.blob(),
                  Bucket: S3_BUCKET,
                  Key: nameFile,
                };

                myBucket
                  .putObject(params)
                  .on("httpUploadProgress", (evt) => {
                    console.log(Math.round((evt.loaded / evt.total) * 100));
                  })
                  .send((err, data) => {
                    if (err) {
                      failure(err);
                    } else {
                      success(
                        "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                          nameFile
                      );
                    }
                  });
              } catch (error: any) {
                console.log(error);
                failure(error);
                return;
              }
            },
          }}
          onEditorChange={(val) => setPilihan(val, pg)}
        />
      </div>
    )
  );
  return (
    <>
      {data.map((data) => (
        <div>{data}</div>
      ))}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const { setSoal, getDetailSoal, setIncrement } =
    useManajemenSoalUpdateContext();
  const { detailSoal, opsiJawaban, increment, isLoadingFetchData } =
    useManajemenSoalUpdateContext().state;
  const { getDetailPaketSoal } = useManajemenSoalDetailContext();
  const { paketSoal } = useManajemenSoalDetailContext().state;

  useEffect(() => {
    getDetailPaketSoal(window.location.pathname.split("/", 5)[4]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tambahJawaban = () => {
    setIncrement(increment);
  };

  useEffect(() => {
    if (paketSoal) {
      getDetailSoal(
        window.location.pathname.split("/", 6)[5],
        paketSoal?.jenis_soal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paketSoal]);

  return (
    <div className="app">
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <BottomHeader />
        </header>
        <div className="container px-8">
          <div className="bg-white rounded-md  px-5 py-5">
            <div className="form-group mb-6">
              <label className="form-label inline-block mb-2 text-gray-700">
                Pertanyaan
              </label>
              <div className="w-full">
                <Editor
                  apiKey={env.REACT_APP_TINYMCE_API_KEY}
                  initialValue={detailSoal != null ? detailSoal.soal : ""}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help | image",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    images_upload_handler: async function (
                      blobInfo: any,
                      success: any,
                      failure: any
                    ) {
                      const S3_BUCKET = "pusdiklat-lms";
                      const REGION = "sgp1";
                      const ENDPOINT = "https://sgp1.digitaloceanspaces.com/";

                      const s3Credentials = new AWS.Credentials({
                        accessKeyId: "PIK5YSSWTA3UWYFKS4UX",
                        secretAccessKey:
                          "VPzwj/EA9pQlo+gEeO5dUyauTfz6Knbh9L4FnEvwIHE",
                      });

                      const myBucket = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        endpoint: ENDPOINT,
                        region: REGION,
                        credentials: s3Credentials,
                      });

                      function makeid(length) {
                        var result = "";
                        var characters =
                          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                        var charactersLength = characters.length;
                        for (var i = 0; i < length; i++) {
                          result += characters.charAt(
                            Math.floor(Math.random() * charactersLength)
                          );
                        }
                        return result;
                      }

                      try {
                        const nameFile =
                          makeid(16) + "." + blobInfo.blob().name.split(".")[1];

                        const params = {
                          ACL: "public-read",
                          Body: blobInfo.blob(),
                          Bucket: S3_BUCKET,
                          Key: nameFile,
                        };

                        myBucket
                          .putObject(params)
                          .on("httpUploadProgress", (evt) => {
                            console.log(
                              Math.round((evt.loaded / evt.total) * 100)
                            );
                          })
                          .send((err, data) => {
                            if (err) {
                              failure(err);
                            } else {
                              success(
                                "https://pusdiklat-lms.sgp1.digitaloceanspaces.com/" +
                                  nameFile
                              );
                            }
                          });
                      } catch (error: any) {
                        console.log(error);
                        failure(error);
                        return;
                      }
                    },
                  }}
                  onEditorChange={(a) => setSoal(a)}
                />
              </div>
            </div>
          </div>

          {/* Jawaban */}
          <div className="bg-white rounded-md px-5 py-5 mt-3 mb-5">
            <If condition={isLoadingFetchData != true}>
              <Then>
                <ItemOpsi opsi={opsiJawaban} />
              </Then>
            </If>
            <div className="text-center py-10">
              <button
                type="button"
                className="px-6 py-2 bg-yellow-500 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-yellow-700 align-left mb-5"
                onClick={tambahJawaban}
              >
                Tambah Field Jawaban
              </button>
              <p className="text-2xl">
                Silahkan klik tombol diatas untuk menambahkan field jawaban.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const UpdateManajemenSoal: React.FC = () => {
  return (
    <ManajemenSoalUpdateController.Provider>
      <ManajemenSoalDetailController.Provider>
        <ToastContainer
          position="top-center"
          style={{ width: "60%", marginTop: "5%" }}
        />
        <SidebarWithHeader title="Manajemen Soal">
          <WrapperMain />
        </SidebarWithHeader>
      </ManajemenSoalDetailController.Provider>
    </ManajemenSoalUpdateController.Provider>
  );
};

export default UpdateManajemenSoal;
