// @ts-nocheck
import { Icon } from "@chakra-ui/react";
import React from "react";
import { MdOutlineMaximize } from "react-icons/md";

const CustomDot: React.FC = ({ onClick, active }) => {
  return (
    <li onClick={() => onClick()}>
      <Icon
        as={MdOutlineMaximize}
        boxSize={8}
        color={active ? "green.700" : "white"}
      />
    </li>
  );
};

export default CustomDot;
