/**
 * PodcastHero component displays the hero section of the podcast page.
 */

import React from "react";
import PodcastBackground from "../../assets/webp/bg_podcast.webp";
import PodcastBackgroundMobile from "../../assets/webp/bg_podcast_mobile.webp";
import LogoPintar from "../../assets/png/logo_pintar_black.png";
import { Box, Text, Img, useBreakpointValue } from "@chakra-ui/react";

/**
 * PodcastHero component.
 * @component
 * @returns {React.FC} The PodcastHero component.
 * @author Bagas Prasetyadi
 */

const PodcastHero: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      backgroundImage={{
        base: `url(${PodcastBackgroundMobile})`,
        md: `url(${PodcastBackground})`,
      }}
      backgroundSize="cover"
      h={{ base: "230px", sm: "330px", md: "52vh" }}
      w="auto"
    >
      <Box my={{ base: 8, md: 12 }} mx={{ base: 8, md: 12 }}>
        <Box mt={{ base: "4rem", md: "6rem" }}>
          {!isMobile && (
            <Img
              src={LogoPintar}
              w={{ base: "100px", md: "150px" }}
              alt="Logo Pintar"
            />
          )}
          <Text
            fontSize={{ base: "xl", md: "5xl" }}
            color="black"
            fontWeight="bold"
            mt={4}
          >
            Simak Podcast untuk
            <br />
            Menambah Kompetensi Anda
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PodcastHero;
