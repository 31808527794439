/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Sidebar } from "../../../../../components/Admin/Sidebar";
import { Navbar } from "../../../../../components/Admin/Navbar";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/all";
import PrettyRating from "pretty-rating-react";
import { ReactComponent as UsersIconYellow } from "../../../../../assets/svg/ic_users_yellow.svg";
import { ReactComponent as CalendarIconYellow } from "../../../../../assets/svg/ic_calendar_yellow.svg";
import { ReactComponent as TimeIconYellow } from "../../../../../assets/svg/ic_time_yellow.svg";
import DetailPelatihanKontenPelatihanController, {
  useDetailPelatihanKontenPelatihanContext,
} from "../../../../../controller/admin/manajemen_pelatihan/detail/konten_pelatihan";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import moment from "moment";
import parse from "html-react-parser";
import { Jawaban } from "../../../../../../../entities/PaketSoal";
import { ImFilePlay, BiTimeFive } from "react-icons/all";
import ReactAudioPlayer from "react-audio-player";
import { JawabanQuiz } from "../../../../../../../entities/Quiz";
import Select from "react-select";
import VideoPlayer from "../../../../../components/VideoPlayer";
import Skeleton from "react-loading-skeleton";

const colors = {
  star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
};

const BottomHeader: React.FC = () => {
  const { setSelectedId, setShowDialogDelete } =
    useDetailPelatihanKontenPelatihanContext();
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Detail Pelatihan</a>
      </div>
      <div className="flex flex-row">
        <button
          type="button"
          className="mr-2 flex px-6 py-2.5 bg-red-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-red-700 mb-3 mt-3"
          onClick={() => {
            setSelectedId(window.location.pathname.split("/", 6)[5]);
            setShowDialogDelete(true);
          }}
        >
          Hapus
          <BsFillTrashFill className="ml-2" style={{ marginTop: "2px" }} />
        </button>
        <a
          href={`/admin/ubah/manajemen_pelatihan/${
            window.location.pathname.split("/", 6)[5]
          }`}
          className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
        >
          Ubah
          <BsFillPencilFill className="ml-2" style={{ marginTop: "2px" }} />
        </a>
      </div>
    </div>
  );
};

const HeaderBanner: React.FC = () => {
  const { getDetailPelatihan } = useDetailPelatihanKontenPelatihanContext();
  const { pelatihan, isLoading } =
    useDetailPelatihanKontenPelatihanContext().state;

  useEffect(() => {
    getDetailPelatihan(window.location.pathname.split("/", 6)[5]);
  }, []);

  return (
    <div className="container px-9 mt-6">
      <div className="py-10 bg-green-600 mt-6 rounded-lg">
        <If condition={isLoading}>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="col-span-1 md:col-span-2">
              <div className="ml-10 mr-10 rounded-lg h-full">
                <Skeleton height={228} />
              </div>
            </div>
            <div className="col-span-3 mr-6 ml-6">
              <Skeleton count={7} height={20} style={{ marginTop: "11px" }} />
            </div>
          </div>
        </If>

        <div
          className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 ${
            isLoading ? "hidden" : ""
          }`}
        >
          <div className="col-span-3 md:col-span-2">
            <div
              className="bg-green-200 hover:bg-blue-300 transition-all duration-500 ml-10 mr-10 rounded-lg w-11/12"
              style={{
                backgroundImage: `url(${pelatihan?.path_thumbnail})`,
                backgroundSize: "cover",
                height: "228px",
              }}
            ></div>
          </div>
          <div className="col-span-3 mr-6 ml-6">
            <h2 className="font-semibold text-2xl text-white">
              {pelatihan?.judul_pelatihan}
            </h2>
            <div className="grid grid-cols-6 mt-4 items-center">
              <div className="bg-white text-xs rounded-full p-2 col-span-3 text-center">
                <p className="text-green-400">{pelatihan?.penyelenggara}</p>
              </div>
              <div className="col-span-3">
                <div className="ml-2 text-right text-sm flex flex-row">
                  <PrettyRating
                    value={pelatihan != null ? pelatihan.rating : 0}
                    colors={colors.star}
                  />
                  <p className="text-white ml-2 font-semibold">
                    {pelatihan?.rating}
                  </p>
                  <p className="text-white ml-2 font-semibold">
                    ({pelatihan?.count_reviewer} Reviews)
                  </p>
                  {/* <p className="text-white ml-2 font-semibold">
                    ({pelatihan?.count_diskusi} Diskusi)
                  </p> */}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <UsersIconYellow />
              <a className="text-white ml-2 font-semibold">
                {pelatihan?.count_peserta} Peserta
              </a>
              <CalendarIconYellow className="ml-3" />
              <a className="text-white ml-2 font-base">
                {moment(pelatihan?.waktu_mulai).format("LL")} s.d{" "}
                {moment(pelatihan?.waktu_selesai).format("LL")}
              </a>
              <TimeIconYellow className="ml-3" />
              <a className="text-white ml-2 font-base">
                <b>{pelatihan?.jp} JP</b>
                {/* ± 39 Jam */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, isLoadingDelete } =
    useDetailPelatihanKontenPelatihanContext().state;
  const { setShowDialogDelete, deletePelatihan } =
    useDetailPelatihanKontenPelatihanContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deletePelatihan()}
                  >
                    <If condition={isLoadingDelete}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ItemSoal = ({
  idAccordion,
  nomor,
  soal,
  jawaban,
}: {
  idAccordion: string;
  nomor: number;
  soal: string;
  jawaban: Jawaban[];
}) => {
  var opsi = ["A", "B", "C", "D", "E"];

  return (
    <div className="accordion-item bg-white border border-gray-200 mt-3 rounded-lg">
      <button
        className="
                accordion-button
                collapsed
                relative
                flex
                items-center
                w-full
                py-4
                px-5
                text-sm text-gray-800 text-left
                border-0
                transition
                focus
                focus:outline-none
              "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + idAccordion}
        aria-expanded="true"
        aria-controls={"#" + idAccordion}
      >
        <a className="px-2 py-1 text-sm font-semibold border-2  border-sky-600">
          {nomor}
        </a>
        <p className="ml-4 mr-2">{parse(soal)}</p>
      </button>
      <div id={idAccordion} className="accordion-collapse collapse rounded-lg">
        <div className="accordion-body rounded-lg">
          <div className="px-5 py-3">
            {jawaban.map((j, i) => {
              return (
                <ItemOpsi
                  jawabanBenar={j.jawaban_benar == 1 ? true : false}
                  opsi={opsi[i]}
                  jawaban={j.pilihan}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemSoalQuiz = ({
  idAccordion,
  nomor,
  soal,
  jawaban,
}: {
  idAccordion: string;
  nomor: number;
  soal: string;
  jawaban: JawabanQuiz[];
}) => {
  var opsi = ["A", "B", "C", "D", "E"];

  return (
    <div className="accordion-item bg-white border border-gray-200 mt-3 rounded-lg">
      <button
        className="
              accordion-button
              collapsed
              relative
              flex
              items-center
              w-full
              py-4
              px-5
              text-sm text-gray-800 text-left
              border-0
              transition
              focus
              focus:outline-none
            "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + idAccordion}
        aria-expanded="true"
        aria-controls={"#" + idAccordion}
      >
        <a className="px-2 py-1 text-sm font-semibold border-2  border-sky-600">
          {nomor}
        </a>
        <p className="ml-4 mr-2">{parse(soal)}</p>
      </button>
      <div id={idAccordion} className="accordion-collapse collapse rounded-lg">
        <div className="accordion-body rounded-lg">
          <div className="px-5 py-3">
            {jawaban.map((j, i) => {
              return (
                <ItemOpsi
                  jawabanBenar={j.jawaban_benar == 1 ? true : false}
                  opsi={opsi[i]}
                  jawaban={j.pilihan}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemOpsi = ({
  jawabanBenar,
  opsi,
  jawaban,
}: {
  jawabanBenar: Boolean;
  opsi: string;
  jawaban: string;
}) => {
  return (
    <div
      className="flex relative px-3 py-3 mb-5 rounded-md"
      style={{ backgroundColor: jawabanBenar ? "#EFFFF9" : "#F3F3F3" }}
    >
      <a
        className="px-2 py-1 text-xs font-semibold rounded"
        style={{
          border: jawabanBenar ? "1px solid #0DBD7F" : "1px solid #4F4F4F",
          color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
          height: "25px",
        }}
      >
        {opsi}
      </a>
      <a
        className="px-2 py-1 text-xs font-normal rounded ml-3"
        style={{
          border: jawabanBenar ? "1px solid #0DBD7F" : "1px solid #4F4F4F",
          color: jawabanBenar ? "#0DBD7F" : "#4F4F4F",
          height: "25px",
        }}
      >
        Jawaban{jawabanBenar ? "Benar" : "Salah"}
      </a>
      <p
        className="px-2 py-1 text-xs font-normal rounded ml-3"
        style={{ color: jawabanBenar ? "#0DBD7F" : "#4F4F4F" }}
      >
        {parse(jawaban)}
      </p>
    </div>
  );
};

const TabsMateri = () => {
  const { detailMateri } = useDetailPelatihanKontenPelatihanContext().state;

  return (
    <div>
      <a className="text-base">{detailMateri?.judul_materi}</a>
      <p className="text-sm mt-4 text-green-600">
        Jenis Pelatihan{" "}
        <span className="font-normal">: {detailMateri?.jenis_pelatihan}</span>
      </p>
      <p className="text-sm text-green-600">
        Jam Pelatihan{" "}
        <span className="font-normal">
          &nbsp;&nbsp;: {detailMateri?.jam_pelatihan} Jam Pelatihan
        </span>
      </p>
      <p className="text-justify text-sm text-gray-500 mt-3 mb-5 font-normal">
        {detailMateri != null ? parse(detailMateri.uraian_deskripsi) : null}
      </p>
    </div>
  );
};

const TabsQuiz = () => {
  const { quiz } = useDetailPelatihanKontenPelatihanContext().state;
  return (
    <div>
      <If condition={quiz.length > 0}>
        <Then>
          <div className="overflow-y-scroll pr-3" style={{ height: "750px" }}>
            {quiz.map((q, i) => {
              return (
                <ItemSoalQuiz
                  idAccordion={"Soal" + (i + 1)}
                  nomor={i + 1}
                  soal={q.pertanyaan}
                  jawaban={q.JawabanQuiz}
                />
              );
            })}
          </div>
        </Then>
      </If>
    </div>
  );
};

const TabsLatihan = () => {
  const { modulPelatihan, selectModulIdx, selectSubModulIdx, paketSoal } =
    useDetailPelatihanKontenPelatihanContext().state;

  var optionsPaketSoal;
  var valuePaketSoal;

  if (modulPelatihan.length > 0) {
    if (paketSoal.length > 0) {
      optionsPaketSoal = paketSoal.map((item) => ({
        value: item.id,
        label: item.judul_soal,
      }));
      paketSoal.map((item) => {
        if (
          item.id ==
          modulPelatihan[selectModulIdx].subModulPelatihan[selectSubModulIdx]
            .m_latihan.paket_soal_id
        ) {
          valuePaketSoal = { value: item.id, label: item.judul_soal };
        }

        return valuePaketSoal;
      });
    } else {
      optionsPaketSoal = [];
    }
  }

  var IndexPaketSoalIdLatihan;

  paketSoal.map((p, i) => {
    if (
      p.id ===
      modulPelatihan[selectModulIdx].subModulPelatihan[selectSubModulIdx]
        .m_latihan.paket_soal_id
    ) {
      IndexPaketSoalIdLatihan = i;
    }

    return IndexPaketSoalIdLatihan;
  });

  return (
    <div>
      <div className="grid md:grid-cols-11 sm:grid-cols-3">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Passing Grade
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="text"
            value={
              modulPelatihan[selectModulIdx].subModulPelatihan[
                selectSubModulIdx
              ].m_latihan.passing_grade
            }
            className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 ">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Durasi Latihan
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <div className="flex justify-between bg-gray-300 px-4 py-2 rounded-lg">
            <a>
              {
                modulPelatihan[selectModulIdx].subModulPelatihan[
                  selectSubModulIdx
                ].m_latihan.durasi_latihan
              }{" "}
              Menit
            </a>
            <BiTimeFive className="text-green-500 text-lg" />
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Paket Soal
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <Select
            className="w-full"
            options={optionsPaketSoal}
            defaultValue={valuePaketSoal}
            placeholder="Pilih Soal"
            isDisabled={true}
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Jumlah Soal
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            {paketSoal[IndexPaketSoalIdLatihan].soal.length} Soal
          </label>
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Kategori Soal
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            {
              paketSoal[IndexPaketSoalIdLatihan].kategori_paket_soal
                .kategori_paket_soal
            }
          </label>
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 mt-4">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Jenis Soal
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            {paketSoal[IndexPaketSoalIdLatihan].jenis_soal}
          </label>
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Petunjuk Soal
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          {parse(paketSoal[IndexPaketSoalIdLatihan].petunjuk_soal)}
        </div>
      </div>
    </div>
  );
};

const Konten: React.FC = () => {
  const {
    getModulPelatihan,
    selectSubModul,
    getPaketSoal,
    getDetailMateri,
    setIndexPathMateri,
  } = useDetailPelatihanKontenPelatihanContext();
  const {
    modulPelatihan,
    selectSubModulType,
    selectModulIdx,
    selectSubModulIdx,
    detailPaketSoal,
    pelatihan,
    quiz,
    pathMateri,
    indexPathMateri,
    pathPanduanProject,
  } = useDetailPelatihanKontenPelatihanContext().state;

  useEffect(() => {
    getModulPelatihan(window.location.pathname.split("/", 6)[5]);
    getPaketSoal();
  }, []);

  var judulSection = "";

  if (
    modulPelatihan.length > 0 &&
    selectSubModulType != "" &&
    selectSubModulType != "sub_modul_materi"
  ) {
    if (selectSubModulType == "sub_modul_ujian_project") {
      judulSection =
        modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
          Number(selectSubModulIdx)
        ].judul + " : Project";
    } else {
      judulSection =
        modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
          Number(selectSubModulIdx)
        ].judul +
        " : Detail Ujian" +
        ` (${detailPaketSoal?.soal.length} Soal)`;
    }
  }

  var optionsMateri;

  if (modulPelatihan.length && selectSubModulType != "") {
    optionsMateri = modulPelatihan[Number(selectModulIdx)].subModulPelatihan[
      Number(selectSubModulIdx)
    ].materi.map((item) => ({
      value: item.materi.id,
      label: item.materi.judul_materi,
    }));
  } else {
    optionsMateri = [];
  }

  var optionsPathMateri;

  if (pathMateri != null && pathMateri.length > 0) {
    optionsPathMateri = pathMateri.map((val, idx) => ({
      value: idx,
      label: val.jenis_materi.toUpperCase(),
    }));
  } else {
    optionsPathMateri = [];
  }

  //  video js options
  var urlVideo;

  if (pathMateri != null && pathMateri.length > 0) {
    urlVideo = `https://www.youtube.com/watch?v=${pathMateri[indexPathMateri].path_materi}`;
  } else {
    urlVideo = null;
  }

  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    controlBar: {
      children: [
        "playToggle",
        "currentTimeDisplay",
        "durationDisplay",
        "volumePanel",
        "fullscreenToggle",
      ],
    },
    userActions: {
      hotkeys: false,
      click: false,
    },
    techOrder: ["youtube"],
    sources: [
      {
        src: urlVideo,
        type: "video/youtube",
      },
    ],
    youtube: {
      controls: 0,
      rel: 0,
      modestbranding: 1,
      disablekb: 1,
      fs: 0,
      enablejsapi: 1,
    },
  };

  return (
    <div className="bg-white rounded-lg  mt-5">
      <div
        className="grid md:grid-cols-11 sm:grid-cols-3"
        style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}
      >
        <div
          className="col-span-3 md:col-span-3 sm:grid-cols-1 "
          style={{ borderRight: "1px solid #E0E0E0", height: "100%" }}
        >
          <p className="text-center px-5 py-5">
            Total JP : {pelatihan?.jp} Jam Pelatihan
          </p>
          <div
            className="py-5"
            style={{ borderTop: "1px solid #E0E0E0", width: "100%" }}
          >
            <div className="flex justify-between">
              <a className="font-semibold ml-3">Section </a>
            </div>
            <div className="overflow-y-scroll" style={{ height: "550px" }}>
              <If condition={modulPelatihan.length > 0}>
                <Then>
                  {modulPelatihan.map((m, idx) => {
                    var SubSection: JSX.Element[] = [];
                    m.subModulPelatihan.map((sm, idxSm) => {
                      return SubSection.push(
                        <div className="bg-gray-100 rounded-full flex justify-between mx-3 px-3 py-4 mt-3">
                          <a
                            className="text-xs font-semibold flex cursor-pointer"
                            style={{
                              color:
                                modulPelatihan[Number(selectModulIdx)]
                                  .subModulPelatihan[Number(selectSubModulIdx)]
                                  .id ==
                                  modulPelatihan[Number(idx)].subModulPelatihan[
                                    Number(idxSm)
                                  ].id && selectSubModulType != ""
                                  ? "#219653"
                                  : "#000000",
                            }}
                            onClick={() => {
                              if (sm.jenis_sub_modul == "materi") {
                                selectSubModul(idx, idxSm, "sub_modul_materi");
                              } else {
                                if (sm.jenis_ujian == "project") {
                                  selectSubModul(
                                    idx,
                                    idxSm,
                                    "sub_modul_ujian_project"
                                  );
                                } else {
                                  selectSubModul(
                                    idx,
                                    idxSm,
                                    "sub_modul_ujian_ujian"
                                  );
                                }
                              }
                            }}
                          >
                            <ImFilePlay
                              className="mr-2"
                              style={{ marginTop: "1px" }}
                            />
                            {sm.judul}
                          </a>
                        </div>
                      );
                    });

                    return (
                      <>
                        <div className="bg-gray-100 rounded-full flex justify-between mx-3 px-3 py-4 mt-3">
                          <a className="text-xs font-semibold">
                            Section {Number(idx + 1)} :{" "}
                            <span className="font-normal">{m.judul_modul}</span>
                          </a>
                        </div>
                        {SubSection}
                      </>
                    );
                  })}
                </Then>
              </If>
            </div>
          </div>
        </div>

        <div className="col-span-8 md:col-span-8 sm:grid-cols-1">
          <div className="grid md:grid-cols-2 sm:grid-cols-1">
            <If condition={selectSubModulType == "sub_modul_materi"}>
              <Then></Then>
              <Else>
                <a className="px-5 py-5">{judulSection}</a>
              </Else>
            </If>
          </div>
          <div
            className="py-5 px-5"
            style={{ borderTop: "1px solid #E0E0E0", width: "100%" }}
          >
            <If condition={selectSubModulType == ""}>
              <h3 className="text-center text-2xl" style={{ marginTop: "50%" }}>
                Belum Memilih Sub Section
              </h3>
            </If>

            <If condition={selectSubModulType == "sub_modul_ujian_ujian"}>
              <Then>
                <div
                  className="overflow-y-scroll pr-3"
                  style={{ height: "750px" }}
                >
                  {detailPaketSoal?.soal.map((s, i) => {
                    return (
                      <ItemSoal
                        idAccordion={"Soal" + (i + 1)}
                        nomor={i + 1}
                        soal={s.soal}
                        jawaban={s.jawaban}
                      />
                    );
                  })}
                </div>
              </Then>
            </If>

            <If condition={selectSubModulType == "sub_modul_materi"}>
              <Then>
                {pathMateri != null && pathMateri.length > 0 ? (
                  <>
                    <If
                      condition={
                        pathMateri[indexPathMateri].jenis_materi == "video"
                      }
                    >
                      <Then>
                        <VideoPlayer options={videoJsOptions} />
                      </Then>
                    </If>
                    <If
                      condition={
                        pathMateri[indexPathMateri].jenis_materi == "audio"
                      }
                    >
                      <Then>
                        <ReactAudioPlayer
                          src={pathMateri[indexPathMateri].path_materi}
                          autoPlay
                          controls
                          style={{
                            borderRadius: 10 + "px",
                            width: "100%",
                            color: "#111111",
                          }}
                        />
                      </Then>
                    </If>
                    <If
                      condition={
                        pathMateri[indexPathMateri].jenis_materi == "ppt"
                      }
                    >
                      <Then>
                        <iframe
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${pathMateri[indexPathMateri].path_materi}`}
                          width="100%"
                          height="600px"
                          style={{ borderRadius: "6px" }}
                        />
                      </Then>
                    </If>
                    <If
                      condition={
                        pathMateri[indexPathMateri].jenis_materi == "pdf"
                      }
                    >
                      <Then>
                        <iframe
                          src={pathMateri[indexPathMateri].path_materi}
                          width="100%"
                          height="600px"
                          style={{ borderRadius: "6px" }}
                        />
                      </Then>
                    </If>
                  </>
                ) : null}
                <div className="flex flex-row justify-between">
                  <ul
                    className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0  mt-4"
                    id="tabs-tab3"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        href="#tabs-materi"
                        className="
                                      nav-link
                                      w-full
                                      block
                                      font-medium
                                      text-xs
                                      leading-tight
                                      uppercase
                                      border-x-0 border-t-0 border-b-2 border-gray
                                      px-6
                                      py-3
                                      my-2
                                      hover:border-transparent hover:bg-gray-100
                                      focus:border-transparent
                                      active
                                      "
                        data-bs-toggle="pill"
                        data-bs-target="#tabs-materi"
                        role="tab"
                        aria-controls="tabs-materi"
                        aria-selected="true"
                      >
                        Materi
                      </a>
                    </li>
                    <If condition={quiz.length > 0}>
                      <Then>
                        <li className="nav-item" role="presentation">
                          <a
                            href="#tabs-quiz"
                            className="
                                          nav-link
                                          w-full
                                          block
                                          font-medium
                                          text-xs
                                          leading-tight
                                          uppercase
                                          border-x-0 border-t-0 border-b-2 border-gray
                                          px-6
                                          py-3
                                          my-2
                                          hover:border-transparent hover:bg-gray-100
                                          focus:border-transparent
                                          "
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-quiz"
                            role="tab"
                            aria-controls="tabs-quiz"
                            aria-selected="false"
                          >
                            Quiz
                          </a>
                        </li>
                      </Then>
                    </If>
                    <If
                      condition={
                        selectModulIdx != "" &&
                        modulPelatihan[selectModulIdx].subModulPelatihan[
                          selectSubModulIdx
                        ].ada_latihan == 1
                      }
                    >
                      <Then>
                        <li className="nav-item" role="presentation">
                          <a
                            href="#tabs-latihan"
                            className="
                                          nav-link
                                          w-full
                                          block
                                          font-medium
                                          text-xs
                                          leading-tight
                                          uppercase
                                          border-x-0 border-t-0 border-b-2 border-gray
                                          px-6
                                          py-3
                                          my-2
                                          hover:border-transparent hover:bg-gray-100
                                          focus:border-transparent
                                          "
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-latihan"
                            role="tab"
                            aria-controls="tabs-latihan"
                            aria-selected="false"
                          >
                            Latihan
                          </a>
                        </li>
                      </Then>
                    </If>
                  </ul>

                  <div className="mt-7 grid grid-cols-2 gap-5">
                    <Select
                      className="w-full"
                      options={optionsMateri}
                      placeholder="Pilih Materi"
                      defaultValue={optionsMateri[0]}
                      onChange={(val: any) => getDetailMateri(val.value)}
                    />

                    {optionsPathMateri.length > 0 ? (
                      <Select
                        className="w-full"
                        options={optionsPathMateri}
                        placeholder="Pilih Jenis Materi"
                        defaultValue={optionsPathMateri[0]}
                        onChange={(val: any) => setIndexPathMateri(val.value)}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="tab-content " id="tabs-tabContent3">
                  <div
                    className="tab-pane fade show active"
                    id="tabs-materi"
                    role="tabpanel"
                  >
                    <TabsMateri />
                  </div>
                  <If condition={quiz.length > 0}>
                    <Then>
                      <div
                        className="tab-pane fade"
                        id="tabs-quiz"
                        role="tabpanel"
                      >
                        <TabsQuiz />
                      </div>
                    </Then>
                  </If>
                  <If
                    condition={
                      selectModulIdx != "" &&
                      modulPelatihan[selectModulIdx].subModulPelatihan[
                        selectSubModulIdx
                      ].ada_latihan == 1
                    }
                  >
                    <Then>
                      <div
                        className="tab-pane fade"
                        id="tabs-latihan"
                        role="tabpanel"
                      >
                        <TabsLatihan />
                      </div>
                    </Then>
                  </If>
                </div>
              </Then>
            </If>

            <If condition={selectSubModulType == "sub_modul_ujian_project"}>
              <Then>
                <If condition={pathPanduanProject != null}>
                  <Then>
                    <iframe
                      src={pathPanduanProject}
                      width="100%"
                      height="600px"
                      style={{ borderRadius: "6px" }}
                    />
                  </Then>
                </If>
              </Then>
            </If>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <div className="app">
      <ModalDelete />
      <Sidebar />
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <Navbar title="Manajemen Pelatihan" />
          <BottomHeader />
        </header>

        <div className="container mb-10">
          <HeaderBanner />
          <div className="container px-9 mt-6">
            <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0">
              <li className="nav-item">
                <a
                  href={`/admin/detail/manajemen_pelatihan/data_pelatihan/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        "
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Data Pelatihan
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        active
                        bg-white"
                >
                  Konten Pelatihan
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  href={`/admin/detail/manajemen_pelatihan/penilaian/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Penilaian
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={`/admin/detail/manajemen_pelatihan/daftar_peserta/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Daftar Peserta
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <Konten />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const DetailKontenPelatihanManajemenPelatihan: React.FC = () => {
  return (
    <DetailPelatihanKontenPelatihanController.Provider>
      <ToastContainer />
      <WrapperMain />
    </DetailPelatihanKontenPelatihanController.Provider>
  );
};

export default DetailKontenPelatihanManajemenPelatihan;
