import env from "@ludovicm67/react-dotenv";
import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface Option {
  label: string;
  value: string;
}

interface IState {
  state: {
    url: string;
    urlHistorySubmission: string;
    fullName: string;
    nik: string;
    nip: string;
    email: string;
    phoneNumber: string;
    title: string;
    linkVideo: string;
    description: string;
    listFileType: Option[];
    fileType: Option | null;
    listBidangPengetahuan: Option[];
    bidangPengetahuan: Option | null;
    category: Option | null;
    pdf: File | null;
    provinsiSelected: Option | null;
    kabupatenSelected: Option | null;
    kecamatanSelected: Option | null;
    kelurahanSelected: Option | null;
    image: File | null;
    fileNaskah: File | null;
    isLoading: boolean;
    isOpenModalNomorPengajuan: boolean;
    isOpenModalDetailKontribusi: boolean;
    pathDetailKontribusi: string;
    categorySelected: string;
    nomorPengajuan: string;
    lisDataHistorySubmission: [];
    isLoadingHistorySubmission: boolean;
    page: number;
    perPage: number;
    totalHistorySubmission: number;
  };
  dispatch: {
    setKontibutorPengajuanState: Function;
    postData: Function;
    getHistorySubmission: Function;
  };
}

const initialState: IState = {
  state: {
    url: "kontributor_pengetahuan",
    urlHistorySubmission:
      "management_content/kontributor_pengetahuan/getPesertaKontributor",
    fullName: "",
    nik: "",
    nip: "",
    email: "",
    phoneNumber: "",
    title: "",
    linkVideo: "",
    description: "",
    listFileType: [
      { value: "video", label: "Video" },
      { value: "pdf", label: "PDF" },
    ],
    listBidangPengetahuan: [
      { value: "Pendidikan", label: "Pendidikan" },
      { value: "Keagamaan", label: "Keagamaan" },
      { value: "Administrasi", label: "Administrasi" },
    ],
    fileType: null,
    bidangPengetahuan: null,
    category: null,
    pdf: null,
    provinsiSelected: null,
    kabupatenSelected: null,
    kecamatanSelected: null,
    kelurahanSelected: null,
    image: null,
    fileNaskah: null,
    isLoading: false,
    isOpenModalNomorPengajuan: false,
    isOpenModalDetailKontribusi: false,
    pathDetailKontribusi: "",
    categorySelected: "",
    nomorPengajuan: "",
    lisDataHistorySubmission: [],
    isLoadingHistorySubmission: false,
    page: 1,
    perPage: 5,
    totalHistorySubmission: 0,
  },
  dispatch: {
    setKontibutorPengajuanState: () => {},
    postData: () => {},
    getHistorySubmission: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: KontibutorPengajuanProvider } = Context;

const KontributorPengajuanController: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const setKontibutorPengajuanState = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: { ...prevState.state, [key]: value },
    }));
  };

  const {
    url,
    urlHistorySubmission,
    fullName,
    nik,
    nip,
    title,
    email,
    phoneNumber,
    linkVideo,
    description,
    fileType,
    category,
    pdf,
    provinsiSelected,
    kabupatenSelected,
    kecamatanSelected,
    kelurahanSelected,
    image,
    fileNaskah,
    bidangPengetahuan,
    page,
    perPage,
    totalHistorySubmission,
  } = state.state;

  const clearState = () => {
    setState(initialState);
  };

  const postData = async () => {
    setKontibutorPengajuanState("isLoading", true);
    const formData = new FormData();
    formData.append("nama_lengkap", fullName);
    formData.append("nik", nik);
    if (nip) {
      formData.append("nip", nip);
    }
    formData.append("email", email);
    formData.append("nomor_telepon", phoneNumber);
    formData.append("judul", title);
    formData.append("status", "diajukan");
    formData.append("link_video", linkVideo);
    formData.append("deskripsi", description);

    if (fileType) {
      formData.append("kategori", fileType.value);
    }

    if (pdf instanceof File) {
      formData.append("file_dokumen", pdf);
    }

    if (image instanceof File) {
      formData.append("thumbnail", image);
    }

    if (fileNaskah instanceof File) {
      formData.append("file_naskah", fileNaskah);
    }

    if (provinsiSelected) {
      formData.append("provinsi_id", provinsiSelected.value);
    }

    if (kabupatenSelected) {
      formData.append("kabupaten_id", kabupatenSelected.value);
    }

    if (kecamatanSelected) {
      formData.append("kecamatan_id", kecamatanSelected.value);
    }

    if (kelurahanSelected) {
      formData.append("kelurahan_id", kelurahanSelected.value);
    }

    if (bidangPengetahuan) {
      formData.append("bidang", bidangPengetahuan.value);
    }

    const baseUrl = env.REACT_APP_BE_PROD_BASE_URL.replace("/api/v1", "");

    try {
      const response = await axios.post(baseUrl + url, formData);

      clearState();
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setKontibutorPengajuanState("isLoading", false);
    }
  };

  const getHistorySubmission = async () => {
    setKontibutorPengajuanState("isLoadingHistorySubmission", true);
    try {
      const { data } = await axiosInstance.get(urlHistorySubmission, {
        params: {
          page: page,
          per_page: perPage,
        },
      });
      setKontibutorPengajuanState("lisDataHistorySubmission", data.data?.data);
      setKontibutorPengajuanState("totalHistorySubmission", data.data?.total);
    } catch (error) {
      throw error;
    } finally {
      setKontibutorPengajuanState("isLoadingHistorySubmission", false);
    }
  };

  return (
    <KontibutorPengajuanProvider
      value={{
        state: state.state,
        dispatch: {
          setKontibutorPengajuanState,
          postData,
          getHistorySubmission,
        },
      }}
    >
      {children}
    </KontibutorPengajuanProvider>
  );
};

export const useKontibutorPengajuanContext = () => useContext(Context);
export default KontributorPengajuanController;
