/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { KecamatanPresenter } from "../../../../../../data/presenters/KecamatanPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Kecamatan } from "../../../../../../entities/Kecamatan";
import { Kabupaten } from "../../../../../../entities/Kabupaten";
import { KabupatenPresenter } from "../../../../../../data/presenters/KabupatenPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalKecamatan: number;
  kecamatan: Kecamatan[];
  kabupaten: Kabupaten[];
  formId: number;
  formDialog: string;
  formKabupatenId: string;
  formNameKecamatan: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
}

interface InitialState {
  state: IState;
  getKecamatan: Function;
  setPage: Function;
  setPageRow: Function;
  searchKecamatan: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormKabupatenId: Function;
  setFormNameKecamatan: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deleteKecamatan: Function;
  getKabupaten: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKecamatan: 1,
    kecamatan: [],
    kabupaten: [],
    formDialog: "",
    formId: 0,
    formKabupatenId: "",
    formNameKecamatan: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
  },
  getKecamatan: () => {},
  searchKecamatan: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormKabupatenId: () => {},
  setFormNameKecamatan: () => {},
  setShowDialog: () => {},
  deleteKecamatan: () => {},
  setShowDialogDelete: () => {},
  getKabupaten: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: MasterKecamatanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKecamatan: 0,
    kecamatan: [],
    kabupaten: [],
    formDialog: "",
    formId: 0,
    formKabupatenId: "",
    formNameKecamatan: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getKecamatan = async (pagee: number, keyword: string, row: number) => {
    setLoading(true);

    try {
      const kecamatanPresenter = container.resolve(KecamatanPresenter);
      const getAllKecamatan = await kecamatanPresenter.getAllKecamatan(
        pagee,
        row,
        keyword
      );

      const data = getAllKecamatan;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        kecamatan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchKecamatan = (value: string) => {
    setLoading(true);
    getKecamatan(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: Kecamatan) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formKabupatenId: value.kabupaten_id.toString(),
      formNameKecamatan: value.nama_kecamatan,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formNameKecamatan == "") {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const kecamatanPresenter = container.resolve(KecamatanPresenter);
      if (state.formDialog == "Tambah") {
        const action = await kecamatanPresenter.createKecamatan(
          state.formKabupatenId,
          state.formNameKecamatan
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKecamatan(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await kecamatanPresenter.updateKecamatan(
          state.formId,
          state.formKabupatenId,
          state.formNameKecamatan
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKecamatan(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormKabupatenId = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formKabupatenId: value,
    }));
  };

  const setFormNameKecamatan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNameKecamatan: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formKabupatenId: "",
      formNameKecamatan: "",
    }));
  };

  const deleteKecamatan = async () => {
    setFormLoading(true);

    try {
      const kecamatanPresenter = container.resolve(KecamatanPresenter);
      const action = await kecamatanPresenter.deleteKecamatan(state.formId);
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getKecamatan(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const getKabupaten = async () => {
    setLoading(true);

    try {
      const kabupatenPresenter = container.resolve(KabupatenPresenter);
      const getAllKabupaten = await kabupatenPresenter.getAllKabupaten(
        1,
        100000000,
        ""
      );

      const data = getAllKabupaten;

      setState((prevstate) => ({
        ...prevstate,
        kabupaten: data,
      }));
    } catch (error: any) {
      console.log("error kabupaten:", error);
      setLoading(false);
    }
  };

  return (
    <MasterKecamatanProvider
      value={{
        state,
        getKecamatan,
        setPage,
        setPageRow,
        searchKecamatan,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormKabupatenId,
        setFormNameKecamatan,
        setShowDialog,
        deleteKecamatan,
        setShowDialogDelete,
        getKabupaten,
      }}
    >
      {children}
    </MasterKecamatanProvider>
  );
};

export const useMasterKecamatanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterKecamatanContext,
  Provider,
};
