import { User } from "../../entities/User";
import { AxiosResponse } from "axios";

export class AuthDataMapper {
  public convertUserDataFromApi(result: AxiosResponse<any>): User {
    const { data } = result.data;

    return new User(
      data.id,
      data.name,
      data?.nip,
      data?.nik,
      data.email,
      data.level,
      data.access_token,
      data.path_foto,
      data.status_pengisian,
      data.expires_in,
      data.jenis_user
    );
  }
}
