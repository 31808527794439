import { AxiosResponse } from "axios";
import {
  MenuJenisPelatihan,
  SubMenuJenisPelatihan,
} from "../../entities/MenuJenisPelatihan";

export class MenuJenisPelatihanDataMapper {
  public convertMenuJenisPelatihanDataFromApi(
    result: AxiosResponse<any>
  ): MenuJenisPelatihan[] {
    const { data } = result.data;

    let modulPelatihan: MenuJenisPelatihan[] = [];

    data.map((m: any) => {
      let subMenuJenisPelatihan: SubMenuJenisPelatihan[] = [];

      m.m_sub_menu_jenis_pelatihan.map((sm: any) => {
        return subMenuJenisPelatihan.push(
          new SubMenuJenisPelatihan(
            sm.id,
            sm.menu_jenis_pelatihan_id,
            sm.sub_menu_jenis_pelatihan,
            sm.judul_menu,
            sm.created_at,
            sm.updated_at
          )
        );
      });

      return modulPelatihan.push(
        new MenuJenisPelatihan(
          m.id,
          m.menu_jenis_pelatihan,
          m.created_at,
          m.updated_at,
          subMenuJenisPelatihan
        )
      );
    });

    return modulPelatihan;
  }

  public convertDetailMenuJenisPelatihanDataFromApi(
    result: AxiosResponse<any>
  ): MenuJenisPelatihan {
    const { data } = result.data;

    let modulPelatihan: MenuJenisPelatihan;

    let subMenuJenisPelatihan: SubMenuJenisPelatihan[] = [];

    data.m_sub_menu_jenis_pelatihan.map((sm: any) => {
      return subMenuJenisPelatihan.push(
        new SubMenuJenisPelatihan(
          sm.id,
          sm.menu_jenis_pelatihan_id,
          sm.sub_menu_jenis_pelatihan,
          sm.judul_menu,
          sm.created_at,
          sm.updated_at
        )
      );
    });

    modulPelatihan = new MenuJenisPelatihan(
      data.id,
      data.menu_jenis_pelatihan,
      data.created_at,
      data.updated_at,
      subMenuJenisPelatihan
    );

    return modulPelatihan;
  }

  public convertSubMenuJenisPelatihanDataFromApi(
    result: AxiosResponse<any>
  ): SubMenuJenisPelatihan[] {
    const { data } = result.data;

    let subMenuJenisPelatihan: SubMenuJenisPelatihan[] = [];

    data.map((sm: any) => {
      return subMenuJenisPelatihan.push(
        new SubMenuJenisPelatihan(
          sm.id,
          sm.menu_jenis_pelatihan_id,
          sm.sub_menu_jenis_pelatihan,
          sm.judul_menu,
          sm.created_at,
          sm.updated_at
        )
      );
    });

    return subMenuJenisPelatihan;
  }

  public convertDetailSubMenuJenisPelatihanDataFromApi(
    result: AxiosResponse<any>
  ): SubMenuJenisPelatihan {
    const { data } = result.data;

    let subMenuJenisPelatihan: SubMenuJenisPelatihan;

    subMenuJenisPelatihan = new SubMenuJenisPelatihan(
      data.id,
      data.menu_jenis_pelatihan_id,
      data.sub_menu_jenis_pelatihan,
      data.judul_menu,
      data.created_at,
      data.updated_at
    );

    return subMenuJenisPelatihan;
  }
}
