import React, { useEffect } from "react";
import parse from "html-react-parser";
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Container,
} from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";

import FaqPublicController, {
  useFaqContext,
} from "../../controller/public/faq";
import { dataFaq } from "../../../../entities/Faq";

const FrequentlyAsked: React.FC = () => {
  const {
    getFaqsData,
    state: { faq: data, isLoading },
  } = useFaqContext();

  useEffect(() => {
    getFaqsData(1, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bgGradient="linear(green.50 0%,  white 10%)">
      <Container maxW="8xl">
        <Flex
          my={{ base: 8, md: 12 }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Text
            w={{ base: "100%", md: "30%" }}
            fontSize={{ base: "2xl", md: "2.5rem" }}
            fontWeight="semibold"
          >
            Paling Sering Ditanyakan
          </Text>
          {isLoading ? (
            <Skeleton
              count={5}
              style={{ height: "50px", marginBottom: "20px" }}
            />
          ) : (
            <Accordion
              w={{ base: "100%", md: "70%" }}
              defaultIndex={[0]}
              allowMultiple
            >
              {data.map((item: dataFaq) => (
                <AccordionItem key={item.id} bg="gray.100" my={4} rounded="md">
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                      {item.pertanyaan}
                    </Box>
                    <Box
                      px="2px"
                      py="0.5px"
                      border="2px"
                      borderColor="BlueTosca"
                      rounded="full"
                    >
                      <AccordionIcon color="BlueTosca" />
                    </Box>
                  </AccordionButton>

                  <AccordionPanel pb={4}>{parse(item.jawaban)}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

const Faq: React.FC = () => {
  return (
    <FaqPublicController.Provider>
      <FrequentlyAsked />
    </FaqPublicController.Provider>
  );
};

export default Faq;
