import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import env from "@ludovicm67/react-dotenv";
import { toast } from "react-toastify";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";

interface IState {
  isLoadingGet: boolean;
  isLoadingDownload: boolean;
  linkPDF: string;
  msgError: string;
  showModal: boolean;
}

interface InitialState {
  state: IState;
  getSertifikat: Function;
  downloadSertifikat: Function;
  setShowModal: Function;
}

const initialState = {
  state: {
    isLoadingGet: false,
    isLoadingDownload: false,
    linkPDF: "",
    msgError: "",
    showModal: false,
  },
  getSertifikat: () => {},
  downloadSertifikat: () => {},
  setShowModal: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: Sertifikat } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoadingGet: false,
    isLoadingDownload: false,
    linkPDF: "",
    msgError: "",
    showModal: false,
  });

  const token = GetLocalStorage({ name: "tokenAuth" });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingGet: value,
    }));
  };

  const setLoadingDownload = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDownload: value,
    }));
  };

  const setShowModal = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModal: value,
    }));
  };

  const getSertifikat = (pelatihan_id: number) => {
    try {
      setLoading(true);
      axios.defaults.headers.common = {
        Authorization: token !== undefined && token,
      };

      // Ganti ke dev apabila ingin mencoba di local
      axios(`${env.REACT_APP_BE_PROD_BASE_URL}sertifikat/${pelatihan_id}`, {
        method: "GET",
        responseType: "blob",
      })
        .then((resp) => {
          const file = new Blob([resp.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          setState((prevstate) => ({
            ...prevstate,
            linkPDF: fileURL,
          }));
          setLoading(false);
        })
        .catch((error) => {
          const location = window.location.pathname;
          const reviewMessage =
            'Mohon maaf, Anda belum memberikan review pada pelatihan ini. Silahkan memberikan review dengan menekan button "';

          const reviewError400Message =
            location === "/"
              ? reviewMessage + 'Tinjau Ulang"'
              : reviewMessage + 'Berikan Review"';

          if (error.response.status === 500) {
            toast.error(
              "Terdapat error pada sistem. Silahkan coba beberapa saat lagi, atau hubungi admin!",
              {
                style: {
                  boxShadow: "0px 1px 6px #F86E70",
                },
              }
            );
          } else if (error.response.status === 400) {
            toast.error(reviewError400Message, {
              style: {
                boxShadow: "0px 1px 6px #F86E70",
              },
            });
          }
          setShowModal(false);
          setLoading(false);
        });
    } catch (error: any) {
      console.log("error get sertifikat:", error);
      setLoading(false);
    }
  };

  const downloadSertifikat = (pelatihan_id: number, nama_pelatihan: string) => {
    try {
      setLoadingDownload(true);
      axios.defaults.headers.common = {
        Authorization: token !== undefined && token,
      };

      // Ganti ke dev apabila ingin mencoba di local
      axios(`${env.REACT_APP_BE_PROD_BASE_URL}sertifikat/${pelatihan_id}`, {
        method: "GET",
        responseType: "blob",
      })
        .then((resp) => {
          const file = new Blob([resp.data], { type: "application/pdf" });
          saveAs(
            file,
            `Sertifikat ${nama_pelatihan} ${moment().format("DD-MM-YYYY")} .pdf`
          );
          setLoadingDownload(false);
        })
        .catch((error) => console.log("error when fetch pdf:", error));
    } catch (error: any) {
      console.log("error download sertifikat:", error);
      setLoadingDownload(false);
    }
  };

  return (
    <Sertifikat
      value={{
        state,
        getSertifikat,
        downloadSertifikat,
        setShowModal,
      }}
    >
      {children}
    </Sertifikat>
  );
};

export const useSertifikatContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useSertifikatContext,
  Provider,
};
