import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";
import { MdCloudDownload } from "react-icons/md";
import { useDetailPelatihanDaftarPesertaContext } from "../../../controller/admin/manajemen_pelatihan/detail/daftar_peserta";
import { saveAs } from "file-saver";
import moment from "moment";

/**
 * The ModalSertifikat component is used to display and download training certificates.
 * This component uses information obtained from `useDetailPelatihanDaftarPesertaContext`.
 *
 * @param {string} nama_pelatihan - The name of the training for which the certificate is displayed.
 * @component
 * @author Bagas Prasetyadi
 */

const ModalSertifikat = ({ nama_pelatihan }: { nama_pelatihan: string }) => {
  const { showModalSertifikat, path_sertifikat } =
    useDetailPelatihanDaftarPesertaContext().state;
  const { setShowSertifikat } = useDetailPelatihanDaftarPesertaContext();

  const handleDownloadSertifikat = () => {
    saveAs(
      path_sertifikat,
      `Sertifikat ${nama_pelatihan} ${moment().format("DD-MM-YYYY")}.pdf`
    );
  };

  return (
    <Modal
      isOpen={showModalSertifikat}
      onClose={() => setShowSertifikat(false)}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" fontWeight="bold">
          Sertifikat Pelatihan
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Box px={6}>
            <iframe
              title="sertifikat pelatihan"
              src={`${path_sertifikat}`}
              width={"100%"}
              height={"600"}
              style={{ borderRadius: "6px" }}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<MdCloudDownload />}
            colorScheme="yellow"
            onClick={handleDownloadSertifikat}
            w="full"
          >
            Download
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSertifikat;
