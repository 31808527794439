import { KabupatenRepositoryInterface } from "../../../data/contracts/Kabupaten";
import { KabupatenDataMapper } from "../../../data/mappers/Kabupaten";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Kabupaten } from "../../../entities/Kabupaten";

export class KabupatenApiRepository implements KabupatenRepositoryInterface {
  private apiService: ApiService;
  private mapper: KabupatenDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: KabupatenDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllKabupaten(
    page: number,
    rows: number,
    keyword: string
  ): Promise<Kabupaten[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.kabupaten(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers
    );
    return this.mapper.convertKabupatenDataFromApi(dataResponse);
  }

  public async getKabupatenById(id: number): Promise<Kabupaten[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.kabupaten() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertKabupatenDataFromApi(dataResponse);
  }

  public async createKabupaten(
    provinsi_id: string,
    nama_kabupaten: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.wilayahKabupaten(),
      {
        provinsi_id: provinsi_id,
        nama_kabupaten: nama_kabupaten,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async updateKabupaten(
    id: number,
    provinsi_id: string,
    nama_kabupaten: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.wilayahKabupaten() + "/" + id,
      {
        provinsi_id: provinsi_id,
        nama_kabupaten: nama_kabupaten,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async deleteKabupaten(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.wilayahKabupaten() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
