import { injectable } from "tsyringe";
import { FinalProjectApiRepository } from "../../app/repository/api/FinalProjectApiRepository";
import { FinalProject } from "../../entities/FinalProject";

@injectable()
export class FinalProjectPresenter {
  private repository: FinalProjectApiRepository;
  constructor(repository: FinalProjectApiRepository) {
    this.repository = repository;
  }

  public storeFinalProject(
      pelatihan_id: number,
      path_final_project: File,
      uraian_penjelasan: string
    ): Promise<any> {
    return this.repository.storeFinalProject(
        pelatihan_id,
        path_final_project,
        uraian_penjelasan
    );
  }


  public storeFinalProjectAdmin(
      sub_modul_pelatihan_id: number,
      panduan_pengerjaan : string,
      path_panduan: File
    ): Promise<any> {
    return this.repository.storeFinalProjectAdmin(
        sub_modul_pelatihan_id,
        panduan_pengerjaan,
        path_panduan
    );  
  }

  public updateFinalProjectAdmin(
      id: number,
      panduan_pengerjaan : string,
      path_panduan: File
    ): Promise<any> {
    return this.repository.updateFinalProjectAdmin(
        id,
        panduan_pengerjaan,
        path_panduan
    );  
  }

  public deleteFinalProjectAdmin(
      id: number
    ): Promise<any> {
    return this.repository.deleteFinalProjectAdmin(
        id
    );  
  }


  public getDetailFinalProjectAdmin(
      id: number
    ): Promise<FinalProject> {
    return this.repository.getDetailFinalProjectAdmin(
        id
    );  
  }


}
