import { SertifikatTTEInterface } from "../../../data/contracts/SertifikatTTE";
import { Endpoints } from "../../misc/Endpoints";
import { LogTTEDataMapper } from "../../../data/mappers/LogTTE";
import { LogTTE } from "../../../entities/LogTTE";
import ApiService from "../../services/ApiService";

export class SertifikatTTERepository implements SertifikatTTEInterface {
  private apiService: ApiService;
  private mapper: LogTTEDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: LogTTEDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   *
   * @param pelatihan_id
   * @param isTteUlang
   * @param action
   * @param userId
   * @param akun_tte_id
   */
  public async tteSertifikat(
    pelatihan_id: number,
    isTteUlang: boolean,
    action: string,
    userId: number,
    akun_tte_id: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("akun_tte_id", akun_tte_id.toString());
    formData.append("pelatihan_id", pelatihan_id.toString());
    formData.append("action", action);
    if (userId !== 0) {
      formData.append("user_id", userId.toString());
    }

    const dataResponse = await this.apiService.invoke(
      "post",
      isTteUlang ? "/sertifikat/generate_ulang" : "/sertifikat/tte",
      {},
      formData,
      headers
    );
    return dataResponse;
  }

  public async logTTESertifikat(page: number, rows: number): Promise<LogTTE[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.logTTESertifikat(),
      {
        page: page,
        rows: rows,
      },
      null,
      headers
    );
    return this.mapper.convertListLogTTEData(dataResponse);
  }
}
