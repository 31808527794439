import env from "@ludovicm67/react-dotenv";

export const SENTRY_DSN =
	env.REACT_APP_SENTRY_DSN ||
	'https://7b1740fd176f4b289d76f28c597fd848@sentry-scrum.impstudio.id/21';

export const SENTRY_TRACE_SAMPLE_RATE = 0.1;

export const getSentryEnvironment = () => {
	const deploymentVersion = env.REACT_APP_ENVIRONMENT;

	const isStaging = deploymentVersion?.toLowerCase().includes('staging');
	const isProduction = deploymentVersion?.toLowerCase().includes('production');

	if (isStaging) {
		return 'staging';
	}
	if (isProduction) {
		return 'production';
	}

	return 'development';
};
