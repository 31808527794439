/* eslint-disable eqeqeq */
import React from "react";
import { Box } from "@chakra-ui/react";
import NotificationsController from "../../controller/notifications";
import NewNavbar from "./NewNavbar";
import { GetAuthData } from "../../../misc/GetAuthData";

export const Navbar: React.FC = () => {
  return (
    <NotificationsController.Provider>
      <Box
        zIndex="40"
        w="full"
        mx="auto"
        className="navbarMenu"
        position="sticky"
        transition="all 300ms ease-in-out"
        bg="white"
        display={{
          base: GetAuthData() !== null ? "none" : "block",
          md: "block",
        }}
      >
        {/* Menu Component */}
        <NewNavbar />
      </Box>
    </NotificationsController.Provider>
  );
};
