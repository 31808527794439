import {
  Badge,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaSearch } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useManajemenPengetahuanDaftarPesertaContext } from "../../../controller/admin/manajemen_pengetahuan/daftar-peserta";
import Loader from "../../Loader";

/**
 * Renders a table of participants in a knowledge-based assessment.
 *
 * @return {JSX.Element} The rendered table.
 * @author Bagas Prasetyadi
 */
const DaftarPesertaPengetahuan = () => {
  const { id } = useParams() as { id: string };

  const { listData, isLoading, isLoadingDownload, search, page, perPage } =
    useManajemenPengetahuanDaftarPesertaContext().state;
  const { fetchData, downloadFileAssessment, setPengetahuanDaftarPeserta } =
    useManajemenPengetahuanDaftarPesertaContext().dispatch;

  const generateBadgeColor = (status) => {
    switch (status) {
      case "selesai":
        return "green";
      case "mengikuti":
        return "yellow";
      default:
        return "gray";
    }
  };

  const columns = [
    {
      name: "NO.",
      selector: (row, index) => index + 1,
      // (pagePengetahuanSaya - 1) * perPagePengetahuanSaya + (index + 1),
      width: "100px",
    },
    {
      name: "NAMA",
      selector: (row) => row?.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "NIP/NIK",
      selector: (row) => row?.nip ?? row?.nik,
      sortable: true,
      wrap: true,
    },
    {
      name: "NILAI KUIS",
      selector: (row) => row?.nilai_quiz,
      sortable: true,
      wrap: true,
    },
    {
      name: "FILE ASSIGNMENT",
      cell: (row, index) => {
        return (
          <>
            {row?.file_asesmen ? (
              <Button
                colorScheme="blue"
                leftIcon={<Icon as={IoDocumentTextOutline} />}
                isLoading={isLoadingDownload[index] || false}
                onClick={() => {
                  downloadFileAssessment(
                    id,
                    row?.userId,
                    row?.nama_file,
                    index
                  );
                }}
                h="35px"
                px={2}
              >
                Download Assignment
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
      wrap: true,
    },
    {
      name: "STATUS",
      selector: (row) => row?.status_pengetahuan,
      sortable: true,
      wrap: true,
      cell: (row) => {
        const badgeColor = generateBadgeColor(row?.status_pengetahuan);
        return (
          <Badge colorScheme={badgeColor}>{row?.status_pengetahuan}</Badge>
        );
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    fetchData(id);
  }, [search, page, perPage]);

  return (
    <Box bg="white" p={4}>
      <Flex
        justifyContent="flex-end"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={4}
      >
        <InputGroup w="250px" mt={{ base: 4, md: 0 }}>
          <InputLeftElement pointerEvents="none">
            <FaSearch />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Cari Disini..."
            onChange={(e) => {
              setPengetahuanDaftarPeserta("search", e.target.value);
              setPengetahuanDaftarPeserta("page", 1);
            }}
            _focus={{
              ring: "1",
              ringColor: "green.700",
              borderColor: "green.700",
            }}
          />
        </InputGroup>
        <Button colorScheme="green" rightIcon={<Icon as={FiDownload} />} ml={3}>
          Download
        </Button>
      </Flex>
      <DataTable
        data={listData}
        columns={columns}
        customStyles={customStyles}
        pagination
        progressPending={isLoading}
        progressComponent={<Loader />}
        noDataComponent="Tidak ada data"
      />
    </Box>
  );
};

export default DaftarPesertaPengetahuan;
