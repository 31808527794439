import { useToast } from "@chakra-ui/react";
import env from "@ludovicm67/react-dotenv";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import history from "../../../../history";
import { SetLocalStorage } from "../../../misc/UseLocalStorage";
import Loader from "../../components/Loader";

const Redirect = () => {
  const signIn = useSignIn();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMe = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token") ?? "";
    const expiresIn = Number(searchParams.get("expires_in"));
    const appCode = searchParams.get("appCode") ?? "";

    const baseUrl = env.REACT_APP_BE_PROD_BASE_URL;

    const url = appCode === "pintar" ? "public/redirect" : "auth/me";

    setIsLoading(true);

    try {
      const config =
        appCode === "pintar"
          ? {
              // Jika appCode adalah "pintar", tidak gunakan header Authorization
              params: {
                token: token,
              },
            }
          : {
              // Jika appCode bukan "pintar", gunakan header Authorization
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

      const res = await axios.get(baseUrl + url, config);

      const { data } = res.data;

      // set foto profile
      SetLocalStorage({
        name: "path_photo",
        value: data.path_foto,
      });

      // set level
      SetLocalStorage({ name: "level", value: data.level });

      // set status pengisian
      SetLocalStorage({
        name: "status_pengisian",
        value: data?.m_peserta?.status_pengisian,
      });

      const user = {
        id: data.id,
        username: data.name,
        nip: data?.nip,
        nik: data?.nik,
        email: data?.email,
        level: data?.level,
        jenis_user: data?.jenis_user,
      };

      if (
        signIn({
          token: appCode === "pintar" ? data?.access_token : token,
          expiresIn: appCode === "pintar" ? data?.expires_in : expiresIn,
          tokenType: "Bearer",
          authState: user,
        })
      ) {
        switch (data.level) {
          case "superadmin":
            history.replace(`/admin/dashboard`);
            break;
          case "admin":
            history.replace(`/admin/dashboard`);
            break;
          case "peserta":
            history.replace("/");
            break;

          default:
            break;
        }

        window.location.reload();
      } else {
        toast({
          title: `Gagal Autentikasi, silahkan hubungi admin!`,
          position: "top-right",
          isClosable: true,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const dataError = error?.response?.data;
      // Memeriksa apakah dataError dan dataError.data tidak null atau undefined
      const {
        nama = "",
        nip = "",
        nik = "",
        email = "",
        path_foto = "",
        jenis_user = "",
      } = dataError && dataError.data ? dataError.data : {};

      toast({
        title:
          error?.response?.data?.message?.error ??
          "Gagal Autentikasi, silahkan coba lagi!",
        position: "top-right",
        isClosable: true,
        status: "error",
      });

      //jika belum autentikasi maka akan redirect ke halaman register
      setTimeout(() => {
        window.location.href = `/register?name=${nama}&nip=${nip}&nik=${nik}&email=${email}&pathFoto=${path_foto}&jenisUser=${jenis_user}&autoFill=${true}`;
      }, 2000);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader loadingText="Sedang dalam proses autentikasi. Mohon tunggu sebentar..." />
      ) : null}
    </>
  );
};

export default Redirect;
