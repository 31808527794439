import { useIsAuthenticated, useAuthHeader } from "react-auth-kit";
import {
  DeleteLocalStorage,
  SetLocalStorage,
} from "../../../misc/UseLocalStorage";

const AuthWrapper: React.FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  if (isAuthenticated()) {
    SetLocalStorage({ name: "tokenAuth", value: authHeader() });
  } else {
    DeleteLocalStorage({ name: "tokenAuth" });
  }

  return <>{children}</>;
};

export default AuthWrapper;
