/* eslint-disable eqeqeq */
import { ModulPelatihanRepositoryInterface } from "../../../data/contracts/ModulPelatihan";
import { ModulPelatihanDataMapper } from "../../../data/mappers/ModulPelatihan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  ModulPelatihan,
  SubModulPelatihan,
} from "../../../entities/ModulPelatihan";

export class ModulPelatihanApiRepository
  implements ModulPelatihanRepositoryInterface
{
  private apiService: ApiService;
  private mapper: ModulPelatihanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: ModulPelatihanDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllModulPelatihan(
    page: number,
    rows: number,
    keyword: string,
    pelatihan_id: number
  ): Promise<ModulPelatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.modulPelatihan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
        pelatihan_id: pelatihan_id,
      },
      null,
      headers
    );
    return this.mapper.convertModulPelatihanDataFromApi(dataResponse);
  }

  public async getDetailModulPelatihan(id: number): Promise<ModulPelatihan> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.modulPelatihan() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertDetailModulPelatihanDataFromApi(dataResponse);
  }

  public async storeModulPelatihan(
    pelatihan_id: number,
    judul_modul: string,
    sequence: number
  ): Promise<any> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("pelatihan_id", pelatihan_id.toString());
    formData.append("judul_modul", judul_modul);
    formData.append("sequence", sequence.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.modulPelatihan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateModulPelatihan(
    id: number,
    judul_modul: string,
    sequence: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("judul_modul", judul_modul);
    formData.append("sequence", sequence.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.modulPelatihan() + "/" + id,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteModulPelatihan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.modulPelatihan() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }

  public async getAllSubModulPelatihan(
    page: number,
    rows: number,
    keyword: string
  ): Promise<SubModulPelatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.subModulPelatihan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers
    );
    return this.mapper.convertSubModulPelatihanDataFromApi(dataResponse);
  }

  public async getDetailSubModulPelatihan(
    id: number
  ): Promise<SubModulPelatihan> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.subModulPelatihan() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertDetailSubModulPelatihanDataFromApi(dataResponse);
  }

  public async storeSubModulPelatihan(
    jenis_form_section: string,
    modul_pelatihan_id: number,
    jenis_sub_modul: string,
    materi_id: any,
    jenis_ujian: any,
    paket_soal_id: any,
    judul: string,
    durasi_latihan: number,
    passing_grade: number,
    sequence: number,
    limit_soal: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("modul_pelatihan_id", modul_pelatihan_id.toString());

    if (jenis_form_section == "sub_modul_materi") {
      materi_id.map((v) => formData.append("materi[]", v));
    }

    formData.append("jenis_sub_modul", jenis_sub_modul);

    if (jenis_form_section == "sub_modul_ujian_ujian") {
      formData.append("jenis_ujian", jenis_ujian);
      formData.append("paket_soal_id", paket_soal_id.toString());
      formData.append("durasi_latihan", durasi_latihan.toString());
      formData.append("passing_grade", passing_grade.toString());
      formData.append("limit_soal", limit_soal.toString());
    }

    if (jenis_form_section == "sub_modul_ujian_project") {
      formData.append("jenis_ujian", jenis_ujian);
    }

    formData.append("judul", judul);
    formData.append("sequence", sequence.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.subModulPelatihan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateSubModulPelatihan(
    jenis_form_section: string,
    id: number,
    modul_pelatihan_id: number,
    jenis_sub_modul: string,
    materi_id: any,
    jenis_ujian: string,
    paket_soal_id: number,
    judul: string,
    ada_latihan: boolean,
    durasi_latihan: number,
    passing_grade: number,
    sequence: number,
    limit_soal: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append("modul_pelatihan_id", modul_pelatihan_id.toString());

    if (jenis_form_section == "sub_modul_materi") {
      materi_id.map((v) => formData.append("materi[]", v));
      formData.append("ada_latihan", ada_latihan.toString());
    }

    formData.append("jenis_sub_modul", jenis_sub_modul);

    if (jenis_form_section == "sub_modul_ujian_ujian") {
      formData.append("jenis_ujian", jenis_ujian);
      formData.append("paket_soal_id", paket_soal_id.toString());
      formData.append("durasi_latihan", durasi_latihan.toString());
      formData.append("passing_grade", passing_grade.toString());
      formData.append("limit_soal", limit_soal.toString());
    }

    formData.append("judul", judul);
    formData.append("sequence", sequence.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.subModulPelatihan() + "/" + id,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteSubModulPelatihan(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.subModulPelatihan() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }

  public async bobotPenilaianSubModulPelatihan(
    sub_modul_pelatihan_id: number,
    bobot_penilaian: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    headers.set("Content-Type", "multipart/form-data");
    let formData = new FormData();
    formData.append(
      "sub_modul_pelatihan_id",
      sub_modul_pelatihan_id.toString()
    );
    formData.append("bobot_penilaian", bobot_penilaian.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.bobotPenilaian(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }
}
