export const Modal = ({
  id_modal,
  children,
}: {
  id_modal: string;
  children;
}) => {
  return (
    <>
      <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50"></div>
      <div
        id={id_modal}
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 z-50  w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full items-center justify-center flex"
      >
        <div className="relative w-full h-full max-w-2xl p-4 md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal content */}
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export const ModalExtraLarge = ({
  id_modal,
  children,
}: {
  id_modal: string;
  children;
}) => {
  return (
    <div
      id={id_modal}
      tabIndex={-1}
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div className="relative w-full h-full max-w-7xl p-4 md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Modal content */}
          {children}
        </div>
      </div>
    </div>
  );
};
