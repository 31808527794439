import { PathMateriInterface } from "../../../data/contracts/PathMateri";
import { PathMateriDataMapper } from "../../../data/mappers/PathMateri";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { PathMateri } from "../../../entities/PathMateri";
import { GetLocalStorage } from "../../misc/UseLocalStorage";

export class PathMateriApiRepository implements PathMateriInterface {
  private apiService: ApiService;
  private mapper: PathMateriDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: PathMateriDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   *
   * @param materi_id
   * @param jenis_materi
   * @returns
   */
  public async getAllPathMateri(
    materi_id: number,
    jenis_materi: string
  ): Promise<PathMateri[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      GetLocalStorage({ name: "tokenAuth" }) !== undefined
        ? this.endpoints.pathMateri()
        : "public/" + this.endpoints.pathMateri(),
      {
        materi_id: materi_id,
        jenis_materi: jenis_materi,
      },
      null,
      headers
    );
    return this.mapper.convertListPathMateri(dataResponse);
  }

  /**
   *
   * @param materi_id
   * @param path_materi
   * @param jenis_materi
   * @param metode
   * @returns
   */
  public async postPathMateri(
    materi_id: number,
    path_materi: File,
    jenis_materi: string,
    metode: any
  ): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("materi_id", materi_id.toString());
    formData.append("path_materi", path_materi);
    formData.append("jenis_materi", jenis_materi);
    if (metode != null) {
      formData.append("metode", metode.toString());
    }
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pathMateri(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  /**
   *
   * @param pathMateriId
   * @param materi_id
   * @param path_materi
   * @param jenis_materi
   * @param metode
   * @returns
   */
  public async putPathMateri(
    pathMateriId: number,
    path_materi: File,
    jenis_materi: string,
    metode: any
  ): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("path_materi", path_materi);
    formData.append("jenis_materi", jenis_materi);
    if (metode != null) {
      formData.append("metode", metode.toString());
    }
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pathMateri() + "/" + pathMateriId,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  /**
   *
   * @param pathMateriId
   * @returns
   */
  public async deletePathMateri(pathMateriId: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.pathMateri() + "/" + pathMateriId,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
