import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../../api/axiosInstance";

interface DetailKontributorItem {
  id: number;
  nama_lengkap: string;
  email: string;
  status: string;
  nik: string;
  nip: string;
  nomor_telepon: string;
  created_at: string;
  judul: string;
  kategori_pengetahuan_id: string;
  nama_file_dokumen: string;
  nama_file_naskah: string;
  link_video: string;
  deskripsi: string;
  file_dokumen: string;
  file_naskah: string;
  alasan: string;
  has_publish: boolean;
  bidang: string;
}
interface IState {
  state: {
    url: string;
    listData: [];
    keyword: string;
    isLoading: boolean;
    page: number;
    perPage: number;
    totalListData: number;
    status: string;
    isLoadingDetail: boolean;
    kontributorItem: DetailKontributorItem | null;
    statusVerification: string;
    message: string;
    isLoadingVerification: boolean;
    isOpenModal: boolean;
  };
  dispatch: {
    setManajemenKontributorPengetahuanState: Function;
    fetchData: Function;
    getDetailKontributorPengetahuan: Function;
    handleVerification: Function;
  };
}

const initialState: IState = {
  state: {
    url: "management_content/kontributor_pengetahuan",
    listData: [],
    keyword: "",
    isLoading: false,
    page: 1,
    perPage: 5,
    totalListData: 0,
    status: "",
    isLoadingDetail: false,
    kontributorItem: null,
    statusVerification: "",
    message: "",
    isLoadingVerification: false,
    isOpenModal: false,
  },
  dispatch: {
    setManajemenKontributorPengetahuanState: () => {},
    fetchData: () => {},
    getDetailKontributorPengetahuan: () => {},
    handleVerification: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: ManajemenKontributorPengetahuanProvider } = Context;

const ManajemenKontributorPengetahuanController: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState.state);
  const history = useHistory();
  const toast = useToast();

  const setManajemenKontributorPengetahuanState = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const {
    url,
    keyword,
    page,
    perPage,
    status,
    message,
    statusVerification,
    kontributorItem,
  } = state;

  const fetchData = async () => {
    setManajemenKontributorPengetahuanState("isLoading", true);
    try {
      const response = await axiosInstance.get(url, {
        params: { keyword, page, per_page: perPage, status },
      });

      setManajemenKontributorPengetahuanState(
        "listData",
        response.data.data?.data
      );
      setManajemenKontributorPengetahuanState(
        "totalListData",
        response.data.data?.total
      );
    } catch (error) {
      throw error;
    } finally {
      setManajemenKontributorPengetahuanState("isLoading", false);
    }
  };

  const getDetailKontributorPengetahuan = async (id: string) => {
    setManajemenKontributorPengetahuanState("isLoadingDetail", true);
    try {
      const response = await axiosInstance.get(`${url}/${id}`);

      setManajemenKontributorPengetahuanState(
        "kontributorItem",
        response.data?.data
      );
    } catch (error) {
    } finally {
      setManajemenKontributorPengetahuanState("isLoadingDetail", false);
    }
  };

  const handleVerification = async () => {
    if (message === "") {
      toast({
        title: "Alasan harus diisi",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setManajemenKontributorPengetahuanState("isLoadingVerification", true);
    try {
      const { data } = await axiosInstance.post(
        `${url}/${kontributorItem?.id}`,
        {
          status: statusVerification,
          alasan: message,
        }
      );

      if (data.code === 200) {
        toast({
          title: "Berhasil di verifikasi",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        history.push("/admin/manajemen_kontributor_pengetahuan");
      }
    } catch (error) {
      toast({
        title: "Gagal di verifikasi",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    } finally {
      setManajemenKontributorPengetahuanState("isLoadingVerification", false);
    }
  };

  return (
    <ManajemenKontributorPengetahuanProvider
      value={{
        state,
        dispatch: {
          setManajemenKontributorPengetahuanState,
          fetchData,
          getDetailKontributorPengetahuan,
          handleVerification,
        },
      }}
    >
      {children}
    </ManajemenKontributorPengetahuanProvider>
  );
};

export const useManajemenKontributorPengetahuanContext = () =>
  useContext(Context);

export default ManajemenKontributorPengetahuanController;
