import React from "react";
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import LogoGreen from "../../../assets/png/logo_admin.png";
import { FaBars } from "react-icons/fa";
import { ReactComponent as IconDashboard } from "../../../assets/admin/svg/ic_dashboard.svg";
import { ReactComponent as IconDataMaster } from "../../../assets/admin/svg/ic_data_master.svg";
import { ReactComponent as IconHelpdesk } from "../../../assets/admin/svg/ic_helpdesk.svg";
import { ReactComponent as IconManajemenMateri } from "../../../assets/admin/svg/ic_manajemen_materi.svg";
import { ReactComponent as IconManajemenPelatihan } from "../../../assets/admin/svg/ic_manajemen_pelatihan.svg";
import { ReactComponent as IconManajemenSoal } from "../../../assets/admin/svg/ic_manajemen_soal.svg";
import { ReactComponent as IconManajemenUser } from "../../../assets/admin/svg/ic_manajemen_user.svg";
import { ReactComponent as IconManajemenCertificate } from "../../../assets/admin/svg/ic_certificate.svg";
import { ReactComponent as IconUserManajemen } from "../../../assets/admin/svg/ic_user_database.svg";
import { If, Then } from "react-if";

import { GetAuthData } from "../../../../misc/GetAuthData";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";

export const Sidebar: React.FC = ({ children }) => {
  const [navbarOpen, setNavbarOpen] = React.useState(
    window.innerWidth <= 760 ? true : false
  );

  const token = GetLocalStorage({ name: "tokenAuth" });

  return (
    <ProSidebar collapsed={navbarOpen} style={{ backgroundColor: "black" }}>
      <SidebarHeader>
        <div
          className={navbarOpen ? "" : "beetween-container"}
          style={{
            padding: "24px",
            paddingTop: "40px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 18,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            backgroundColor: "#363636",
            color: "#0DBD7F",
            height: "102px",
          }}
        >
          {navbarOpen ? (
            ""
          ) : (
            <img
              src={LogoGreen}
              alt="logo_green"
              style={{ width: "150px", height: "60px", marginTop: "-20px" }}
            />
          )}{" "}
          <FaBars
            className="cursor-pointer"
            onClick={() => setNavbarOpen(!navbarOpen)}
            style={{ color: "#FFFFFF" }}
          />
        </div>
      </SidebarHeader>
      <Menu iconShape="square">
        {/* Menu Dashboard */}
        <MenuItem
          icon={<IconDashboard />}
          onClick={() => (window.location.href = "/admin/dashboard")}
          className={`${
            window.location.pathname === "/admin/dashboard" ||
            window.location.pathname === "/"
              ? "text-white"
              : ""
          }`}
          style={{
            borderLeft:
              window.location.pathname === "/admin/dashboard" ||
              window.location.pathname === "/"
                ? "5px solid #007E39"
                : "",
          }}
        >
          Dashboard
        </MenuItem>

        {/* Manajemen Pelatihan */}
        <MenuItem
          onClick={() => (window.location.href = "/admin/manajemen_pelatihan")}
          className={`${
            window.location.pathname === "/admin/manajemen_pelatihan" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "tambah/manajemen_pelatihan" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "kelola_konten/manajemen_pelatihan" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] +
              "/" +
              window.location.pathname.split("/", 5)[4] ===
              "kelola_konten/detail_soal/manajemen_pelatihan" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "detail/manajemen_pelatihan"
              ? "text-white"
              : ""
          }`}
          style={{
            borderLeft:
              window.location.pathname === "/admin/manajemen_pelatihan" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "tambah/manajemen_pelatihan" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "kelola_konten/manajemen_pelatihan" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] +
                "/" +
                window.location.pathname.split("/", 5)[4] ===
                "kelola_konten/detail_soal/manajemen_pelatihan" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "detail/manajemen_pelatihan"
                ? "5px solid #007E39"
                : "",
          }}
          icon={<IconManajemenPelatihan />}
        >
          Manajemen Pelatihan
        </MenuItem>

        {/* Manajemen Materi */}
        <MenuItem
          icon={<IconManajemenMateri />}
          onClick={() => (window.location.href = "/admin/manajemen_materi")}
          className={`${
            window.location.pathname === "/admin/manajemen_materi" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "detail/manajemen_materi" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "tambah/manajemen_materi"
              ? "text-white"
              : ""
          }`}
          style={{
            borderLeft:
              window.location.pathname === "/admin/manajemen_materi" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "detail/manajemen_materi" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "tambah/manajemen_materi"
                ? "5px solid #007E39"
                : "",
          }}
        >
          Manajemen Materi
        </MenuItem>

        {/* Manajemen Soal */}
        <MenuItem
          icon={<IconManajemenSoal />}
          onClick={() => (window.location.href = "/admin/manajemen_soal")}
          className={`${
            window.location.pathname === "/admin/manajemen_soal" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "detail/manajemen_soal" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "tambah/manajemen_soal" ||
            window.location.pathname.split("/", 4)[2] +
              "/" +
              window.location.pathname.split("/", 4)[3] ===
              "ubah/manajemen_soal"
              ? "text-white"
              : ""
          }`}
          style={{
            borderLeft:
              window.location.pathname === "/admin/manajemen_soal" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "detail/manajemen_soal" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "tambah/manajemen_soal" ||
              window.location.pathname.split("/", 4)[2] +
                "/" +
                window.location.pathname.split("/", 4)[3] ===
                "ubah/manajemen_soal"
                ? "5px solid #007E39"
                : "",
          }}
        >
          Manajemen Soal
        </MenuItem>

        {/* If level is superadmin */}
        <If
          condition={
            token !== undefined && GetAuthData()?.level === "superadmin"
          }
        >
          <Then>
            {/* Manajemen User */}
            <MenuItem
              onClick={() => (window.location.href = "/admin/manajemen_user")}
              className={`${
                window.location.pathname.split("/", 4)[2] === "manajemen_user"
                  ? "text-white"
                  : ""
              }`}
              style={{
                borderLeft:
                  window.location.pathname.split("/", 4)[2] === "manajemen_user"
                    ? "5px solid #007E39"
                    : "",
              }}
              icon={<IconManajemenUser />}
            >
              Manajemen User
            </MenuItem>

            {/* Manajemen Sertifikat */}
            <MenuItem
              onClick={() =>
                (window.location.href = "/admin/manajemen_sertifikat")
              }
              className={`${
                window.location.pathname.split("/", 4)[2] ===
                "manajemen_sertifikat"
                  ? "text-white"
                  : ""
              }`}
              style={{
                borderLeft:
                  window.location.pathname.split("/", 4)[2] ===
                  "manajemen_sertifikat"
                    ? "5px solid #007E39"
                    : "",
              }}
              icon={<IconManajemenCertificate />}
            >
              Manajemen Sertifikat
            </MenuItem>

            {/* Manajemen Alumni Pelatihan Simdiklat */}
            <MenuItem
              onClick={() =>
                (window.location.href = "/admin/manajemen_alumni_simdiklat")
              }
              className={`${
                window.location.pathname.split("/", 4)[2] ===
                "manajemen_alumni_simdiklat"
                  ? "text-white"
                  : ""
              }`}
              style={{
                borderLeft:
                  window.location.pathname.split("/", 4)[2] ===
                  "manajemen_alumni_simdiklat"
                    ? "5px solid #007E39"
                    : "",
              }}
              icon={<IconUserManajemen />}
            >
              Manajemen Alumni <br /> Pelatihan Simdiklat
            </MenuItem>

            {/* Helpdesk */}
            <SubMenu
              title="Helpdesk"
              icon={<IconHelpdesk />}
              defaultOpen={
                window.location.pathname.split("/", 4)[2] === "helpdesk"
                  ? true
                  : false
              }
            >
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/helpdesk/konsultasi")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "helpdesk/konsultasi"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "helpdesk/konsultasi"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Konsultasi
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/helpdesk/bantuan")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "helpdesk/bantuan"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "helpdesk/bantuan"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Bantuan
              </MenuItem>
            </SubMenu>

            {/* Data Master */}
            <SubMenu
              title="Data Master"
              icon={<IconDataMaster />}
              defaultOpen={
                window.location.pathname.split("/", 4)[2] === "master"
                  ? true
                  : false
              }
            >
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/master/jenis_pelatihan")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/jenis_pelatihan"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/jenis_pelatihan"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Jenis Pelatihan
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/master/kategori_soal")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/kategori_soal"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/kategori_soal"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Kategori Soal
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/master/provinsi")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/provinsi"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/provinsi"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Provinsi
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/master/kabupaten")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/kabupaten"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/kabupaten"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Kabupaten
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/master/kecamatan")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/kecamatan"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/kecamatan"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Kecamatan
              </MenuItem>
              <MenuItem
                onClick={() =>
                  (window.location.href = "/admin/master/kelurahan")
                }
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/kelurahan"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/kelurahan"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                Kelurahan
              </MenuItem>
              <MenuItem
                onClick={() => (window.location.href = "/admin/master/faq")}
                className={`${
                  window.location.pathname.split("/", 4)[2] +
                    "/" +
                    window.location.pathname.split("/", 4)[3] ===
                  "master/faq"
                    ? "text-white"
                    : ""
                }`}
                style={{
                  borderLeft:
                    window.location.pathname.split("/", 4)[2] +
                      "/" +
                      window.location.pathname.split("/", 4)[3] ===
                    "master/faq"
                      ? "5px solid #007E39"
                      : "",
                }}
              >
                FAQ
              </MenuItem>
            </SubMenu>
          </Then>
        </If>
      </Menu>
    </ProSidebar>
  );
};
