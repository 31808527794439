import { FinalProject } from '../../entities/FinalProject'
import { AxiosResponse } from 'axios'

export class FinalProjectDataMapper {
    public convertDetailFinalProject(result: AxiosResponse<any>): FinalProject {
        const { data } = result.data
    
        return new FinalProject(
            data.id,
            data.sub_modul_pelatihan_id,
            data.panduan_pengerjaan,
            data.path_panduan,
            data.created_at,
            data.updated_at
        );
    }
}
