import env from "@ludovicm67/react-dotenv";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "pusdiklat-message.firebaseapp.com",
  projectId: "pusdiklat-message",
  storageBucket: "pusdiklat-message.appspot.com",
  messagingSenderId: "569703373117",
  appId: "1:569703373117:web:789f2282a13b962f234d54",
  measurementId: "G-2Z7EECHL6S",
};
// initialize firebase
const app = initializeApp(config);

// initialize firestore
export const db = getFirestore(app);
