import { Badge, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useKontibutorPengajuanContext } from "../../controller/kontributor/pengajuan";
import Loader from "../Loader";

/**
 * RiwayatPengajuanKontributor component is responsible for displaying a table of submission history,
 * including details such as submission number, registration date, title, short description, status, and actions.
 *
 * The table uses server-side pagination and provides the ability to view submission details (either a video or PDF).
 *
 * @component
 * @example
 * return (
 *   <RiwayatPengajuanKontributor />
 * )
 * @author Bagas Prasetyadi
 */
const RiwayatPengajuanKontributor = () => {
  const {
    lisDataHistorySubmission,
    isLoadingHistorySubmission,
    totalHistorySubmission,
    page,
    perPage,
  } = useKontibutorPengajuanContext().state;
  const { getHistorySubmission, setKontibutorPengajuanState } =
    useKontibutorPengajuanContext().dispatch;

  const getStatusColor = (status) => {
    switch (status) {
      case "diajukan":
        return "blue";
      case "diterima":
        return "green";
      case "ditolak":
        return "red";
      default:
        return "gray";
    }
  };

  const getYouTubeVideoId = (url) => {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get("v");
    return videoId;
  };

  const columns: any = [
    {
      name: "NO",
      selector: (row, index) => (page - 1) * perPage + (index + 1),
      sortable: true,
      width: "70px",
    },
    {
      name: "NO PENGAJUAN",
      selector: (row) => row.nomor_pengajuan,
      sortable: true,
    },
    {
      name: "TGL PENDAFTARAN",
      selector: (row) => row.created_at,
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "JUDUL",
      selector: (row) => row.judul,
      sortable: true,
    },
    {
      name: "DESKRIPSI SINGKAT",
      selector: (row) => row.deskripsi,
      wrap: true,
      width: "350px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <Badge colorScheme={getStatusColor(row.status)}>{row.status}</Badge>
      ),
    },
    {
      name: "AKSI",
      cell: (row) => (
        <Button
          color="white"
          bg="green.700"
          _hover={{ bg: "green.800" }}
          _active={{ bg: "green.800" }}
          h="35px"
          onClick={() => {
            let path = ``;

            if (row.kategori === "video") {
              path = getYouTubeVideoId(row.link_video) ?? "";
            } else {
              path = row.file_dokumen;
            }

            setKontibutorPengajuanState("isOpenModalDetailKontribusi", true);
            setKontibutorPengajuanState("pathDetailKontribusi", path);
            setKontibutorPengajuanState("categorySelected", row.kategori);
          }}
        >
          Lihat Detail
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "150px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  const handlePageChange = (page) => {
    setKontibutorPengajuanState("page", page);
  };

  const handlePerRowsChange = (newPerPage) => {
    setKontibutorPengajuanState("page", 1);
    setKontibutorPengajuanState("perPage", newPerPage);
  };

  useEffect(() => {
    getHistorySubmission();
  }, [page, perPage]);

  return (
    <div className="mt-20">
      <h2
        className="font-semibold tracking-tight text-2xl mb-4"
        style={{ color: "#3C4952" }}
      >
        Riwayat Pengajuan Kontribusi
      </h2>
      <DataTable
        columns={columns}
        data={lisDataHistorySubmission}
        pagination
        paginationServer
        paginationTotalRows={
          lisDataHistorySubmission.length > 0 ? totalHistorySubmission : 0
        }
        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
        paginationPerPage={perPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        progressPending={isLoadingHistorySubmission}
        progressComponent={<Loader />}
      />
    </div>
  );
};

export default RiwayatPengajuanKontributor;
