import { QuizRepositoryInterface } from "../../../data/contracts/Quiz";
import { QuizDataMapper } from "../../../data/mappers/Quiz";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Quiz } from "../../../entities/Quiz";

export class QuizApiRepository implements QuizRepositoryInterface {
  private apiService: ApiService;
  private mapper: QuizDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: QuizDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailQuiz(id: number): Promise<Quiz> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.quiz() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertDetailQuizDataFromApi(dataResponse);
  }

  public async getAllQuiz(sub_modul_id: number): Promise<Quiz[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.quiz(),
      {
        page: 1,
        rows: 10000000000,
        sub_modul_id: sub_modul_id,
      },
      null,
      headers
    );
    return this.mapper.convertListQuizDataFromApi(dataResponse);
  }

  public async storeQuiz(
    sub_modul_pelatihan_id: number,
    materi_id: number,
    waktu_ke: string,
    pertanyaan: string,
    pilihan_a: string,
    jawaban_benar_a: boolean,
    pilihan_b: string,
    jawaban_benar_b: boolean,
    pilihan_c: string,
    jawaban_benar_c: boolean,
    pilihan_d: string,
    jawaban_benar_d: boolean,
    pilihan_e: string,
    jawaban_benar_e: boolean
  ): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append(
      "sub_modul_pelatihan_id",
      sub_modul_pelatihan_id.toString()
    );
    formData.append("materi_id", materi_id.toString());
    formData.append("waktu_ke", waktu_ke);
    formData.append("pertanyaan", pertanyaan);
    formData.append("pilihan_a", pilihan_a);
    formData.append("jawaban_benar_a", jawaban_benar_a.toString());
    formData.append("pilihan_b", pilihan_b);
    formData.append("jawaban_benar_b", jawaban_benar_b.toString());
    formData.append("pilihan_c", pilihan_c);
    formData.append("jawaban_benar_c", jawaban_benar_c.toString());
    formData.append("pilihan_d", pilihan_d);
    formData.append("jawaban_benar_d", jawaban_benar_d.toString());
    formData.append("pilihan_e", pilihan_e);
    formData.append("jawaban_benar_e", jawaban_benar_e.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.quiz(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateQuiz(
    quizId: number,
    waktu_ke: string,
    pertanyaan: string,
    pilihan_a: string,
    jawaban_benar_a: boolean,
    jawaban_quiz_id_a: number,
    pilihan_b: string,
    jawaban_benar_b: boolean,
    jawaban_quiz_id_b: number,
    pilihan_c: string,
    jawaban_benar_c: boolean,
    jawaban_quiz_id_c: number,
    pilihan_d: string,
    jawaban_benar_d: boolean,
    jawaban_quiz_id_d: number,
    pilihan_e: string,
    jawaban_benar_e: boolean,
    jawaban_quiz_id_e: number
  ): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("waktu_ke", waktu_ke);
    formData.append("pertanyaan", pertanyaan);
    formData.append("pilihan_a", pilihan_a);
    formData.append("jawaban_benar_a", jawaban_benar_a.toString());
    formData.append("jawaban_quiz_id_a", jawaban_quiz_id_a.toString());
    formData.append("pilihan_b", pilihan_b);
    formData.append("jawaban_benar_b", jawaban_benar_b.toString());
    formData.append("jawaban_quiz_id_b", jawaban_quiz_id_b.toString());
    formData.append("pilihan_c", pilihan_c);
    formData.append("jawaban_benar_c", jawaban_benar_c.toString());
    formData.append("jawaban_quiz_id_c", jawaban_quiz_id_c.toString());
    formData.append("pilihan_d", pilihan_d);
    formData.append("jawaban_benar_d", jawaban_benar_d.toString());
    formData.append("jawaban_quiz_id_d", jawaban_quiz_id_d.toString());
    formData.append("pilihan_e", pilihan_e);
    formData.append("jawaban_benar_e", jawaban_benar_e.toString());
    formData.append("jawaban_quiz_id_e", jawaban_quiz_id_e.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.quiz() + "/" + quizId,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteQuiz(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.quiz() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
