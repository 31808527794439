import { dataFaq } from "../../entities/Faq";
import { AxiosResponse } from "axios";

export class FaqsDataMapper {
  public convertListFaqsPublic(result: AxiosResponse<any>): dataFaq[] {
    const { data } = result.data;
    let faqs: dataFaq[] = [];

    data.map((u: any) => {
      return faqs.push(
        new dataFaq(
          u.id,
          u.pertanyaan,
          u.jawaban,
          u.created_at,
          u.updated_at,
          result.data.pagination.total_rows,
          u.status_publish
        )
      );
    });

    return faqs;
  }
}
