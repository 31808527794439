import React from "react";
import { Wrapper } from "../../../components/Wrapper";
import { ToastContainer } from "react-toastify";
import If, { Else } from "if-else-react";
import ConfirmationPasswordController, {
  useConfirmationPassword,
} from "../../../controller/auth/confirmation_password";

const ForgotForm: React.FC = () => {
  // Get Params Id
  const id = window.location.pathname.split("/password_reset/", 2)[1];

  // Call Controller
  const { setPassword, setPasswordConfirmation, handleResetPassword } =
    useConfirmationPassword();
  const { isLoading, msgError } = useConfirmationPassword().state;

  const handleReset = (e) => {
    e.preventDefault();
    handleResetPassword(id);
  };

  return (
    <div className="max-w-2xl mx-auto lg:px-7 pt-4 pb-18 w-full text-center mt-5 mb-5">
      <h1 className="text-2xl md:text-3xl font-semibold">Reset Password</h1>
      {/* Form Login */}
      <div className="w-5/6 mx-auto mt-8">
        <div className="bg-white rounded-lg overflow-hidden mb-10">
          <div className="p-5 sm:p-7 md:p-6 xl:p-7 w-10/12 mx-auto">
            <p className="text-sm font-normal text-gray-400 pb-5 text-center">
              Masukkan password baru dan konfimasi password anda pada kotak yang
              telah disediakan.
            </p>

            {msgError.length > 0 && (
              <div
                className="mb-4 text-sm text-red-700 bg-red-100 rounded-lg p-4 text-left"
                role="alert"
              >
                <ul className="list-disc px-5">
                  {msgError.map((val, key) => (
                    <li key={key}>{val}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Divider */}
            <div className="border-b-2 border-dashed"></div>
            {/* /Divider */}

            {/* Form */}
            <form className="mt-5" onSubmit={(e) => handleReset(e)}>
              <div className="border-3 border-gray-400 mt-2">
                {/* Password Baru */}
                <p className="text-sm text-left mb-2">Password Baru</p>
                <div className="relative text-gray-700">
                  <i className="fas fa-lock absolute mt-3 ml-4 text-gray-300 focus:text-green-600"></i>
                  <input
                    className="w-full h-10 pl-10 pr-8 text-base placeholder-gray-300 border-1 border-gray-300 rounded-lg focus:border-green-600 focus:ring-1 focus:ring-green-600"
                    placeholder="Password Baru"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                {/* Konfirmasi Password Baru */}
                <p className="text-sm text-left mb-2 mt-2">
                  Konfirmasi Password
                </p>
                <div className="relative text-gray-700">
                  <i className="fas fa-lock absolute mt-3 ml-4 text-gray-300 focus:text-green-600"></i>
                  <input
                    className="w-full h-10 pl-10 pr-8 text-base placeholder-gray-300 border-1 border-gray-300 rounded-lg focus:border-green-600 focus:ring-1 focus:ring-green-600"
                    placeholder="Konfirmasi Password"
                    type="password"
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </div>
              </div>
              {/* /Form */}

              {/* Divider */}
              <div className="border-b-2 border-dashed mt-5"></div>
              {/* /Divider */}

              {/* Footer Sign In */}
              <div className="flex justify-between items-center mt-5">
                <button
                  type="submit"
                  className="py-2 px-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-3 md:text-md md:px-5 w-full"
                >
                  <If condition={isLoading}>
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                    <Else />
                    Reset Password
                  </If>
                </button>
              </div>
              {/* /Footer Sign In */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <Wrapper>
      {/* Content */}
      <ForgotForm />
    </Wrapper>
  );
};

const ConfirmationPasswordForgot: React.FC = () => {
  return (
    <ConfirmationPasswordController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </ConfirmationPasswordController.Provider>
  );
};

export default ConfirmationPasswordForgot;
