import {
  Box,
  Text,
  Button,
  Image,
  Icon,
  Skeleton,
  Container,
} from "@chakra-ui/react";
import Carousel from "react-multi-carousel";
import React from "react";
import moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { ReactComponent as PintarIcon } from "../../assets/svg/pintar_white.svg";
import CircleIconBottom from "../../assets/svg/circle_event_bottom.svg";
import CircleIconTop from "../../assets/svg/circle_event_top.svg";
import { useEventPintarPublicContext } from "../../controller/public/event_pintar";
import { useEventPintarContext } from "../../controller/admin/landing_page/event_pintar";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

interface EventPinteProps {
  accessFor: string;
}

const EventPintar = ({ accessFor }: EventPinteProps) => {
  const { listData: dataPublic, loadingData: loadingPublic } =
    useEventPintarPublicContext().state;

  const { listData: dataPrivate, loadingData: loadingPrivate } =
    useEventPintarContext().state;

  const listData = accessFor === "public" ? dataPublic : dataPrivate;
  const loadingData = accessFor === "public" ? loadingPublic : loadingPrivate;

  const formatDate = (dateString) => {
    const dateObject = moment(dateString, "YYYY-MM-DD HH:mm:ss");
    return dateObject.format("DD MMM YYYY");
  };

  const sliceHTML = (html, maxLength) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const textContent = doc.body.textContent || "";
    const threeDots = textContent.length > maxLength ? "..." : "";
    return textContent.slice(0, maxLength) + threeDots;
  };

  const renderLoadingSkeleton = () => (
    <Box display="flex" justifyContent="space-between">
      {[1, 2, 3, 4].map((index) => (
        <Skeleton key={index} height="380px" width="280px" />
      ))}
    </Box>
  );

  const renderNoEventData = () => (
    <Box mx="auto" textAlign="center" my={20}>
      <Text fontSize="xl" color="white">
        Belum Ada Event Pintar
      </Text>
    </Box>
  );

  const renderEventCard = (item) => (
    <Box key={item.id} rounded="lg" shadow="lg" mr={4} bg="white" p={4}>
      <Box position="relative">
        <Box position="absolute" top={1} left={2}>
          <Icon as={PintarIcon} boxSize={8} />
        </Box>
        <Image
          src={item.image}
          fallback={<Skeleton w="full" h="full" />}
          alt="Gambar Pelatihan"
          objectFit="cover"
          height="150px"
          rounded="md"
          w="full"
        />
      </Box>
      <Box my={4}>
        <Text fontWeight="medium" fontSize="lg" mb={2} color="black">
          {item.title}
        </Text>
        <Text fontSize="small" color="black">
          {sliceHTML(item.description, 50)}
        </Text>
        <Box display="flex" mt={2} alignItems="center" gap={2}>
          <Icon as={AiOutlineCalendar} mr={2} />
          <Text mr={1}>{formatDate(item?.training_start_date)}</Text>
          <Text mr={1}>s.d</Text>
          <Text>{formatDate(item?.training_end_date)}</Text>
        </Box>
        <Box display="flex" mt={2} alignItems="center" gap={2}>
          <Icon as={IoLocationOutline} mr={2} />
          <Text>{item.location}</Text>
        </Box>
      </Box>
      <Box pb={4}>
        <Button
          color="white"
          bg="green.700"
          rounded="2xl"
          p={2}
          w="full"
          _hover={{ bg: "green.800" }}
          _active={{ bg: "green.800" }}
          onClick={() => {
            window.location.href = `/detail/event-pintar/${item.id}`;
          }}
        >
          Lihat Selengkapnya
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box bg="white">
      <Container maxW="8xl" bg={{ base: "BlueTosca", md: "transparent" }}>
        <Box
          bg={{ base: "transparent", md: "BlueTosca" }}
          w="full"
          position="relative"
          my={{ base: 8, md: 12 }}
          rounded={{ base: "none", md: "lg" }}
          py={{ base: 8, md: 4 }}
          px={{ base: 0, md: 4 }}
        >
          <Image
            src={CircleIconTop}
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            display={{ base: "none", md: "block" }}
          />
          <Image
            src={CircleIconBottom}
            position="absolute"
            bottom={0}
            left={0}
            display={{ base: "none", md: !listData.length ? "none" : "block" }}
          />
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            color="white"
            fontWeight="semibold"
          >
            Event Pintar
          </Text>
          <Text color="white" fontSize="sm">
            Temukan rangkaian event yang menarik dari PINTAR
          </Text>
          <Box my={8}>
            {loadingData ? (
              renderLoadingSkeleton()
            ) : !listData.length ? (
              renderNoEventData()
            ) : (
              <Carousel responsive={responsive}>
                {listData.map((item) => renderEventCard(item))}
              </Carousel>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default EventPintar;
