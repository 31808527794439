import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Wrapper } from "../../components/Wrapper";
import Icon1 from "../../assets/png/asset_kontributor_left.png";
import Icon2 from "../../assets/png/asset_kontributor_right.png";
import { useHistory } from "react-router-dom";

/**
 * A functional component representing the Kontributor page.
 * It displays a call-to-action to become a contributor and a form to check the status of a contribution.
 *
 * @return {JSX.Element} The JSX element representing the Kontributor page.
 * @author Bagas Prasetyadi
 */

const Kontributor = () => {
  const history = useHistory();
  const toast = useToast();

  const [nomorPengajuan, setNomorPengajuan] = React.useState("");

  const handleCheckNomorPengajuan = () => {
    if (nomorPengajuan === "") {
      toast({
        title: "Nomor pengajuan tidak boleh kosong",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }
    history.push(`/kontributor/cek-status-pengajuan/${nomorPengajuan}`);
  };
  return (
    <Wrapper>
      <Box w="full" bg="#2E81F7">
        <Box
          position="absolute"
          bottom="250px"
          right={0}
          h="250px"
          w="250px"
          bg="transparent"
          display={{ base: "none", md: "block" }}
          backgroundImage={Icon2}
        ></Box>
        <Box
          position="absolute"
          bottom="250px"
          left={-8}
          h="250px"
          w="250px"
          bg="transparent"
          display={{ base: "none", md: "block" }}
          backgroundImage={Icon1}
        ></Box>
        <Box my={24} mx={{ base: 4, md: "auto" }} maxW="3xl" textAlign="center">
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            color="white"
            fontWeight="semibold"
            lineHeight={{ base: "36px", md: "58px" }}
          >
            Kontribusi Anda, Kemajuan Pendidikan. <br /> Daftar Menjadi
            Kontributor di Pintar Kemenag
          </Heading>
          <Heading
            as="h3"
            fontSize="xl"
            color="white"
            mt={4}
            fontWeight="normal"
            lineHeight="32px"
          >
            Dengan menjadi kontributor, Anda dapat membantu menciptakan materi
            pelatihan yang inovatif dan pengetahuan yang bermanfaat bagi semua.
          </Heading>
          <Flex w="full" mt={4} justifyContent="center">
            <Flex w="70%" justifyContent="center" alignItems="center">
              <Button
                colorScheme="orange"
                onClick={() => history.push("/kontributor/pengajuan")}
              >
                Daftar Kontribusi
              </Button>
              <InputGroup size="md" ml={3}>
                <Input
                  pr="4.5rem"
                  type="text"
                  placeholder="Masukkan Nomor Pengajuan"
                  bg="white"
                  onChange={(e) => setNomorPengajuan(e.target.value)}
                />
                <InputRightElement width="4.5rem" mr={2}>
                  <Button
                    h="1.75rem"
                    size="sm"
                    bg="green.700"
                    _hover={{ bg: "green.800" }}
                    color="white"
                    onClick={handleCheckNomorPengajuan}
                  >
                    Cek
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Kontributor;
