import { KategoriPengetahuanRepositoryInterface } from "../../../data/contracts/KategoriPengetahuan";
import { KategoriPengetahuanDataMapper } from "../../../data/mappers/KategoriPengetahuan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { KategoriPengetahuan } from "../../../entities/KategoriPengetahuan";

export class KategoriPengetahuanApiRepository
  implements KategoriPengetahuanRepositoryInterface
{
  private apiService: ApiService;
  private mapper: KategoriPengetahuanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: KategoriPengetahuanDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllKategoriPengetahuan(
    page: number,
    rows: number,
    keyword: string
  ): Promise<KategoriPengetahuan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.kategoriPengetahuan(),
      {
        page: page,
        rows: rows,
        keyword: keyword,
      },
      null,
      headers
    );
    return this.mapper.convertListKategoriPengetahuanDataFromApi(dataResponse);
  }

  public async storeKategoriPengetahuan(
    nama: string,
    icon: File
  ): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("nama", nama);
    formData.append("path_icon", icon);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.kategoriPengetahuan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateKategoriPengetahuan(
    id: string,
    nama: string,
    icon: File | string
  ): Promise<any> {
    const headers = new Map<string, string>();
    const formData = new FormData();
    formData.append("nama", nama);
    formData.append("path_icon", icon);

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.kategoriPengetahuan() + "/" + id,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteKategoriPengetahuan(id: string): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.kategoriPengetahuan() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
