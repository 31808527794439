import { ExamRepositoryInterface } from "../../../data/contracts/Exam";
import { ExamDataMapper } from "../../../data/mappers/Exam";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Exam } from "../../../entities/Exam";

export class ExamApiRepository implements ExamRepositoryInterface {
  private apiService: ApiService;
  private mapper: ExamDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: ExamDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getLatihan(latihan_id: number): Promise<Exam> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.latihanPeserta() + "/" + latihan_id,
      {},
      null,
      headers
    );
    return this.mapper.convertListExamData(dataResponse);
  }

  public async startLatihan(latihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihanPeserta() + "/start",
      {
        latihan_id: latihan_id,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async finishLatihan(latihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihanPeserta() + "/finish",
      {
        latihan_id: latihan_id,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async answerLatihan(
    soal_id: number,
    id_jawaban_peserta: number,
    jawaban_peserta: string
  ): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihanPeserta() + "/answer",
      {
        soal_id: soal_id,
        id_jawaban_peserta: id_jawaban_peserta,
        jawaban_peserta: jawaban_peserta,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async updateWaktu(latihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihanPeserta() + "/update_waktu",
      {
        latihan_id: latihan_id,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async reattemptLatihan(latihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.latihanPeserta() + "/reattempt",
      {
        latihan_id: latihan_id,
      },
      null,
      headers
    );
    return dataResponse;
  }
}
