import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import axiosInstance from "../../../../api/axiosInstance";
import env from "@ludovicm67/react-dotenv";

interface EditorProps {
  description: string;
  setDescription: Function;
  keyState?: string;
  bgColor?: string;
}

/**
 * Render the Editor component.
 *
 * @param {EditorProps} props - The props for the Editor component.
 * @param {string} props.description - The description value.
 * @param {Function} props.setDescription - The function to set the description value.
 * @return {JSX.Element} The rendered Editor component.
 * @author Bagas Prasetyadi
 */

const EditorComponent = ({
  description,
  setDescription,
  keyState = "description",
  bgColor = "#F6F5F8",
}: EditorProps) => {
  return (
    <Editor
      value={description}
      apiKey={env.REACT_APP_TINYMCE_API_KEY}
      init={{
        height: "300px",
        menubar: false,
        plugins: [
          "advlist autolink lists link charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount image",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | help | image",
        content_style: `body { background-color: ${bgColor} }`,
        images_upload_handler: async function (
          blobInfo: any,
          success: any,
          failure: any
        ) {
          let imageFile = new FormData();
          imageFile.append("image", blobInfo.blob());
          try {
            const response = await axiosInstance.post(
              "/public/upload",
              imageFile
            );
            success(response.data.data);
          } catch (error) {
            failure(error);
          }
        },
      }}
      onEditorChange={(val) => {
        setDescription(keyState, val);
      }}
    />
  );
};

export default EditorComponent;
