export class FinalProject{
    id: number
    sub_modul_pelatihan_id: string
    panduan_pengerjaan : string
    path_panduan : string | null
    created_at: string
    updated_at: string
    constructor(
        id: number,
        sub_modul_pelatihan_id: string,
        panduan_pengerjaan : string,
        path_panduan : string | null,
        created_at: string,
        updated_at: string
    ) {
      this.id = id;
      this.sub_modul_pelatihan_id = sub_modul_pelatihan_id;
      this.panduan_pengerjaan = panduan_pengerjaan;
      this.path_panduan = path_panduan;
      this.created_at = created_at;
      this.updated_at = updated_at;
    }
  }