/* eslint-disable no-useless-escape */
import React from "react";
import { container } from "tsyringe";
import { UsersPresenter } from "../../../../../data/presenters/UsersPresenter";
import { detailUser } from "../../../../../entities/User";
import history from "../../../../../history";
import { toast } from "react-toastify";
import { useIsAuthenticated } from "react-auth-kit";
import { SetLocalStorage } from "../../../../misc/UseLocalStorage";

interface IState {
  isLoading: boolean;
  isLoadingPhoto: boolean;
  isLoadingUpdateProfileSimpeg: boolean;
  isLoadingUpdateProfile: boolean;
  dataUser: detailUser | null;
  name: string;
  email: string;
  path_photo: any;
  foto_user: string;
  pangkat: string;
  golongan: string;
  jabatan: string;
  instansi: string;
  tempat_tugas: string;
  nik: string;
  jenis_kelamin: string;
  tempat_lahir: string;
  tanggal_lahir: string;
  provinsi_id: number;
  kabupaten_id: number;
  kecamatan_id: number;
  kelurahan_id: number;
  alamat: string;
  no_hp: string;
  status_pengisian: boolean;
  oldPassword: string;
  password: string;
  confirmPassword: string;
  error: boolean;
  msgError: string;
  errorArray: any[];
  showModalUploadFoto: boolean;
  agama: string;
  status_kawin: string;
  status_pegawai: string;
  dataError: any[];
  isUserSSO: number;
  isUpdateProfileSSO: number;
}

interface InitialState {
  state: IState;
  getDetailUser: Function;
  updateProfile: Function;
  updatePassword: Function;
  updatePhoto: Function;
  setName: Function;
  setEmail: Function;
  setPathPhoto: Function;
  setPangkat: Function;
  setGolongan: Function;
  setJabatan: Function;
  setInstansi: Function;
  setTempatTugas: Function;
  setNik: Function;
  setJenisKelamin: Function;
  setTempatLahir: Function;
  setTanggalLahir: Function;
  setProvinsi: Function;
  setKabupaten: Function;
  setKecamatan: Function;
  setKelurahan: Function;
  setAlamat: Function;
  setNoHp: Function;
  setOldPassword: Function;
  setPassword: Function;
  setConfirmPassword: Function;
  checkSession: Function;
  setShowModalUploadFoto: Function;
  setAgama: Function;
  setStatusKawin: Function;
  setStatusPegawai: Function;
  updateProfileSimpeg: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingPhoto: false,
    isLoadingUpdateProfileSimpeg: false,
    isLoadingUpdateProfile: false,
    dataUser: null,
    name: "",
    email: "",
    path_photo: null,
    foto_user: "",
    pangkat: "",
    golongan: "",
    jabatan: "",
    instansi: "",
    tempat_tugas: "",
    nik: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    provinsi_id: 0,
    kabupaten_id: 0,
    kecamatan_id: 0,
    kelurahan_id: 0,
    alamat: "",
    no_hp: "",
    status_pengisian: false,
    oldPassword: "",
    password: "",
    confirmPassword: "",
    error: false,
    msgError: "",
    errorArray: [],
    showModalUploadFoto: false,
    agama: "",
    status_kawin: "",
    status_pegawai: "",
    dataError: [],
    isUserSSO: 0,
    isUpdateProfileSSO: 0,
  },
  getDetailUser: () => {},
  updateProfile: () => {},
  updatePassword: () => {},
  updatePhoto: () => {},
  setName: () => {},
  setEmail: () => {},
  setPathPhoto: () => {},
  setPangkat: () => {},
  setGolongan: () => {},
  setJabatan: () => {},
  setInstansi: () => {},
  setTempatTugas: () => {},
  setNik: () => {},
  setJenisKelamin: () => {},
  setTempatLahir: () => {},
  setTanggalLahir: () => {},
  setProvinsi: () => {},
  setKabupaten: () => {},
  setKecamatan: () => {},
  setKelurahan: () => {},
  setAlamat: () => {},
  setNoHp: () => {},
  setOldPassword: () => {},
  setPassword: () => {},
  setConfirmPassword: () => {},
  checkSession: () => {},
  setShowModalUploadFoto: () => {},
  setAgama: () => {},
  setStatusKawin: () => {},
  setStatusPegawai: () => {},
  updateProfileSimpeg: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: UserProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingPhoto: false,
    isLoadingUpdateProfileSimpeg: false,
    isLoadingUpdateProfile: false,
    dataUser: null,
    name: "",
    email: "",
    path_photo: null,
    foto_user: "",
    pangkat: "",
    golongan: "",
    jabatan: "",
    instansi: "",
    tempat_tugas: "",
    nik: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    provinsi_id: 0,
    kabupaten_id: 0,
    kecamatan_id: 0,
    kelurahan_id: 0,
    alamat: "",
    no_hp: "",
    status_pengisian: false,
    oldPassword: "",
    password: "",
    confirmPassword: "",
    error: false,
    msgError: "",
    errorArray: [],
    showModalUploadFoto: false,
    agama: "",
    status_kawin: "",
    status_pegawai: "",
    dataError: [],
    isUserSSO: 0,
    isUpdateProfileSSO: 0,
  });

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingPhoto = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingPhoto: value,
    }));
  };

  const setLoadingUpdateProfileSimpeg = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingUpdateProfileSimpeg: value,
    }));
  };

  // Get Detail User
  const getDetailUser = async () => {
    setLoading(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const getDetail = await usersPresenter.getUser();

      const data: detailUser = getDetail[0];

      setState((prevstate) => ({
        ...prevstate,
        // dataUser: data,
        name: data.nama_lengkap,
        email: data.email,
        pangkat: data.pangkat,
        golongan: data.golongan,
        jabatan: data.jabatan,
        instansi: data.instansi,
        tempat_tugas: data.tempat_tugas,
        nik:
          data.is_user_sso === 1 && data.is_update_profile === 0
            ? ""
            : data.nik,
        jenis_kelamin: data.jenis_kelamin,
        tempat_lahir: data.tempat_lahir,
        tanggal_lahir: data.tanggal_lahir,
        provinsi_id: data.provinsi_id,
        kabupaten_id: data.kabupaten_id,
        kecamatan_id: data.kecamatan_id,
        kelurahan_id: data.kelurahan_id,
        alamat: data.alamat,
        no_hp: data.no_hp,
        status_pengisian: data.status_pengisian,
        foto_user: data.path_foto,
        agama: data.agama,
        status_kawin: data.status_kawin,
        status_pegawai: data.status_pegawai,
        isUserSSO: data.is_user_sso,
        isUpdateProfileSSO: data.is_update_profile,
      }));

      // set foto profile
      SetLocalStorage({
        name: "path_photo",
        value: data.path_foto,
      });

      // set status_pengisian
      SetLocalStorage({
        name: "status_pengisian",
        value: data.status_pengisian,
      });

      setLoading(false);
      return data;
    } catch (error: any) {
      console.log("error detail user:", error);
      setLoading(false);
    }
  };

  // Update Profile
  const updateProfile = async () => {
    setState((prev) => ({
      ...prev,
      isLoadingUpdateProfile: true,
    }));

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!state.email || regex.test(state.email) === false) {
      toast.error(
        "Email yang di masukan tidak valid! Mohon cek kembali email anda.",
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        }
      );
      setState((prev) => ({
        ...prev,
        dataError: [
          ...state.dataError,
          {
            name: "email",
            message:
              "Email yang di masukan tidak valid! Mohon cek kembali email anda.",
          },
        ],
      }));
    }

    if (state.foto_user === "") {
      toast.error("Anda Belum Mengupload Foto Profile!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setState((prev) => ({
        ...prev,
        isLoadingUpdateProfile: false,
      }));
    }

    try {
      const usersPresenter = container.resolve(UsersPresenter);

      // update profile
      await usersPresenter.updateProfile(
        state.name,
        state.email,
        state.pangkat,
        state.golongan,
        state.jabatan,
        state.instansi,
        state.tempat_tugas,
        state.nik,
        state.jenis_kelamin,
        state.tempat_lahir,
        state.tanggal_lahir,
        state.provinsi_id,
        state.kabupaten_id,
        state.kecamatan_id,
        state.kelurahan_id,
        state.alamat,
        state.no_hp,
        state.agama,
        state.status_kawin,
        state.status_pegawai
      );

      toast.success("Berhasil merubah profile!", {
        style: {
          boxShadow: "0px 1px 6px #019166",
        },
      });

      setState((prevstate) => ({
        ...prevstate,
        errorArray: [],
      }));

      setState((prevstate) => ({
        ...prevstate,
        dataError: [],
      }));

      setState((prev) => ({
        ...prev,
        isLoadingUpdateProfile: false,
      }));

      getDetailUser();
    } catch (error: any) {
      console.log("error profile:", error);
      setState((prevstate) => ({
        ...prevstate,
        errorArray: [error.response.data.errors],
      }));
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setState((prev) => ({
        ...prev,
        isLoadingUpdateProfile: false,
      }));
    }
  };

  // Update Password
  const updatePassword = async () => {
    setLoading(true);

    if (state.oldPassword === null) {
      toast.error("Anda harus mengisi password lama anda!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    if (state.password !== state.confirmPassword) {
      toast.error(
        "Konfirmasi password tidak sesuai dengan password yang anda masukkan!",
        {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        }
      );
      setLoading(false);
      return;
    }

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const store = await usersPresenter.updatePassword(
        state.oldPassword,
        state.password
      );
      const data = store;

      if (data.data.code === 200) {
        toast.success("Berhasil Mengubah Password.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/profile`);
        window.location.reload();
      } else {
        toast.error("Ada Kesalahan Saat Mengubah Password!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoading(false);
    } catch (error: any) {
      console.log("error profile:", error);
      toast.error("Ada Kesalahan Saat Mengubah Password!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  // Update Foto Profile
  const updatePhoto = async (path_photo: File) => {
    setLoadingPhoto(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const store = await usersPresenter.updatePhoto(path_photo);
      const data = store;

      if (data.data.code === 200) {
        toast.success("Berhasil Upload Foto!", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setState((prevstate) => ({
          ...prevstate,
          foto_user: data.data.data.path_foto,
        }));

        SetLocalStorage({ name: "path_photo", value: data.data.path_photo });
      } else {
        toast.error("Ada Kesalahan Saat Mengubah Foto!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingPhoto(false);
      setShowModalUploadFoto(false);
    } catch (error: any) {
      console.log("error photo upload:", error);
      toast.error("Ada Kesalahan Saat Mengubah Foto!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingPhoto(false);
    }
  };

  // Update profile simpeg
  const updateProfileSimpeg = async () => {
    setLoadingUpdateProfileSimpeg(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const store = await usersPresenter.updateProfileSimpeg();
      const getDetail = store;

      if (getDetail.length > 0) {
        toast.success("Berhasil Sync Data Ke Simpeg!", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });

        const data: detailUser = getDetail[0];

        setState((prevstate) => ({
          ...prevstate,
          dataUser: data,
          name: data.nama_lengkap,
          email: data.email,
          pangkat: data.pangkat,
          golongan: data.golongan,
          jabatan: data.jabatan,
          instansi: data.instansi,
          tempat_tugas: data.tempat_tugas,
          nik: data.nik,
          jenis_kelamin: data.jenis_kelamin,
          tempat_lahir: data.tempat_lahir,
          tanggal_lahir: data.tanggal_lahir,
          provinsi_id: data.provinsi_id,
          kabupaten_id: data.kabupaten_id,
          kecamatan_id: data.kecamatan_id,
          kelurahan_id: data.kelurahan_id,
          alamat: data.alamat,
          no_hp: data.no_hp,
          status_pengisian: data.status_pengisian,
          foto_user: data.path_foto,
          agama: data.agama,
          status_kawin: data.status_kawin,
          status_pegawai: data.status_pegawai,
        }));

        setLoadingUpdateProfileSimpeg(false);

        getDetailUser();
      } else {
        toast.error("Ada Kesalahan Saat Sync Data Ke Simpeg!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingUpdateProfileSimpeg(false);
    } catch (error) {
      console.log("error update profile simpeg:", error);
      toast.error("Ada Kesalahan Saat Sync Data Ke Simpeg!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingUpdateProfileSimpeg(false);
    }
  };

  const setName = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      name: value,
    }));
  };

  const setEmail = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      email: value,
    }));
  };

  const setAgama = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      agama: value,
    }));
  };

  const setStatusKawin = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      status_kawin: value,
    }));
  };

  const setPathPhoto = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_photo: value[0],
    }));
  };

  const setPangkat = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      pangkat: value,
    }));
  };

  const setGolongan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      golongan: value,
    }));
  };

  const setAlamat = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      alamat: value,
    }));
  };

  const setInstansi = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      instansi: value,
    }));
  };

  const setJabatan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jabatan: value,
    }));
  };

  const setJenisKelamin = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jenis_kelamin: value,
    }));
  };

  const setKabupaten = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      kabupaten_id: value,
    }));
  };

  const setProvinsi = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      provinsi_id: value,
    }));
  };

  const setKecamatan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      kecamatan_id: value,
    }));
  };

  const setKelurahan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      kelurahan_id: value,
    }));
  };

  const setNik = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      nik: value,
    }));
  };

  const setNoHp = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      no_hp: value,
    }));
  };

  const setTempatLahir = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      tempat_lahir: value,
    }));
  };

  const setStatusPegawai = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      status_pegawai: value,
    }));
  };

  const setTanggalLahir = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      tanggal_lahir: value,
    }));
  };

  const setTempatTugas = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      tempat_tugas: value,
    }));
  };

  const setOldPassword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      oldPassword: value,
    }));
  };

  const setPassword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      password: value,
    }));
  };

  const setConfirmPassword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      confirmPassword: value,
    }));
  };

  const setShowModalUploadFoto = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalUploadFoto: value,
    }));
  };

  const checkSession = async () => {
    if (isAuthenticated() !== false) {
      history.replace(`/`);
      window.location.reload();
    } else {
      history.replace("/login");
      window.location.reload();
      toast.error("Session anda telah habis, silahkan login kembali!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  return (
    <UserProvider
      value={{
        state,
        getDetailUser,
        updateProfile,
        updatePassword,
        updatePhoto,
        setName,
        setEmail,
        setPangkat,
        setGolongan,
        setAlamat,
        setInstansi,
        setJabatan,
        setJenisKelamin,
        setKabupaten,
        setKecamatan,
        setKelurahan,
        setNik,
        setNoHp,
        setProvinsi,
        setTanggalLahir,
        setTempatLahir,
        setTempatTugas,
        setPathPhoto,
        setOldPassword,
        setPassword,
        setConfirmPassword,
        checkSession,
        setShowModalUploadFoto,
        setAgama,
        setStatusKawin,
        setStatusPegawai,
        updateProfileSimpeg,
      }}
    >
      {children}
    </UserProvider>
  );
};

export const useUserContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useUserContext,
  Provider,
};
