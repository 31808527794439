import React from "react";
import { container } from "tsyringe";
import { AuthPresenter } from "../../../../../data/presenters/AuthPresenter";
import history from "../../../../../history";
import { toast } from "react-toastify";

interface IState {
  isLoading: boolean;
  password: string;
  password_confirmation: string;
  error: boolean;
  msgError: any[];
}

interface InitialState {
  state: IState;
  setPassword: Function;
  setPasswordConfirmation: Function;
  handleResetPassword: Function;
}

const initialState = {
  state: {
    isLoading: false,
    password: "",
    password_confirmation: "",
    error: false,
    msgError: [],
  },
  setPassword: () => {},
  setPasswordConfirmation: () => {},
  handleResetPassword: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ConfirmationPasswordProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    password: "",
    password_confirmation: "",
    error: false,
    msgError: [],
  });

  /**
   *
   * @param value
   * @returns
   */
  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  /**
   *
   * @param value
   * @returns
   */
  const setPassword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      password: value,
    }));
  };

  const setPasswordConfirmation = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      password_confirmation: value,
    }));
  };

  /**
   *
   * @param token
   * @returns
   */
  const handleResetPassword = async (token: string) => {
    setLoading(true);
    try {
      const authPresenter = container.resolve(AuthPresenter);
      await authPresenter.resetPassword(
        token,
        state.password,
        state.password_confirmation
      );

      toast.success(
        "Berhasil merubah password anda, silahkan login dengan password yang baru",
        {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        }
      );

      setLoading(false);
      history.replace("/login");
      window.location.reload();
    } catch (error: any) {
      console.log(error.response);
      toast.error("Terdapat error saat reset password!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setState((prevstate) => ({
        ...prevstate,
        msgError: error.response.data.errors?.password,
      }));
      setLoading(false);
      return;
    }
  };

  return (
    <ConfirmationPasswordProvider
      value={{
        state,
        setPassword,
        setPasswordConfirmation,
        handleResetPassword,
      }}
    >
      {children}
    </ConfirmationPasswordProvider>
  );
};

export const useConfirmationPassword = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useConfirmationPassword,
  Provider,
};
