import {AxiosResponse} from "axios";
import {JenisPelatihan} from "../../entities/JenisPelatihan";

export class JenisPelatihanDataMapper {
    public convertJenisPelatihanDataFromApi(
        result: AxiosResponse<any>
    ): JenisPelatihan[] {
        const {data} = result.data;

        let jenisPelatihan: JenisPelatihan[] = [];

        data.map((jp: any) => {
            return jenisPelatihan.push(
                new JenisPelatihan(
                    jp.id,
                    jp.sub_menu_jenis_pelatihan_id,
                    jp.kode,
                    jp.nama_pelatihan,
                    jp.kompetensi,
                    jp.jp,
                    jp.keterangan,
                    jp.created_at,
                    jp.updated_at,
                    result.data.pagination.total_rows,
                    jp.metode_penambahan
                )
            );
        });

        return jenisPelatihan;
    }

    public convertDetailJenisPelatihanDataFromApi(
        result: AxiosResponse<any>
    ): JenisPelatihan {
        const {data} = result.data;

        let jenisPelatihan: JenisPelatihan;

        jenisPelatihan = new JenisPelatihan(
            data.id,
            data.sub_menu_jenis_pelatihan_id,
            data.kode,
            data.nama_pelatihan,
            data.kompetensi,
            data.jp,
            data.keterangan,
            data.created_at,
            data.updated_at,
            0,
            data.metode_penambahan
        );

        return jenisPelatihan;
    }
}
