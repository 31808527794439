import { injectable } from 'tsyringe'
import { ReviewMateriApiRepository } from '../../app/repository/api/ReviewMateriApiRepository'
import { ReviewMateri } from '../../entities/ReviewMateri'

@injectable()
export class ReviewMateriPresenter {
  private repository: ReviewMateriApiRepository
  constructor(repository: ReviewMateriApiRepository) {
    this.repository = repository
  }

  /**
   *
   * @param page
   * @param rows
   * @param keyword
   * @param materi_id
   * @param rating
   * @param foto
   * @returns
   */
  public getReviewMateri(
    page: number,
    rows: number,
    keyword: string,
    materi_id: number,
    rating: string,
    foto: boolean,
  ): Promise<ReviewMateri[]> {
    return this.repository.getReviewMateri(
      page,
      rows,
      keyword,
      materi_id,
      rating,
      foto,
    )
  }

  /**
   *
   * @param materi_id
   * @param komentar
   * @param path_foto_review_pelatihan
   * @param rating
   * @returns
   */
  public storeReviewMateri(
    materi_id: number,
    komentar: string,
    path_foto_review_pelatihan: any[],
    rating: number,
  ): Promise<any> {
    return this.repository.storeReviewMateri(
      materi_id,
      komentar,
      path_foto_review_pelatihan,
      rating,
    )
  }
}
