import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface InfografisItem {
  id: number;
  title: string;
  image: string;
  thumbnail_cover: string;
  description: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}
interface IState {
  state: {
    loadingData: boolean;
    infografisData: InfografisItem[];
    url: string;
  };
  setInfografisState: Function;
  getInfografis: Function;
}

const initialState: IState = {
  state: {
    loadingData: false,
    infografisData: [],
    url: "public/infografis",
  },
  setInfografisState: () => {},
  getInfografis: () => {},
};

/**
 * The InfografisPublicContext provides a context for managing the state related to infografis in landing page.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: InfografisPublicProvider } = Context;

/**
 * The provider component that wraps its children with the InfografisPublicContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */
const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const { url } = state.state;

  const setInfografisState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const getInfografis = async () => {
    setInfografisState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: "1",
        },
      });

      setInfografisState("infografisData", response.data?.data);
      setInfografisState("loadingData", false);
    } catch (error) {
      setInfografisState("loadingData", false);
      throw error;
    }
  };

  return (
    <InfografisPublicProvider
      value={{
        state: state.state,
        setInfografisState,
        getInfografis,
      }}
    >
      {children}
    </InfografisPublicProvider>
  );
};

export const useInfografisPublicContext = () => useContext(Context);

const InfografisPublicContext = {
  useInfografisPublicContext,
  Provider,
};

export default InfografisPublicContext;
