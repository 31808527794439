import React from 'react'

const NotFound: React.FC = () => {
  return (
    <div
        className="
            flex
            items-center
            justify-center
            w-screen
            h-screen
            bg-gradient-to-r
            from-green-600
            to-green-400
        "
        >
        <div className="px-40 py-20 bg-white rounded-md shadow-xl">
            <div className="flex flex-col items-center">
            <h1 className="font-bold text-green-600 text-9xl">404</h1>

            <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                <span className="text-red-500">Oops!</span> Halaman Tidak Ditemukan
            </h6>

            <p className="mb-8 text-center text-gray-500 md:text-lg">
            Halaman yang Anda cari tidak ada.
            </p>

            <a
                href="/"
                className="px-6 py-2 text-sm font-semibold text-green-800 bg-green-100"
                >Kembali</a
            >
            </div>
        </div>
        </div>
  )
}

const WrapperMain: React.FC = () => {
  return (
    <NotFound/>
  )
}

const NotFoundPage: React.FC = () => {
  return <WrapperMain />
}

export default NotFoundPage
