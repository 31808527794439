export class JawabanSoal {
  id: number
  soal_id: number
  pilihan: string
  jawaban_benar: number

  constructor(
    id: number,
    soal_id: number,
    pilihan: string,
    jawaban_benar: number
  ) {
    this.id = id;
    this.soal_id = soal_id;
    this.pilihan = pilihan;
    this.jawaban_benar = jawaban_benar;
  }
}