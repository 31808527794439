import { AxiosResponse } from "axios";
import { Slider } from "../../entities/Slider";

export class SliderDataMapper {
  public convertSliderDataFromApi(result: AxiosResponse<any>): Slider[] {
    const { data } = result.data;

    let slider: Slider[] = [];

    data.map((s: any) => {
      return slider.push(
        new Slider(
          s.id,
          s.nama_user_testimoni,
          s.testimoni,
          s.jabatan_user_testimoni,
          s.path_slider,
          s.status_publish,
          s.created_at,
          s.updated_at,
          result.data.pagination.total_rows
        )
      );
    });

    return slider;
  }
}
