import { Exam, MSoal } from "../../entities/Exam";
import { AxiosResponse } from "axios";

export class UjianDataMapper {
  public convertListUjianData(result: AxiosResponse<any>): Exam {
    const { data } = result.data;

    let m_soal: any = [];

    data.soal.map((u: any) => {
      // Push MSoal
      return m_soal.push(
        new MSoal(
          u.id,
          u.paket_soal_id,
          u.soal,
          u.id_jawaban_ujian,
          u.jawaban_peserta,
          u.created_at,
          u.updated_at,
          u.m_jawaban_soal
        )
      );
    });

    let exam: Exam = new Exam(
      data.ujian.judul,
      data.ujian.durasi_latihan,
      data.ujian.passing_grade,
      data.ujian.sisa_waktu,
      data.ujian.nilai,
      data.ujian.status,
      m_soal,
      data?.latihan?.sequence ?? "",
      data?.latihan?.total_sequence ?? ""
    );

    return exam;
  }
}
