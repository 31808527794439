import { SimdiklatInterface } from "../../../data/contracts/Simdiklat";
import { SimdiklatDataMapper } from "../../../data/mappers/Simdiklat";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  AgamaMasterSimdiklat,
  JabatanMasterSimdiklat,
  PangkatMasterSimdiklat,
  PelatihanSimdiklat,
  StatusKawinMasterSimdiklat,
  StatusPegawaiMasterSimdiklat,
} from "../../../entities/Simdiklat";
import { LogTTE } from "../../../entities/LogTTE";

export class SimdiklatApiRepository implements SimdiklatInterface {
  private apiService: ApiService;
  private mapper: SimdiklatDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: SimdiklatDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   *
   * @param page
   * @param limit
   * @returns
   */
  public async getPelatihan(
    page: number,
    limit: number
  ): Promise<PelatihanSimdiklat[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/pelatihan",
      {
        mode: "mooc-pintar",
        page: page,
        limit: limit,
      },
      null,
      headers
    );

    return this.mapper.convertPelatihanSimdiklatDataFromApi(dataResponse);
  }

  /**
   *
   * @param id
   * @returns
   */
  public async getPelatihanById(id: number): Promise<PelatihanSimdiklat> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/pelatihan",
      {
        mode: "mooc-pintar",
        id: id,
      },
      null,
      headers
    );

    return this.mapper.convertPelatihanDetailSimdiklatDataFromApi(dataResponse);
  }

  /**
   *
   * @returns
   */
  public async getMasterPangkat(): Promise<PangkatMasterSimdiklat[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/master_data/pangkat",
      {},
      null,
      headers
    );

    return this.mapper.convertMasterDataPangkatFromApi(dataResponse);
  }

  /**
   *
   * @returns
   */
  public async getMasterJabatan(): Promise<JabatanMasterSimdiklat[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/master_data/jabatan",
      {},
      null,
      headers
    );

    return this.mapper.convertMasterDataJabatanFromApi(dataResponse);
  }

  /**
   *
   * @returns
   */
  public async getMasterAgama(): Promise<AgamaMasterSimdiklat[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/master_data/agama",
      {},
      null,
      headers
    );

    return this.mapper.convertMasterDataAgamaFromApi(dataResponse);
  }

  /**
   *
   * @returns
   */
  public async getMasterStatusKawin(): Promise<StatusKawinMasterSimdiklat[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/master_data/status_kawin",
      {},
      null,
      headers
    );

    return this.mapper.convertMasterDataStatusKawinFromApi(dataResponse);
  }

  /**
   *
   * @returns
   */
  public async getMasterStatusPegawai(): Promise<
    StatusPegawaiMasterSimdiklat[]
  > {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.simdiklatAPI() + "/master_data/status_pegawai",
      {},
      null,
      headers
    );

    return this.mapper.convertMasterDataStatusKawinFromApi(dataResponse);
  }

  /**
   *
   * @param page
   * @param rows
   * @returns
   */
  public async logDataAlumniSimdiklat(
    page: number,
    rows: number
  ): Promise<LogTTE[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.logDataAlumniSimdiklat(),
      {
        page: page,
        rows: rows,
      },
      null,
      headers
    );
    return this.mapper.convertListLogTTEData(dataResponse);
  }

  /**
   *
   * @param pelatihan_id
   * @returns
   */
  public async kirimDataAlumniSimdiklat(pelatihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const formData = new FormData();
    formData.append("pelatihan_id", pelatihan_id.toString());

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.simdiklatAPI() + "/data_alumni",
      {},
      formData,
      headers
    );

    return dataResponse;
  }
}
