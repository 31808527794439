import { HelpDeskBantuanPublicRepository } from "../../../data/contracts/HelpDeskBantuanPublic";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class HelpDeskBantuanPublicApiRepository
  implements HelpDeskBantuanPublicRepository
{
  private apiService: ApiService;
  private endpoints: Endpoints;

  constructor(apiService: ApiService, endpoints: Endpoints) {
    this.apiService = apiService;
    this.endpoints = endpoints;
  }

  /**
   * storeHelpDeskBantuan
   */
  public async storeHelpDeskBantuan(
    nama: string,
    email: string,
    subject: string,
    asal_kantor: string,
    nip: string,
    pelatihan_diikuti: string,
    pesan: string,
    path_pendukung: File,
    g_recaptcha_response: string
  ): Promise<any> {
    const headers = new Map<string, string>();
    let formData = new FormData();
    formData.append("nama", nama);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("asal_kantor", asal_kantor);
    formData.append("nip", nip);
    formData.append("pelatihan_diikuti", pelatihan_diikuti);
    formData.append("pesan", pesan);
    formData.append("path_pendukung", path_pendukung);
    formData.append("g-recaptcha-response", g_recaptcha_response);
    const dataResponse = await this.apiService.invoke(
      "post",
      "public/" + this.endpoints.helpdeskBantuan(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }
}
