import { SoalRepositoryInterface } from "../../../data/contracts/Soal";
import { SoalDataMapper } from "../../../data/mappers/Soal";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { Soal } from "../../../entities/PaketSoal";

export class SoalApiRepository implements SoalRepositoryInterface {
  private apiService: ApiService;
  private mapper: SoalDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: SoalDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailSoal(id: number): Promise<Soal> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.soal() + "/" + id,
      {},
      null,
      headers
    );
    return this.mapper.convertDetailSoalDataFromApi(dataResponse);
  }

  public async storeSoal(paket_soal_id: number, soal: string): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("paket_soal_id", paket_soal_id.toString());
    formData.append("soal", soal);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.soal(),
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateSoal(paket_soal_id: number, soal: string): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("soal", soal);
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.soal() + "/" + paket_soal_id,
      null,
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteSoal(id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.soal() + "/" + id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
