import React from "react";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../data/presenters/PelatihanPresenter";
import "react-toastify/dist/ReactToastify.css";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import { SubMenuJenisPelatihan } from "../../../../../entities/MenuJenisPelatihan";
import { MenuJenisPelatihanPresenter } from "../../../../../data/presenters/MenuJenisPelatihanPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  tahun: string;
  jenis_pelatihan_id: string;
  totalPelatihan: number;
  pelatihan: Pelatihan[];
  subMenuJenisPelatihan: SubMenuJenisPelatihan[];
}

interface InitialState {
  state: IState;
  getPelatihan: Function;
  setPage: Function;
  searchPelatihan: Function;
  filterTahun: Function;
  filterJenisPelatihan: Function;
  getSubMenuJenisPelatihan: Function;
  handleSearchPelatihan: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    tahun: "",
    jenis_pelatihan_id: "",
    totalPelatihan: 1,
    pelatihan: [],
    subMenuJenisPelatihan: [],
  },
  getPelatihan: () => {},
  searchPelatihan: () => {},
  setPage: () => {},
  filterTahun: () => {},
  filterJenisPelatihan: () => {},
  getSubMenuJenisPelatihan: () => {},
  handleSearchPelatihan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: PelatihanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    tahun: "",
    jenis_pelatihan_id: "",
    totalPelatihan: 0,
    pelatihan: [],
    subMenuJenisPelatihan: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setLoading(true);

    getPelatihan(
      value + 1,
      state.keyword,
      state.tahun,
      state.jenis_pelatihan_id
    );
    setState((prevstate) => ({
      ...prevstate,
      page: value + 1,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getPelatihan = async (
    pagee: number,
    keyword: string,
    tahun: string,
    jenis_pelatihan_id: string,
    event_id = null
  ) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getAllPelatihan = await pelatihanPresenter.getAllPelatihan(
        pagee,
        12,
        keyword,
        tahun,
        jenis_pelatihan_id,
        event_id
      );

      const data = getAllPelatihan;

      setJumlahPage(data[0].jumlahData / 12);

      setState((prevstate) => ({
        ...prevstate,
        pelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setLoading(false);
    }
  };

  const searchPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
      page: 1,
    }));
    if (state.pelatihan.length > 0) {
      localStorage.removeItem("keywordPelatihan");
    }
  };

  const handleSearchPelatihan = () => {
    localStorage.removeItem("keywordPelatihan");
    setState((prevstate) => ({
      ...prevstate,
      pelatihan: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPelatihan(1, state.keyword, state.tahun, state.jenis_pelatihan_id);
  };

  const filterTahun = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihan: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPelatihan(1, state.keyword, value, state.jenis_pelatihan_id);
    setState((prevstate) => ({
      ...prevstate,
      tahun: value,
      page: 1,
    }));
  };

  const filterJenisPelatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihan: [],
      jumlahPage: 0,
    }));
    setLoading(true);
    getPelatihan(1, state.keyword, state.tahun, value);
    setState((prevstate) => ({
      ...prevstate,
      jenis_pelatihan_id: value,
      page: 1,
    }));
  };

  const getSubMenuJenisPelatihan = async () => {
    try {
      const menuJenisPelatihanPresenter = container.resolve(
        MenuJenisPelatihanPresenter
      );

      const get = await menuJenisPelatihanPresenter.getAllSubMenuJenisPelatihan(
        1,
        10000000,
        ""
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        subMenuJenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  return (
    <PelatihanProvider
      value={{
        state,
        getPelatihan,
        setPage,
        searchPelatihan,
        filterTahun,
        filterJenisPelatihan,
        getSubMenuJenisPelatihan,
        handleSearchPelatihan,
      }}
    >
      {children}
    </PelatihanProvider>
  );
};

export const usePelatihanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  usePelatihanContext,
  Provider,
};
