// 1. Import the extendTheme function
import {
    AlertDialogBody,
    AlertDialogHeader,
    Button,
    extendTheme,
    FormErrorMessage,
    FormLabel,
    Input,
    NumberInputField,
    Select,
    Tab,
    Table,
    Text,
    Textarea,
    ThemeConfig,
    ModalCloseButton,
    Container
  } from "@chakra-ui/react";
  
  // 2. Extend the theme to include custom colors, fonts, etc
  const colors = {
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
    BluePrimary: {
      100: "#F7FAFC",
      200: "#47ABF7",
      500: "#1572E9",
    },
    PurplePrimary: {
      50: "#CEBCFB",
      100: "#593CA5",
      200: "#4D3689",
      300: "#3F2F67",
      400: "#30244F",
    },
    GreenPrimary: {
      50: "#F0FFF4",
      100: "#D7FEE7",
      200: "#86D99E",
      300: "#5ABF7A",
      400: "#3EA162",
      500: "#277E4F",
      600: "#0F8445",
      700: "#276749",
      800: "#025628",
      900: "#00411E",
    },
    YellowPrimary: {
      "50": "#fffef9",
      "100": "#fffad9",
      "200": "#feef88",
      "300": "#fddf1e",
      "400": "#e7cc1b",
      "500": "#bea816",
      "600": "#988612",
      "700": "#77690e",
      "800": "#594e0a",
      "900": "#494109",
    },
    GrayPrimary: {
      50: "#7A8990",
      600: "#828282",
    },
    OrangePrimary: {
      400: "#FFF3E4",
      500: "#FFFBE4",
      600: "#FEBD1E",
    },
    BlackPrimary: {
      200: "rgba(75,75,75,0.38)",
      400: "#2D3748",
      500: "#3C4952",
    },
    WhitePrimary: {
      50: "#F9F9F9",
    },
    GreenSage: "#A9DB8A",
    BlueTosca: "#86D9CE",
    Cream: "#FEEBCB",
  };
  
  const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
  };
  
  FormLabel.defaultProps = { ...FormLabel.defaultProps, fontSize: "13px" };
  Select.defaultProps = { ...Select.defaultProps, fontSize: "12px" };
  Text.defaultProps = { ...Text.defaultProps, fontSize: "12px" };
  Textarea.defaultProps = { ...Textarea.defaultProps, fontSize: "12px" };
  Table.defaultProps = { ...Table.defaultProps, fontSize: "12px" };
  NumberInputField.defaultProps = {
    ...NumberInputField.defaultProps,
    fontSize: "13px",
  };
  FormErrorMessage.defaultProps = {
    ...FormErrorMessage.defaultProps,
    fontSize: "12px",
  };
  Tab.defaultProps = {
    ...Tab.defaultProps,
    fontSize: "14px",
    _focus: { outline: "none" },
  };
  Input.defaultProps = {
    ...Input.defaultProps,
    fontSize: "12px",
  };
  AlertDialogHeader.defaultProps = {
    ...AlertDialogHeader.defaultProps,
    fontSize: "14px",
  };
  AlertDialogBody.defaultProps = {
    ...AlertDialogBody.defaultProps,
    fontSize: "13px",
  };
  Button.defaultProps = {
    ...Button.defaultProps,
    fontSize: "12px",
    h: "38px",
    px: 6,
    _focus: { outline: "none" },
  };
  
  ModalCloseButton.defaultProps = {
    ...ModalCloseButton.defaultProps,
    _focus: { outline: "none" },
  };

  Container.defaultProps = {
    ...Container.defaultProps,
    px: 8
  }
  
  const fonts = {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  };
  
  export const theme = extendTheme({ colors, config, fonts });
  
