import { injectable } from "tsyringe";
import { YoutubeApiRepository } from "../../app/repository/api/YoutubeApiRepository";
import { Youtube } from "../../entities/Youtube";

@injectable()
export class YoutubePresenter {
  private repository: YoutubeApiRepository;
  constructor(repository: YoutubeApiRepository) {
    this.repository = repository;
  }

  public getAllYoutubeVideo(): Promise<Youtube[]> {
    return this.repository.getAllVideo();
  }
}
