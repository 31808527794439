import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
  FormLabel,
  Input,
  Text,
  VisuallyHidden,
  Divider,
  Image,
  Flex,
} from "@chakra-ui/react";
import { BsInfoCircleFill } from "react-icons/bs";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useRegisterContext } from "../../controller/auth/register";
import PasFotoPria from "../../assets/png/past_photo_01.png";
import PasFotoWanita from "../../assets/png/past_photo_02.png";
import { resizeImage } from "../../../misc/ImageHelper";

interface ModalUploadImageProps {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Component to display an error message.
 *
 * @param {object} props - Component props
 * @param {string} props.error - The error message to display
 * @returns {JSX.Element} Error message component
 */

const ErrorMessage = ({ error }) => {
  return (
    <>
      {error !== "" && (
        <Alert status="error" borderRadius="md" p={4} mb={4} fontSize="sm">
          <AlertIcon as={AiOutlineExclamationCircle} boxSize={5} mr={3} />
          <Box>
            <AlertTitle>Perhatian!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Box>
        </Alert>
      )}
    </>
  );
};

/**
 * Component for the modal to upload an image.
 *
 * @param {object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open or not
 * @param {Function} props.onClose - Function to close the modal
 * @returns {JSX.Element} ModalUploadImage component
 */

const ModalUploadImage = ({ isOpen, onClose }: ModalUploadImageProps) => {
  const { handleUploadImage } = useRegisterContext();
  const [fileImage, setFileImage] = React.useState<File | null>(null);
  const [error, setError] = React.useState("");
  const handleChangeImage = async (e) => {
    const imageFile = e.files[0];

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      setError("Mohon pilih file yang sesuai dan valid.");
      e.value = null;
      return false;
    }

    if (imageFile.size > 2 * 1024 * 1024) {
      setError("Ukuran file melebihi batas maksimum 2MB.");
      e.value = null;
      return false;
    }

    setError("");

    const resizedDataUrl = await resizeImage(imageFile);
    setFileImage(resizedDataUrl as File);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "350px", md: "700px" }}>
          <ModalHeader
            fontWeight="semibold"
            color="black"
            fontSize="xl"
            display="flex"
            justifyContent="space-between"
            w="full"
          >
            Upload Foto Profile Untuk Sertifikat
            <Button
              type="button"
              color="white"
              bgColor="red.400"
              bg="transparent"
              rounded="lg"
              fontSize="sm"
              p={1.5}
              ml="auto"
              onClick={onClose}
              _hover={{ color: "white", bg: "red.600" }}
              _active={{ bg: "red.600" }}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </ModalHeader>
          <ModalBody>
            <Alert
              status="info"
              borderRadius="md"
              p={4}
              mb={4}
              fontSize="sm"
              display="flex"
              flexDirection="column"
              alignItems="start"
              bg="#dbeafe"
            >
              <Box display="flex" alignItems="center">
                <AlertIcon
                  as={BsInfoCircleFill}
                  boxSize={5}
                  mr={3}
                  color="#1d4ed8"
                />
                <AlertTitle color="#1d4ed8" fontWeight="semibold">
                  Keterangan untuk foto profile
                </AlertTitle>
              </Box>

              <AlertDescription mt={1.5} ml={4}>
                <UnorderedList
                  listStyleType="disc"
                  listStylePosition="inside"
                  color="#1d4ed8"
                >
                  <ListItem>
                    Pas Photo 4x6 dengan resolusi 300x450px Formal Background
                    Merah
                  </ListItem>
                  <ListItem>
                    Disarankan Menggunakan Jas dan Dasi Hitam (Untuk Pria)
                  </ListItem>
                  <ListItem>
                    Menggunakan hijab Polos Warna Gelap/Hitam (Untuk Perempuan
                    Berhijab)
                  </ListItem>
                  <ListItem>
                    Tipe file: jpg, jpeg, png | Ukuran maks: 2MB
                  </ListItem>
                </UnorderedList>
              </AlertDescription>
              <Flex
                justifyContent="space-between"
                px={{ base: 2, md: 8 }}
                mt={2}
                w="full"
              >
                <Box>
                  <Text color="#1d4ed8" fontWeight="semibold">
                    Contoh Pria
                  </Text>
                  <Image src={PasFotoPria} w={{ base: "100px", md: "200px" }} />
                </Box>
                <Box>
                  <Text color="#1d4ed8" fontWeight="semibold">
                    Contoh Wanita
                  </Text>
                  <Image
                    src={PasFotoWanita}
                    w={{ base: "100px", md: "200px" }}
                  />
                </Box>
              </Flex>
            </Alert>
            <ErrorMessage error={error} />
            <Box>
              <FormLabel
                mb={2}
                fontSize="sm"
                fontWeight="medium"
                color="gray.900"
                dark={{ color: "gray.300" }}
                htmlFor="file_input"
              >
                Upload image
              </FormLabel>
              <Input
                w="full"
                fontSize="sm"
                color="gray.900"
                bg="gray.50"
                rounded="lg"
                border="1px"
                borderColor="gray.300"
                cursor="pointer"
                dark={{
                  color: "gray.400",
                  bg: "gray.700",
                  border: "1px",
                  borderColor: "gray.600",
                  placeholder: "gray.400",
                }}
                type="file"
                id="file_input"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => handleChangeImage(e.target)}
              />
              <Text
                mt={1}
                fontSize="sm"
                color="gray.500"
                dark={{ color: "gray.300" }}
                id="file_input_help"
              >
                JPEG, PNG, or JPG (MAX SIZE. 2MB).
              </Text>
              {/* VisuallyHidden is used to hide the assistive label from visual rendering */}
              <VisuallyHidden>
                <label htmlFor="file_input">Upload image</label>
              </VisuallyHidden>
            </Box>
          </ModalBody>

          <Divider my={4} />

          <ModalFooter justifyContent="start">
            <Button
              color="white"
              bg="green.600"
              _hover={{
                bg: "green.700",
              }}
              _active={{
                bg: "grren.700",
              }}
              mr={3}
              onClick={() => {
                handleUploadImage(fileImage);
                onClose();
              }}
              disabled={!error.length && fileImage ? false : true}
            >
              Simpan
            </Button>
            <Button
              type="button"
              color="gray.500"
              bg="white"
              _hover={{ bg: "gray.100", color: "black" }}
              _focus={{ ring: "4", outline: "none", ringColor: "blue.300" }}
              rounded="lg"
              border="1px"
              borderColor="gray.200"
              fontSize="sm"
              fontWeight="medium"
              px={5}
              py={2.5}
              onClick={onClose}
            >
              Batal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalUploadImage;
