/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { UsersPresenter } from "../../../../../data/presenters/UsersPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ManagemenUser } from "../../../../../entities/User";
import axiosInstance from "../../../../api/axiosInstance";

interface Activity {
  id: number;
  user_id: number;
  activity: string;
  metadata: string;
  created_at: string;
  updated_at: string;
}

interface IState {
  isLoading: boolean;
  isLoadingUpdateProfileSimpeg: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  internal: boolean;
  peserta: boolean;
  totalManagemenUser: number;
  managemenUser: ManagemenUser[];
  formId: number;
  formDialog: string;
  formName: string;
  formEmail: string;
  formNIK: string;
  formNIP: string;
  formJabatan: string;
  formLevel: string;
  formPhoneNumber: string;
  formPassword: string;
  formPasswordConfirmation: string;
  formPathFoto: any;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  showDialogResetPassword: boolean;
  isKemenag: any;
  showLogActivity: boolean;
  logActivity: Activity[];
  isLoadingLogActivity: boolean;
  formTempatLahir: string;
  formTanggalLahir: string;
}

interface InitialState {
  state: IState;
  getManagemenUser: Function;
  setPage: Function;
  setPageRow: Function;
  searchManagemenUser: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormName: Function;
  setFormEmail: Function;
  setFormNIK: Function;
  setFormNIP: Function;
  setFormJabatan: Function;
  setFormLevel: Function;
  setFormPhoneNumber: Function;
  setFormPassword: Function;
  setFormPasswordConfirm: Function;
  setFormPathFoto: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  setShowDialogResetPassword: Function;
  deleteUser: Function;
  setInternal: Function;
  setPeserta: Function;
  setAll: Function;
  setIsKemenag: Function;
  resetPassword: Function;
  updateProfileSimpeg: Function;
  setShowLogActivity: Function;
  getLogActivity: Function;
  setFormTempatLahir: Function;
  setFormTanggalLahir: Function;
}

const initialState = {
  state: {
    isLoading: true,
    isLoadingUpdateProfileSimpeg: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    internal: false,
    peserta: false,
    totalManagemenUser: 1,
    managemenUser: [],
    formDialog: "",
    formId: 0,
    formName: "",
    formEmail: "",
    formNIK: "",
    formNIP: "",
    formJabatan: "",
    formLevel: "",
    formPhoneNumber: "",
    formPassword: "",
    formPasswordConfirmation: "",
    formPathFoto: null,
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    showDialogResetPassword: false,
    isKemenag: null,
    showLogActivity: false,
    logActivity: [],
    isLoadingLogActivity: false,
    formTempatLahir: "",
    formTanggalLahir: "",
  },
  getManagemenUser: () => {},
  searchManagemenUser: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormName: () => {},
  setFormEmail: () => {},
  setFormNIK: () => {},
  setFormNIP: () => {},
  setFormJabatan: () => {},
  setFormLevel: () => {},
  setFormPhoneNumber: () => {},
  setFormPassword: () => {},
  setFormPasswordConfirm: () => {},
  setFormPathFoto: () => {},
  setShowDialog: () => {},
  deleteUser: () => {},
  setShowDialogDelete: () => {},
  setShowDialogResetPassword: () => {},
  setInternal: () => {},
  setPeserta: () => {},
  setAll: () => {},
  setIsKemenag: () => {},
  resetPassword: () => {},
  updateProfileSimpeg: () => {},
  setShowLogActivity: () => {},
  getLogActivity: () => {},
  setFormTempatLahir: () => {},
  setFormTanggalLahir: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenUserProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingUpdateProfileSimpeg: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    internal: false,
    peserta: false,
    totalManagemenUser: 0,
    managemenUser: [],
    formDialog: "",
    formId: 0,
    formName: "",
    formEmail: "",
    formNIK: "",
    formNIP: "",
    formJabatan: "",
    formLevel: "",
    formPhoneNumber: "",
    formPassword: "",
    formPasswordConfirmation: "",
    formPathFoto: null,
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    showDialogResetPassword: false,
    isKemenag: null,
    showLogActivity: false,
    logActivity: [],
    isLoadingLogActivity: false,
    formTempatLahir: "",
    formTanggalLahir: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingUpdateProfileSimpeg = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingUpdateProfileSimpeg: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const setIsKemenag = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      isKemenag: value,
    }));
  };

  const getManagemenUser = async (
    pagee: number,
    keyword: string,
    row: number,
    internal: boolean,
    peserta: boolean
  ) => {
    setLoading(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const getAllManagemenUser = await usersPresenter.getAllManagemenUser(
        pagee,
        row,
        keyword,
        internal,
        peserta
      );

      const data = getAllManagemenUser;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        managemenUser: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error managemen user:", error);
      setLoading(false);
    }
  };

  const searchManagemenUser = (value: string) => {
    setLoading(true);
    getManagemenUser(
      state.page,
      value,
      state.row,
      state.internal,
      state.peserta
    );
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: ManagemenUser) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formName: value.name,
      formEmail: value.email,
      formNIK: value.nik,
      formNIP: value.nip,
      formLevel: value.level,
      formPhoneNumber: value.phone_number,
      isKemenag: value.jenis_user,
      formPathFoto: value.path_foto,
      formTempatLahir: value.tempat_lahir,
      formTanggalLahir: value.tanggal_lahir,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formDialog == "Tambah") {
      if (
        state.formName == "" ||
        state.formEmail == "" ||
        state.formLevel == "" ||
        state.formPassword == "" ||
        state.formPasswordConfirmation == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }

      if (state.formPassword.length < 6) {
        toast.error("Password Minimal 6 Karakter!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }

      if (state.formPassword != state.formPasswordConfirmation) {
        toast.error("Password Confirmation Harus Sama dengan Password Awal!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }
    } else {
      if (
        state.formName == "" ||
        state.formEmail == "" ||
        state.formLevel == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }

      if (state.formPassword != "" && state.formPassword.length < 6) {
        toast.error("Password Minimal 6 Karakter!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }

      if (
        state.formPassword != "" &&
        state.formPassword != state.formPasswordConfirmation
      ) {
        toast.error("Password Confirmation Harus Sama dengan Password Awal!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }
    }

    try {
      var action;

      const usersPresenter = container.resolve(UsersPresenter);
      if (state.formDialog == "Tambah") {
        if (state.formLevel == "peserta") {
          if (state.isKemenag == 1) {
            action = await usersPresenter.createManagemenUser(
              state.formName,
              state.formEmail,
              "",
              state.formNIP,
              "",
              state.formLevel,
              state.formPhoneNumber,
              state.formPassword,
              state.formPasswordConfirmation,
              state.formPathFoto != null ? state.formPathFoto[0] : null,
              state.isKemenag,
              state.formTempatLahir,
              state.formTanggalLahir
            );
          } else {
            action = await usersPresenter.createManagemenUser(
              state.formName,
              state.formEmail,
              state.formNIK,
              "",
              "",
              state.formLevel,
              state.formPhoneNumber,
              state.formPassword,
              state.formPasswordConfirmation,
              state.formPathFoto != null ? state.formPathFoto[0] : null,
              state.isKemenag,
              state.formTempatLahir,
              state.formTanggalLahir
            );
          }
        } else if (state.formLevel == "widyaiswara") {
          action = await usersPresenter.createManagemenUser(
            state.formName,
            state.formEmail,
            "",
            state.formNIP,
            state.formJabatan,
            state.formLevel,
            state.formPhoneNumber,
            state.formPassword,
            state.formPasswordConfirmation,
            state.formPathFoto != null ? state.formPathFoto[0] : null,
            0,
            state.formTempatLahir,
            state.formTanggalLahir
          );
        } else {
          action = await usersPresenter.createManagemenUser(
            state.formName,
            state.formEmail,
            "",
            "",
            "",
            state.formLevel,
            "",
            state.formPassword,
            state.formPasswordConfirmation,
            state.formPathFoto != null ? state.formPathFoto[0] : null,
            0,
            state.formTempatLahir,
            state.formTanggalLahir
          );
        }

        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getManagemenUser(
            state.page,
            state.keyword,
            state.row,
            state.internal,
            state.peserta
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        if (state.formLevel == "peserta") {
          if (state.isKemenag == 1) {
            action = await usersPresenter.updateManagemenUser(
              state.formId,
              state.formName,
              state.formEmail,
              "",
              state.formNIP,
              "",
              state.formLevel,
              state.formPhoneNumber,
              state.formPassword,
              state.formPasswordConfirmation,
              state.formPathFoto != null ? state.formPathFoto[0] : null,
              state.isKemenag,
              state.formTempatLahir,
              state.formTanggalLahir
            );
          } else {
            action = await usersPresenter.updateManagemenUser(
              state.formId,
              state.formName,
              state.formEmail,
              state.formNIK,
              "",
              "",
              state.formLevel,
              state.formPhoneNumber,
              state.formPassword,
              state.formPasswordConfirmation,
              state.formPathFoto != null ? state.formPathFoto[0] : null,
              state.isKemenag,
              state.formTempatLahir,
              state.formTanggalLahir
            );
          }
        } else if (state.formLevel == "widyaiswara") {
          action = await usersPresenter.updateManagemenUser(
            state.formId,
            state.formName,
            state.formEmail,
            "",
            state.formNIP,
            state.formJabatan,
            state.formLevel,
            state.formPhoneNumber,
            state.formPassword,
            state.formPasswordConfirmation,
            state.formPathFoto != null ? state.formPathFoto[0] : null,
            0,
            state.formTempatLahir,
            state.formTanggalLahir
          );
        } else {
          action = await usersPresenter.updateManagemenUser(
            state.formId,
            state.formName,
            state.formEmail,
            "",
            "",
            "",
            state.formLevel,
            "",
            state.formPassword,
            state.formPasswordConfirmation,
            state.formPathFoto != null ? state.formPathFoto[0] : null,
            0,
            state.formTempatLahir,
            state.formTanggalLahir
          );
        }

        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getManagemenUser(
            state.page,
            state.keyword,
            state.row,
            state.internal,
            state.peserta
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error managemen user:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormName = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formName: value,
    }));
  };

  const setFormEmail = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formEmail: value,
    }));
  };

  const setFormNIK = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNIK: value,
    }));
  };

  const setFormNIP = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNIP: value,
    }));
  };

  const setFormJabatan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJabatan: value,
    }));
  };

  const setFormLevel = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formLevel: value,
    }));
  };

  const setFormPhoneNumber = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPhoneNumber: value,
    }));
  };

  const setFormPassword = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPassword: value,
    }));
  };

  const setFormPasswordConfirm = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPasswordConfirmation: value,
    }));
  };

  const setFormPathFoto = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      formPathFoto: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const setFormTempatLahir = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formTempatLahir: value,
    }));
  };

  const setFormTanggalLahir = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formTanggalLahir: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formName: "",
      formEmail: "",
      formNIK: "",
      formNIP: "",
      formJabatan: "",
      formLevel: "",
      formPhoneNumber: "",
      formPassword: "",
      formPasswordConfirmation: "",
      formTanggalLahir: "",
      formTempatLahir: "",
    }));
  };

  const deleteUser = async () => {
    setFormLoading(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const action = await usersPresenter.deleteManagemenUser(state.formId);
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getManagemenUser(
          state.page,
          state.keyword,
          state.row,
          state.internal,
          state.peserta
        );
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error managemen user:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setShowDialogResetPassword = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogResetPassword: value,
    }));
  };

  const setShowLogActivity = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showLogActivity: value,
    }));
  };

  const setInternal = () => {
    setState((prevstate) => ({
      ...prevstate,
      managemenUser: [],
    }));
    getManagemenUser(1, state.keyword, state.row, true, false);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      internal: true,
      peserta: false,
    }));
  };

  const setPeserta = () => {
    setState((prevstate) => ({
      ...prevstate,
      managemenUser: [],
    }));
    getManagemenUser(1, state.keyword, state.row, false, true);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      peserta: true,
      internal: false,
    }));
  };

  const setAll = () => {
    setState((prevstate) => ({
      ...prevstate,
      managemenUser: [],
    }));
    getManagemenUser(1, state.keyword, state.row, false, false);
    setState((prevstate) => ({
      ...prevstate,
      page: 1,
      peserta: false,
      internal: false,
    }));
  };

  const resetPassword = async () => {
    setFormLoading(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const action = await usersPresenter.resetPasswordManagemenUser(
        state.formId
      );
      const data = action.data;

      setFormLoading(false);

      if (data.code == 200) {
        toast.success(data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogResetPassword(false);
        getManagemenUser(
          state.page,
          state.keyword,
          state.row,
          state.internal,
          state.peserta
        );
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data! error :" + data.message, {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      console.log("error when reset password :", error.message);
      toast.error(`Error saat reset password : ${error.message}`, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setFormLoading(false);
    }
  };

  // Update profile simpeg
  const updateProfileSimpeg = async () => {
    setLoadingUpdateProfileSimpeg(true);

    try {
      const usersPresenter = container.resolve(UsersPresenter);
      const store = await usersPresenter.updateProfileSimpeg();
      const getDetail = store;

      if (getDetail.data.code === 200) {
        toast.success("Berhasil Sync Data Ke Simpeg!", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });

        setLoadingUpdateProfileSimpeg(false);

        window.location.reload();
      } else {
        toast.error("Ada Kesalahan Saat Sync Data Ke Simpeg!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingUpdateProfileSimpeg(false);
    } catch (error) {
      console.log("error update profile simpeg:", error);
      toast.error("Ada Kesalahan Saat Sync Data Ke Simpeg!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingUpdateProfileSimpeg(false);
    }
  };

  const getLogActivity = async () => {
    setState((prev) => ({
      ...prev,
      isLoadingLogActivity: true,
    }));

    try {
      const res = await axiosInstance.get("/user_activity", {
        params: {
          user_id: state.formId,
          page: 1,
          rows: 1000,
        },
      });

      setState((prev) => ({
        ...prev,
        logActivity: res.data.data,
      }));

      setState((prev) => ({
        ...prev,
        isLoadingLogActivity: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isLoadingLogActivity: true,
      }));

      throw error;
    }
  };

  return (
    <ManajemenUserProvider
      value={{
        state,
        getManagemenUser,
        setPage,
        setPageRow,
        searchManagemenUser,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormName,
        setFormEmail,
        setFormNIK,
        setFormNIP,
        setFormJabatan,
        setFormLevel,
        setFormPhoneNumber,
        setFormPassword,
        setFormPasswordConfirm,
        setFormPathFoto,
        setShowDialog,
        deleteUser,
        setShowDialogDelete,
        setInternal,
        setPeserta,
        setAll,
        setIsKemenag,
        setShowDialogResetPassword,
        resetPassword,
        updateProfileSimpeg,
        setShowLogActivity,
        getLogActivity,
        setFormTempatLahir,
        setFormTanggalLahir,
      }}
    >
      {children}
    </ManajemenUserProvider>
  );
};

export const useManajemenUserContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenUserContext,
  Provider,
};
