/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSignOut } from "react-auth-kit";
import history from "../../../../../history";
import { GetAuthData } from "../../../../misc/GetAuthData";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";

export const Navbar = ({ title }: { title: string }) => {
  const signOut = useSignOut();

  return (
    <nav
      className="relative w-full flex flex-wrap items-center justify-between py-3 text-white shadow-lg"
      style={{ backgroundColor: "#007E39" }}
    >
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        <div className="container-fluid">
          <a className="text-base" href="#">
            {title}
          </a>
        </div>
        <div className="flex items-center relative">
          <a
            className="text-gray-500 hover:text-gray-700 focus:text-gray-700 mr-4"
            href="#"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="bell"
              className="w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="#FFFFFF"
                d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
              ></path>
            </svg>
          </a>
          <a className="mr-2 text-sm" href="#">
            {GetAuthData()?.username}
          </a>
          <div className="dropdown relative">
            <a
              className="dropdown-toggle flex items-center hidden-arrow"
              href="#"
              id="dropdownMenuButton2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={
                  GetLocalStorage({ name: "path_photo" }) !== "null"
                    ? GetLocalStorage({ name: "path_photo" })
                    : `https://ui-avatars.com/api/?name=${
                        GetAuthData()?.username
                      }&background=007E39&color=fff`
                }
                className="rounded-full"
                style={{ height: "25px", width: "25px" }}
                alt=""
                loading="lazy"
              />
            </a>
            <ul
              className="dropdown-menu w-44 min-w-max absolute  bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none left-auto right-0"
              aria-labelledby="dropdownMenuButton2"
            >
              <li>
                <button
                  className="dropdown-item text-sm py-2 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 text-left"
                  onClick={() => {
                    signOut();
                    history.replace(`/login`);
                    window.location.reload();
                  }}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
