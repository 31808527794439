export class Kelurahan {
  id: number;
  kecamatan_id: number;
  nama_kelurahan: string;
  created_at: string;
  updated_at: string;
  jumlahData: number;
  kabupaten_id: number;

  constructor(
    id: number,
    kecamatan_id: number,
    nama_kelurahan: string,
    created_at: string,
    updated_at: string,
    jumlahData: number,
    kabupaten_id: number
  ) {
    this.id = id;
    this.kecamatan_id = kecamatan_id;
    this.nama_kelurahan = nama_kelurahan;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahData = jumlahData;
    this.kabupaten_id = kabupaten_id;
  }
}
