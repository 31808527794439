import React from "react";
import { container } from "tsyringe";
import { DashboardPresenter } from "../../../../../data/presenters/DashboardPresenter";
import { DashboardRekapitulasiPresenter } from "../../../../../data/presenters/DashboardRekapitulasiPresenter";
import { Dashboard } from "../../../../../entities/Dashboard";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import axios from "axios";
import moment from "moment";
import env from "@ludovicm67/react-dotenv";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";

interface IState {
  isLoading: boolean;
  dashboard: Dashboard | null;
  dataRekapitulasi: any[];
  page: number;
  rows: number;
  keyword: string;
  tahun: string;
  jumlahPage: number;
  showDialogExcel: boolean;
  isLoadingDownload: boolean;
}

interface InitialState {
  state: IState;
  getDashboard: Function;
  getDashboardRekapitulasi: Function;
  setPage: Function;
  setPageRow: Function;
  setTahun: Function;
  setShowDialogExcel: Function;
  downloadExcel: Function;
}

const initialState = {
  state: {
    isLoading: false,
    dashboard: null,
    dataRekapitulasi: [],
    page: 1,
    rows: 10,
    keyword: "",
    tahun: "",
    jumlahPage: 0,
    showDialogExcel: false,
    isLoadingDownload: false,
  },
  getDashboard: () => {},
  getDashboardRekapitulasi: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setTahun: () => {},
  setShowDialogExcel: () => {},
  downloadExcel: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: DashboardProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    dashboard: null,
    dataRekapitulasi: [],
    page: 1,
    rows: 10,
    keyword: "",
    tahun: "",
    jumlahPage: 0,
    showDialogExcel: false,
    isLoadingDownload: false,
  });

  const token = GetLocalStorage({ name: "tokenAuth" });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      rows: value,
    }));
  };

  const setTahun = (value: string) => {
    getDashboardRekapitulasi(state.keyword, value, false);

    setState((prevstate) => ({
      ...prevstate,
      tahun: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const setShowDialogExcel = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogExcel: value,
    }));
  };

  const setLoadingDownload = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDownload: value,
    }));
  };

  const getDashboardRekapitulasi = async (
    keyword: string,
    tahun: string,
    is_simdiklat: boolean,
    event_id: string = ""
  ) => {
    setLoading(true);

    try {
      const dashboardRekapitulasiPresenter = container.resolve(
        DashboardRekapitulasiPresenter
      );
      const get =
        await dashboardRekapitulasiPresenter.getDashboardRekapitulasiData({
          page: state.page,
          rows: state.rows,
          keyword: keyword,
          tahun: tahun,
          is_simdiklat: is_simdiklat,
          event_id,
        });

      const data = get;

      if (data.length > 0) {
        setJumlahPage(data[0].jumlah_data / state.rows);
      }

      setState((prevstate) => ({
        ...prevstate,
        dataRekapitulasi: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error when fetch dashboard rekapitulasi", error);
      toast.error("Ada Kesalahan Saat Mengambil Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const getDashboard = async () => {
    setLoading(true);

    try {
      const dashboardPresenter = container.resolve(DashboardPresenter);
      const get = await dashboardPresenter.getDashboardData(7);
      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        dashboard: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error when fetch dashboard :", error);
      toast.error("Ada Kesalahan Saat Mengambil Data!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
    }
  };

  const downloadExcel = async () => {
    setLoadingDownload(true);

    try {
      axios.defaults.headers.common = {
        Authorization: token !== undefined && token,
      };

      // Ganti ke dev apabila ingin mencoba di local
      axios(
        `${env.REACT_APP_BE_PROD_BASE_URL}dashboard/export-rekapitulasi?tahun=${state.tahun}`,
        {
          method: "GET",
          responseType: "arraybuffer",
        }
      )
        .then((resp) => {
          const file = new Blob([resp.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(
            file,
            `Export Rekapitulasi Pelatihan ${moment().format(
              "DD-MM-YYYY"
            )} .xlsx`
          );
          setLoadingDownload(false);
        })
        .catch((error) => console.log("error when fetch pdf:", error));
    } catch (error: any) {
      console.log("error when download data :", error);
      toast.error("Error saat mendownload file excel!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoadingDownload(false);
    }
  };

  return (
    <DashboardProvider
      value={{
        state,
        getDashboard,
        getDashboardRekapitulasi,
        setPage,
        setPageRow,
        setTahun,
        setShowDialogExcel,
        downloadExcel,
      }}
    >
      {children}
    </DashboardProvider>
  );
};

export const useDashboardContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useDashboardContext,
  Provider,
};
