import {JenisPelatihanRepositoryInterface} from "../../../data/contracts/JenisPelatihan";
import {JenisPelatihanDataMapper} from "../../../data/mappers/JenisPelatihan";
import {Endpoints} from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {JenisPelatihan} from "../../../entities/JenisPelatihan";

export class JenisPelatihanApiRepository
    implements JenisPelatihanRepositoryInterface {
    private apiService: ApiService;
    private mapper: JenisPelatihanDataMapper;
    private endpoints: Endpoints;

    constructor(
        apiService: ApiService,
        mapper: JenisPelatihanDataMapper,
        endpoints: Endpoints
    ) {
        this.apiService = apiService;
        this.mapper = mapper;
        this.endpoints = endpoints;
    }

    public async getAllJenisPelatihan(
        page: number,
        rows: number,
        keyword: string,
        sub_menu_jenis_pelatihan_id: string
    ): Promise<JenisPelatihan[]> {
        const headers = new Map<string, string>();

        const dataResponse = await this.apiService.invoke(
            "get",
            this.endpoints.jenisPelatihan(),
            {
                page: page,
                rows: rows,
                keyword: keyword,
                sub_menu_jenis_pelatihan_id: sub_menu_jenis_pelatihan_id,
            },
            null,
            headers
        );
        return this.mapper.convertJenisPelatihanDataFromApi(dataResponse);
    }

    public async getDetailJenisPelatihan(id: number): Promise<JenisPelatihan> {
        const headers = new Map<string, string>();

        const dataResponse = await this.apiService.invoke(
            "get",
            this.endpoints.jenisPelatihan() + "/" + id,
            {},
            null,
            headers
        );
        return this.mapper.convertDetailJenisPelatihanDataFromApi(dataResponse);
    }

    public async storeJenisPelatihan(
        sub_menu_jenis_pelatihan_id: number,
        kode: string,
        nama_pelatihan: string,
        kompetensi: string,
        jp: string,
        keterangan: string,
        metode_penambahan: number
    ): Promise<any> {
        const headers = new Map<string, string>();

        headers.set("Content-Type", "multipart/form-data");
        let formData = new FormData();
        formData.append(
            "sub_menu_jenis_pelatihan_id",
            sub_menu_jenis_pelatihan_id.toString()
        );
        formData.append("kode", kode);
        formData.append("nama_pelatihan", nama_pelatihan);
        formData.append("kompetensi", kompetensi);
        formData.append("jp", jp);
        formData.append("keterangan", keterangan);
        formData.append("metode_penambahan", metode_penambahan.toString());
        const dataResponse = await this.apiService.invoke(
            "post",
            this.endpoints.jenisPelatihan(),
            null,
            formData,
            headers
        );
        return dataResponse;
    }

    public async updateJenisPelatihan(
        id: number,
        sub_menu_jenis_pelatihan_id: number,
        kode: string,
        nama_pelatihan: string,
        kompetensi: string,
        jp: string,
        keterangan: string,
        metode_penambahan: number
    ): Promise<any> {
        const headers = new Map<string, string>();

        headers.set("Content-Type", "multipart/form-data");
        let formData = new FormData();
        formData.append(
            "sub_menu_jenis_pelatihan_id",
            sub_menu_jenis_pelatihan_id.toString()
        );
        formData.append("kode", kode);
        formData.append("nama_pelatihan", nama_pelatihan);
        formData.append("kompetensi", kompetensi);
        formData.append("jp", jp);
        formData.append("keterangan", keterangan);
        formData.append("metode_penambahan", metode_penambahan.toString());
        const dataResponse = await this.apiService.invoke(
            "post",
            this.endpoints.jenisPelatihan() + "/" + id,
            null,
            formData,
            headers
        );
        return dataResponse;
    }

    public async deleteJenisPelatihan(id: number): Promise<any> {
        const headers = new Map<string, string>();

        const dataResponse = await this.apiService.invoke(
            "delete",
            this.endpoints.jenisPelatihan() + "/" + id,
            {},
            null,
            headers
        );
        return dataResponse;
    }
}
