/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../../../data/presenters/PelatihanPresenter";
import { Pelatihan } from "../../../../../../../entities/Pelatihan";
import { toast } from "react-toastify";
import history from "../../../../../../../history";
import { ModulPelatihan } from "../../../../../../../entities/ModulPelatihan";
import { PaketSoal } from "../../../../../../../entities/PaketSoal";
import { Materi } from "../../../../../../../entities/Materi";
import { MateriPresenter } from "../../../../../../../data/presenters/MateriPresenter";
import { PaketSoalPresenter } from "../../../../../../../data/presenters/PaketSoalPresenter";
import { ModulPelatihanPresenter } from "../../../../../../../data/presenters/ModulPelatihanPresenter";
import { Quiz } from "../../../../../../../entities/Quiz";
import { QuizPresenter } from "../../../../../../../data/presenters/QuizPresenter";
import { PathMateri } from "../../../../../../../entities/PathMateri";
import { PathMateriPresenter } from "../../../../../../../data/presenters/PathMateriPresenter";

interface IState {
  isLoading: boolean;
  pelatihan: Pelatihan | null;
  modulPelatihan: ModulPelatihan[];
  detailMateri: Materi | null;
  detailPaketSoal: PaketSoal | null;
  selectModulIdx: string;
  selectSubModulIdx: string;
  showDialogDelete: boolean;
  selectedId: number;
  isLoadingDelete: boolean;
  selectSubModulType: string;
  quiz: Quiz[];
  paketSoal: PaketSoal[];
  pathMateri: PathMateri[] | null;
  indexPathMateri: number;
  pathPanduanProject: string;
}

interface InitialState {
  state: IState;
  getDetailPelatihan: Function;
  getModulPelatihan: Function;
  getDetailMateri: Function;
  getDetailPaketSoal: Function;
  selectSubModul: Function;
  setShowDialogDelete: Function;
  setSelectedId: Function;
  deletePelatihan: Function;
  getQuiz: Function;
  getPaketSoal: Function;
  setIndexPathMateri: Function;
  setPelatihan: Function;
}

const initialState = {
  state: {
    isLoading: true,
    pelatihan: null,
    modulPelatihan: [],
    detailMateri: null,
    detailPaketSoal: null,
    selectModulIdx: "",
    selectSubModulIdx: "",
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDelete: false,
    selectSubModulType: "",
    quiz: [],
    paketSoal: [],
    pathMateri: [],
    indexPathMateri: 0,
    pathPanduanProject: "",
  },
  getDetailPelatihan: () => {},
  getModulPelatihan: () => {},
  getDetailMateri: () => {},
  getDetailPaketSoal: () => {},
  selectSubModul: () => {},
  setShowDialogDelete: () => {},
  setSelectedId: () => {},
  deletePelatihan: () => {},
  getQuiz: () => {},
  getPaketSoal: () => {},
  setIndexPathMateri: () => {},
  setPelatihan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenPelatihanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    pelatihan: null,
    modulPelatihan: [],
    detailMateri: null,
    detailPaketSoal: null,
    selectModulIdx: "",
    selectSubModulIdx: "",
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDelete: false,
    selectSubModulType: "",
    quiz: [],
    paketSoal: [],
    pathMateri: [],
    indexPathMateri: 0,
    pathPanduanProject: "",
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setIndexPathMateri = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexPathMateri: value,
    }));
  };

  const setPelatihan = (value: Pelatihan) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihan: value,
    }));
  };

  const getDetailPelatihan = async (id: number) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        pelatihan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  const getModulPelatihan = async (pelatihan_id: number) => {
    setLoading(true);

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );
      const getAllModulPelatihan =
        await modulPelatihanPresenter.getAllModulPelatihan(
          1,
          1000,
          "",
          pelatihan_id
        );

      const data = getAllModulPelatihan;

      setState((prevstate) => ({
        ...prevstate,
        modulPelatihan: data,
        pelatihanId: pelatihan_id,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error paket soal:", error);
      setLoading(false);
    }
  };

  const getDetailMateri = async (id: number) => {
    try {
      // Get Materi
      const materiPresenter = container.resolve(MateriPresenter);
      const getDetailMateri = await materiPresenter.getDetailMateri(id);
      const data = getDetailMateri;

      // Get Path Materi
      const pathMateriPresenter = container.resolve(PathMateriPresenter);
      const get = await pathMateriPresenter.getAllPathMateri(id, "");
      const response = get;

      setState((prevstate) => ({
        ...prevstate,
        detailMateri: data,
        pathMateri: response,
      }));
    } catch (error: any) {
      console.log("error materi:", error);
    }
  };

  const getDetailPaketSoal = async (id: number) => {
    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const getDetailPaketSoal = await paketSoalPresenter.getDetailPaketSoal(
        id
      );

      const data = getDetailPaketSoal;

      setState((prevstate) => ({
        ...prevstate,
        detailPaketSoal: data,
      }));
    } catch (error: any) {
      console.log("error paket soal:", error);
    }
  };

  const selectSubModul = (
    idxModul: number,
    idxSubModul: number,
    type: string
  ) => {
    setState((prevstate) => ({
      ...prevstate,
      selectSubModulType: type,
      selectModulIdx: idxModul.toString(),
      selectSubModulIdx: idxSubModul.toString(),
      detailPaketSoal: null,
      detailMateri: null,
    }));

    if (type == "sub_modul_materi") {
      getDetailMateri(
        state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul].materi_id
      );
      getQuiz(state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul].id);
    } else if (type == "sub_modul_ujian_ujian") {
      getDetailPaketSoal(
        state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
          .paket_soal_id
      );
    } else if (type == "sub_modul_ujian_project") {
      setState((prevstate) => ({
        ...prevstate,
        pathPanduanProject: state.pelatihan?.modulPelatihan[idxModul].sub_modul[
          idxSubModul
        ].m_final_project
          ? state.pelatihan?.modulPelatihan[idxModul].sub_modul[idxSubModul]
              .m_final_project[0].path_panduan
          : null,
      }));
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setSelectedId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedId: value,
    }));
  };

  const setLoadingDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const deletePelatihan = async () => {
    setLoadingDelete(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const deletePelatihan = await pelatihanPresenter.deletePelatihan(
        state.selectedId
      );

      const data = deletePelatihan;

      if (data.data.code == 200) {
        toast.success("Berhasil Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_pelatihan`);
        window.location.reload();
      } else {
        toast.error("Gagal Menghapus Data Soal.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingDelete(false);
      setShowDialogDelete(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setLoadingDelete(false);
    }
  };

  const getQuiz = async (sub_modul_id: number) => {
    try {
      const quizPresenter = container.resolve(QuizPresenter);
      const getAllQuiz = await quizPresenter.getAllQuiz(sub_modul_id);

      const data = getAllQuiz;

      setState((prevstate) => ({
        ...prevstate,
        quiz: data,
      }));
    } catch (error: any) {
      console.log("error Quiz:", error);
    }
  };

  const getPaketSoal = async () => {
    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const getAllPaketSoal = await paketSoalPresenter.getAllPaketSoal(
        1,
        1000000,
        "",
        "",
        "",
        ""
      );

      const data = getAllPaketSoal;

      setState((prevstate) => ({
        ...prevstate,
        paketSoal: data,
      }));
    } catch (error: any) {
      console.log("error materi:", error);
    }
  };

  return (
    <ManajemenPelatihanDetailProvider
      value={{
        state,
        getDetailPelatihan,
        getModulPelatihan,
        getDetailMateri,
        getDetailPaketSoal,
        selectSubModul,
        setShowDialogDelete,
        setSelectedId,
        deletePelatihan,
        getQuiz,
        getPaketSoal,
        setIndexPathMateri,
        setPelatihan,
      }}
    >
      {children}
    </ManajemenPelatihanDetailProvider>
  );
};

export const useDetailPelatihanKontenPelatihanContext = () =>
  React.useContext(Context);

export default {
  useDetailPelatihanKontenPelatihanContext,
  Provider,
};
