/* eslint-disable eqeqeq */
import { AxiosResponse } from "axios";

import { GetLocalStorage } from "../../app/misc/UseLocalStorage";
import { Provinsi } from "../../entities/Provinsi";

export class ProvinsiDataMapper {
  public convertProvinsiDataFromApi(result: AxiosResponse<any>): Provinsi[] {
    const { data } = result.data;

    let provinsi: Provinsi[] = [];

    data.map((u: any) => {
      return provinsi.push(
        new Provinsi(
          u.id,
          u.nama_provinsi,
          u.created_at,
          u.updated_at,
          GetLocalStorage({ name: "level" }) == "superadmin"
            ? result.data.pagination.total_rows
            : ""
        )
      );
    });

    return provinsi;
  }
}
