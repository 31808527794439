export class SettingPenilaian {
    id: number
    jenis_pelatihan_id: string
    nilai_bawah: string
    nilai_atas: string
    predikat: string
    created_at: string
    updated_at: string
  
    constructor(
      id: number,
      jenis_pelatihan_id: string,
      nilai_bawah: string,
      nilai_atas: string,
      predikat: string,
      created_at: string,
      updated_at: string
    ) {
      this.id = id
      this.jenis_pelatihan_id = jenis_pelatihan_id
      this.nilai_bawah = nilai_bawah
      this.nilai_atas = nilai_atas
      this.predikat = predikat
      this.created_at = created_at
      this.updated_at = updated_at
    }
  }