/**
 * PodcastPlayer component displays the podcast player for playing and controlling podcast episodes.
 */

import React, { useEffect } from "react";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { BsArrowRepeat, BsDot } from "react-icons/bs";

import {
  Grid,
  GridItem,
  Slide,
  Image,
  Box,
  Text,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  Icon,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { usePodcastContext } from "../../controller/podcast";
import { GetAuthData } from "../../../misc/GetAuthData";

/**
 * PodcastPlayer component.
 * @component
 * @returns {React.FC} The PodcastPlayer component.
 * @author Bagas Prasetyadi
 */

const PodcastPlayer = () => {
  const {
    handlePodcastPlayer,
    renderPlayPauseIcon,
    setIsPlaying,
    audioRef,
    setIsRepeat,
    setVolume,
    setSeekTime,
    renderVolumeIcon,
    getTime,
    setSeeDetail,
    handleBackNextPodcast,
  } = usePodcastContext();
  const {
    isPlaying,
    showPodcastPlayer,
    currentTrack,
    isRepeat,
    volume,
    duration,
    sliderValue,
    seekTime,
  } = usePodcastContext().state;

  const MobileBottom = "70px";
  const DesktopBottom = "0px";

  const bottomHeight = useBreakpointValue({
    base: MobileBottom,
    md: DesktopBottom,
  });

  const handlePlayPause = (event) => {
    event.stopPropagation();

    setIsPlaying(!isPlaying);

    if (audioRef?.current) {
      if (isPlaying) {
        audioRef?.current.pause();
      } else {
        audioRef?.current.play();
      }
    }
  };

  const handleRepeatPodcast = () => {
    setIsRepeat(!isRepeat);
  };

  const truncateTitle = (text: string) => {
    if (text.length > 50) {
      return text.slice(0, 50) + "...";
    }
    return text;
  };

  useEffect(() => {
    handlePodcastPlayer(true);
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.currentTime = seekTime;
    }
  }, [seekTime]);

  return (
    <Slide
      direction="bottom"
      in={showPodcastPlayer}
      style={{ zIndex: 10, bottom: GetAuthData() ? bottomHeight : 0 }}
    >
      {/* large screen */}
      <Grid
        w="full"
        bg="white"
        position="sticky"
        bottom={0}
        boxShadow="xs"
        py={8}
        px={12}
        templateColumns="repeat(7, 1fr)"
        gap={2}
        display={{ base: "none", md: "grid" }}
        roundedTop="xl"
      >
        <GridItem display="flex" colSpan={2}>
          <Image
            src={currentTrack?.thumbnail}
            alt={`thumbnail ${currentTrack?.thumbnail}}`}
            objectFit="cover"
            w="80px"
            h="80px"
            mr={4}
            rounded="md"
          />
          <Box mt={2}>
            <Text fontSize="xl" fontWeight="semibold" color="black">
              {currentTrack?.title}
            </Text>
            <div
              dangerouslySetInnerHTML={{
                __html: currentTrack?.description
                  ? truncateTitle(currentTrack?.description)
                  : "",
              }}
            />
          </Box>
        </GridItem>
        <GridItem mt={2} colSpan={3}>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            h="full"
          >
            <Flex justifyContent="center" alignItems="center">
              <Icon
                as={MdSkipPrevious}
                mr={4}
                boxSize={8}
                onClick={() => handleBackNextPodcast("back")}
                _hover={{ cursor: "pointer" }}
              />
              <Icon
                as={renderPlayPauseIcon(currentTrack)}
                mr={4}
                boxSize={8}
                color="black"
                onClick={(event) => handlePlayPause(event)}
                _hover={{ cursor: "pointer" }}
              />
              <Icon
                as={MdSkipNext}
                mr={4}
                boxSize={8}
                onClick={() => handleBackNextPodcast("next")}
                _hover={{ cursor: "pointer" }}
              />
              <Icon
                as={BsArrowRepeat}
                boxSize={5}
                color={isRepeat ? "green" : "black"}
                onClick={handleRepeatPodcast}
                _hover={{ cursor: "pointer" }}
              />
            </Flex>

            <Box display="flex" mt={2}>
              <Text fontSize="sm" mr={3}>
                {getTime(sliderValue)}
              </Text>
              <Slider
                id="podcast-slider"
                w="400px"
                aria-label="progress"
                value={sliderValue}
                min={0}
                max={duration}
                colorScheme="green"
                mr={2}
                onChange={(value) => setSeekTime(value)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>

              <Text fontSize="sm">{getTime(duration)}</Text>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex alignItems="center" h="full" justifyContent="flex-end">
            <Icon as={renderVolumeIcon(volume)} boxSize={6} mr={2} />
            <Slider
              aria-label="progress"
              value={volume}
              min={0}
              max={100}
              w="130px"
              onChange={(value) => setVolume(value)}
              colorScheme="blackAlpha"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
        </GridItem>
      </Grid>

      {/* small screen */}
      <Box
        display={{ base: "block", md: "none" }}
        w="full"
        bg="white"
        position="sticky"
        bottom={0}
        boxShadow="xs"
        px={8}
        pb={4}
        roundedTop="md"
        onClick={() => {
          setSeeDetail(true);
          handlePodcastPlayer(false);
        }}
      >
        <Slider
          w="full"
          aria-label="progress"
          value={sliderValue}
          min={0}
          max={duration}
          colorScheme="green"
          mb={2}
          onInput={(value) => setSeekTime(value)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
        </Slider>
        <Flex alignItems="center" justifyContent="space-between">
          <Image
            src={currentTrack?.thumbnail}
            alt={`thumbnail ${currentTrack?.thumbnail}}`}
            objectFit="cover"
            w="60px"
            h="60px"
            mr={2}
            rounded="md"
          />
          <Box mr={4}>
            <Text fontSize="md" fontWeight="semibold" color="black">
              {truncateTitle(currentTrack?.title ?? "")}
            </Text>
            <Box display="flex" alignItems="center">
              <Text color="#7A8990">Mar 2023</Text>
              <Icon as={BsDot} boxSize={4} color="#7A8990" />
              <Text color="#7A8990">{getTime(duration)}</Text>
            </Box>
          </Box>
          <Icon
            as={renderPlayPauseIcon(currentTrack)}
            boxSize={8}
            color="black"
            onClick={handlePlayPause}
            _hover={{ cursor: "pointer" }}
          />
        </Flex>
      </Box>
    </Slide>
  );
};

export default PodcastPlayer;
