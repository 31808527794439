import { HelpdeskBantuan } from "../../entities/HelpdeskBantuan";
import { AxiosResponse } from "axios";

export class HelpdeskBantuanDataMapper {
  public convertListHelpdeskBantuan(
    result: AxiosResponse<any>
  ): HelpdeskBantuan[] {
    const { data } = result.data;
    let helpdeskBantuan: HelpdeskBantuan[] = [];

    data.map((h: any) => {
      return helpdeskBantuan.push(
        new HelpdeskBantuan(
          h.id,
          h.nama ?? "",
          h.emai ?? "",
          h.subject ?? "",
          h.asal_kantor ?? "",
          h.nip ?? "",
          h.pelatihan_diikuti ?? "",
          h.pesan ?? "",
          h.path_pendukung ?? "",
          h.tanggapan ?? "",
          h.created_at,
          h.updated_at,
          result.data.pagination.total_rows
        )
      );
    });

    return helpdeskBantuan;
  }
}
