import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface VideCourseCategoryItem {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface ListDataOption {
  label: string;
  value: string;
}

interface IState {
  state: {
    mode: string;
    name: string;
    url: string;
    openModal: boolean;
    listData: VideCourseCategoryItem[];
    dataUpdate: VideCourseCategoryItem | null;
    loadingAction: boolean;
    loadingData: boolean;
    listDataOption: ListDataOption[];
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
  };
  setVideoCourseCategoryState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
}

const initialState: IState = {
  state: {
    mode: "",
    name: "",
    url: "management_content/video_course/category",
    openModal: false,
    listData: [],
    dataUpdate: null,
    loadingAction: false,
    loadingData: false,
    listDataOption: [],
    pagination: null,
    page: 1,
    perPage: 10,
  },
  setVideoCourseCategoryState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleDelete: () => {},
};

/**
 * The VideCourseCategoryContext provides a context for managing the state related to video course categories.
 * It includes state properties and functions to update the state and perform actions related to vudeo course categories.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: VideoCourseCategoryProvider } = Context;

/**
 * The provider component that wraps its children with the VideoCourseCategoryContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const { name, url, mode, dataUpdate, page, perPage } = state.state;

  const setVideoCourseCategoryState = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async () => {
    setVideoCourseCategoryState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          rows: perPage,
          page: page,
          keyword: "",
        },
      });
      const data = response.data?.data;
      setVideoCourseCategoryState("listData", data);
      setVideoCourseCategoryState("loadingData", false);

      const transformedData = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setVideoCourseCategoryState("listDataOption", transformedData);
      setVideoCourseCategoryState("pagination", response.data?.pagination);
    } catch (error) {
      setVideoCourseCategoryState("loadingData", false);
      throw error;
    }
  };

  const postData = async () => {
    setVideoCourseCategoryState("loadingAction", true);
    const newUrl = mode === "create" ? url : `${url}/${dataUpdate?.id}`;
    try {
      const response = await axiosInstance.post(newUrl, { name });
      setVideoCourseCategoryState("loadingAction", false);
      setVideoCourseCategoryState("openModal", false);

      fetchData();
      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setVideoCourseCategoryState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      Object.keys(responseData.errors).forEach((key) => {
        responseData.errors[key].forEach((errorMessage) => {
          toast({
            title: titleMessage,
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        });
      });
      throw error;
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  return (
    <VideoCourseCategoryProvider
      value={{
        state: state.state,
        setVideoCourseCategoryState,
        fetchData,
        postData,
        handleDelete,
      }}
    >
      {children}
    </VideoCourseCategoryProvider>
  );
};

/**
 * A custom hook function to access the VideoCourseCategoryContext.
 * @returns {IState} The state and functions provided by the context.
 */

export const useVideoCourseCategoryContext = () => useContext(Context);

const VideoCourseCategoryContext = {
  useVideoCourseCategoryContext,
  Provider,
};

export default VideoCourseCategoryContext;
