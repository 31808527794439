import { PelatihanSayaRepositoryInterface } from "../../../data/contracts/PelatihanSaya";
import { PelatihanSayaDataMapper } from "../../../data/mappers/PelatihanSaya";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { PelatihanSaya } from "../../../entities/PelatihanSaya";

export class PelatihanSayaApiRepository
  implements PelatihanSayaRepositoryInterface
{
  private apiService: ApiService;
  private mapper: PelatihanSayaDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: PelatihanSayaDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getAllPelatihanSaya(
    page: number,
    rows: number | null,
    keyword: string,
    pelatihan_id: string
  ): Promise<PelatihanSaya[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.pelatihanSaya(),
      {
        page: page,
        ...(rows ? { rows: rows } : {}),
        keyword: keyword,
        pelatihan_id: pelatihan_id,
      },
      null,
      headers
    );
    return this.mapper.convertListPelatihanSayaDataFromApi(dataResponse);
  }

  public async storePelatihanSaya(pelatihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.pelatihanSaya(),
      {
        pelatihan_id: pelatihan_id,
      },
      null,
      headers
    );
    return dataResponse;
  }

  public async deletePelatihanSaya(pelatihan_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.pelatihanSaya() + "/" + pelatihan_id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
