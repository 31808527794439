export class Slider {
    id: number
    nama_user_testimoni: string
    testimoni: string
    jabatan_user_testimoni: string
    path_slider: string
    status_publish : number
    created_at: string
    updated_at: string
    jumlahDataSlider : number
  
    constructor(
      id: number,
      nama_user_testimoni: string,
      testimoni: string,
      jabatan_user_testimoni: string,
      path_slider: string,
      status_publish : number,
      created_at: string,
      updated_at: string,
      jumlahDataSlider : number
    ) {
      this.id = id
      this.nama_user_testimoni = nama_user_testimoni
      this.testimoni = testimoni
      this.jabatan_user_testimoni = jabatan_user_testimoni
      this.path_slider = path_slider
      this.status_publish = status_publish
      this.created_at = created_at
      this.updated_at = updated_at
      this.jumlahDataSlider = jumlahDataSlider
    }
  }