import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Webcam from "react-webcam";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { MJawabanSoal } from "../../../../entities/Exam";
import If, { Else } from "if-else-react";
import { Wrapper } from "../../components/Wrapper";
import PengetahuanQuizContext, {
  usePengetahuanQuizContext,
} from "../../controller/pengetahuan/quiz";
import PengetahuanDetailContext, {
  usePengetahuanDetailContext,
} from "../../controller/pengetahuan/detail";
import { useParams } from "react-router-dom";
import CountdownTimer from "react-component-countdown-timer";
import Skeleton from "react-loading-skeleton";
import SetInterval from "set-interval";
import { Modal } from "../../components/Modal";
import { IoIosArrowBack } from "react-icons/io";

type Props = {};

const HeaderContent: React.FC = () => {
  const { pengetahuanItem } = usePengetahuanDetailContext().state;

  return (
    <header>
      <div className="container-fluid bg-blue-500 p-5 text-center">
        <h1 className="text-lg font-semibold text-white">
          {pengetahuanItem?.title}
        </h1>
      </div>
    </header>
  );
};

const Soal = ({ soal }: { soal: any }) => {
  // Call State On Controller Exam
  //   const { soalDipilih, indexSoal } = useLatihanContext().state;
  const { soalDipilih, indexSoal } = usePengetahuanQuizContext().state;

  //   let indexSoal = 0;
  //   let soalDipilih = soal[0].soal;

  return (
    <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg">
      {soal != null && (
        <>
          <span className="text-blue-500 font-semibold">
            {indexSoal + 1} dari {soal.length} soal
          </span>
          <div className="mt-1">{parse(soalDipilih)}</div>
        </>
      )}
    </div>
  );
};

const ComponentJawaban = ({
  data_jawaban,
  index,
  opsi,
}: {
  data_jawaban: MJawabanSoal;
  index: number;
  opsi: string;
}) => {
  const { jawabanPeserta } = usePengetahuanQuizContext().state;
  const { onChangeAnswer } = usePengetahuanQuizContext().dispatch;

  return (
    <>
      <div className="radio-jawaban mb-3">
        <input
          type="radio"
          id={index.toString()}
          name="answer"
          value={data_jawaban.jawaban_soal_id}
          onChange={(input) => {
            onChangeAnswer(input.currentTarget.value);
          }}
          checked={data_jawaban.jawaban_soal_id === Number(jawabanPeserta)}
        />
        <label
          htmlFor={index.toString()}
          className="flex flex-row border border-2 rounded-lg p-3"
        >
          <p className="text-sm mb-2 border border-2 p-3 mr-3 font-semibold">
            {opsi}
          </p>
          <div className="text-sm mt-3">{parse(data_jawaban.pilihan)}</div>
        </label>
      </div>
    </>
  );
};

const Jawaban = ({ jawaban }: { jawaban: any }) => {
  const opsi = "ABCDEFGHIJKLMNOPQRSTUFWXYZ".split("");

  return (
    <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg flex flex-col">
      {jawaban.map((v, idx) => {
        return (
          <ComponentJawaban
            data_jawaban={v}
            index={idx}
            key={idx}
            opsi={opsi[idx]}
          />
        );
      })}
    </div>
  );
};

const SoalPilihan = ({ soal }: { soal: any }) => {
  const { bankSoal, sisaWaktu, paketSoalId, timingFinish } =
    usePengetahuanQuizContext().state;
  const { updateQuizTime, setPengetahuanQuizState } =
    usePengetahuanQuizContext().dispatch;

  const onClickChangeSoal = (value) => {
    if (bankSoal !== null) {
      const soal = bankSoal[value];
      setPengetahuanQuizState("indexSoal", value);
      setPengetahuanQuizState("soalDipilih", soal.soal);
      setPengetahuanQuizState("bankJawaban", soal.m_jawaban_soal);
      setPengetahuanQuizState("jawabanPeserta", soal.jawaban_peserta);
    }
  };

  const onTimingFinish = () => setPengetahuanQuizState("timingFinish", true);

  useEffect(() => {
    if (!paketSoalId) return;

    const fetchData = () => {
      updateQuizTime(paketSoalId);
      // Add your logic for fetching data here
    };

    const startInterval = () => {
      // Set up the interval to call fetchData every 1 minute (60000 ms)
      const interval = setInterval(() => {
        fetchData();
      }, 60000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(interval);
    };

    // Delay the initial fetch by setting a timeout to start the interval after 1 minute
    const initialTimeout = setTimeout(() => {
      fetchData();
      startInterval();
    }, 60000);

    // Clean up the initial timeout if the component unmounts before the interval starts
    return () => clearTimeout(initialTimeout);
  }, [paketSoalId]);

  return (
    <>
      <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg flex flex-col text-center">
        <p className="text-base font-semibold">Waktu Tersisa</p>
        <If condition={sisaWaktu !== null}>
          <div className="font-semibold">
            <CountdownTimer
              count={sisaWaktu}
              hideDay={true}
              size={40}
              color={"#007E39"}
              onEnd={() => {
                onTimingFinish();
                SetInterval.clear();
              }}
            />
          </div>
          <Else />
          <Skeleton height={50} />
        </If>

        {/* Pilihan Soal */}
        <div className="grid grid-cols-5 gap-2 text-white mt-3 justify-items-center">
          {soal != null
            ? soal.map((v, idx) => {
                if (v.jawaban_peserta === null) {
                  return (
                    <button
                      key={idx}
                      className="bg-blue-300 text-2xl font-semibold rounded-full hover:bg-blue-400 w-10 h-10"
                      onClick={() => onClickChangeSoal(idx)}
                    >
                      {idx + 1}
                    </button>
                  );
                }

                if (v.jawaban_peserta !== "0") {
                  return (
                    <button
                      key={idx}
                      className="bg-blue-400 text-2xl font-semibold rounded-full hover:bg-blue-500 w-10 h-10"
                      onClick={() => onClickChangeSoal(idx)}
                    >
                      {idx + 1}
                    </button>
                  );
                }

                return true;
              })
            : null}
        </div>
      </div>

      {/* ModalTiming */}
      <ModalTiming showModal={timingFinish} />

      {/* WebCam */}
      <Webcam mirrored className="mt-5 rounded-lg" />
    </>
  );
};

const ModalFinishLatihan = () => {
  const { showModalFinishLatihan, isSavingAnswer, isLoadingFinishQuiz } =
    usePengetahuanQuizContext().state;

  const { setPengetahuanQuizState, finishQuiz } =
    usePengetahuanQuizContext().dispatch;

  return (
    <>
      {showModalFinishLatihan && (
        <Modal id_modal="modalFinishLatihan">
          <div className="flex items-start justify-between p-6 rounded-t">
            <h3 className="text-xl font-semibold text-red-400">Selesai Kuis</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              style={{ color: "white" }}
              onClick={() =>
                setPengetahuanQuizState("showModalFinishLatihan", false)
              }
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6 -mt-5">
            <div
              className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
              <div>
                <span className="font-medium">Perhatian!</span> Apakah anda
                yakin menyelesaikan latihan ini?
              </div>
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
              type="button"
              className={`text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                isSavingAnswer || isLoadingFinishQuiz
                  ? "bg-gray-400 hover:bg-gray-500"
                  : "bg-yellow-500 hover:bg-yellow-600"
              }`}
              onClick={() => finishQuiz()}
              disabled={isSavingAnswer || isLoadingFinishQuiz}
            >
              {isSavingAnswer || isLoadingFinishQuiz
                ? "Mengirim jawaban, tunggu sebentar..."
                : "Ya, Saya Yakin"}
            </button>
            <button
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
              onClick={() =>
                setPengetahuanQuizState("showModalFinishLatihan", false)
              }
            >
              Tidak
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

const ModalTiming = ({ showModal }: { showModal: boolean }) => {
  const { isSavingAnswer, isLoadingFinishQuiz } =
    usePengetahuanQuizContext().state;
  const { finishQuiz } = usePengetahuanQuizContext().dispatch;

  const finish = () => {
    finishQuiz();
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="modal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-non w-full ">
              {/*content*/}
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                {/*header*/}
                <div className="modal-header flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-red-400">
                    Waktu Anda Telah Habis
                  </h3>
                </div>

                {/*body*/}
                <div className="modal-body relative p-6">
                  Waktu Anda telah habis, silakan tekan kirim untuk mengirimkan
                  jawaban Anda.
                </div>

                {/*footer*/}
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  <button
                    className={`px-4 py-2 rounded-md text-white ${
                      isSavingAnswer || isLoadingFinishQuiz
                        ? "bg-gray-400 hover:bg-gray-500"
                        : "bg-yellow-500 hover:bg-yellow-600"
                    }`}
                    style={{ marginLeft: 10 + "px" }}
                    onClick={() => finish()}
                    disabled={isSavingAnswer || isLoadingFinishQuiz}
                  >
                    {isSavingAnswer || isLoadingFinishQuiz
                      ? "Menyiapkan, tunggu sebentar..."
                      : "Kirim"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-50 bg-black w-full"></div>
        </>
      ) : null}
    </>
  );
};

const ModalShowScoreQuiz = () => {
  const { pengetahuanItem } = usePengetahuanDetailContext().state;
  const { showScoreQuiz, scoreQuiz } = usePengetahuanQuizContext().state;
  const { setPengetahuanQuizState } = usePengetahuanQuizContext().dispatch;

  const cancelRef = React.useRef(null);

  const onClose = () => {
    setPengetahuanQuizState("showScoreQuiz", false);
    setPengetahuanQuizState("timingFinish", false);
    window.location.href = `/detail/pengetahuan/${pengetahuanItem?.id}`;
  };

  return (
    <>
      <AlertDialog
        isOpen={showScoreQuiz}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent py={6}>
            <AlertDialogBody>
              <Text
                fontSize="md"
                fontWeight="semibold"
                mt={2}
                textAlign="center"
              >
                Anda Telah Menyelesaikan Quiz {pengetahuanItem?.title}
              </Text>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Text
                  bg="gray.500"
                  color="white"
                  fontSize="lg"
                  fontWeight="semibold"
                  px={2.5}
                  py={0.5}
                  borderRadius="full"
                  my={6}
                >
                  Skor Anda
                </Text>
                <Text fontSize="xl" fontWeight="semibold">
                  {scoreQuiz}
                </Text>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                colorScheme="green"
                onClick={onClose}
                leftIcon={<IoIosArrowBack />}
              >
                Kembali ke halaman detail pengetahuan
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const ContentExam: React.FC = () => {
  const { bankSoal, bankJawaban, indexSoal, isSavingAnswer } =
    usePengetahuanQuizContext().state;
  const { setPengetahuanQuizState } = usePengetahuanQuizContext().dispatch;

  const handleNext = () => {
    if (bankSoal.length > 0) {
      setPengetahuanQuizState("indexSoal", (indexSoal + 1) % bankSoal.length);
    }
  };

  const handleBack = () => {
    if (bankSoal.length > 0) {
      setPengetahuanQuizState("indexSoal", (indexSoal - 1) % bankSoal.length);
    }
  };

  const fetchSoal = () => {
    if (bankSoal.length > 0) {
      const soal = bankSoal[indexSoal];
      setPengetahuanQuizState("soalDipilih", soal.soal);
      setPengetahuanQuizState("bankJawaban", soal.m_jawaban_soal);
      setPengetahuanQuizState("jawabanPeserta", soal.jawaban_peserta);
    }
  };

  useEffect(() => {
    fetchSoal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexSoal]);

  return (
    <Grid
      maxW={{ base: "42rem", lg: "80rem" }}
      mx="auto"
      my={5}
      w="full"
      templateColumns="repeat(4, 1fr)"
      gap={5}
    >
      <GridItem colSpan={{ base: 4, lg: 3 }} mx={{ base: 8, lg: 0 }}>
        {/* Soal */}
        <div className="mb-5">
          <Soal soal={bankSoal} />
        </div>
        {/* Jawaban */}
        <div className="mb-5">
          <Jawaban jawaban={bankJawaban} />
        </div>
        {/* Navigation Soal */}
        <div className="bg-white shadow-md drop-shadow-2xl p-5 rounded-lg flex flex-row justify-end">
          {/* Back Ujian */}
          <If condition={indexSoal != 0}>
            <button
              type="button"
              className="text-white bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2"
              onClick={handleBack}
            >
              Soal Sebelumnya
            </button>
          </If>
          {/* Finish Ujian */}
          <button
            type="button"
            className={`text-white bg-purple-400 hover:bg-purple-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2 ${
              bankSoal.length == indexSoal + 1 ? "" : "hidden"
            } ${isSavingAnswer ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={() =>
              setPengetahuanQuizState("showModalFinishLatihan", true)
            }
            disabled={isSavingAnswer}
          >
            {isSavingAnswer ? "Menyimpan Jawaban..." : "  Selesai Kuis"}
          </button>
          {/* Next Ujian */}
          <If condition={bankSoal.length != indexSoal + 1}>
            <button
              type="button"
              className={`text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2 ${
                isSavingAnswer ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleNext}
              disabled={isSavingAnswer}
            >
              {isSavingAnswer ? "Menyimpan Jawaban..." : "Soal Selanjutnya"}
            </button>
          </If>
        </div>
      </GridItem>
      {/* Soal Pilihan Siswa */}
      <GridItem colSpan={{ base: 4, lg: 1 }} mx={{ base: 8, lg: 0 }}>
        <SoalPilihan soal={bankSoal} />
      </GridItem>
    </Grid>
  );
};

const WrapperMain = (props: Props) => {
  const { pengetahuanId } = useParams() as { pengetahuanId: string };
  const { fetchDataQuiz } = usePengetahuanQuizContext().dispatch;

  const { fetchData: fetchDetailPengetahuan } = usePengetahuanDetailContext();

  useEffect(() => {
    fetchDataQuiz(pengetahuanId);
    fetchDetailPengetahuan(pengetahuanId);
  }, []);
  return (
    <Wrapper>
      <HeaderContent />

      <ContentExam />

      <ModalFinishLatihan />
      <ModalShowScoreQuiz />
    </Wrapper>
  );
};

const PengetahuanQuiz = () => {
  return (
    <PengetahuanQuizContext.Provider>
      <PengetahuanDetailContext.Provider>
        <WrapperMain />
      </PengetahuanDetailContext.Provider>
    </PengetahuanQuizContext.Provider>
  );
};

export default PengetahuanQuiz;
