import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Grid,
  GridItem,
  Input,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Textarea,
  IconButton,
} from "@chakra-ui/react";
import Select, { StylesConfig } from "react-select";
import React, { useState } from "react";
import { useManajemenPengetahuanContext } from "../../../controller/admin/manajemen_pengetahuan";
import { useManajemenPengetahuanKontenPemateriContext } from "../../../controller/admin/manajemen_pengetahuan/konten-pemateri";
import { useKelolaKontenManajemenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/kelola_konten";
import { useYoutubeContext } from "../../../controller/youtube/index";
import BoxUploadFile from "../UploadFile/BoxUploadFile";
import MultiplePDFUpload from "../UploadFile/MultipleUploadFile";
import { MdOutlineChangeCircle } from "react-icons/md";
import { extractYouTubeVideoId } from "../../../../misc/extractYouTubeVideoId";

/**
 * A modal that is used to add a speaker to a pengetahuan item. The modal has
 * three sections: the speaker's name, the title of the materi, and the detail
 * konten. The modal also has a button to post the data to the server.
 *
 * @return {JSX.Element} The JSX element representing the modal.
 * @author Bagas Prasetyadi
 */

const ModalAddSpeaker = () => {
  const { isOpenModal, hasAssesment, hasQuiz } =
    useManajemenPengetahuanKontenPemateriContext().state;
  const { setPengetahuanKontenPemateri: setState } =
    useManajemenPengetahuanKontenPemateriContext().disptach;

  const { pengetahuanItem, listCategory, listVideoTautan } =
    useManajemenPengetahuanContext().state;

  const { optionsPaketSoal } = useKelolaKontenManajemenPelatihanContext().state;

  const {
    speakerName,
    title,
    linkStreaming,
    quizDuration,
    titleAssesment,
    descriptionAssesment,
    linkDrive,
    category,
    youtubeVideoSelected,
    videoTautan,
    pdf,
    urlTautan,
    isLoadingPost,
    listSpeakers,
    isAddingNewSpeaker,
    speakerSelected,
    pathFileAssesment,
    startDate,
    startTime,
  } = useManajemenPengetahuanKontenPemateriContext().state;
  const { setPengetahuanKontenPemateri, postData, clearState } =
    useManajemenPengetahuanKontenPemateriContext().disptach;

  const { listVideo } = useYoutubeContext().state;

  const [tabIndex, setTabIndex] = useState(0);

  const setPathFilesAssesment = (files: File[]) => {
    setState("pathFileAssesment", files);
  };

  const listOptionVideoYoutube: any = listVideo.map((item) => ({
    value: item?.videoId,
    label: item?.title,
  }));

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        padding: "2px 8px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 100,
      };
    },
  };

  const onClose = () => {
    setState("isOpenModal", false);
    clearState();
  };
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpenModal}
      onClose={onClose}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="semibold">
          Tambah Pemateri
        </ModalHeader>

        <ModalCloseButton
          bg="#F67070"
          color="white"
          _hover={{ bg: "#DC2626" }}
          _active={{ bg: "#DC2626" }}
        />
        <ModalBody>
          <Grid templateColumns="repeat(8, 1fr)" gap={6}>
            {/* Nama Pemateri */}
            <GridItem colSpan={2}>
              <Text fontWeight="semibold" fontSize="sm">
                Nama Pemateri
              </Text>
              <Text fontSize="xs">
                {isAddingNewSpeaker
                  ? "Masukkan Nama Pemateri"
                  : "Pilih Pemateri"}
              </Text>
            </GridItem>
            <GridItem colSpan={6} display="flex" justifyContent="space-between">
              <Box w="90%">
                {isAddingNewSpeaker ? (
                  <Input
                    type="text"
                    value={speakerName}
                    placeholder="Masukkan Nama Pemateri"
                    onChange={(e) =>
                      setPengetahuanKontenPemateri(
                        "speakerName",
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <Select
                    styles={colourStyles}
                    options={listSpeakers}
                    value={speakerSelected}
                    onChange={(e) =>
                      setPengetahuanKontenPemateri("speakerSelected", e)
                    }
                    placeholder="Pilih Pemateri"
                  />
                )}
              </Box>
              <IconButton
                icon={<MdOutlineChangeCircle size={20} />}
                aria-label="Switch Materi Option"
                onClick={() =>
                  setPengetahuanKontenPemateri(
                    "isAddingNewSpeaker",
                    !isAddingNewSpeaker
                  )
                }
                colorScheme="blue"
              >
                {isAddingNewSpeaker ? "Pilih Pemateri" : "Input Manual"}
              </IconButton>
            </GridItem>

            {/* Judul Materi */}
            <GridItem colSpan={2}>
              <Text fontWeight="semibold" fontSize="sm">
                Judul Materi
              </Text>
            </GridItem>
            <GridItem colSpan={6}>
              <Input
                type="text"
                value={title}
                placeholder="Masukkan Judul Materi"
                onChange={(e) =>
                  setPengetahuanKontenPemateri("title", e.target.value)
                }
              />
            </GridItem>

            {/* Detail Konten */}
            {pengetahuanItem?.jenis_pengetahuan === "synchronized" && (
              <>
                <GridItem colSpan={2}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Tanggal
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Input
                    type="date"
                    value={startDate}
                    onChange={(e) =>
                      setPengetahuanKontenPemateri("startDate", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Waktu
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Input
                    type="time"
                    value={startTime}
                    onChange={(e) =>
                      setPengetahuanKontenPemateri("startTime", e.target.value)
                    }
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Detail Konten
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Input
                    type="text"
                    value={linkStreaming}
                    placeholder="Masukkan Detail Konten"
                    onChange={(e) =>
                      setPengetahuanKontenPemateri(
                        "linkStreaming",
                        e.target.value
                      )
                    }
                  />
                </GridItem>
              </>
            )}

            {/* Tipe File */}
            {pengetahuanItem?.jenis_pengetahuan === "asynchronized" && (
              <>
                <GridItem colSpan={2}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Pilih Tipe File
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Select
                    placeholder="Pilih Tipe File"
                    options={listCategory}
                    isClearable
                    onChange={(e) => {
                      setPengetahuanKontenPemateri("category", e)
                      setPengetahuanKontenPemateri("pdf", null)
                    }}
                    value={category}
                    styles={colourStyles}
                  />
                </GridItem>
              </>
            )}

            {category?.value === "video" && (
              <>
                <GridItem colSpan={2}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Pilih Video/Tautan
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Select
                    placeholder="Pilih Video/Tautan"
                    options={listVideoTautan}
                    isClearable
                    onChange={(e) => {
                      setPengetahuanKontenPemateri("videoTautan", e)
                      setPengetahuanKontenPemateri("youtubeVideoSelected", null)
                      setPengetahuanKontenPemateri("urlTautan", "")
                    }
                    }
                    value={videoTautan}
                    styles={colourStyles}
                  />
                </GridItem>

                {videoTautan?.value === 'video' && (
                  <>
                    <GridItem colSpan={2}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Pilih Video
                      </Text>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Select
                        placeholder="Pilih Video"
                        options={listOptionVideoYoutube}
                        isClearable
                        onChange={(e) =>
                          setPengetahuanKontenPemateri("youtubeVideoSelected", e)
                        }
                        value={youtubeVideoSelected}
                        styles={colourStyles}
                      />
                    </GridItem>
                  </>
                )}

                {videoTautan?.value === 'tautan' && (
                  <>
                    <GridItem colSpan={2}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Tautan Video
                      </Text>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <Input
                        type="text"
                        value={urlTautan}
                        placeholder="Masukkan Tautan Video Pembelajaran"
                        onChange={(e) =>
                          setPengetahuanKontenPemateri("urlTautan", e.target.value)
                        }
                      />
                    </GridItem>
                  </>
                )}

                {youtubeVideoSelected?.value && (
                  <>
                    <GridItem colSpan={2}></GridItem>
                    <GridItem colSpan={6}>
                      <iframe
                        title="youtube video"
                        src={`https://www.youtube.com/embed/${youtubeVideoSelected.value}`}
                        allowFullScreen
                        style={{ width: "100%", height: "300px" }}
                      />
                    </GridItem>
                  </>
                )}
                {extractYouTubeVideoId(urlTautan) && (
                  <>
                    <GridItem colSpan={2}></GridItem>
                    <GridItem colSpan={6}>
                      <iframe
                        title="youtube video"
                        src={`https://www.youtube.com/embed/${extractYouTubeVideoId(urlTautan)}`}
                        allowFullScreen
                        style={{ width: "100%", height: "300px" }}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}

            {/* Upload PDF */}
            {category?.value === "pdf" && (
              <>
                <GridItem colSpan={2}>
                  <Text fontWeight="semibold" fontSize="sm">
                    Upload PDF
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <BoxUploadFile
                    setFile={setPengetahuanKontenPemateri}
                    typeFile="pdf"
                    defaultFile={pdf}
                  />
                </GridItem>
              </>
            )}

            {/* Quiz/Assesment */}
            <GridItem colSpan={2}>
              <Text fontWeight="semibold" fontSize="sm">
                Tambah Quiz/Assignment
              </Text>
            </GridItem>
            <GridItem
              colSpan={6}
              display="flex"
              textAlign="center"
              justifyContent="space-between"
            >
              <Box
                w="49%"
                py={2}
                bgColor="green.100"
                borderColor="green"
                borderWidth="1px"
                borderRadius="lg"
              >
                <label className="container text-green-500">
                  Quiz
                  <input
                    type="checkbox"
                    className="ml-2"
                    checked={hasQuiz}
                    onChange={(e) =>
                      setPengetahuanKontenPemateri("hasQuiz", e.target.checked)
                    }
                  />
                </label>
              </Box>
              <Box
                w="49%"
                py={2}
                bgColor="orange.100"
                borderColor="orange"
                borderWidth="1px"
                borderRadius="lg"
              >
                <label className="container text-orange-500">
                  Assignment
                  <input
                    type="checkbox"
                    className="ml-2"
                    checked={hasAssesment}
                    onChange={(e) =>
                      setPengetahuanKontenPemateri(
                        "hasAssesment",
                        e.target.checked
                      )
                    }
                  />
                </label>
              </Box>
            </GridItem>

            {(hasQuiz || hasAssesment) && (
              <>
                <GridItem colSpan={2}></GridItem>
                <GridItem colSpan={6}>
                  <Tabs
                    variant="line"
                    colorScheme="green"
                    onChange={(index) => setTabIndex(index)}
                    index={tabIndex}
                  >
                    <TabPanels>
                      {hasQuiz && (
                        <Box
                          border="1px"
                          borderColor="gray.500"
                          borderRadius="md"
                          p={4}
                        >
                          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                            <GridItem colSpan={6}>
                              <Text
                                color="#3C4952"
                                fontWeight="bold"
                                fontSize="md"
                              >
                                Tambahan Quiz
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2}>Pilih Soal</GridItem>
                            <GridItem colSpan={4}>
                              <Select
                                className="w-full"
                                options={optionsPaketSoal}
                                onChange={(val: any) =>
                                  setPengetahuanKontenPemateri(
                                    "paketSoalId",
                                    val.value
                                  )
                                }
                                placeholder="Pilih Soal"
                                styles={colourStyles}
                              />
                            </GridItem>

                            {/* Durasi Soal */}
                            <GridItem colSpan={2}>Durasi Soal</GridItem>
                            <GridItem colSpan={4}>
                              <Input
                                type="number"
                                fontSize="sm"
                                value={quizDuration}
                                onChange={(e) =>
                                  setPengetahuanKontenPemateri(
                                    "quizDuration",
                                    e.target.value
                                  )
                                }
                              />
                            </GridItem>
                          </Grid>
                        </Box>
                      )}

                      {hasAssesment && (
                        <Box
                          border="1px"
                          borderColor="gray.500"
                          borderRadius="md"
                          p={4}
                          mt={hasQuiz ? 4 : 0}
                        >
                          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                            <GridItem colSpan={6}>
                              <Text
                                color="#3C4952"
                                fontWeight="bold"
                                fontSize="md"
                              >
                                Tambahan Assignment
                              </Text>
                            </GridItem>

                            {/* Judul Assesment */}
                            <GridItem colSpan={2}>Judul</GridItem>
                            <GridItem colSpan={4}>
                              <Input
                                fontSize="sm"
                                type="text"
                                value={titleAssesment}
                                onChange={(e) =>
                                  setPengetahuanKontenPemateri(
                                    "titleAssesment",
                                    e.target.value
                                  )
                                }
                              />
                            </GridItem>

                            {/* Dekripsi Assesment */}
                            <GridItem colSpan={2}>Deskripsi</GridItem>
                            <GridItem colSpan={4}>
                              <Textarea
                                fontSize="sm"
                                value={descriptionAssesment}
                                onChange={(e) =>
                                  setPengetahuanKontenPemateri(
                                    "descriptionAssesment",
                                    e.target.value
                                  )
                                }
                              />
                            </GridItem>

                            <GridItem colSpan={2}>Bahan Tugas</GridItem>
                            <GridItem colSpan={4}>
                              <MultiplePDFUpload
                                setFiles={setPathFilesAssesment}
                                defaultFiles={pathFileAssesment}
                              />
                            </GridItem>

                            {/* Link Assesment */}
                            <GridItem colSpan={2}>Link Assignment</GridItem>
                            <GridItem colSpan={4}>
                              <Input
                                fontSize="sm"
                                value={linkDrive}
                                onChange={(e) =>
                                  setPengetahuanKontenPemateri(
                                    "linkDrive",
                                    e.target.value
                                  )
                                }
                              />
                            </GridItem>
                          </Grid>
                        </Box>
                      )}
                    </TabPanels>
                  </Tabs>
                </GridItem>
              </>
            )}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Batal
          </Button>
          <Button
            colorScheme="green"
            isLoading={isLoadingPost}
            onClick={() => postData(pengetahuanItem?.id)}
          >
            Simpan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddSpeaker;
