import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  IoIosArrowForward,
  IoIosInformationCircleOutline,
} from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Wrapper } from "../../../components/Wrapper";
import StatusPengajuanController, {
  LogKontributorPengajuan,
  useStatusKontributor,
} from "../../../controller/kontributor/status-pengajuan";

/**
 * A component that displays a grid item with a label and a value.
 *
 * @param {string} label - The label to be displayed.
 * @param {string} value - The value to be displayed.
 * @return {JSX.Element} The JSX element representing the grid item.
 */
const ItemDetail = ({ label, value }) => (
  <Flex justifyContent="space-between" alignItems="center" mt={2}>
    <Text fontSize="sm">{label}</Text>
    <Text fontSize="sm" fontWeight="semibold">
      {value ?? "-"}
    </Text>
  </Flex>
);

/**
 * A component that displays a single item in the history list of a contributor's application status.
 *
 * @param {Object} props - The props object.
 * @param {LogKontributorPengajuan} props.item - The item to be displayed.
 * @return {JSX.Element} The JSX element representing the item.
 */
const ListItemHistory = ({ item }: { item: LogKontributorPengajuan }) => {
  const [date, time] = item.created_at.split(/(?=\d{2}:\d{2})/);
  return (
    <Flex mt={4}>
      <Box>
        <Text fontWeight="semibold" fontSize="sm">
          {date}
        </Text>
        <Text color="#9B9B9B" fontSize="xs" mt={1}>
          {time}
        </Text>
      </Box>
      <Box mx={6} position="relative">
        {/* Circle Icon */}
        <Box
          width="10px"
          height="10px"
          bg="green.700"
          borderRadius="50%"
          position="absolute"
          top="0px"
          left="50%"
          transform="translateX(-50%)"
        />
        <Divider
          orientation="vertical"
          h="70%"
          position="absolute"
          bottom="0"
        />
      </Box>
      <Box>
        <Text fontWeight="semibold" fontSize="sm">
          {item?.status}
        </Text>
        <Text fontSize="xs" mt={1}>
          {item?.alasan}
        </Text>
      </Box>
    </Flex>
  );
};

/**
 * Component for displaying the status of a contributor's application.
 * Renders the application details and tracking history.
 *
 * @return {JSX.Element} The status pengajuan kontributor component
 * @author Bagas Prasetyadi
 */

const KontibutorStatusPengajuan = () => {
  const { statusPengajuanItem, isLoading } = useStatusKontributor().state;
  const { fetchData } = useStatusKontributor().dispatch;

  const history = useHistory();

  const { nomorPengajuan } = useParams() as { nomorPengajuan: string };

  useEffect(() => {
    fetchData(nomorPengajuan);
  }, []);

  return (
    <Wrapper>
      <Box my={8} mx={10}>
        <Stack spacing={2} direction="row" alignItems="center" mt={2}>
          <Text
            fontSize="sm"
            color="#7A8990"
            onClick={() => history.goBack()}
            cursor="pointer"
            _hover={{ color: "green.700" }}
          >
            Kontibutor Status Pengajuan
          </Text>
          <Icon as={IoIosArrowForward} mr={2} boxSize={4} />
          <Text fontSize="sm" color="green.700" fontWeight="semibold">
            Cek Status Pengajuan
          </Text>
        </Stack>
        <Box my={4} textAlign="center">
          <Heading
            as="h1"
            fontSize={{ base: "2xl", md: "3xl" }}
            fontWeight="semibold"
          >
            Status Pengajuan Anda
          </Heading>
          {isLoading ? (
            <Loader />
          ) : !statusPengajuanItem ? (
            <Center mt={10}>
              <Box
                bg="white"
                p={6}
                rounded="md"
                boxShadow="lg"
                maxW="sm"
                textAlign="center"
              >
                <Icon
                  as={IoIosInformationCircleOutline}
                  boxSize={12}
                  color="gray.400"
                  mb={4}
                />
                <Text fontSize="lg" fontWeight="semibold" mb={2}>
                  Data Pengajuan Tidak Ditemukan
                </Text>
                <Text fontSize="sm" color="gray.500">
                  Cek kembali nomor pengajuan anda
                </Text>
              </Box>
            </Center>
          ) : (
            <Grid templateColumns="repeat(4, 1fr)" gap={4} mt={4}>
              <GridItem colSpan={1}>
                <Box bg="white" p={4} rounded="md">
                  <Text fontSize="md" fontWeight="semibold">
                    Detail Pengajuan{" "}
                  </Text>
                  <Divider my={3} />
                  <ItemDetail
                    label="Nama Lengkap"
                    value={statusPengajuanItem?.nama_lengkap}
                  />
                  <ItemDetail label="NIK" value={statusPengajuanItem?.nik} />
                  <ItemDetail label="NIP" value={statusPengajuanItem?.nip} />
                  <ItemDetail
                    label="Email"
                    value={statusPengajuanItem?.email}
                  />
                  <ItemDetail
                    label="No. HP"
                    value={statusPengajuanItem?.nomor_telepon}
                  />
                  <ItemDetail
                    label="Judul"
                    value={statusPengajuanItem?.judul}
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={3}>
                <Box bg="white" p={4} rounded="md">
                  <Flex flexDir="column" w="full" alignItems="flex-start">
                    <Text fontSize="sm">Nomor Pengajuan</Text>
                    <Text fontSize="xl" color="#2E81F7" fontWeight="semibold">
                      {nomorPengajuan}
                    </Text>
                  </Flex>
                  <Divider my={3} />
                  <Box textAlign="left">
                    <Text fontSize="sm">Lacak Histori Pengajuan</Text>
                    <Box display="flex" flexDirection="column">
                      {statusPengajuanItem?.log_kontributor_pengetahuan.map(
                        (item: LogKontributorPengajuan) => {
                          return <ListItemHistory item={item} key={item.id} />;
                        }
                      )}
                    </Box>
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

const StatusPengajuanWrapper = () => {
  return (
    <StatusPengajuanController>
      <KontibutorStatusPengajuan />
    </StatusPengajuanController>
  );
};

export default StatusPengajuanWrapper;
