import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

interface SlideBannerItem {
  id: number;
  title: string;
  description: string;
  link: string;
  file: string;
  status_publish: number;
  created_at: string;
  updated_at: string;
}

interface IState {
  state: {
    sliderBannerData: SlideBannerItem[];
    loadingData: boolean;
    url: string;
  };
  setSlideBannerState: Function;
  fetchData: Function;
}

const initialState: IState = {
  state: {
    sliderBannerData: [],
    loadingData: true,
    url: "public/slider_banner",
  },
  setSlideBannerState: () => {},
  fetchData: () => {},
};

/**
 * The SlideBannerContext provides a context for managing the state related to slide banners.
 * It includes state properties and functions to update the state and post data to a server.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: SliderBannerPublicProvider } = Context;

/**
 * The provider component that wraps its children with the SlideBannerContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const { url } = state.state;

  const setSlideBannerState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async () => {
    setSlideBannerState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: "1",
        },
      });

      setSlideBannerState("sliderBannerData", response.data?.data);
      setSlideBannerState("loadingData", false);
    } catch (error) {
      setSlideBannerState("loadingData", false);
      throw error;
    }
  };

  return (
    <SliderBannerPublicProvider
      value={{
        state: state.state,
        setSlideBannerState,
        fetchData,
      }}
    >
      {children}
    </SliderBannerPublicProvider>
  );
};

export const useSliderBannerPublicContext = () => useContext(Context);

const SliderBannerPublicContext = {
  useSliderBannerPublicContext,
  Provider,
};

export default SliderBannerPublicContext;
