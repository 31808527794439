import {
  Box,
  Button,
  Checkbox,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import Select, { StylesConfig } from "react-select";
import BoxUploadFile from "../../../components/Admin/UploadFile/BoxUploadFile";
import ModalNomorPengajuan from "../../../components/Kontributor/ModalNomorPengajuan";
import { Wrapper } from "../../../components/Wrapper";
import ManajemenPengetahuanContext, {
  useManajemenPengetahuanContext,
} from "../../../controller/admin/manajemen_pengetahuan";
import KontributorPengajuanController, {
  useKontibutorPengajuanContext,
} from "../../../controller/kontributor/pengajuan";
import KabupatenController, {
  useKabupatenContext,
} from "../../../controller/wilayah/kabupaten";
import KecamatanController, {
  useKecamatanContext,
} from "../../../controller/wilayah/kecamatan";
import KelurahanController, {
  useKelurahanContext,
} from "../../../controller/wilayah/kelurahan";

import ProvinsiController, {
  useProvinsiContext,
} from "../../../controller/wilayah/provinsi";

/**
 * A function that handles the contribution of knowledge by a user.
 * It manages the state of the contribution form, validates user input,
 * and submits the contribution data to the server.
 *
 * @return {JSX.Element} The JSX element representing the contribution form.
 * @author Bagas Prasetyadi
 */
const KontributorPengajuan = () => {
  const {
    fullName,
    nik,
    nip,
    email,
    phoneNumber,
    title,
    linkVideo,
    description,
    listFileType,
    pdf,
    fileType,
    provinsiSelected,
    kabupatenSelected,
    kecamatanSelected,
    kelurahanSelected,
    image,
    isLoading,
    listBidangPengetahuan,
  } = useKontibutorPengajuanContext().state;
  const { setKontibutorPengajuanState, postData } =
    useKontibutorPengajuanContext().dispatch;

  const [isChecked, setIsChecked] = useState(false);

  const toast = useToast();

  const handleFileNaskahChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2 MB in bytes

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        event.target.value = "";
        toast({
          title: "Hanya file PDF  yang diizinkan!",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      if (file.size > maxSize) {
        event.target.value = "";
        toast({
          title: "Ukuran file maksimal adalah 2 MB!",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      setKontibutorPengajuanState("fileNaskah", file);
    }
  };

  // Get Provinsi
  const { getAllProvinsi } = useProvinsiContext();
  const { provinsi, isLoading: isLoadingProvinsi } = useProvinsiContext().state;
  // Get Kabupaten
  const { getKabupatenById } = useKabupatenContext();
  const { kabupaten, isLoading: isLoadingKabupatenKota } =
    useKabupatenContext().state;

  // Get Kecamatan
  const { getKecamatanById } = useKecamatanContext();
  const { kecamatan, isLoading: isLoadingKecamatan } =
    useKecamatanContext().state;

  // Get Kelurahan
  const { getKelurahanById } = useKelurahanContext();
  const { kelurahan, isLoading: isLoadingKelurahan } =
    useKelurahanContext().state;

  useEffect(() => {
    getAllProvinsi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provinsiSelected) {
      getKabupatenById(provinsiSelected?.value);
      setKontibutorPengajuanState("kabupatenSelected", null);
      setKontibutorPengajuanState("kecamatanSelected", null);
      setKontibutorPengajuanState("kelurahanSelected", null);
    }
  }, [provinsiSelected]);

  useEffect(() => {
    if (kabupatenSelected) {
      getKecamatanById(kabupatenSelected?.value);
      setKontibutorPengajuanState("kecamatanSelected", null);
      setKontibutorPengajuanState("kelurahanSelected", null);
    }
  }, [kabupatenSelected]);

  useEffect(() => {
    if (kecamatanSelected) {
      getKelurahanById(kecamatanSelected?.value);
    }
  }, [kecamatanSelected]);

  const handlePostKontributor = async () => {
    try {
      const response = await postData();
      console.log({ response });
      if (response.code === 200) {
        toast({
          title: "Berhasil",
          description: "Berhasil Mengajukan",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setKontibutorPengajuanState("fileNaskah", null);
        setKontibutorPengajuanState("isOpenModalNomorPengajuan", true);
        setKontibutorPengajuanState(
          "nomorPengajuan",
          response.data?.nomor_pengajuan
        );
      }
    } catch (error) {
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      if (responseData.code === 422) {
        Object.keys(responseData.errors).forEach((key) => {
          responseData.errors[key].forEach((errorMessage) => {
            toast({
              title: titleMessage,
              description: errorMessage,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          });
        });
      } else {
        toast({
          title: "Terjadi kesalahan saat menyimpan data",
          status: "error",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        padding: "2px 8px",
        fontSize: "12px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 100,
      };
    },
  };

  const listProvinsi = provinsi.map((item) => {
    return { value: item.id, label: item.nama_provinsi };
  });
  const listKabupatenKota = kabupaten.map((item) => {
    return { value: item.id, label: item.nama_kabupaten };
  });
  const listKecamatan = kecamatan.map((item) => {
    return { value: item.id, label: item.nama_kecamatan };
  });
  const listKelurahan = kelurahan.map((item) => {
    return { value: item.id, label: item.nama_kelurahan };
  });

  // Reusable label component with red asterisk
  const LabelWithAsterisk = ({ label, isRequired = false }) => (
    <Text>
      {label}
      {isRequired && (
        <Text as="span" color="red">
          *
        </Text>
      )}
    </Text>
  );

  return (
    <Wrapper>
      <Box my={20} mx="auto" textAlign="center">
        <Box mb={6}>
          <Heading
            as="h2"
            fontSize={{ base: "2xl", md: "3xl" }}
            mx={{ base: 4, md: 0 }}
          >
            Pengajuan Kontributor Pengetahuan
          </Heading>
        </Box>
        <Box
          bg="white"
          w={{ base: "auto", md: "3xl" }}
          py={10}
          px={6}
          rounded="md"
          mx={{ base: "4", md: 0 }}
        >
          <Grid
            templateColumns="repeat(5, 1fr)"
            gap={4}
            alignItems="center"
            textAlign="left"
          >
            {/* Nama */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Nama Lengkap" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                placeholder="Masukkan Nama Lengkap"
                value={fullName}
                onChange={(e) =>
                  setKontibutorPengajuanState("fullName", e.target.value)
                }
              />
            </GridItem>

            {/* NIK */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="NIK" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                placeholder="Masukkan NIK"
                value={nik}
                type="number"
                onChange={(e) =>
                  setKontibutorPengajuanState("nik", e.target.value)
                }
              />
            </GridItem>

            {/* NIP */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="NIP" />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                placeholder="Masukkan NIP"
                value={nip}
                type="number"
                onChange={(e) =>
                  setKontibutorPengajuanState("nip", e.target.value)
                }
              />
            </GridItem>

            {/* Email */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Email" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                placeholder="Masukkan Email"
                value={email}
                onChange={(e) =>
                  setKontibutorPengajuanState("email", e.target.value)
                }
              />
            </GridItem>

            {/* No HP */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="No HP" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                placeholder="Masukkan No HP"
                value={phoneNumber}
                type="number"
                onChange={(e) =>
                  setKontibutorPengajuanState("phoneNumber", e.target.value)
                }
              />
            </GridItem>

            {/* Judul */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Judul" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                placeholder="Masukkan Judul"
                value={title}
                onChange={(e) =>
                  setKontibutorPengajuanState("title", e.target.value)
                }
              />
            </GridItem>

            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Bidang Pengetahuan" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Select
                placeholder="Pilih Bidang Pengetahuan"
                options={listBidangPengetahuan}
                onChange={(e) =>
                  setKontibutorPengajuanState("bidangPengetahuan", e)
                }
                styles={colourStyles}
              />
            </GridItem>

            {/* Kategori */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Kategori" />
            </GridItem>
            <GridItem colSpan={4}>
              <Input value={"Lainnya"} disabled />
            </GridItem>

            {/* Jenis File */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Jenis File" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Select
                placeholder="Pilih Jenis File"
                options={listFileType}
                onChange={(e) => setKontibutorPengajuanState("fileType", e)}
                styles={colourStyles}
              />
            </GridItem>

            {fileType?.value === "pdf" && (
              <>
                {/* Dokumen Publikasi */}
                <GridItem colSpan={1}>
                  <LabelWithAsterisk label="Dokumen Publikasi" isRequired />
                </GridItem>
                <GridItem colSpan={4}>
                  <BoxUploadFile
                    setFile={setKontibutorPengajuanState}
                    typeFile="pdf"
                    defaultFile={pdf}
                  />
                </GridItem>
              </>
            )}

            {fileType?.value === "video" && (
              <>
                {/* Link Kontribusi */}
                <GridItem colSpan={1}>
                  <LabelWithAsterisk label="Link Kontribusi" isRequired />
                </GridItem>
                <GridItem colSpan={4}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <FaLink color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder="Https://"
                      valie={linkVideo}
                      onChange={(e) =>
                        setKontibutorPengajuanState("linkVideo", e.target.value)
                      }
                    />
                  </InputGroup>
                </GridItem>
              </>
            )}

            {/* Thumbnail */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Thumbnail" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <BoxUploadFile
                setFile={setKontibutorPengajuanState}
                typeFile="image"
                defaultFile={image}
              />
            </GridItem>

            {/* Naskah Publikasi */}
            <GridItem>
              <LabelWithAsterisk label="Naskah Publikasi" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Input
                type="file"
                onChange={handleFileNaskahChange}
                accept=".pdf"
              />
              <Text color="red" fontSize="xs" fontStyle="italic">
                *Upload File pdf. Maksimal ukuran file 2MB.
              </Text>
            </GridItem>

            {/* Provinsi */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Provinsi" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Select
                placeholder="Pilih Provinsi"
                options={listProvinsi}
                value={provinsiSelected}
                onChange={(e) =>
                  setKontibutorPengajuanState("provinsiSelected", e)
                }
                styles={colourStyles}
                isLoading={isLoadingProvinsi}
                loadingMessage={() => "Sedang memuat data provinsi..."}
              />
            </GridItem>

            {/* Kabupaten Kota */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Kabupaten/Kota" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Select
                placeholder="Pilih Kabupaten/Kota"
                options={listKabupatenKota}
                value={kabupatenSelected}
                onChange={(e) =>
                  setKontibutorPengajuanState("kabupatenSelected", e)
                }
                styles={colourStyles}
                isLoading={isLoadingKabupatenKota}
                loadingMessage={() => "Sedang memuat data kabupaten/kota..."}
              />
            </GridItem>

            {/* Kecamatan */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Kecamatan" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Select
                placeholder="Pilih Kecamatan"
                options={listKecamatan}
                value={kecamatanSelected}
                onChange={(e) =>
                  setKontibutorPengajuanState("kecamatanSelected", e)
                }
                styles={colourStyles}
                isLoading={isLoadingKecamatan}
                loadingMessage={() => "Sedang memuat data kecamatan..."}
              />
            </GridItem>

            {/* Kelurahan */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Kelurahan" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Select
                placeholder="Pilih Kelurahan"
                options={listKelurahan}
                value={kelurahanSelected}
                onChange={(e) =>
                  setKontibutorPengajuanState("kelurahanSelected", e)
                }
                styles={colourStyles}
                isLoading={isLoadingKelurahan}
                loadingMessage={() => "Sedang memuat data kelurahan..."}
              />
            </GridItem>

            {/* Deskripsi */}
            <GridItem colSpan={1}>
              <LabelWithAsterisk label="Deskripsi Singkat" isRequired />
            </GridItem>
            <GridItem colSpan={4}>
              <Textarea
                placeholder="Masukkan Deskripsi"
                value={description}
                onChange={(e) =>
                  setKontibutorPengajuanState("description", e.target.value)
                }
              />
            </GridItem>
          </Grid>

          {/* divider */}
          <Box
            w="full"
            mt={6}
            borderTop="1px"
            borderColor="gray.300"
            borderStyle="dashed"
          ></Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={10}
          >
            <Stack direction="row" spacing={2}>
              <Checkbox
                isChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                colorScheme="green"
              />
              <Text>
                <Box as="span">Saya setuju dengan</Box>
                <Box as="span" color="#007E39" fontWeight="bold" ml={1}>
                  syarat & ketentuan berlaku
                </Box>
              </Text>
            </Stack>
            <Button
              colorScheme="green"
              onClick={() => handlePostKontributor()}
              isLoading={isLoading}
              isDisabled={!isChecked}
            >
              Daftar
            </Button>
          </Box>
        </Box>
      </Box>
      <ModalNomorPengajuan />
    </Wrapper>
  );
};

const KontributorPengajuanWrapper = () => {
  return (
    <KontributorPengajuanController>
      <ProvinsiController.Provider>
        <KabupatenController.Provider>
          <KecamatanController.Provider>
            <KelurahanController.Provider>
              <ManajemenPengetahuanContext.Provider>
                <KontributorPengajuan />
              </ManajemenPengetahuanContext.Provider>
            </KelurahanController.Provider>
          </KecamatanController.Provider>
        </KabupatenController.Provider>
      </ProvinsiController.Provider>
    </KontributorPengajuanController>
  );
};

export default KontributorPengajuanWrapper;
