import { AxiosResponse } from "axios";
import { KategoriPaketSoal } from "../../entities/KategoriPaketSoal";

export class KategoriPaketSoalDataMapper {
  public convertListKategoriPaketSoalDataFromApi(
    result: AxiosResponse<any>
  ): KategoriPaketSoal[] {
    const { data } = result.data;

    let kategoriPaketSoal: KategoriPaketSoal[] = [];

    data.map((d: any) => {
      return kategoriPaketSoal.push(
        new KategoriPaketSoal(
          d.id,
          d.kategori_paket_soal,
          d.created_at,
          d.updated_at,
          result.data.pagination.total_rows
        )
      );
    });

    return kategoriPaketSoal;
  }
}
