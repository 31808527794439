import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useKontibutorPengajuanContext } from "../../controller/kontributor/pengajuan";

/**
 * ModalDetailKontribusi component is responsible for rendering a modal dialog
 * to display details about a contribution, either as a video or PDF.
 *
 * It dynamically embeds a YouTube video or a PDF document based on the selected category.
 *
 * @component
 * @example
 * return (
 *   <ModalDetailKontribusi />
 * )
 * @author Bagas Prasetyadi
 */
const ModalDetailKontribusi = () => {
  const {
    isOpenModalDetailKontribusi: isOpen,
    pathDetailKontribusi,
    categorySelected,
  } = useKontibutorPengajuanContext().state;
  const { setKontibutorPengajuanState: setState } =
    useKontibutorPengajuanContext().dispatch;

  const onClose = () => {
    setState("isOpenModalDetailKontribusi", false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="semibold" color="black">
          Detail Kontribusi
        </ModalHeader>
        <ModalCloseButton
          bg="#F67070"
          color="white"
          _hover={{ bg: "#DC2626" }}
          _active={{ bg: "#DC2626" }}
          w="30px"
          h="30px"
          mt={1}
        />
        <ModalBody textAlign="center" pb={4}>
          {categorySelected === "video" && (
            <iframe
              title="detail kontribusi video"
              src={`https://www.youtube.com/embed/${pathDetailKontribusi}`}
              width="100%"
              height="350px"
            ></iframe>
          )}

          {categorySelected === "pdf" && (
            <iframe
              title="detail kontribusi pdf"
              src={`${pathDetailKontribusi}`}
              width="100%"
              height="350px"
            ></iframe>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalDetailKontribusi;
