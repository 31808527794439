import React, { useEffect } from "react";
import {
  Flex,
  Heading,
  Box,
  Button,
  Text,
  Input,
  Grid,
  GridItem,
  Switch,
  Textarea,
  Skeleton,
  IconButton,
} from "@chakra-ui/react";
import { AiFillPlusCircle, AiOutlineArrowLeft } from "react-icons/ai";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import CardItem from "../../../../components/Admin/Card/CardItem";
import BoxUploadFile from "../../../../components/Admin/UploadFile/BoxUploadFile";
import SlideBannerController, {
  useSlideBannerContext,
} from "../../../../controller/admin/landing_page/slide_banner";
import Pagination from "../../../../components/Pagination";

/**
 * SlideBanner is a component that allows users to manage slide banners.
 * It provides a form to create new slide banners and displays a list of existing slide banners.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */

const SlideBanner = () => {
  const {
    setSlideBannerState,
    fetchData,
    handleDelete,
    postData,
    clearState,
    handleEdit,
  } = useSlideBannerContext();
  const state = useSlideBannerContext().state;

  const {
    publish,
    title,
    description,
    link,
    image,
    loadingData,
    mode,
    loadingAction,
    sliderBannerData: data,
    pagination,
    perPage,
    page,
  } = state;

  const totalPage = pagination ? Math.ceil(pagination.total_rows / perPage) : 0;

  const handlePageChange = (e) => {
    setSlideBannerState("page", e.selected + 1);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="var(--grey-100, #EDF2F7)"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            {mode !== "" && (
              <IconButton
                aria-label="Previous Page"
                icon={<AiOutlineArrowLeft />}
                fontSize="md"
                mr={2}
                onClick={() => setSlideBannerState("mode", "")}
              />
            )}
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              {mode === "create" ? "Tambah" : mode === "edit" ? "Ubah" : ""}{" "}
              Slide Banner
            </Heading>
          </Flex>
          {(mode === "create" || mode === "edit") && publish ? (
            <Button
              colorScheme="green"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Publish"
              onClick={() => postData()}
            >
              Publish
            </Button>
          ) : (mode === "create" || mode === "edit") && !publish ? (
            <Button
              color="white"
              bg="#3C4952"
              _hover={{ bg: "#3C4952" }}
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              isLoading={loadingAction}
              spinnerPlacement="start"
              loadingText="Simpan Draft"
              onClick={() => postData()}
            >
              Simpan Draft
            </Button>
          ) : (
            <Button
              colorScheme="green"
              rightIcon={<AiFillPlusCircle color="white" size={16} />}
              onClick={() => {
                clearState();
                setSlideBannerState("mode", "create");
              }}
            >
              Tambah Baru
            </Button>
          )}
        </Flex>
      </Box>
      {mode === "create" || mode === "edit" ? (
        <Box m={4} px={4} py={8} boxShadow="lg" borderRadius="xl" bg="white">
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* judul */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Judul
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={title}
                onChange={(e) => setSlideBannerState("title", e.target.value)}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
              />
            </GridItem>

            {/* Banner */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Banner
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <BoxUploadFile
                setFile={setSlideBannerState}
                typeFile="image"
                defaultFile={image}
              />
            </GridItem>

            {/* Deskripsi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Deskripsi
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Textarea
                defaultValue={description}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) =>
                  setSlideBannerState("description", e.target.value)
                }
              />
            </GridItem>

            {/* Link */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Link
              </Text>
            </GridItem>
            <GridItem colSpan={5}>
              <Input
                defaultValue={link}
                bg="#F6F5F8"
                _focus={{
                  ring: "1",
                  ringColor: "green",
                  borderColor: "green",
                }}
                onChange={(e) => setSlideBannerState("link", e.target.value)}
              />
            </GridItem>

            {/* Publikasi */}
            <GridItem colSpan={1}>
              <Text fontWeight="semibold" fontSize="sm">
                Publikasi
              </Text>
            </GridItem>
            <GridItem colSpan={5} display="flex">
              <Switch
                defaultChecked={publish}
                colorScheme="green"
                mr={2}
                onChange={(e) =>
                  setSlideBannerState("publish", e.target.checked)
                }
              />
              <Text fontWeight="semibold" fontSize="sm">
                {publish ? "Publikasi" : "Draft"}
              </Text>
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={4} m={4}>
          {loadingData ? (
            <>
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
              <Skeleton height="170px" width="100%" />
            </>
          ) : (
            data.map((item) => (
              <GridItem colSpan={{ base: 3, md: 1 }} key={item.id}>
                <CardItem
                  item={item}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  image={item.file}
                  module="slide banner"
                />
              </GridItem>
            ))
          )}
        </Grid>
      )}
      {mode === "" && pagination && totalPage > 1 && (
        <Flex justifyContent="end" mt={2} mb={4} mr={3}>
          <Pagination
            handleChange={handlePageChange}
            totalPage={totalPage}
            page={page}
          />
        </Flex>
      )}
    </SidebarWithHeader>
  );
};

const SlideBannerMain: React.FC = () => {
  return (
    <SlideBannerController.Provider>
      <SlideBanner />
    </SlideBannerController.Provider>
  );
};

export default SlideBannerMain;
