import { injectable } from 'tsyringe'
import { AuthApiRepository } from '../../app/repository/api/AuthApiRepository'
import { User } from '../../entities/User'

@injectable()
export class AuthPresenter {
  private repository: AuthApiRepository
  constructor(repository: AuthApiRepository) {
    this.repository = repository
  }

  /**
   * [doLoginPresenter description]
   *
   * @param   {string}         no_akun   [no_akun description]
   * @param   {string<User>}   password  [password description]
   *
   * @return  {Promise<User>}            [return description]
   */
  public doLoginPresenter(no_akun: string, password: string): Promise<User> {
    return this.repository.login(no_akun, password)
  }

  /**
   * [doRegisterPresenter description]
   *
   * @return  {[type]}  [return description]
   */
  public doRegisterPresenter(
    nip: string,
    nik: string,
    name: string,
    email: string,
    jenis_user: number,
    password: string,
    password_confirmation: string,
    path_foto: File,
    g_recaptcha_response: string
  ): Promise<any> {
    return this.repository.register(
      nip,
      nik,
      name,
      email,
      jenis_user,
      password,
      password_confirmation,
      path_foto,
      g_recaptcha_response
    )
  }

  /**
   * [requestResetPassword description]
   *
   * @return  {[type]}  [return description]
   */
  public requestResetPassword(
    email: string
  ): Promise<any> {
    return this.repository.requestResetPassword(email)
  }

  /**
   * [resetPassword description]
   *
   * @return  {[type]}  [return description]
   */
  public resetPassword(
    token: string,
    password: string,
    password_confirmation: string
  ): Promise<any> {
    return this.repository.resetPassword(token, password, password_confirmation)
  }
}
