/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Sidebar } from "../../../../../components/Admin/Sidebar";
import { Navbar } from "../../../../../components/Admin/Navbar";
import { BsFillPencilFill, BsFillTrashFill } from "react-icons/all";
import PrettyRating from "pretty-rating-react";
import { ReactComponent as UsersIconYellow } from "../../../../../assets/svg/ic_users_yellow.svg";
import { ReactComponent as CalendarIconYellow } from "../../../../../assets/svg/ic_calendar_yellow.svg";
import { ReactComponent as TimeIconYellow } from "../../../../../assets/svg/ic_time_yellow.svg";
import DetailPenilaianPelatihanController, {
  useDetailPenilaianPelatihanContext,
} from "../../../../../controller/admin/manajemen_pelatihan/detail/penilaian";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const colors = {
  star: ["#FFDA6A", "#FFDA6A", "#434b4d"],
};

const BottomHeader: React.FC = () => {
  const { setSelectedId, setShowDialogDelete } =
    useDetailPenilaianPelatihanContext();
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Detail Pelatihan</a>
      </div>
      <div className="flex flex-row">
        <button
          type="button"
          className="mr-2 flex px-6 py-2.5 bg-red-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-red-700 mb-3 mt-3"
          onClick={() => {
            setSelectedId(window.location.pathname.split("/", 6)[5]);
            setShowDialogDelete(true);
          }}
        >
          Hapus
          <BsFillTrashFill className="ml-2" style={{ marginTop: "2px" }} />
        </button>
        <a
          href={`/admin/ubah/manajemen_pelatihan/${
            window.location.pathname.split("/", 6)[5]
          }`}
          className="mr-5 flex px-6 py-2.5 bg-green-600 text-white font-semibold text-xs leading-tight rounded shadow-md hover:bg-green-700 mb-3 mt-3"
        >
          Ubah
          <BsFillPencilFill className="ml-2" style={{ marginTop: "2px" }} />
        </a>
      </div>
    </div>
  );
};

const HeaderBanner: React.FC = () => {
  const { getDetailPelatihan } = useDetailPenilaianPelatihanContext();
  const { pelatihan, isLoading } = useDetailPenilaianPelatihanContext().state;

  useEffect(() => {
    getDetailPelatihan(window.location.pathname.split("/", 6)[5]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container px-9 mt-6">
      <div className="py-10 bg-green-600 mt-6 rounded-lg">
        <If condition={isLoading}>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="col-span-1 md:col-span-2">
              <div className="ml-10 mr-10 rounded-lg h-full">
                <Skeleton height={228} />
              </div>
            </div>
            <div className="col-span-3 mr-6 ml-6">
              <Skeleton count={7} height={20} style={{ marginTop: "11px" }} />
            </div>
          </div>
        </If>

        <div
          className={`grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 ${
            isLoading ? "hidden" : ""
          }`}
        >
          <div className="col-span-3 md:col-span-2">
            <div
              className="bg-green-200 hover:bg-blue-300 transition-all duration-500 ml-10 mr-10 rounded-lg w-11/12"
              style={{
                backgroundImage: `url(${pelatihan?.path_thumbnail})`,
                backgroundSize: "cover",
                height: "228px",
              }}
            ></div>
          </div>
          <div className="col-span-3 mr-6 ml-6">
            <h2 className="font-semibold text-2xl text-white">
              {pelatihan?.judul_pelatihan}
            </h2>
            <div className="grid grid-cols-6 mt-4 items-center">
              <div className="bg-white text-xs rounded-full p-2 col-span-3 text-center">
                <p className="text-green-400">{pelatihan?.penyelenggara}</p>
              </div>
              <div className="col-span-3">
                <div className="ml-2 text-right text-sm flex flex-row">
                  <PrettyRating
                    value={pelatihan != null ? pelatihan.rating : 0}
                    colors={colors.star}
                  />
                  <p className="text-white ml-2 font-semibold">
                    {pelatihan?.rating}
                  </p>
                  <p className="text-white ml-2 font-semibold">
                    ({pelatihan?.count_reviewer} Reviews)
                  </p>
                  {/* <p className="text-white ml-2 font-semibold">
                    ({pelatihan?.count_diskusi} Diskusi)
                  </p> */}
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <UsersIconYellow />
              <a className="text-white ml-2 font-semibold">
                {pelatihan?.count_peserta} Peserta
              </a>
              <CalendarIconYellow className="ml-3" />
              <a className="text-white ml-2 font-base">
                {moment(pelatihan?.waktu_mulai).format("LL")} s.d{" "}
                {moment(pelatihan?.waktu_selesai).format("LL")}
              </a>
              <TimeIconYellow className="ml-3" />
              <a className="text-white ml-2 font-base">
                <b>{pelatihan?.jp} JP</b>
                {/* ± 39 Jam */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalDelete: React.FC = () => {
  const { showDialogDelete, isLoadingDelete } =
    useDetailPenilaianPelatihanContext().state;
  const { setShowDialogDelete, deletePelatihan } =
    useDetailPenilaianPelatihanContext();

  return (
    <>
      {showDialogDelete ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Hapus Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialogDelete(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  Apakah Anda Yakin Ingin Menghapus Data Ini?
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialogDelete(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => deletePelatihan()}
                  >
                    <If condition={isLoadingDelete}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Yakin</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const { getModulPelatihan } = useDetailPenilaianPelatihanContext();
  const { modulPelatihan } = useDetailPenilaianPelatihanContext().state;

  useEffect(() => {
    getModulPelatihan(window.location.pathname.split("/", 6)[5]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app">
      <ModalDelete />
      <Sidebar />
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <Navbar title="Manajemen Pelatihan" />
          <BottomHeader />
        </header>

        <div className="container mb-10">
          <HeaderBanner />
          <div className="container px-9 mt-6">
            <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0">
              <li className="nav-item">
                <a
                  href={`/admin/detail/manajemen_pelatihan/data_pelatihan/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        "
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Data Pelatihan
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  href={`/admin/detail/manajemen_pelatihan/konten_pelatihan/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Konten Pelatihan
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent
                        active
                        bg-white"
                >
                  Penilaian
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={`/admin/detail/manajemen_pelatihan/daftar_peserta/${
                    window.location.pathname.split("/", 6)[5]
                  }`}
                  className="
                        ml-1
                        nav-link
                        block
                        font-medium
                        text-xs
                        leading-tight
                        uppercase
                        border-x-0 border-t-0 border-b-2 border-transparent
                        px-6
                        py-3
                        my-2
                        hover:border-transparent hover:bg-gray-100
                        focus:border-transparent"
                  style={{ backgroundColor: "#F8F8F8" }}
                >
                  Daftar Peserta
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active bg-white px-6 py-6 rounded-lg">
                <h5 className="px-5 py-5">Bobot Penilaian</h5>
                <hr />

                <div className="px-10 py-5">
                  <If condition={modulPelatihan.length > 0}>
                    <Then>
                      {modulPelatihan.map((mp, idx) => {
                        return (
                          <>
                            <h5>
                              Section {idx + 1}. {mp.judul_modul}
                            </h5>

                            <div className="px-5 mt-3">
                              {mp.subModulPelatihan.map((sm) => {
                                return (
                                  <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
                                    <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                                      <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                                        {sm.judul}
                                      </label>
                                    </div>
                                    <div className="col-span-5 md:col-span-5   sm:grid-cols-1">
                                      <input
                                        type="number"
                                        className="form-control
                                                                block
                                                                w-full
                                                                px-3
                                                                py-1.5
                                                                text-base
                                                                font-normal
                                                                text-gray-700
                                                                bg-white bg-clip-padding
                                                                border border-solid border-gray-300
                                                                rounded
                                                                transition
                                                                ease-in-out
                                                                m-0
                                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        readOnly
                                        defaultValue={sm.ada_latihan == 0 && sm.jenis_ujian == "" ? 0 : sm.bobot_penilaian}
                                        placeholder="Tidak Ada Nilai"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}
                    </Then>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const DetailPenilaianManajemenPelatihan: React.FC = () => {
  return (
    <DetailPenilaianPelatihanController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </DetailPenilaianPelatihanController.Provider>
  );
};

export default DetailPenilaianManajemenPelatihan;
