import { Box, Icon, Flex, Text, Link, Divider, Img } from "@chakra-ui/react";
import React from "react";

import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/twitter_logo.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/svg/youtube.svg";

/**
 * Footer component.
 * @component
 * @returns {React.FC} Footer component
 * @author Bagas Prasetyadi
 */

const Footer: React.FC = () => {
  const logoFooterLeft = localStorage.getItem("logoFooterLeft") ?? "";
  const logoFooterCenter = localStorage.getItem("logoFooterCenter") ?? "";
  const contact = localStorage.getItem("contact") ?? "";
  const urlInstagram = localStorage.getItem("urlInstagram") ?? "";
  const urlFacebook = localStorage.getItem("urlFacebook") ?? "";
  const urlTwitter = localStorage.getItem("urlTwitter") ?? "";
  const urlYoutube = localStorage.getItem("urlYoutube") ?? "";
  const copyright = localStorage.getItem("copyright") ?? "";

  return (
    <Box bg="green.700" w="full" py={8} px={{ base: 8, md: 12 }}>
      {/* dekstop screen */}
      <Box
        justifyContent="space-between"
        mt={{ md: 4 }}
        alignItems="start"
        flexDirection={{ base: "column", md: "row" }}
        display={{ base: "none", md: "flex" }}
      >
        <Box w="300px" mt={4}>
          <Flex alignItems="start" justifyContent="start" h="full">
            <Img src={logoFooterLeft} h="3rem" w="auto" />
          </Flex>
        </Box>
        <Box w={{ base: "300px", md: "450px" }} mt={4} h="3rem">
          <Flex
            alignItems="center"
            justifyContent="start"
            h="full"
            flexDirection="column"
          >
            <Img w="full" h="full" src={logoFooterCenter} objectFit="contain" />
          </Flex>
        </Box>
        <Box
          w="300px"
          mt={4}
          display="flex"
          justifyContent={{ md: "flex-end" }}
        >
          <Box>
            <Text fontWeight="semibold" color="white" fontSize="md">
              Kontak
            </Text>
            <Text fontWeight="normal" color="white" fontSize="md">
              {contact}
            </Text>
          </Box>
        </Box>
      </Box>

      {/* mobile screen */}
      <Box display={{ base: "block", md: "none" }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box w="30%">
            <Img src={logoFooterLeft} w="auto" h="1.5rem" />
          </Box>
          <Box w="60%">
            <Img w="full" src={logoFooterCenter} objectFit="contain" />
          </Box>
        </Flex>
        <Box mt={8}>
          <Text fontWeight="semibold" color="white" fontSize="sm">
            Kontak
          </Text>
          <Text fontWeight="normal" color="white" fontSize="sm">
            {contact}
          </Text>
        </Box>
      </Box>

      <Flex
        justifyContent={{ base: "start", md: "center" }}
        mt={{ base: "2rem", md: "3rem" }}
      >
        <Link mr={{ base: 8, md: 2 }} href={urlFacebook} isExternal>
          <Icon as={FacebookIcon} boxSize={6} />
        </Link>
        <Link mr={{ base: 8, md: 2 }} href={urlInstagram} isExternal>
          <Icon as={InstagramIcon} boxSize={6} />
        </Link>
        <Link mr={{ base: 8, md: 2 }} href={urlYoutube} isExternal>
          <Icon as={YoutubeIcon} boxSize={8} />
        </Link>
        <Link mr={{ base: 8, md: 2 }} href={urlTwitter} isExternal>
          <Icon as={TwitterIcon} boxSize={6} />
        </Link>
      </Flex>
      <Divider my={4} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Text color="white" fontSize={{ base: "11px", md: "sm" }}>
          © {copyright}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
