import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { useManajemenPengetahuanContext } from "../../../controller/admin/manajemen_pengetahuan";

type ButtonModalDeleteProps = {
  id: string;
  title: string;
};

/**
 * Renders a button that, when clicked, opens a modal dialog for confirming the deletion of a knowledge item.
 *
 * @param {ButtonModalDeleteProps} props - An object containing the ID and title of the knowledge item to be deleted.
 * @return {JSX.Element} A JSX element containing a button and an alert dialog.
 * @author Bagas Prasetyadi
 */

const ButtonModalDelete = ({ id, title }: ButtonModalDeleteProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const { handleDelete } = useManajemenPengetahuanContext();
  const { isLoadingDelete } = useManajemenPengetahuanContext().state;
  return (
    <>
      <Button
        colorScheme="red"
        rightIcon={<AiOutlineDelete color="white" size={16} />}
        onClick={onOpen}
      >
        Hapus
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Konfirmasi Penghapusan
            </AlertDialogHeader>

            <AlertDialogBody>
              Apakah Anda yakin ingin menghapus pengetahuan{" "}
              <strong>{title}</strong> ini?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Batal
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete(id)}
                ml={3}
                isLoading={isLoadingDelete}
              >
                Yakin
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ButtonModalDelete;
