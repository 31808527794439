import { injectable } from "tsyringe";
import { SertifikatTTERepository } from "../../app/repository/api/SertifikatTTERepository";

@injectable()
export class SertifikatTTEPresenter {
  private repository: SertifikatTTERepository;
  constructor(repository: SertifikatTTERepository) {
    this.repository = repository;
  }

  /**
   *
   * @param pelatihan_id
   * @param isTteUlang
   * @param action
   * @param userId
   * @param akun_tte_id
   * @returns
   */
  public tteSertifikat(
    pelatihan_id: number,
    isTteUlang: boolean,
    action: string,
    userId: number,
    akun_tte_id: number
  ): Promise<any> {
    return this.repository.tteSertifikat(
      pelatihan_id,
      isTteUlang,
      action,
      userId,
      akun_tte_id
    );
  }

  /**
   *
   * @param pelatihan_id
   * @returns
   */
  public logTTESertifikat(page: number, rows: number): Promise<any> {
    return this.repository.logTTESertifikat(page, rows);
  }
}
