import { injectable } from 'tsyringe'
import { KecamatanApiRepository } from '../../app/repository/api/KecamatanApiRepository'
import { Kecamatan } from '../../entities/Kecamatan'

@injectable()
export class KecamatanPresenter {
  private repository: KecamatanApiRepository
  constructor(repository: KecamatanApiRepository) {
    this.repository = repository
  }

  public getAllKecamatan(page: number, rows: number , keyword : string ): Promise<Kecamatan[]> {
    return this.repository.getAllKecamatan(page,rows,keyword);
  }

  public getKecamatanById(id: number): Promise<Kecamatan[]> {
    return this.repository.getKecamatanById(id);
  }

  public createKecamatan(
    kabupaten_id: string,
    nama_kecamatan : string 
  ): Promise<any> {
    return this.repository.createKecamatan(kabupaten_id,nama_kecamatan);
  }

  public updateKecamatan(
    id : number,
    kabupaten_id: string,
    nama_kecamatan : string 
  ): Promise<any> {
    return this.repository.updateKecamatan(id,kabupaten_id,nama_kecamatan);
  }

  public deleteKecamatan(
    id : number
  ): Promise<any> {
    return this.repository.deleteKecamatan(id);
  }
}
