import { useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import HeroCarouselDesktop from "./HeroCarouselDekstop";
import HeroCarouselMobile from "./HeroCarouselMobile";

/**
 * Renders a hero carousel component based on the device breakpoint.
 *
 * @return {JSX.Element} The rendered hero carousel component.
 * @author Bagas Prasetyadi
 */

const HeroCarousel = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return isMobile ? <HeroCarouselMobile /> : <HeroCarouselDesktop />;
};

export default HeroCarousel;
