export class ReviewPelatihan {
  id: number;
  user_id: number;
  pelatihan_id: number;
  komentar: string;
  rating: number;
  created_at: string;
  updated_at: string;
  jumlahDataReview: number;
  path_foto_komentar_materi: PathFotoKomentar[];
  name: string;
  path_foto_user: string;
  reviews: CountReview;

  constructor(
    id: number,
    user_id: number,
    pelatihan_id: number,
    komentar: string,
    rating: number,
    created_at: string,
    updated_at: string,
    jumlahDataReview: number,
    path_foto_komentar_materi: PathFotoKomentar[],
    name: string,
    path_foto_user: string,
    reviews: CountReview
  ) {
    this.id = id;
    this.user_id = user_id;
    this.pelatihan_id = pelatihan_id;
    this.komentar = komentar;
    this.rating = rating;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.jumlahDataReview = jumlahDataReview;
    this.path_foto_komentar_materi = path_foto_komentar_materi;
    this.name = name;
    this.path_foto_user = path_foto_user;
    this.reviews = reviews;
  }
}

export class PathFotoKomentar {
  id: number;
  label: string;
  review_pelatihan_id: number;

  constructor(id: number, label: string, review_pelatihan_id: number) {
    this.id = id;
    this.label = label;
    this.review_pelatihan_id = review_pelatihan_id;
  }
}

export class DetailReview {
  id: number;
  komentar: string;
  name: string;
  path_foto: string;
  path_foto_komentar_pelatihan: any[];
  pelatihan_id: number;
  rating: number;
  user_id: number;
  created_at: string;
  updated_at: string;

  constructor(
    id: number,
    komentar: string,
    name: string,
    path_foto: string,
    path_foto_komentar_pelatihan: any[],
    pelatihan_id: number,
    rating: number,
    user_id: number,
    created_at: string,
    updated_at: string
  ) {
    this.id = id;
    this.komentar = komentar;
    this.name = name;
    this.path_foto = path_foto;
    this.path_foto_komentar_pelatihan = path_foto_komentar_pelatihan;
    this.pelatihan_id = pelatihan_id;
    this.rating = rating;
    this.user_id = user_id;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
export class CountReview {
  excelent_review: number;
  very_good_review: number;
  averange_review: number;
  poor_review: number;
  terrible_review: number;

  constructor(
    excelent_review: number,
    very_good_review: number,
    averange_review: number,
    poor_review: number,
    terrible_review: number
  ) {
    this.excelent_review = excelent_review;
    this.very_good_review = very_good_review;
    this.averange_review = averange_review;
    this.poor_review = poor_review;
    this.terrible_review = terrible_review;
  }
}
