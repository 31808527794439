export class Provinsi {
  id: number
  nama_provinsi: string
  created_at: string
  updated_at: string
  jumlahData : number;

  constructor(
    id: number,
    nama_provinsi: string,
    created_at: string,
    updated_at: string,
    jumlahData : number
  ) {
    this.id = id
    this.nama_provinsi = nama_provinsi
    this.created_at = created_at
    this.updated_at = updated_at
    this.jumlahData = jumlahData
  }
}