import React from "react";
import { container } from "tsyringe";
import { SertifikatTTEPresenter } from "../../../../../data/presenters/SertifikatTTEPresenter";
import { toast } from "react-toastify";

interface Option {
  label: string;
  value: number;
}

interface IState {
  isLoading: boolean;
  isLoadingLog: boolean;
  formDialog: string;
  showDialog: boolean;
  nik: number;
  passphrase: string;
  pelatihan_id: number;
  page: number;
  rows: number;
  logTTEData: any[];
  isTteUlang: boolean;
  tteAction: string;
  userId: number;
  accountTTESelected: Option | null;
}

interface InitialState {
  state: IState;
  setFormDialog: Function;
  setShowDialog: Function;
  setFormNIK: Function;
  setPassphrase: Function;
  setPelatihanId: Function;
  tteSertifikat: Function;
  getLogSertifikat: Function;
  setPage: Function;
  setPageRow: Function;
  setTteUlang: Function;
  setTteAction: Function;
  setUserId: Function;
  setAccountTTESelected: Function;
}

const initialState = {
  state: {
    isLoading: false,
    isLoadingLog: false,
    formDialog: "",
    showDialog: false,
    nik: 0,
    passphrase: "",
    pelatihan_id: 0,
    page: 1,
    rows: 12,
    logTTEData: [],
    isTteUlang: false,
    tteAction: "standard",
    userId: 0,
    accountTTESelected: null,
  },
  setFormDialog: () => {},
  setShowDialog: () => {},
  setFormNIK: () => {},
  setPassphrase: () => {},
  setPelatihanId: () => {},
  tteSertifikat: () => {},
  getLogSertifikat: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setTteUlang: () => {},
  setTteAction: () => {},
  setUserId: () => {},
  setAccountTTESelected: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenSertifikatProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    isLoadingLog: false,
    formDialog: "",
    showDialog: false,
    nik: 0,
    passphrase: "",
    pelatihan_id: 0,
    page: 1,
    rows: 12,
    logTTEData: [],
    isTteUlang: false,
    tteAction: "standard",
    userId: 0,
    accountTTESelected: null,
  });

  const setAccountTTESelected = (value: Option | null) => {
    setState((prevstate) => ({
      ...prevstate,
      accountTTESelected: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingLog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingLog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      nik: 0,
      passphrase: "",
      pelatihan_id: 0,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const setFormNIK = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      nik: value,
    }));
  };

  const setTteUlang = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isTteUlang: value,
    }));
  };

  const setPassphrase = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      passphrase: value,
    }));
  };

  const setPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      pelatihan_id: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setTteAction = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      tteAction: value,
    }));
  };

  const setUserId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      userId: value,
    }));
  };

  const tteSertifikat = async () => {
    setLoading(true);

    if (state.accountTTESelected === null) {
      toast.error("Akun TTE Tidak Boleh Kosong!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      setLoading(false);
      return;
    }

    try {
      const sertifikatTTEPresenter = container.resolve(SertifikatTTEPresenter);
      const response = await sertifikatTTEPresenter.tteSertifikat(
        state.pelatihan_id,
        state.isTteUlang,
        state.tteAction,
        state.userId,
        state.accountTTESelected.value
      );

      if (response.status === 200) {
        toast.success(response.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error: any) {
      console.log("error saat tte sertifikat :", error);
      setLoading(false);
    }
  };

  const getLogSertifikat = async (page, rows) => {
    setLoadingLog(true);

    try {
      const sertifikatTTEPresenter = container.resolve(SertifikatTTEPresenter);
      const tteLog = await sertifikatTTEPresenter.logTTESertifikat(page, rows);

      const data = tteLog;

      setState((prevstate) => ({
        ...prevstate,
        logTTEData: data,
      }));

      setLoadingLog(false);
    } catch (error: any) {
      console.log("error saat get log sertifikat :", error);
      setLoadingLog(false);
    }
  };

  return (
    <ManajemenSertifikatProvider
      value={{
        state,
        setFormDialog,
        setShowDialog,
        setFormNIK,
        setPassphrase,
        setPelatihanId,
        tteSertifikat,
        getLogSertifikat,
        setPage,
        setPageRow,
        setTteUlang,
        setTteAction,
        setUserId,
        setAccountTTESelected,
      }}
    >
      {children}
    </ManajemenSertifikatProvider>
  );
};

export const useManajemenSertifikatContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenSertifikatContext,
  Provider,
};
