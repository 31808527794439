import React from "react";
import Carousel from "react-multi-carousel";
import { Box, Image, Skeleton, Link } from "@chakra-ui/react";
import CustomDot from "../CustomDot";
import { useSliderBannerPublicContext } from "../../../controller/public/slider_banner";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 10,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 10,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    partialVisibilityGutter: 10,
  },
};

/**
 * Renders a Hero Carousel component for desktop.
 *
 * @returns {React.FC} The HeroCarousel component.
 * @author Bagas Prasetyadi
 */

const HeroCarouselDekstop: React.FC = () => {
  const { sliderBannerData: data, loadingData } =
    useSliderBannerPublicContext().state;

  if (loadingData) {
    return (
      <Box display="flex" justifyContent="center" my={8}>
        <Skeleton height="350px" width="80%" />
      </Box>
    );
  }

  if (data.length === 1) {
    return (
      <Box
        w="full"
        bg="GreenSage"
        display="flex"
        justifyContent="center"
        py={8}
      >
        <Image src={data[0].file} h="50vh" borderRadius="lg" />
      </Box>
    );
  }

  return (
    <Box w="full" bg="GreenSage" display="flex" justifyContent="center">
      <Carousel
        arrows={false}
        autoPlay
        autoPlaySpeed={5000}
        centerMode
        className="custom-carousel"
        containerClass="container"
        draggable
        focusOnSelect={false}
        infinite
        responsive={responsive}
        showDots
        slidesToSlide={1}
        swipeable
        customDot={<CustomDot />}
        dotListClass="dot-carousel"
      >
        {data.map((item) =>
          item.link ? (
            <Link
              href={item.link}
              isExternal
              key={item.id}
              display="flex"
              position="relative"
            >
              <Image
                fallback={<Skeleton h="full" w="full" />}
                borderRadius="lg"
                src={item.file}
                alt={`Hero ${item.title}`}
                w="full"
                objectFit="cover"
              />
            </Link>
          ) : (
            <Box key={item.id} display="flex" position="relative">
              <Image
                fallback={<Skeleton h="full" w="full" />}
                borderRadius="lg"
                src={item.file}
                alt={`Hero ${item.title}`}
                w="full"
                objectFit="cover"
              />
            </Box>
          )
        )}
      </Carousel>
    </Box>
  );
};

export default HeroCarouselDekstop;
