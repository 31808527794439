/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import { ToastContainer } from "react-toastify";
import DashboardController, {
  useDashboardContext,
} from "../../../controller/admin/dashboard";
import ManajemenSertifikatController, {
  useManajemenSertifikatContext,
} from "../../../controller/admin/manajemen_sertifikat";
import moment from "moment";
import ProgressBar from "@ramonak/react-progress-bar";
import SidebarWithHeader from "../../../components/Admin/SidebarWithHeader";
import { Box, Heading } from "@chakra-ui/react";
import MasterTTEContext, {
  useMasterTTEContext,
} from "../../../controller/admin/data_master/tte";
import Select from "react-select";

const ModalTTESertifikat: React.FC = () => {
  const {
    setShowDialog,
    tteSertifikat,

    setAccountTTESelected,
  } = useManajemenSertifikatContext();
  const { showDialog, isLoading, tteAction, accountTTESelected } =
    useManajemenSertifikatContext().state;

  const { listDataOption: listAccountTTEOption } = useMasterTTEContext().state;

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    TTE Sertifikat Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => {
                      setShowDialog(false);
                      setAccountTTESelected(null);
                    }}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  {/* Alert */}
                  <div
                    id="alert-4"
                    className="flex p-4 mb-4 bg-yellow-100 rounded-lg"
                    role="alert"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-5 h-5 text-yellow-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-3">
                      <p className="text-md font-semibold text-yellow-700">
                        Tanda Tangan Elektronik
                      </p>
                      <p className="mt-2 text-red-700">
                        <b>PASSPHRASE</b> adalah password yang di-set ketika
                        membuat kunci tanda tangan digital Kementrian Agama
                        bersama Badan Siber dan Sandi Nasional (BSSN) Jika kunci
                        salah, maka proses penandatanganan digital akan{" "}
                        <b>GAGAL</b>
                      </p>
                      {tteAction === "urgent" && (
                        <p className="mt-2 text-red-700">
                          *TTE ini digunakan untuk seluruh sertifikat pelatihan
                          termasuk yang tidak lulus
                        </p>
                      )}
                    </div>
                    <button
                      type="button"
                      className="ml-auto -mx-1.5 -my-1.5 bg-yellow-100 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex h-8 w-8 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300"
                      data-dismiss-target="#alert-4"
                      aria-label="Close"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>

                  {/* Form */}
                  <div>
                    {/* Akun TTE */}
                    <div>
                      <label className="text-sm font-medium text-gray-900">
                        Pilih Akun TTE
                      </label>
                      <div className="mt-3">
                        <Select
                          placeholder="Pilih Akun TTE"
                          options={listAccountTTEOption}
                          isClearable
                          onChange={(selectedOption) =>
                            setAccountTTESelected(selectedOption)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => {
                      setShowDialog(false);
                      setAccountTTESelected(null);
                    }}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className={`inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1
                    ${
                      !accountTTESelected ? "opacity-50 cursor-not-allowed" : ""
                    }
                    `}
                    onClick={() => tteSertifikat()}
                    disabled={!accountTTESelected}
                  >
                    <If condition={isLoading}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Tanda Tangani Sertifikat Secara Digital</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  const { getDashboardRekapitulasi, setPageRow, setPage } =
    useDashboardContext();
  const { dataRekapitulasi, keyword, isLoading, page, rows } =
    useDashboardContext().state;
  const {
    setPelatihanId,
    setShowDialog,
    getLogSertifikat,
    setTteUlang,
    setTteAction,
  } = useManajemenSertifikatContext();

  const { fetchData: getListAccountTTE } = useMasterTTEContext();

  const { logTTEData, isLoadingLog } = useManajemenSertifikatContext().state;
  const [perPage, setPerPage] = useState(10);
  const [perPageTTE, setPerPageTTE] = useState(10);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const eventId = urlSearchParams.get("eventId");

  const columns = [
    {
      name: "No.",
      selector: (row, index) => (page - 1) * rows + (index + 1),
      grow: 0,
    },
    {
      name: "Nama Pelatihan",
      selector: (row) => row.judul_pelatihan,
      wrap: true,
    },
    {
      name: "Waktu Pelaksanaan",
      selector: (row) => {
        return (
          <>
            <p>
              {moment(row.waktu_mulai).format("DD-MM-YYYY")} sd <br />{" "}
              {moment(row.waktu_selesai).format("DD-MM-YYYY")}
            </p>
          </>
        );
      },
      wrap: true,
    },
    {
      name: "Presentase TTE",
      cell: (row) => {
        return (
          <ProgressBar
            completed={row.persentase_tte}
            maxCompleted={row.persentase_tte}
            className="w-full"
            height="14px"
            bgColor="#007E39"
            animateOnRender={true}
          />
        );
      },
      width: "200px",
      wrap: true,
    },
    {
      name: "Jumlah Peserta Lulus",
      cell: (row) => {
        const data = row.predikat.filter((v) => v.variable.includes("Lulus,"));

        return (
          <>
            <table>
              {data.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.variable}</td>
                    <td>:</td>
                    <td>{val.value}</td>
                  </tr>
                );
              })}
            </table>
          </>
        );
      },
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            {/* Edit Button */}
            <button
              className="ml-2 px-2 py-2 text-xs font-semibold text-green-800 bg-green-100 hover:bg-green-200 rounded-lg mb-2 mt-2"
              onClick={() => {
                setShowDialog(true);
                setPelatihanId(row.id);
                setTteUlang(false);
                setTteAction("standard");
              }}
            >
              TTE Sertifikat
            </button>
            {/* Edit Button */}
            <button
              className="ml-2 px-2 py-2 text-xs font-semibold text-yellow-800 bg-yellow-100 hover:bg-yellow-200 rounded-lg mb-2"
              onClick={() => {
                setShowDialog(true);
                setPelatihanId(row.id);
                setTteUlang(true);
                setTteAction("standard");
              }}
            >
              TTE Ulang Sertifikat
            </button>

            <button
              className="ml-2 px-2 py-2 text-xs font-semibold text-red-800 bg-red-100 hover:bg-red-200 rounded-lg mb-2"
              onClick={() => {
                setShowDialog(true);
                setPelatihanId(row.id);
                setTteUlang(false);
                setTteAction("urgent");
              }}
            >
              TTE Semua Sertifikat
            </button>

            <button
              className="ml-2 px-2 py-2 text-xs font-semibold text-blue-800 bg-blue-100 hover:bg-blue-200 rounded-lg mb-2"
              onClick={() => {
                setShowDialog(true);
                setPelatihanId(row.id);
                setTteUlang(true);
                setTteAction("urgent");
              }}
            >
              TTE Ulang Semua Sertifikat
            </button>
          </div>
        );
      },
      wrap: true,
    },
  ];

  const columnsLog: any = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Nama Pelatihan",
      selector: (row) => row.judul_pelatihan,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Tanggal Mulai Pelatihan",
      selector: (row) => moment(row.waktu_mulai).format("DD-MM-YYYY HH:mm"),
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Waktu Generate TTE",

      wrap: true,
      minWidth: "150px",
      selector: (row) => {
        return (
          <>
            <p>{moment(row.created_at).format("DD-MM-YYYY HH:mm")}</p>
          </>
        );
      },
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    {
      name: "Di Proses",
      selector: (row) => row.diproses,
    },
    {
      name: "Berhasil",
      selector: (row) => row.berhasil,
    },
    {
      name: "Gagal",
      selector: (row) => row.gagal,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row.diproses < row.total !=
              moment(row.updated_at)
                .startOf("day")
                .fromNow()
                .includes("hari") &&
            moment(row.updated_at).startOf("day").fromNow().includes("jam") ? (
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Sedang Dalam Proses
              </span>
            ) : row.total == row.berhasil ? (
              <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Selesai
              </span>
            ) : row.gagal == row.diproses ? (
              <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Gagal
              </span>
            ) : row.diproses != row.berhasil && row.berhasil > 0 ? (
              <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                Terdapat ({row.gagal}) Sertifikat Gagal Di TTE
              </span>
            ) : (
              moment(row.updated_at)
                .startOf("day")
                .fromNow()
                .includes("hari") && (
                <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  Job Tidak Terupdate Selama{" "}
                  {moment(row.updated_at).startOf("day").fromNow()}
                </span>
              )
            )}
          </>
        );
      },
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  };

  useEffect(() => {
    getLogSertifikat(1, 10);
    getListAccountTTE();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDashboardRekapitulasi(keyword, "", false, eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  // handle page change data pelatihan
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPageRow(newPerPage);
  };

  // handle page change data log tte
  const handlePageChangeTTE = (page) => {
    getLogSertifikat(page, perPageTTE);
  };

  const handlePerRowsChangeTTE = async (newPerPageTTE, page) => {
    getLogSertifikat(page, newPerPageTTE);
    setPerPageTTE(newPerPageTTE);
  };

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <ReactLoading type={"bars"} color={"green"} height={100} width={100} />
      <div>Loading Data...</div>
    </div>
  );

  return (
    <SidebarWithHeader title="Manajemen Sertifikat">
      <Box className="app">
        <ModalTTESertifikat />

        <Box as="main" style={{ padding: "0px" }}>
          <Box className="container">
            {/* Table Data Pelatihan */}
            <Box bg="white" p={5} borderRadius="lg" mb={5} shadow="lg">
              <Heading
                as="h4"
                fontSize="xl"
                fontWeight="semibold"
                mb={5}
                className="text-xl font-semibold mb-5"
              >
                Data Pelatihan
              </Heading>
              <DataTable
                columns={columns}
                data={dataRekapitulasi}
                progressPending={isLoading}
                pagination
                paginationServer
                paginationTotalRows={
                  dataRekapitulasi.length > 0
                    ? dataRekapitulasi[0].jumlah_data
                    : 0
                }
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                customStyles={customStyles}
                progressComponent={<CustomLoader />}
              />
            </Box>

            {/* Table Log */}
            <Box bg="white" p={5} borderRadius="lg" mb={5} shadow="lg">
              <Heading as="h4" fontSize="xl" fontWeight="semibold" mb={5}>
                Log TTE
              </Heading>
              <DataTable
                columns={columnsLog}
                data={logTTEData}
                progressPending={isLoadingLog}
                pagination
                paginationServer
                paginationTotalRows={
                  logTTEData.length > 0 ? logTTEData[0].jumlah_data : 0
                }
                onChangeRowsPerPage={handlePerRowsChangeTTE}
                onChangePage={handlePageChangeTTE}
                customStyles={customStyles}
                progressComponent={<CustomLoader />}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SidebarWithHeader>
  );
};

const ManajemenSertifikat: React.FC = () => {
  return (
    <DashboardController.Provider>
      <MasterTTEContext.Provider>
        <ManajemenSertifikatController.Provider>
          <ToastContainer
            position="top-center"
            style={{ width: "60%", marginTop: "5%" }}
          />
          <WrapperMain />
        </ManajemenSertifikatController.Provider>
      </MasterTTEContext.Provider>
    </DashboardController.Provider>
  );
};

export default ManajemenSertifikat;
