/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { Wrapper } from "../../../components/Wrapper";
import If, { Else } from "if-else-react";
import backgroundSkor from "../../../assets/png/background_skor.png";
import LatihanController, {
  useLatihanContext,
} from "../../../controller/latihan/index";
import DetailPelatihanController, {
  useDetailPelatihanContext,
} from "../../../controller/pelatihan/detail";
import { useParams } from "react-router-dom";
import { Modal } from "../../../components/Modal";
import { useToast } from "@chakra-ui/react";

const Content = ({ limitPengulangan }: { limitPengulangan: number }) => {
  // Get Params Id
  const { id } = useParams() as { id: string };

  const nilai = localStorage.getItem("nilai_peserta");
  const passing_grade = localStorage.getItem("passing_grade");
  const judul_latihan = localStorage.getItem("judul_sub_modul");
  const sub_modul_id = localStorage.getItem("id_subModul");
  const pelatihan_id = localStorage.getItem("pelatihan_id");
  const ujianAkhir = localStorage.getItem("ujian_akhir");
  const sub_modul_length = Number(localStorage.getItem("subModulLength"));
  const last_index_sub_modul =
    Number(localStorage.getItem("lastIndexDataSubModul")) + 1;

  // Call Controller Pelatihan
  const { handleNextSubModul } = useDetailPelatihanContext();
  const { isLoadingNext } = useDetailPelatihanContext().state;

  const handleLanjutkan = async () => {
    const response = await handleNextSubModul(sub_modul_id);

    localStorage.removeItem("nilai_peserta");
    localStorage.removeItem("passing_grade");
    localStorage.removeItem("judul_sub_modul");

    if (response == 200) {
      if (sub_modul_length == last_index_sub_modul) {
        window.location.href = "/detail/pelatihan/" + pelatihan_id;
      } else {
        window.location.href = "/detail_pelatihan_saya/" + pelatihan_id;
      }
    }
  };

  const selesaiUjian = async () => {
    const response = await handleNextSubModul(sub_modul_id);

    localStorage.removeItem("nilai_peserta");
    localStorage.removeItem("passing_grade");
    localStorage.removeItem("judul_sub_modul");
    localStorage.removeItem("ujian_akhir");

    if (response == 200) {
      window.location.href = "/detail/pelatihan/" + pelatihan_id;
    }
  };

  const { setShowModalReattempt, reattemptLatihan } = useLatihanContext();

  const handleBackToMateri = async () => {
    localStorage.removeItem("nilai_peserta");
    localStorage.removeItem("passing_grade");
    localStorage.removeItem("judul_sub_modul");

    const response = await reattemptLatihan(id);
    if (response == 200) {
      window.location.href = "/detail_pelatihan_saya/" + pelatihan_id;
    }
  };

  return (
    <>
      <If condition={ujianAkhir == "true"}>
        <div className="max-w-2xl mx-auto lg:max-w-7xl lg:px-7 py-20 mb-5 w-full mt-5 text-center">
          <h3 className="text-2xl font-semibold">
            Anda Telah Menyelesaikan Soal Latihan {judul_latihan}
          </h3>
          <div className="mt-8 mb-5">
            <span className="bg-gray-500 text-white text-lg font-semibold px-2.5 py-0.5 rounded-full">
              Skor Anda
            </span>
          </div>
          <h1 className="text-7xl font-semibold mb-7">{Number(nilai)}</h1>

          <h3 className="text-2xl font-semibold text-green-400">
            Selamat Anda Telah Menyelesaikan Ujian & Pelatihan
          </h3>
          <p className="text-md">
            Selanjutnya anda dapat klik{" "}
            <span className="font-semibold">Selesai</span> untuk dapat melihat
            sertifikat dan memberikan review.
          </p>
          <div className="flex flex-row justify-center mt-4">
            <button
              type="button"
              className="text-white bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2"
              onClick={selesaiUjian}
            >
              Selesai
            </button>
          </div>
        </div>
        <Else />
        <div
          className="max-w-2xl mx-auto lg:max-w-7xl lg:px-7 py-20 mb-5 w-full mt-5 text-center"
          style={{
            backgroundImage:
              Number(nilai) < Number(passing_grade)
                ? ""
                : `url(${backgroundSkor})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <h3 className="text-2xl font-semibold">
            Anda Telah Menyelesaikan Soal Latihan {judul_latihan}
          </h3>
          <div className="mt-8 mb-5">
            <span className="bg-gray-500 text-white text-lg font-semibold px-2.5 py-0.5 rounded-full">
              Skor Anda
            </span>
          </div>
          <h1 className="text-7xl font-semibold mb-7">{Number(nilai)}</h1>

          <If condition={Number(nilai) < Number(passing_grade)}>
            <h3 className="text-2xl font-semibold text-red-400">
              Anda Tidak Lolos Passing Grade
            </h3>
            <p className="text-md">
              Silahkan ulangi latihan dangan klik{" "}
              <span className="font-semibold">
                Ulangi Latihan, jumlah pengulangan latihan: {limitPengulangan}
              </span>
            </p>
            <div className="flex flex-row justify-center mt-4">
              <button
                onClick={handleBackToMateri}
                className="text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2"
              >
                Kembali Ke Materi
              </button>
              <button
                type="button"
                className={`text-white ${
                  limitPengulangan <= 0
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-300"
                } font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2`}
                disabled={limitPengulangan <= 0}
                onClick={() => setShowModalReattempt(true)}
              >
                Ulangi Latihan
              </button>
            </div>
            <Else />
            <If condition={sub_modul_length == last_index_sub_modul}>
              <h3 className="text-2xl font-semibold text-green-400">
                Anda Lolos Passing Grade
              </h3>
              <p className="text-md">
                Anda telah lulus pelatihan ini, silahkan klik{" "}
                <span className="font-semibold">Selesai Pelatihan</span> untuk
                lanjut mencetak sertifikat
              </p>
              <div className="flex flex-row justify-center mt-4">
                <button
                  type="button"
                  className={`text-white  focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2
                    ${
                      isLoadingNext
                        ? "cursor-not-allowed bg-gray-400 hover:bg-gray-500"
                        : "bg-green-400 hover:bg-green-500"
                    }
                    `}
                  onClick={handleLanjutkan}
                  disabled={isLoadingNext}
                >
                  Selesai Pelatihan
                </button>
              </div>
              <Else />
              <h3 className="text-2xl font-semibold text-green-400">
                Anda Lolos Passing Grade
              </h3>
              <p className="text-md">
                Silakan lanjutkan modul pembelajaran selanjutnya dengan klik{" "}
                <span className="font-semibold">Lanjutkan</span>!
              </p>
              <div className="flex flex-row justify-center mt-4">
                <button
                  type="button"
                  className={`text-white ${
                    limitPengulangan <= 0
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-300"
                  } font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2`}
                  disabled={limitPengulangan <= 0}
                  onClick={() => setShowModalReattempt(true)}
                >
                  Ulangi Latihan
                </button>
                <button
                  type="button"
                  className={`text-white  focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2.5 mr-2 mb-2 ${
                    isLoadingNext
                      ? "cursor-not-allowed bg-gray-400 hover:bg-gray-500"
                      : "bg-green-400 hover:bg-green-500"
                  }`}
                  onClick={handleLanjutkan}
                  disabled={isLoadingNext}
                >
                  Lanjutkan
                </button>
              </div>
            </If>
          </If>
        </div>
      </If>
    </>
  );
};

const WrapperMain: React.FC = () => {
  // Get Params Id
  const { id } = useParams() as { id: string };
  const toast = useToast();

  const { getLatihan, setShowModalReattempt } = useLatihanContext();
  const { latihan, showModalReattempt } = useLatihanContext().state;

  const judul_latihan = localStorage.getItem("judul_sub_modul");

  // Call Controller Latihan
  const { reattemptLatihan } = useLatihanContext();
  const limitPengulangan =
    Number(latihan?.sequence) - Number(latihan?.total_sequence);

  const handleReattemptLatihan = async () => {
    localStorage.removeItem("nilai_peserta");
    localStorage.removeItem("passing_grade");
    localStorage.removeItem("judul_sub_modul");

    if (limitPengulangan <= 0) {
      toast({
        title: "Mohon maaf, Anda telah mencapai limit pengulangan",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const response = await reattemptLatihan(id);

    if (response == 200) {
      window.location.href = "/latihan/" + id;
    }
  };

  useEffect(() => {
    getLatihan(id);
  }, []);

  return (
    <Wrapper>
      {/* Content */}
      <Content limitPengulangan={limitPengulangan} />

      {/* Modal Reattempt */}
      {showModalReattempt && (
        <Modal id_modal="modalReattempt">
          <div className="flex items-start justify-between p-6 rounded-t">
            <h3 className="text-xl font-semibold text-red-400">
              Ulangi Latihan
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              style={{ color: "white" }}
              onClick={() => setShowModalReattempt(false)}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6 -mt-5">
            {/* Alert Info */}
            <div
              className="p-4 text-sm bg-yellow-100 rounded-lg flex flex-col"
              role="alert"
            >
              <p className="text-sm">
                {" "}
                <span className="font-semibold">Perhatian!</span> anda akan
                mengulangi latihan pada modul {judul_latihan}. Harap manfaatkan
                dengan baik.
              </p>
            </div>
          </div>
          <div className="items-center p-6">
            <button
              type="button"
              className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800 w-full"
              onClick={handleReattemptLatihan}
            >
              Mulai mengerjakan
            </button>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

const Skor: React.FC = () => {
  return (
    <LatihanController.Provider>
      <DetailPelatihanController.Provider>
        <WrapperMain />
      </DetailPelatihanController.Provider>
    </LatihanController.Provider>
  );
};

export default Skor;
