import {
  Box,
  Flex,
  IconButton,
  Heading,
  Stack,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
  Input,
  Text,
  RadioGroup,
  Radio,
  Switch,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineEdit,
  AiOutlineSave,
  AiOutlineEye,
} from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import EditorComponent from "../../../../components/Admin/Editor";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import BoxUploadFile from "../../../../components/Admin/UploadFile/BoxUploadFile";
import ManajemenPengetahuanContext, {
  useManajemenPengetahuanContext,
} from "../../../../controller/admin/manajemen_pengetahuan";
import YoutubeContext, {
  useYoutubeContext,
} from "../../../../controller/youtube/index";
import KelolaKontenManajemenPelatihanController, {
  useKelolaKontenManajemenPelatihanContext,
} from "../../../../controller/admin/manajemen_pelatihan/kelola_konten";
import DaftarPesertaPengetahuan from "../../../../components/Admin/Pengetahuan/DaftarPeserta";
import ManajemenPengetahuanDaftarPesertaController from "../../../../controller/admin/manajemen_pengetahuan/daftar-peserta";
import Select, { StylesConfig } from "react-select";
import ButtonModalDelete from "../../../../components/Admin/Pengetahuan/ButtonModalDelete";
import Loader from "../../../../components/Loader";
import KontenPemateri from "../../../../components/Admin/Pengetahuan/KontenPemateri";
import ManajemenPengetahuanKontenPemateriContext from "../../../../controller/admin/manajemen_pengetahuan/konten-pemateri";
import ModalAddSpeaker from "../../../../components/Admin/ModalPengetahuan/ModalAddSpeaker";

const DetailPengetahuan = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };

  const { getListVideo } = useYoutubeContext();
  const { listVideo } = useYoutubeContext().state;
  const { getPaketSoal } = useKelolaKontenManajemenPelatihanContext();

  const [tabIndexMenu, setTabIndexMenu] = useState(0);

  const {
    publish,
    image,

    description,
    loadingData,
    mode,
    title,
    organizer,
    kategoriPengetahuanId,
    listKategoriPengetahuan,
    loadingAction,
    type,
    startDate,
    endDate,

    pengetahuanItem,
  } = useManajemenPengetahuanContext().state;
  const {
    postData,
    setManajemenPengetahuanState,
    getDetailPengetahuan,
    getListKategoriPengetahuan,
  } = useManajemenPengetahuanContext();

  const listOptionVideoYoutube = listVideo.map((item) => ({
    value: item.videoId,
    label: item.title,
  }));

  useEffect(() => {
    const fetchAllData = async () => {
      setManajemenPengetahuanState("loadingData", true);
      await getListVideo(); // Wait for getListVideo to complete
      await getListKategoriPengetahuan(); // Wait for getListKategoriPengetahuan to complete
      getPaketSoal();
      getDetailPengetahuan(id);
    };

    fetchAllData(); // Call the async function
  }, [id]); // Add dependencies as needed

  useEffect(() => {
    if (listKategoriPengetahuan) {
      const kategoriPengetahuanIdSelected = listKategoriPengetahuan.find(
        (item) => item.value === pengetahuanItem?.kategori_pengetahuan_id
      );
      setManajemenPengetahuanState(
        "kategoriPengetahuanId",
        kategoriPengetahuanIdSelected
      );

      console.log("run effect listKategoriPengetahuan");
    }
  }, [listKategoriPengetahuan, pengetahuanItem?.kategori_pengetahuan_id]);

  useEffect(() => {
    if (
      listVideo &&
      pengetahuanItem?.category === "video" &&
      pengetahuanItem?.path_file
    ) {
      const videoSelected = listOptionVideoYoutube.find(
        (item) => item.value === pengetahuanItem?.path_file
      );
      setManajemenPengetahuanState("youtubeVideoSelected", videoSelected);
      console.log("run effect listOptionVideoYoutube");
    }
  }, [listVideo, pengetahuanItem?.path_file, pengetahuanItem?.category]);

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: "#F6F5F8",
        padding: "2px 8px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 100,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = "#38A169";

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? color
          : isFocused
          ? "#C6F6D5"
          : undefined,
        color: isDisabled ? "white" : isSelected ? "white" : "black",

        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "white"
              : color
            : undefined,
          color: isDisabled ? "white" : isSelected ? "black" : "white",
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  return (
    <SidebarWithHeader spacing={false}>
      <Box
        p={4}
        bg="#EDF2F7"
        boxShadow="0px 10px 15px -3px rgba(15, 23, 42, 0.08)"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="center" alignItems="center">
            <IconButton
              aria-label="Previous Page"
              icon={<AiOutlineArrowLeft />}
              fontSize="md"
              mr={2}
              onClick={() => history.push("/admin/manajemen_pengetahuan")}
            />
            <Heading
              as="h2"
              fontSize="md"
              fontWeight="bold"
              lineHeight="normal"
            >
              Pengetahuan {title}
            </Heading>
          </Flex>

          {tabIndexMenu === 0 && (
            <Stack direction="row" spacing={2}>
              <Button
                colorScheme="orange"
                rightIcon={
                  mode === "edit" ? (
                    <AiOutlineEye color="white" size={16} />
                  ) : (
                    <AiOutlineEdit color="white" size={16} />
                  )
                }
                onClick={() => {
                  if (mode === "edit") {
                    setManajemenPengetahuanState("mode", "view");
                  }
                  if (mode === "view") {
                    setManajemenPengetahuanState("mode", "edit");
                  }
                }}
              >
                {mode === "edit" ? "View" : "Edit"}
              </Button>
              <ButtonModalDelete title={title} id={id} />
              <Button
                colorScheme="green"
                rightIcon={<AiOutlineSave color="white" size={16} />}
                isLoading={loadingAction}
                spinnerPlacement="start"
                loadingText="Menyimpan..."
                onClick={() => postData()}
                isDisabled={mode === "view" || loadingData}
              >
                Simpan
              </Button>
            </Stack>
          )}
        </Flex>
      </Box>
      <Box>
        <Tabs
          variant="unstyled"
          color="black"
          mt={4}
          mx={4}
          index={tabIndexMenu}
          onChange={(index) => setTabIndexMenu(index)}
        >
          <TabList bg="transparent" borderTopRadius="lg" pt={2}>
            <Tab
              bg={tabIndexMenu === 0 ? "white" : "#E5E7EB"}
              roundedTop="lg"
              mr={2}
            >
              Detail Pengetahuan
            </Tab>
            <Tab
              bg={tabIndexMenu === 1 ? "white" : "#E5E7EB"}
              roundedTop="lg"
              mr={2}
            >
              Daftar Peserta
            </Tab>
            <Tab bg={tabIndexMenu === 2 ? "white" : "#E5E7EB"} roundedTop="lg">
              Konten Pemateri
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} pb={5}>
              <Grid
                templateColumns="repeat(6, 1fr)"
                py={8}
                gap={4}
                px={4}
                boxShadow="lg"
                bg="white"
              >
                {loadingData ? (
                  <GridItem colSpan={6} w="full">
                    <Loader />
                  </GridItem>
                ) : (
                  <>
                    {/* judul */}
                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Judul
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Input
                        onChange={(e) =>
                          setManajemenPengetahuanState("title", e.target.value)
                        }
                        fontSize="sm"
                        value={title}
                        readOnly={mode === "view"}
                        bg="#F6F5F8"
                        _focus={{
                          ring: "1",
                          ringColor: "green",
                          borderColor: "green",
                        }}
                      />
                    </GridItem>

                    {/* Sync/Async */}
                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Jenis Pengetahuan
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <RadioGroup
                        defaultValue={type}
                        onChange={(e) =>
                          setManajemenPengetahuanState("type", e)
                        }
                      >
                        <Stack spacing={5} direction="row">
                          <Radio
                            colorScheme="green"
                            value="synchronized"
                            isDisabled={mode === "view"}
                          >
                            Synchronized
                          </Radio>
                          <Radio
                            colorScheme="green"
                            value="asynchronized"
                            isDisabled={mode === "view"}
                          >
                            Asynchronized
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </GridItem>

                    {type === "synchronized" && (
                      <>
                        {/* Jadwal Pengetahuan */}
                        <GridItem colSpan={1}>
                          <Text fontWeight="semibold" fontSize="sm">
                            Jadwal Pengetahuan
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2} display="flex">
                          <Input
                            defaultValue={startDate}
                            type="datetime-local"
                            onChange={(e) =>
                              setManajemenPengetahuanState(
                                "startDate",
                                e.target.value
                              )
                            }
                            bg="#F6F5F8"
                            isReadOnly={mode === "view"}
                            // w="30%"
                            _focus={{
                              ring: "1",
                              ringColor: "green",
                              borderColor: "green",
                            }}
                          />
                        </GridItem>
                        <GridItem
                          colSpan={1}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text fontWeight="semibold" fontSize="sm">
                            SD
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2} display="flex">
                          <Input
                            defaultValue={endDate}
                            type="datetime-local"
                            onChange={(e) =>
                              setManajemenPengetahuanState(
                                "endDate",
                                e.target.value
                              )
                            }
                            bg="#F6F5F8"
                            isReadOnly={mode === "view"}
                            _focus={{
                              ring: "1",
                              ringColor: "green",
                              borderColor: "green",
                            }}
                          />
                        </GridItem>
                      </>
                    )}

                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Penyelenggara
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Input
                        onChange={(e) =>
                          setManajemenPengetahuanState(
                            "organizer",
                            e.target.value
                          )
                        }
                        fontSize="sm"
                        value={organizer}
                        bg="#F6F5F8"
                        isReadOnly={mode === "view"}
                        _focus={{
                          ring: "1",
                          ringColor: "green",
                          borderColor: "green",
                        }}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Pilih Kategori
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Select
                        placeholder="Pilih Kategori"
                        options={listKategoriPengetahuan}
                        isClearable
                        onChange={(e) =>
                          setManajemenPengetahuanState(
                            "kategoriPengetahuanId",
                            e
                          )
                        }
                        isDisabled={mode === "view"}
                        value={kategoriPengetahuanId}
                        styles={colourStyles}
                      />
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Cover Thumbnail
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <BoxUploadFile
                        setFile={setManajemenPengetahuanState}
                        typeFile="image"
                        defaultFile={image}
                        isReadOnly={mode === "view"}
                      />
                    </GridItem>

                    {/* Informasi Umum */}
                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Informasi Umum
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Box pointerEvents={mode === "view" ? "none" : "auto"}>
                        <EditorComponent
                          description={description}
                          setDescription={setManajemenPengetahuanState}
                        />
                      </Box>
                    </GridItem>

                    {/* Publikasi */}
                    <GridItem colSpan={1}>
                      <Text fontWeight="semibold" fontSize="sm">
                        Publikasi
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5} display="flex">
                      <Switch
                        defaultChecked={publish}
                        colorScheme="green"
                        mr={2}
                        isReadOnly={mode === "view"}
                        onChange={(e) =>
                          setManajemenPengetahuanState(
                            "publish",
                            e.target.checked
                          )
                        }
                      />
                      <Text fontWeight="semibold" fontSize="sm">
                        {publish ? "Publikasi" : "Draft"}
                      </Text>
                    </GridItem>
                  </>
                )}
              </Grid>
            </TabPanel>
            <TabPanel p={0}>
              <DaftarPesertaPengetahuan />
            </TabPanel>
            <TabPanel p={0}>
              {pengetahuanItem ? (
                <KontenPemateri />
              ) : (
                <Center>
                  <Loader />
                </Center>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <ModalAddSpeaker />
    </SidebarWithHeader>
  );
};

const DetailPengetahuanWrapper = () => {
  return (
    <ManajemenPengetahuanKontenPemateriContext>
      <ManajemenPengetahuanDaftarPesertaController.Provider>
        <ManajemenPengetahuanContext.Provider>
          <YoutubeContext.Provider>
            <KelolaKontenManajemenPelatihanController.Provider>
              <DetailPengetahuan />
            </KelolaKontenManajemenPelatihanController.Provider>
          </YoutubeContext.Provider>
        </ManajemenPengetahuanContext.Provider>
      </ManajemenPengetahuanDaftarPesertaController.Provider>
    </ManajemenPengetahuanKontenPemateriContext>
  );
};

export default DetailPengetahuanWrapper;
