/**
 * PodcastAudio component handles the audio playback of the podcast.
 */

import React from "react";
import { usePodcastContext } from "../../controller/podcast";

/**
 * PodcastAudio component.
 * @component
 * @returns {React.FC} The PodcastAudio component.
 * @author Bagas Prasetyadi
 */

const PodcastAudio: React.FC = () => {
  const {
    handleLoadedMetadata,
    audioRef,
    setCurrentTrack,
    handleSliderChange,
  } = usePodcastContext();
  const { currentTrack, isRepeat, isPlaying, listData } =
    usePodcastContext().state;

  return (
    <audio
      ref={audioRef}
      src={currentTrack?.audio ?? ""}
      loop={isRepeat}
      onLoadedData={(e: React.SyntheticEvent<HTMLAudioElement>) => {
        const audioElement = e.target as HTMLAudioElement;
        handleLoadedMetadata(audioElement.duration);
        if (audioRef?.current && isPlaying) {
          audioRef.current.play();
        }
      }}
      onEnded={() => {
        const lastIndexTrack = listData.findIndex(
          (item) => item.audio === currentTrack?.audio
        );
        if (listData.length - 1 === lastIndexTrack) {
          setCurrentTrack(listData[0]);
        } else {
          const newTrack = listData[lastIndexTrack + 1];

          setCurrentTrack(newTrack);
        }
      }}
      onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement>) => {
        const audioElement = e.target as HTMLAudioElement;
        handleSliderChange(audioElement.currentTime);
      }}
    ></audio>
  );
};

export default PodcastAudio;
