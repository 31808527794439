/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slider } from "../../../../../../entities/Slider";
import { SliderPresenter } from "../../../../../../data/presenters/SliderPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalSlider: number;
  formId: number;
  formDialog: string;
  formNamaUserTestimoni: string;
  formTestimoni: string;
  formJabatanTestimoni: string;
  formPathSlider: any;
  formStatusPublish: boolean;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  slider: Slider[];
}

interface InitialState {
  state: IState;
  getSlider: Function;
  setPage: Function;
  setPageRow: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setformNamaUserTestimoni: Function;
  setformTestimoni: Function;
  setformJabatanTestimoni: Function;
  setFormPathSlider: Function;
  setFormStatusPublish: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deleteSlider: Function;
  searchSlider: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalSlider: 1,
    paketSoal: [],
    formDialog: "",
    formId: 0,
    formNamaUserTestimoni: "",
    formTestimoni: "",
    formJabatanTestimoni: "",
    formPathSlider: null,
    formStatusPublish: true,
    formJenisSoal: "",
    formPetunjukSoal: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    slider: [],
  },
  getSlider: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setformNamaUserTestimoni: () => {},
  setFormPathSlider: () => {},
  setFormStatusPublish: () => {},
  setShowDialog: () => {},
  deleteSlider: () => {},
  setShowDialogDelete: () => {},
  setformTestimoni: () => {},
  setformJabatanTestimoni: () => {},
  searchSlider: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenSoalProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalSlider: 0,
    formId: 0,
    formDialog: "",
    formNamaUserTestimoni: "",
    formTestimoni: "",
    formJabatanTestimoni: "",
    formPathSlider: null,
    formStatusPublish: true,
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    slider: [],
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getSlider = async (pagee: number, keyword: string, row: number) => {
    setLoading(true);

    try {
      const sliderPresenter = container.resolve(SliderPresenter);
      const getAllSlider = await sliderPresenter.getAllSlider(
        pagee,
        row,
        keyword
      );

      const data = getAllSlider;

      setJumlahPage(data[0].jumlahDataSlider / row);

      setState((prevstate) => ({
        ...prevstate,
        slider: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchSlider = (value: string) => {
    setLoading(true);
    getSlider(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: Slider) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formNamaUserTestimoni: value.nama_user_testimoni,
      formJabatanTestimoni: value.jabatan_user_testimoni,
      formTestimoni: value.testimoni,
      formStatusPublish: value.status_publish == 1 ? true : false,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formDialog == "Tambah") {
      if (state.formPathSlider == null || state.formNamaUserTestimoni == "") {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }
    } else {
      if (state.formNamaUserTestimoni == "") {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        return;
      }
    }

    try {
      const sliderPresenter = container.resolve(SliderPresenter);
      if (state.formDialog == "Tambah") {
        const action = await sliderPresenter.storeSlider(
          state.formNamaUserTestimoni,
          state.formTestimoni,
          state.formJabatanTestimoni,
          state.formPathSlider,
          state.formStatusPublish
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getSlider(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action = await sliderPresenter.updateSlider(
          state.formId,
          state.formNamaUserTestimoni,
          state.formTestimoni,
          state.formJabatanTestimoni,
          state.formPathSlider,
          state.formStatusPublish
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getSlider(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setformNamaUserTestimoni = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNamaUserTestimoni: value,
    }));
  };

  const setformTestimoni = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formTestimoni: value,
    }));
  };

  const setformJabatanTestimoni = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJabatanTestimoni: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const setFormPathSlider = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      formPathSlider: value,
    }));
  };

  const setFormStatusPublish = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formStatusPublish: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formNamaUserTestimoni: "",
      formPathSlider: null,
      formStatusPublish: true,
    }));
  };

  const deleteSlider = async () => {
    setFormLoading(true);

    try {
      const sliderPresenter = container.resolve(SliderPresenter);
      const action = await sliderPresenter.deleteSlider(state.formId);
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getSlider(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  return (
    <ManajemenSoalProvider
      value={{
        state,
        getSlider,
        setPage,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setformNamaUserTestimoni,
        setFormPathSlider,
        setFormStatusPublish,
        setShowDialog,
        deleteSlider,
        setShowDialogDelete,
        setformJabatanTestimoni,
        setformTestimoni,
        setPageRow,
        searchSlider,
      }}
    >
      {children}
    </ManajemenSoalProvider>
  );
};

export const useMasterSliderContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterSliderContext,
  Provider,
};
