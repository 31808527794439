import React from "react";
import { Box } from "@chakra-ui/react";
import { Wrapper } from "../../components/Wrapper";
import PodcastController from "../../controller/podcast";
import {
  Hero,
  Filter,
  ListPodcast,
  Player,
  Audio,
  PlayerDetail,
} from "../../components/Podcast";
import PodcastCategoryContext from "../../controller/admin/data_master/kategori_podcast";

/**
 * Podcast component.
 *
 * This component renders the podcast section, including the hero, filter,
 * list of podcasts, player, audio, and footer.
 *
 * @returns {React.FC} The podcast component.
 * @author Bagas Prasetyadi
 */

const Podcast: React.FC = () => {
  return (
    <Wrapper>
      <Hero />
      <Box bg="white">
        <Filter />
        <ListPodcast />
        <Player />
        <PlayerDetail />
        <Audio />
      </Box>
    </Wrapper>
  );
};

/**
 * PodcastMain component.
 *
 * This component serves as the main entry point for the podcast page.
 * It wraps the Podcast component with the PodcastController.Provider.
 *
 * @returns {React.FC} The podcast main component.
 * @author Bagas Prasetyadi
 */

const PodcastMain: React.FC = () => {
  return (
    <PodcastController.Provider>
      <PodcastCategoryContext.Provider>
        <Podcast />
      </PodcastCategoryContext.Provider>
    </PodcastController.Provider>
  );
};

export default PodcastMain;
