/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { Sidebar } from "../../../../components/Admin/Sidebar";
import { Navbar } from "../../../../components/Admin/Navbar";
import PenilaianManajemenPelatihanController, {
  usePenilaianManajemenPelatihanContext,
} from "../../../../controller/admin/manajemen_pelatihan/penilaian";
import { toast, ToastContainer } from "react-toastify";
import { If, Then, Else } from "react-if";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";

const BottomHeader: React.FC = () => {
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Kelola Pelatihan</a>
      </div>
    </div>
  );
};

const Form: React.FC = () => {
  const {
    getModulPelatihan,
    bobotPenilaianSubModul,
    setPenilaianSubModul,
    setTotalBobotNilai,
    handleFinish,
    getDetailPelatihan,
  } = usePenilaianManajemenPelatihanContext();
  const {
    modulPelatihan,
    bobotNilai,
    penilaianSubModul,
    totalBobotNilai,
    jenisPenilaianId,
  } = usePenilaianManajemenPelatihanContext().state;

  useEffect(() => {
    getDetailPelatihan(window.location.pathname.split("/", 5)[4]).then(
      (data) => {
        getModulPelatihan(data.id);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bobotNilai, jenisPenilaianId]);

  useEffect(() => {
    if (penilaianSubModul.length) {
      const resultTotal = penilaianSubModul.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.bobotNilai),
        0
      );
      setTotalBobotNilai(resultTotal);
    }
  }, [penilaianSubModul]);

  return (
    <div className="bg-white rounded-lg mt-5">
      <h5 className="px-5 py-5">Bobot Penilaian</h5>
      <hr />

      <div className="px-10 py-5">
        <If condition={modulPelatihan.length > 0}>
          <Then>
            {modulPelatihan.map((mp, idx) => {
              return (
                <div key={mp.id}>
                  <h5>
                    Section {idx + 1}. {mp.judul_modul}
                  </h5>

                  {mp.subModulPelatihan.map((sm) => {
                    return (
                      <If
                        condition={
                          (sm.ada_latihan == 0 && sm.jenis_ujian == "") ||
                          jenisPenilaianId === 2
                        }
                        key={sm.id}
                      >
                        <Then>
                          <div className="px-5 mt-3">
                            <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
                              <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                                <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                                  {sm.judul}
                                </label>
                              </div>
                              <div className="col-span-5 md:col-span-5   sm:grid-cols-1">
                                <input
                                  type="number"
                                  disabled
                                  className="form-control
                                                                block
                                                                w-full
                                                                px-3
                                                                py-1.5
                                                                text-base
                                                                font-normal
                                                                text-gray-700
                                                                bg-gray-100 bg-clip-padding
                                                                border border-solid border-gray-300
                                                                rounded
                                                                transition
                                                                ease-in-out
                                                                m-0
                                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  value={0}
                                  onChange={(e) =>
                                    bobotPenilaianSubModul(
                                      sm.id,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Masukkan Nilai"
                                />
                              </div>
                            </div>
                          </div>
                        </Then>
                        <Else>
                          <If
                            condition={
                              sm.ada_latihan == 1 ||
                              sm.jenis_ujian == "ujian" ||
                              sm.jenis_ujian == "project"
                            }
                          >
                            <Then>
                              <div className="px-5 mt-3">
                                <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
                                  <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
                                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                                      {sm.judul}
                                    </label>
                                  </div>
                                  <div className="col-span-5 md:col-span-5   sm:grid-cols-1">
                                    <input
                                      type="number"
                                      className="form-control
                                                                    block
                                                                    w-full
                                                                    px-3
                                                                    py-1.5
                                                                    text-base
                                                                    font-normal
                                                                    text-gray-700
                                                                    bg-white bg-clip-padding
                                                                    border border-solid border-gray-300
                                                                    rounded
                                                                    transition
                                                                    ease-in-out
                                                                    m-0
                                                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                      defaultValue={sm.bobot_penilaian}
                                      onChange={(e) => {
                                        setPenilaianSubModul({
                                          id: sm.id,
                                          bobotNilai: e.target.value,
                                        });
                                      }}
                                      placeholder="Masukkan Nilai"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Then>
                          </If>
                        </Else>
                      </If>
                    );
                  })}
                </div>
              );
            })}
          </Then>
        </If>

        <h5>Total Bobot</h5>

        <div className="px-5 mt-3">
          <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
            <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
              <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                Bobot Penilaian
              </label>
            </div>
            <div className="col-span-5 md:col-span-5 sm:grid-cols-1">
              <input
                type="number"
                disabled
                className="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-gray-100 bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                value={totalBobotNilai}
                placeholder="Nilai Bobot"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <a
          href={`/admin/kelola_konten/manajemen_pelatihan/${
            window.location.pathname.split("/", 5)[4]
          }`}
          className="px-6 py-2.5 border border-green-400 text-green-400 font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 mr-2"
        >
          Sebelumnya
        </a>
        <button
          onClick={() => handleFinish()}
          className="px-6 py-2.5 bg-green-400 text-white font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 mr-2"
        >
          Selesai
        </button>
      </div>
    </div>
  );
};

const ModalFinish: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { showDialog, loadingFinishSave, statusPublish, pelatihanId } =
    usePenilaianManajemenPelatihanContext().state;
  const { setShowDialog, savePelatihan, setStatusPublish } =
    usePenilaianManajemenPelatihanContext();

  return (
    <>
      {showDialog ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Simpan Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowDialog(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <div
                    className="flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
                    role="alert"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 inline w-5 h-5 mr-3"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="text-justify">
                      <span className="font-bold">Pemberitahuan!</span> Pastikan
                      konten pelatihan{" "}
                      <span className="font-bold">
                        sudah di buat dan sesuai{" "}
                      </span>
                      . Jika dirasa belum sesuai harap kembali ke{" "}
                      <span className="font-bold">step - 2</span> untuk
                      <span className="font-bold">
                        {" "}
                        menyesuaikan kembali
                      </span>{" "}
                      konten pelatihan. Jika anda ingin{" "}
                      <span className="font-bold">
                        {" "}
                        menambahkan konten pelatihan{" "}
                      </span>{" "}
                      atau{" "}
                      <span className="font-bold">
                        merubah konten pelatihan{" "}
                      </span>{" "}
                      pada hari lain, harap ubah{" "}
                      <span className="font-bold"> Publikasi Pelatihan </span>{" "}
                      pada switch dibawah ini menjadi{" "}
                      <span className="font-bold">abu-abu</span>. Dan jika anda
                      merasa konten yang anda tambahkan sudah sesuai dan tidak
                      akan menambah konten pelatihan lainnya, harap ubah{" "}
                      <span className="font-bold"> Publikasi Pelatihan </span>{" "}
                      pada switch dibawah ini menjadi{" "}
                      <span className="font-bold">hijau</span>. Terimakasih.
                    </div>
                  </div>

                  <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
                    <div className="col-span-4 md:col-span-3 sm:grid-cols-2">
                      <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
                        Publikasi Pelatihan
                      </label>
                    </div>
                    <div className="col-span-8 md:col-span-8 sm:grid-cols-1">
                      <Switch
                        className="ml-2 mt-2"
                        height={20}
                        onChange={() => setStatusPublish(!statusPublish)}
                        checked={statusPublish}
                      />
                    </div>
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowDialog(false)}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={() => savePelatihan(pelatihanId)}
                  >
                    <If condition={loadingFinishSave}>
                      <Then>
                        <ReactLoading
                          type={"bars"}
                          color={"white"}
                          height={15}
                          width={20}
                        />
                      </Then>
                      <Else>Simpan</Else>
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <div className="app">
      <ModalFinish />

      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-30">
          <BottomHeader />
        </header>

        <div className="container px-4 md:px-12 mb-10">
          <div className="bg-white rounded-lg ">
            <ul className="stepper pb-3 rounded-lg" data-mdb-stepper="stepper">
              <li className="stepper-step">
                <a
                  href={`/admin/ubah/manajemen_pelatihan/${
                    window.location.pathname.split("/", 5)[4]
                  }`}
                >
                  <div className="stepper-head">
                    <span
                      className="stepper-head-icon font-semibold"
                      style={{
                        width: "37px",
                        height: "37px",
                        backgroundColor: "#0DBD7F",
                      }}
                    >
                      01
                    </span>
                    <span className="stepper-head-text font-semibold">
                      Data Pelatihan
                    </span>
                  </div>
                </a>
              </li>
              <li className="stepper-step">
                <a
                  href={`/admin/kelola_konten/manajemen_pelatihan/${
                    window.location.pathname.split("/", 5)[4]
                  }`}
                >
                  <div className="stepper-head">
                    <span
                      className="stepper-head-icon font-semibold"
                      style={{
                        width: "37px",
                        height: "37px",
                        backgroundColor: "#0DBD7F",
                      }}
                    >
                      02
                    </span>
                    <span className="stepper-head-text font-semibold">
                      Kelola Konten
                    </span>
                  </div>
                </a>
              </li>
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{
                      width: "37px",
                      height: "37px",
                      backgroundColor: "#0DBD7F",
                    }}
                  >
                    03
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Penilaian
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <Form />
        </div>
      </main>
    </div>
  );
};

const PenilaianManajemenPelatihan: React.FC = () => {
  return (
    <PenilaianManajemenPelatihanController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <SidebarWithHeader title="Manajemen Pelatihan">
        <WrapperMain />
      </SidebarWithHeader>
    </PenilaianManajemenPelatihanController.Provider>
  );
};

export default PenilaianManajemenPelatihan;
