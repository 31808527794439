import { AxiosResponse } from "axios";
import { LogLatihan } from "../../entities/LogLatihan";

export class LogLatihanDataMapper {
  public convertListLogLatihanData(result: AxiosResponse<any>): LogLatihan[] {
    const { data } = result.data;

    let logLatihan: LogLatihan[] = [];

    data.map((d: any) => {
      return logLatihan.push(
        new LogLatihan(
          d.total_jawaban_benar,
          d.total_soal,
          d.nilai,
          d.passing_grade,
          d.status,
          d.created_at,
          d.updated_at,
          result.data.pagination.total_rows
        )
      );
    });

    return logLatihan;
  }
}
