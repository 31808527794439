import React from "react";
import { useKelolaKontenManajemenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/kelola_konten";
import { useManajemenPengetahuanKontenPemateriContext } from "../../../controller/admin/manajemen_pengetahuan/konten-pemateri";
import ItemSoal from "../ContentPelatihan/ItemSoal";

type Props = {
  showModalDetailQuiz: boolean;
  setShowModalDetailQuiz: Function;
};

/**
 * Props for ModalDetailLatihanQuiz component.
 * @typedef {Object} Props
 * @property {boolean} showModalDetailQuiz - Flag to show or hide the detail quiz modal.
 * @property {Function} setShowModalDetailQuiz - Function to set the visibility of the detail quiz modal.
 */

/**
 * ModalDetailLatihanQuiz component to display a modal with quiz details.
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @author Bagas Prasetyadi
 */

const ModalDetailLatihanQuiz = ({
  showModalDetailQuiz,
  setShowModalDetailQuiz,
}: Props) => {
  const { paketSoal } = useKelolaKontenManajemenPelatihanContext().state;
  const { paketSoalId } = useManajemenPengetahuanKontenPemateriContext().state;

  const IndexPaketSoalIdLatihan = paketSoal.findIndex(
    (paketSoal) => paketSoal.id === Number(paketSoalId)
  );

  return (
    <>
      {showModalDetailQuiz ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Detail Soal Latihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowModalDetailQuiz(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2 overflow-y-auto h-96">
                  {paketSoal[IndexPaketSoalIdLatihan].soal.map((s, i) => {
                    return (
                      <ItemSoal
                        idAccordion={"Soal" + (i + 1)}
                        nomor={i + 1}
                        soal={s.soal}
                        jawaban={s.jawaban}
                      />
                    );
                  })}
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => setShowModalDetailQuiz(false)}
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ModalDetailLatihanQuiz;
