/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import {
  FiBriefcase,
  FiCalendar,
  FiStar,
  FiUsers,
  FiPlus,
  FiSearch,
} from "react-icons/fi";
import ReactPaginate from "react-paginate";
import ManajemenPelatihanController, {
  useManajemenPelatihanContext,
} from "../../../../controller/admin/manajemen_pelatihan/index";
import { If, Then, Else } from "react-if";
import { Pelatihan } from "../../../../../../entities/Pelatihan";
import Select from "react-select";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import {
  Center,
  Grid,
  GridItem,
  Spinner,
  Box,
  Flex,
  useColorModeValue,
  Image,
  Stack,
  Text,
  Heading,
  Tag,
  Link,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import { GetAuthData } from "../../../../../misc/GetAuthData";

const BottomHeader: React.FC = () => {
  const { subMenuJenisPelatihan } = useManajemenPelatihanContext().state;
  const { searchPelatihan, filterTahun, filterJenisPelatihan } =
    useManajemenPelatihanContext();
  let tahun: number[] = [];

  for (var i = 2020; i <= 2028; i++) {
    tahun.push(i);
  }

  let optionsTahun;

  if (tahun.length > 0) {
    optionsTahun = tahun.map((item) => ({ value: item, label: item }));
  } else {
    optionsTahun = [];
  }

  var optionsSubMenuJenisPelatihan;

  if (subMenuJenisPelatihan.length > 0) {
    optionsSubMenuJenisPelatihan = subMenuJenisPelatihan.map((item) => ({
      value: item.id,
      label: item.judul_menu,
    }));
  } else {
    optionsSubMenuJenisPelatihan = [];
  }

  const clearFilter = () => {
    filterTahun("");
    filterJenisPelatihan("");
  };

  const level = GetAuthData()?.level;

  return (
    <Flex flexWrap="wrap" justifyContent="space-between">
      <Flex flexWrap="wrap">
        {/* Filter Tahun */}
        <Box mr={1} zIndex={3}>
          <Select
            options={optionsTahun}
            onChange={(val: any) => filterTahun(val.value)}
            placeholder="Tahun"
          />
        </Box>

        {/* Filter Jenis Pelatihan */}
        <Box mr={1} zIndex={2}>
          <Select
            options={optionsSubMenuJenisPelatihan}
            onChange={(val: any) => filterJenisPelatihan(val.value)}
            placeholder="Jenis Pelatihan"
          />
        </Box>

        {/* Clear Filter */}
        <Box>
          <Button
            background="GreenPrimary.200"
            color="green"
            onClick={clearFilter}
            _hover={{ color: "white", background: "GreenPrimary.400" }}
          >
            Clear
          </Button>
        </Box>
      </Flex>

      <Flex
        flexWrap="wrap"
        mb={{ base: 2, md: 0 }}
        justifyContent="space-between"
      >
        {/* Search Input */}
        <Box mr={1}>
          <InputGroup size="md">
            <InputLeftAddon children={<FiSearch />} background="white" />
            <Input
              placeholder="Cari Pelatihan..."
              background="white"
              onChange={(e) => searchPelatihan(e.target.value)}
            />
          </InputGroup>
        </Box>

        {/* Tambah Pelatihan Button */}
        {(level === "superadmin" || level === "admin") && (
          <Button
            colorScheme="whatsapp"
            background="GreenPrimary.500"
            as="a"
            href="/admin/tambah/manajemen_pelatihan"
          >
            <Flex>
              Tambah
              <Icon ml={2} as={FiPlus} />
            </Flex>
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

const Pagination = () => {
  const { setPage } = useManajemenPelatihanContext();
  const { jumlahPage } = useManajemenPelatihanContext().state;

  var endFix =
    jumlahPage > Number(jumlahPage.toFixed())
      ? Number(jumlahPage.toFixed()) + 1
      : Number(jumlahPage.toFixed());

  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  return (
    <If condition={endFix > 1}>
      <Then>
        <div className="flex justify-center mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Selanjutnya"
            activeClassName="active"
            containerClassName="pagination"
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            pageCount={
              jumlahPage > Number(jumlahPage.toFixed())
                ? Number(jumlahPage.toFixed()) + 1
                : Number(jumlahPage.toFixed())
            }
            previousLabel="Sebelumnya"
          />
        </div>
      </Then>
      <Else>
        <></>
      </Else>
    </If>
  );
};

const ItemPelatihan = ({ pelatihan }: { pelatihan: Pelatihan }) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      href={`/admin/detail/manajemen_pelatihan/data_pelatihan/${pelatihan.uuid}`}
    >
      <Box
        role={"group"}
        p={6}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
        h={"full"}
      >
        <Box
          rounded={"lg"}
          pos={"relative"}
          height={"230px"}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "full",
            h: "full",
            pos: "absolute",
            top: 5,
            left: 0,
            backgroundImage: `url(${pelatihan.path_thumbnail})`,
            filter: "blur(15px)",
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: "blur(20px)",
            },
          }}
        >
          <Box
            position="absolute"
            px={3}
            color="white"
            bg={pelatihan.status_publish === 1 ? "#0DBD7F" : "#3C4952"}
            borderRadius="40px"
            top={2}
            right={2}
            fontSize="sm"
            fontWeight="semibold"
          >
            {pelatihan.status_publish === 1 ? "Terpublikasi" : "Draft"}
          </Box>
          <Image
            rounded={"lg"}
            height={230}
            width="full"
            objectFit={"cover"}
            src={pelatihan.path_thumbnail}
          />
        </Box>
        <Stack pt={10}>
          <Tag colorScheme="whatsapp" p={4}>
            {pelatihan.penyelenggara}
          </Tag>
          <Heading fontSize={"xl"} fontFamily={"body"} fontWeight={500}>
            {pelatihan.judul_pelatihan}
          </Heading>
          <Stack direction={"row"} align={"center"} fontSize={16} pt={3}>
            <FiCalendar />
            <Text fontSize={14}>{pelatihan.tanggal_pelatihan}</Text>
          </Stack>
          <Stack direction={"row"} align={"center"} fontSize={16}>
            <FiUsers />
            <Text fontSize={14}>{pelatihan.count_peserta} Peserta</Text>
          </Stack>
          <Stack direction={"row"} align={"center"} fontSize={16}>
            <FiStar />
            <Text fontSize={14}>{pelatihan.rating}</Text>
          </Stack>
          <Stack direction={"row"} align={"center"} fontSize={16}>
            <FiBriefcase />
            <Text fontSize={14}>Angkatan Ke - {pelatihan.angkatan}</Text>
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};

const WrapperMain: React.FC = () => {
  const { getPelatihan, getSubMenuJenisPelatihan } =
    useManajemenPelatihanContext();
  const { pelatihan, isLoading } = useManajemenPelatihanContext().state;

  useEffect(() => {
    getPelatihan(1, "");
    getSubMenuJenisPelatihan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarWithHeader title="Manajemen Pelatihan">
      <BottomHeader />
      {isLoading ? (
        <Center>
          <Box mt={300} mb={300}>
            <Spinner />
          </Box>
        </Center>
      ) : (
        <>
          <Grid
            templateColumns={{
              lg: "repeat(3, 1fr)",
              md: "repeat(2, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
            gap={3}
            mt={5}
          >
            {pelatihan.map((p: Pelatihan, idx) => {
              return (
                <GridItem key={idx}>
                  <ItemPelatihan pelatihan={p} />
                </GridItem>
              );
            })}
          </Grid>
        </>
      )}

      {/* pagination */}
      <Pagination />
    </SidebarWithHeader>
  );
};

const ManajemenPelatihan: React.FC = () => {
  return (
    <ManajemenPelatihanController.Provider>
      <WrapperMain />
    </ManajemenPelatihanController.Provider>
  );
};

export default ManajemenPelatihan;
