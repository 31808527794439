import env from "@ludovicm67/react-dotenv";
import axios from "axios";
import React, { createContext, useContext, useState } from "react";

export interface LogKontributorPengajuan {
  id: number;
  kontributor_pengetahuan_id: number;
  status: string;
  alasan: string;
  created_at: string;
  updated_at: string;
}

interface StatusPengajuanItem {
  id: number;
  nama_lengkap: string;
  nik: string;
  nip: string;
  email: string;
  nomor_telepon: string;
  judul: string;
  log_kontributor_pengetahuan: LogKontributorPengajuan[];
}

interface IState {
  state: {
    url: string;
    statusPengajuanItem: StatusPengajuanItem | null;
    isLoading: boolean;
  };
  dispatch: {
    setStatusPengajuanState: Function;
    fetchData: Function;
  };
}

const initialState: IState = {
  state: {
    url: "kontributor_pengetahuan",
    statusPengajuanItem: null,
    isLoading: false,
  },
  dispatch: {
    setStatusPengajuanState: () => {},
    fetchData: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: StatusPengajuanProvider } = Context;

const StatusPengajuanController: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const { url } = state.state;

  const setStatusPengajuanState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: { ...prevState.state, [key]: value },
    }));
  };

  const fetchData = async (nomorPengajuan: string) => {
    const baseUrl = env.REACT_APP_BE_PROD_BASE_URL.replace("/api/v1", "");

    setStatusPengajuanState("isLoading", true);
    try {
      const response = await axios.get(`${baseUrl}${url}/${nomorPengajuan}`);
      setStatusPengajuanState("statusPengajuanItem", response.data.data);
    } catch (error) {
      throw error;
    } finally {
      setStatusPengajuanState("isLoading", false);
    }
  };

  return (
    <StatusPengajuanProvider
      value={{
        state: state.state,
        dispatch: {
          setStatusPengajuanState,
          fetchData,
        },
      }}
    >
      {children}
    </StatusPengajuanProvider>
  );
};

export const useStatusKontributor = () => useContext(Context);
export default StatusPengajuanController;
