import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./router";
import reportWebVitals from "./reportWebVitals";
import { AppComponent } from "./app/di/AppComponent";
import "./app/ui/assets/css/index.css";
import "tw-elements";
import "./app/ui/assets/admin/style.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "moment/locale/id";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from "@sentry/integrations";
import {
  getSentryEnvironment,
  SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE,
} from "./config/sentry";
import "@fontsource/poppins";

// initialize sentry
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new ExtraErrorDataIntegration({
      depth: 5,
    }),
    new HttpClientIntegration({
      failedRequestStatusCodes: [[500, 599]],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

  release: "lms-fe@" + process.env.npm_package_version,
  environment: getSentryEnvironment(),
});

// Set time to local indonesia
moment.locale("id");

// Allow console
console.warn = () => {};
// console.log = () => {};

// Use App Component
AppComponent.init();

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
