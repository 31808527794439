import { JawabanSoalRepositoryInterface } from "../../../data/contracts/JawabanSoal";
import { JawabanSoalDataMapper } from "../../../data/mappers/JawabanSoal";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { JawabanSoal } from "../../../entities/JawabanSoal";

export class JawabanSoalApiRepository
  implements JawabanSoalRepositoryInterface
{
  private apiService: ApiService;
  private mapper: JawabanSoalDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: JawabanSoalDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getJawabanSoalBySoalId(soal_id: number): Promise<JawabanSoal[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.jawabanSoal(),
      {
        soal_id: soal_id,
      },
      null,
      headers
    );
    return this.mapper.convertDetailJawabanSoalDataFromApi(dataResponse);
  }

  public async storeJawabanSoal(
    soal_id: number,
    pilihan: string,
    jawaban_benar: boolean
  ): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("soal_id", soal_id.toString());
    formData.append("pilihan", pilihan);
    formData.append("jawaban_benar", jawaban_benar.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.jawabanSoal(),
      {},
      formData,
      headers
    );
    return dataResponse;
  }

  public async updateJawabanSoal(
    jawaban_soal_id: number,
    pilihan: string,
    jawaban_benar: boolean
  ): Promise<any> {
    const headers = new Map<string, string>();

    let formData = new FormData();
    formData.append("pilihan", pilihan);
    formData.append("jawaban_benar", jawaban_benar.toString());
    const dataResponse = await this.apiService.invoke(
      "post",
      this.endpoints.jawabanSoal() + "/" + jawaban_soal_id,
      {},
      formData,
      headers
    );
    return dataResponse;
  }

  public async deleteJawabanSoal(jawaban_soal_id: number): Promise<any> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "delete",
      this.endpoints.jawabanSoal() + "/" + jawaban_soal_id,
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
