import { injectable } from "tsyringe";
import { KategoriPengetahuanApiRepository } from "../../app/repository/api/KategoriPengetahuanApiRepository";
import { KategoriPengetahuan } from "../../entities/KategoriPengetahuan";

@injectable()
export class KategoriPengetahuanPresenter {
  private repository: KategoriPengetahuanApiRepository;
  constructor(repository: KategoriPengetahuanApiRepository) {
    this.repository = repository;
  }

  public getAllKategoriPengetahuan(
    page: number,
    rows: number,
    keyword: string
  ): Promise<KategoriPengetahuan[]> {
    return this.repository.getAllKategoriPengetahuan(page, rows, keyword);
  }

  public storeKategoriPengetahuan(nama: string, icon: File): Promise<any> {
    return this.repository.storeKategoriPengetahuan(nama, icon);
  }

  public updateKategoriPengetahuan(
    id: string,
    nama: string,
    icon: File | string
  ): Promise<any> {
    return this.repository.updateKategoriPengetahuan(id, nama, icon);
  }

  public deleteKategoriPengetahuan(id: string): Promise<any> {
    return this.repository.deleteKategoriPengetahuan(id);
  }
}
