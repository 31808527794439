export function SetLocalStorage({ name, value }: { name: string; value: any }) {
  localStorage.setItem(name, value);
}

export function GetLocalStorage({ name }: { name: string }) {
  const storageLocal = localStorage.getItem(name);

  if (storageLocal) {
    return storageLocal;
  } else {
    return undefined;
  }
}

export function ClearLocalStorage() {
  localStorage.clear();
}

export function DeleteLocalStorage({ name }: { name: string }) {
  localStorage.removeItem(name);
}

export function clearLocalStorageExcept(keysToKeep) {
  const keysInLocalStorage = Object.keys(localStorage);

  keysInLocalStorage.forEach((key) => {
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  });
}
