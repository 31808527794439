import { LandingDataRepositoryInterface } from "../../../data/contracts/LandingData";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class LandingDataApiRepository
  implements LandingDataRepositoryInterface
{
  private apiService: ApiService;
  private endpoints: Endpoints;

  constructor(apiService: ApiService, endpoints: Endpoints) {
    this.apiService = apiService;
    this.endpoints = endpoints;
  }

  public async getAllDataLanding(): Promise<any> {
    const headers = new Map<string, string>();
    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.landingData(),
      {},
      null,
      headers
    );
    return dataResponse;
  }
}
