import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { BiSearch } from "react-icons/bi";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import Loader from "../../../../components/Loader";
import VideoCourseCategoryContext, {
  useVideoCourseCategoryContext,
} from "../../../../controller/admin/data_master/kategori_kursus_video";

/**
 * Represents a modal dialog for creating or editing video course categories.
 * @component
 * @returns {JSX.Element} JSX.Element
 */

const ModalDialog = () => {
  const { mode, dataUpdate, openModal, loadingAction } =
    useVideoCourseCategoryContext().state;
  const { setVideoCourseCategoryState, postData } =
    useVideoCourseCategoryContext();

  const handleCloseModal = () => {
    setVideoCourseCategoryState("dataUpdate", null);
    setVideoCourseCategoryState("openModal", false);
  };
  return (
    <Modal isOpen={openModal} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="black">
          {mode === "create" ? "Tambah" : "Ubah"} Kategori Kursus Video
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="semibold" w="20%">
              Kategori
            </Text>
            <Input
              w="70%"
              type="text"
              defaultValue={dataUpdate?.name}
              onChange={(e) =>
                setVideoCourseCategoryState("name", e.target.value)
              }
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} h="30px" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button
            colorScheme="green"
            h="30px"
            isLoading={loadingAction}
            spinnerPlacement="start"
            onClick={() => postData()}
          >
            Simpan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * Represents the main Video Course Category page component.
 * This component displays the list of video course categories in a data table,
 * along with search and pagination features. Users can create, edit, and delete
 * video course categories using this interface.
 * @component
 * @returns {JSX.Element} JSX.Element
 */
const VideoCourseCategory = () => {
  const { listData, loadingData, pagination, page, perPage } =
    useVideoCourseCategoryContext().state;
  const { setVideoCourseCategoryState, fetchData, handleDelete } =
    useVideoCourseCategoryContext();

  const handlePageChange = (e) => {
    setVideoCourseCategoryState("page", e);
  };

  const handlePerRowsChange = (e) => {
    setVideoCourseCategoryState("perPage", e);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage]);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Kategori",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <Button
              px="2"
              py="1"
              fontSize="xs"
              bg="green.100"
              variant="outline"
              rounded="full"
              h="24px"
              _hover={{ bg: "green.200" }}
              _active={{
                bg: "green.200",
              }}
              onClick={() => {
                setVideoCourseCategoryState("mode", "edit");
                setVideoCourseCategoryState("dataUpdate", row);
                setVideoCourseCategoryState("openModal", true);
              }}
            >
              Ubah Data
            </Button>
            <Button
              ml="2"
              px="2"
              py="1"
              fontSize="xs"
              bg="red.100"
              variant="outline"
              rounded="full"
              h="24px"
              _hover={{ bg: "red.200" }}
              _active={{
                bg: "red.200",
              }}
              onClick={() => {
                handleDelete(row.id);
              }}
            >
              Hapus Data
            </Button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <SidebarWithHeader>
      <Box boxShadow="lg" borderRadius="md" bg="white" px={4} py={8}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          px={4}
        >
          <Heading
            as="h2"
            fontSize="md"
            fontWeight="bold"
            lineHeight="normal"
            _hover={{ cursor: "pointer" }}
          >
            Kategori Kursus Video
          </Heading>
          <Box w={{ base: "full", md: "30%" }}>
            <Flex>
              <InputGroup mr={2}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BiSearch} color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search"
                  _focus={{
                    ring: "1",
                    ringColor: "green",
                    borderColor: "green",
                  }}
                />
              </InputGroup>
              <Button
                colorScheme="green"
                onClick={() => {
                  setVideoCourseCategoryState("mode", "create");
                  setVideoCourseCategoryState("openModal", true);
                }}
              >
                Tambah Baru
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Box mt={4}>
          <DataTable
            columns={columns}
            data={listData}
            progressPending={loadingData}
            pagination
            paginationServer
            paginationTotalRows={pagination ? pagination.total_rows : 0}
            paginationPerPage={perPage}
            progressComponent={<Loader />}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Box>
      </Box>
      <ModalDialog />
    </SidebarWithHeader>
  );
};

/**
 * Represents the main VideoCourse Category page component wrapped in the context provider.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */
const VideoCourseCategoryMain: React.FC = () => {
  return (
    <VideoCourseCategoryContext.Provider>
      <VideoCourseCategory />
    </VideoCourseCategoryContext.Provider>
  );
};

export default VideoCourseCategoryMain;
