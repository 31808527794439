/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KategoriPengetahuan } from "../../../../../../entities/KategoriPengetahuan";
import { KategoriPengetahuanPresenter } from "../../../../../../data/presenters/KategoriPengetahuanPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalKategoriPengetahuan: number;
  kategoriPengetahuan: KategoriPengetahuan[];
  formId: string;
  formDialog: string;
  formNama: string;
  formIcon: File | null | string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
}

interface InitialState {
  state: IState;
  getKategoriPengetahuan: Function;
  setPage: Function;
  setPageRow: Function;
  searchKategoriPengetahuan: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormNama: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deletePengetahuan: Function;
  setFormIcon: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKategoriPengetahuan: 1,
    kategoriPengetahuan: [],
    formDialog: "",
    formId: "",
    formNama: "",
    formIcon: null,
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
  },
  getKategoriPengetahuan: () => {},
  searchKategoriPengetahuan: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormNama: () => {},
  setShowDialog: () => {},
  deletePengetahuan: () => {},
  setShowDialogDelete: () => {},
  setFormIcon: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: MasterKategoriSoalProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalKategoriPengetahuan: 0,
    kategoriPengetahuan: [],
    formDialog: "",
    formId: "",
    formNama: "",
    formIcon: null,
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getKategoriPengetahuan = async (
    pagee: number,
    keyword: string,
    row: number
  ) => {
    setLoading(true);
    try {
      const kategoriPengetahuanPresenter = container.resolve(
        KategoriPengetahuanPresenter
      );
      const getAllKategoriPengetahuan =
        await kategoriPengetahuanPresenter.getAllKategoriPengetahuan(
          pagee,
          row,
          keyword
        );

      const data = getAllKategoriPengetahuan;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        kategoriPengetahuan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  const searchKategoriPengetahuan = (value: string) => {
    setLoading(true);
    getKategoriPengetahuan(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: KategoriPengetahuan) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formNama: value.nama,
      formIcon: value.path_icon,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formNama == "" || state.formIcon == null) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const kategoriPengetahuanPresenter = container.resolve(
        KategoriPengetahuanPresenter
      );
      if (state.formDialog == "Tambah") {
        const action =
          await kategoriPengetahuanPresenter.storeKategoriPengetahuan(
            state.formNama,
            state.formIcon as File
          );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKategoriPengetahuan(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.formDialog == "Ubah") {
        const action =
          await kategoriPengetahuanPresenter.updateKategoriPengetahuan(
            state.formId,
            state.formNama,
            state.formIcon
          );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getKategoriPengetahuan(state.page, state.keyword, state.row);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log(error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormNama = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formNama: value,
    }));
  };

  const setFormIcon = (value: File) => {
    setState((prevstate) => ({
      ...prevstate,
      formIcon: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: "",
      formNama: "",
      formIcon: null,
    }));
  };

  const deletePengetahuan = async () => {
    setFormLoading(true);

    try {
      const kategoriPengetahuanPresenter = container.resolve(
        KategoriPengetahuanPresenter
      );
      const action =
        await kategoriPengetahuanPresenter.deleteKategoriPengetahuan(
          state.formId
        );
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getKategoriPengetahuan(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  return (
    <MasterKategoriSoalProvider
      value={{
        state,
        getKategoriPengetahuan,
        setPage,
        setPageRow,
        searchKategoriPengetahuan,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormNama,
        setShowDialog,
        deletePengetahuan,
        setShowDialogDelete,
        setFormIcon,
      }}
    >
      {children}
    </MasterKategoriSoalProvider>
  );
};

export const useMasterKategoriPengetahuanContext = () =>
  React.useContext(Context);
// eslint-disable-next-line
export default {
  useMasterKategoriPengetahuanContext,
  Provider,
};
