/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import ManajemenPelatihanCreateController, {
  useManajemenPelatihanCreateContext,
} from "../../../../controller/admin/manajemen_pelatihan/create";
import SimdiklatController, {
  useSimdiklatContext,
} from "../../../../controller/simdiklat";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import { If, Then, Else } from "react-if";
import Select from "react-select";
import { PelatihanSimdiklat } from "../../../../../../entities/Simdiklat";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import { Checkbox, Switch, Text } from "@chakra-ui/react";
import EditorComponent from "../../../../components/Admin/Editor";
import MasterJenisPelatihanController, {
  useMasterJenisPelatihanContext,
} from "../../../../controller/admin/data_master/jenis_pelatihan";
import { isBefore, isAfter } from "date-fns";
import BoxUploadFile from "../../../../components/Admin/UploadFile/BoxUploadFile";

const BottomHeader: React.FC = () => {
  return (
    <div className="w-full bg-white flex flex-wrap justify-between">
      <div className="flex flex-wrap items-center px-6 py-2 ">
        <a>Tambah Pelatihan</a>
      </div>
    </div>
  );
};

const Form: React.FC = () => {
  // use manajemen jenis pelatihan create context
  const {
    createPelatihan,
    setJenisPelatihanId,
    setSubMenuJenisPelatihanId,
    setWaktuMulai,
    setWaktuSelesai,
    setWaktuMulaiPendaftaran,
    setWaktuSelesaiPendaftaran,
    setPenyelenggara,
    setAngkatan,
    getJenisPelatihan,
    getSubMenuJenisPelatihan,
    setIsPPKB,
    setCreateManajemenPelatihan,
    setEventId,
    setIsPretest,
    getJenisPenilaian,
    setJenisPenilaian,
  } = useManajemenPelatihanCreateContext();

  const {
    isLoading,
    jenisPelatihan,
    subMenuJenisPelatihan,
    jenis_pelatihan_id,
    angkatan,
    waktu_mulai,
    waktu_selesai,
    waktu_mulai_pendaftaran,
    waktu_selesai_pendaftaran,
    penyelenggara,
    msgError,
    isPPKB,
    materiInti,
    eventId,
    isPretest: isPretestState,
    persyaratan,
    informasi_umum,
    kompetensi_teknis,
    path_thumbnail,
    isForGuruMadrasah,
    listOptionsJenisPenilaian,
    jenisPenilaianSelected,
  } = useManajemenPelatihanCreateContext().state;

  // use simdiklat context
  const { getListPelatihanByIdSimdiklat } = useSimdiklatContext();

  // use master jenis pelatihan context
  const { getMenuJenisPelatihan, getSettingPenilaian } =
    useMasterJenisPelatihanContext();

  var optionsJenisPelatihan;

  if (jenisPelatihan.length > 0) {
    optionsJenisPelatihan = jenisPelatihan.map((item) => ({
      value: item.id,
      label: item.nama_pelatihan,
    }));
  } else {
    optionsJenisPelatihan = [];
  }

  var optionsSubMenuJenisPelatihan;

  if (subMenuJenisPelatihan.length > 0) {
    optionsSubMenuJenisPelatihan = subMenuJenisPelatihan.map((item) => ({
      value: item.id,
      label: item.judul_menu,
    }));
  } else {
    optionsSubMenuJenisPelatihan = [];
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const eventIdParam = urlSearchParams.get("eventId");
  const titlePelatihanParam = urlSearchParams.get("name");
  const startDateParam = urlSearchParams.get("startDate");
  const endDateParam = urlSearchParams.get("endDate");
  const isPretest = urlSearchParams.get("isPretest");
  const isPretestParam = isPretest === "true" ? JSON.parse(isPretest) : false;

  useEffect(() => {
    getMenuJenisPelatihan();
    getSubMenuJenisPelatihan();
    setSubMenuJenisPelatihanId(5);
    getJenisPenilaian();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventIdParam) {
      const formattedStartTime = "T00:00"; // Tetapkan waktu pada pukul 00:00
      const formattedEndTime = "T23:59"; // Tetapkan waktu pada pukul 23:59

      setEventId(eventIdParam);
      setWaktuMulai(startDateParam + formattedStartTime);
      setWaktuSelesai(endDateParam + formattedEndTime);
      setWaktuMulaiPendaftaran(startDateParam + formattedStartTime);
      setWaktuSelesaiPendaftaran(endDateParam + formattedEndTime);
      setIsPretest(isPretestParam);
    }
  }, [eventIdParam]);

  const checkJudulPelatihan = async (event) => {
    const settingPenilaian = await getSettingPenilaian(event.value);
    if (settingPenilaian.length === 0) {
      toast(
        "Judul pelatihan ini belum mengisi predikat pada halaman master jenis pelatihan!"
      );

      return;
    }

    setJenisPelatihanId(event.value);
    const jenisPelatihanData = jenisPelatihan.filter(
      (val) => val.id === event.value
    );

    if (jenisPelatihanData[0].metode_penambahan === 2) {
      await getListPelatihanByIdSimdiklat(jenisPelatihanData[0].kode).then(
        (data: PelatihanSimdiklat) => {
          setAngkatan(data.angkatan);
          setWaktuMulai(data.tanggal_mulai + "T00:00");
          setWaktuSelesai(data.tanggal_selesai + "T00:00");
          setPenyelenggara(data.balai);
        }
      );
    }
  };

  return (
    <div className="bg-white rounded-lg px-8 py-5 mt-5">
      {msgError.length > 0 && (
        <div
          className="mb-4 text-sm text-red-700 bg-red-100 rounded-lg p-4 text-left"
          role="alert"
        >
          <ul className="list-disc px-5">
            {msgError.map((val) =>
              Object.keys(val).map((u, key) => {
                return <li key={key}>{val[u]}</li>;
              })
            )}
          </ul>
        </div>
      )}

      {eventId && (
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              Event Id MAP
            </label>
          </div>
          <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
            <input
              type="text"
              className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              value={eventId}
              disabled
            />
          </div>
        </div>
      )}

      {eventId && (
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mb-5">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              Pre Test MAP
            </label>
          </div>
          <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
            <div className="flex items-center h-full">
              <Switch
                colorScheme="green"
                isChecked={isPretestState}
                isReadOnly
              />
              <Text fontSize="sm" ml={3}>
                {isPretestState ? "Ya" : "Tidak"}
              </Text>
            </div>
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Jenis Pelatihan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
          <Select
            options={optionsSubMenuJenisPelatihan}
            onChange={(val: any) => {
              setSubMenuJenisPelatihanId(val.value);
              getJenisPelatihan(val.value);
              setJenisPelatihanId(0);
            }}
            placeholder="Pilih Jenis Pelatihan"
          />
        </div>
      </div>

      {eventId && (
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1"></div>
          <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
            <span>Belum punya jenis pelatihan? </span>
            <a
              href={`/admin/master/jenis_pelatihan?titlePelatihan=${titlePelatihanParam}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:text-blue-700 underline mb-3 mt-3 inline-block ml-1"
            >
              klik disini
            </a>
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Cover thumbnail
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
          <BoxUploadFile
            setFile={setCreateManajemenPelatihan}
            typeFile="image"
            defaultFile={path_thumbnail}
            nameFile="path_thumbnail"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Judul Pelatihan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <Select
            options={optionsJenisPelatihan}
            onChange={(val: any) => checkJudulPelatihan(val)}
            value={optionsJenisPelatihan.filter(
              (val) => val.value === jenis_pelatihan_id
            )}
            placeholder="Pilih Judul Pelatihan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Jenis Penilaian
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <Select
            options={listOptionsJenisPenilaian}
            onChange={(val: any) => setJenisPenilaian(val)}
            value={jenisPenilaianSelected}
            placeholder="Pilih Jenis Penilaian"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Angkatan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-2">
          <input
            type="text"
            className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setAngkatan(e.target.value)}
            value={angkatan}
            placeholder="Masukkan Angkatan"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Waktu Pelaksanaan
          </label>
        </div>
        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="datetime-local"
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setWaktuMulai(e.target.value)}
            value={waktu_mulai}
            placeholder="Masukkan Waktu Pelatihan"
          />
        </div>

        <div className="col-span-1 text-center">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            sd
          </label>
        </div>

        <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
          <input
            type="datetime-local"
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setWaktuSelesai(e.target.value)}
            value={waktu_selesai}
            placeholder="Masukkan Waktu Pelatihan"
          />
        </div>
      </div>

      {!eventId && (
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 ">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              Waktu Pendaftaran
            </label>
          </div>
          <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
            <input
              type="datetime-local"
              className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              onChange={(e) => {
                const registrationStart = new Date(e.target.value);
                const eventStart = new Date(waktu_mulai);

                // Periksa apakah waktu pendaftaran berada sebelum waktu pelaksanaan
                if (isBefore(registrationStart, eventStart)) {
                  // Jika ya, waktu pendaftaran valid
                  setWaktuMulaiPendaftaran(e.target.value);
                } else {
                  // Jika tidak, tampilkan pesan kesalahan
                  toast("Waktu pendaftaran harus sebelum  waktu pelaksanaan");
                  return;
                }
              }}
              placeholder="Masukkan Waktu Pelatihan"
              value={waktu_mulai_pendaftaran}
            />
          </div>

          <div className="col-span-1 text-center">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              sd
            </label>
          </div>

          <div className="col-span-4 md:col-span-4 sm:grid-cols-1">
            <input
              type="datetime-local"
              className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              onChange={(e) => {
                const registrationStart = new Date(waktu_mulai_pendaftaran);
                const registrationEnd = new Date(e.target.value);

                // Periksa apakah waktu selesai pendaftaran berada sesudah waktu mulai pendaftaran
                if (isAfter(registrationEnd, registrationStart)) {
                  // Jika ya, waktu pendaftaran valid
                  setWaktuSelesaiPendaftaran(e.target.value);
                } else {
                  // Jika tidak, tampilkan pesan kesalahan

                  toast(
                    "Waktu selesai pendaftaran harus setelah  waktu mulai pendaftaran"
                  );
                  return;
                }
              }}
              placeholder="Masukkan Waktu Pelatihan"
              value={waktu_selesai_pendaftaran}
            />
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Penyelenggara
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <input
            type="text"
            className="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            onChange={(e) => setPenyelenggara(e.target.value)}
            value={penyelenggara}
            placeholder="Masukkan Penyelenggara"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Bimtek PPKB
          </label>
        </div>
        <div className="col-span-3 md:col-span-3 sm:grid-cols-1">
          <div className="flex items-center h-full">
            <Switch
              colorScheme="green"
              isChecked={isPPKB}
              onChange={() => setIsPPKB(!isPPKB)}
            />
            <Text fontSize="sm" ml={3}>
              {isPPKB ? "Ya" : "Tidak"}
            </Text>
          </div>
        </div>
        <div className="col-span-6 md:col-span-6 sm:grid-cols-1">
          <div className="flex items-center h-full">
            <Checkbox
              colorScheme="green"
              isChecked={isForGuruMadrasah}
              onChange={(e) =>
                setCreateManajemenPelatihan(
                  "isForGuruMadrasah",
                  e.target.checked
                )
              }
              size="md"
            >
              Relevan Untuk Guru Madrasah
            </Checkbox>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-8">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Informasi Umum
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <EditorComponent
            description={informasi_umum}
            setDescription={setCreateManajemenPelatihan}
            keyState="informasi_umum"
            bgColor="#fff"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-5">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Persyaratan
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <EditorComponent
            description={persyaratan}
            setDescription={setCreateManajemenPelatihan}
            keyState="persyaratan"
            bgColor="#fff"
          />
        </div>
      </div>

      <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
        <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
          <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
            Kompetensi Teknis
          </label>
        </div>
        <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
          <EditorComponent
            description={kompetensi_teknis}
            setDescription={setCreateManajemenPelatihan}
            keyState="kompetensi_teknis"
            bgColor="#fff"
          />
        </div>
      </div>

      {isPPKB && (
        <div className="grid md:grid-cols-11 sm:grid-cols-3 gap-5 mt-4">
          <div className="col-span-2 md:col-span-2 sm:grid-cols-1">
            <label className="form-label inline-block py-1.5 text-gray-700 font-medium">
              Materi Inti
            </label>
          </div>
          <div className="col-span-9 md:col-span-9 sm:grid-cols-1">
            <EditorComponent
              description={materiInti}
              setDescription={setCreateManajemenPelatihan}
              keyState="materiInti"
              bgColor="#fff"
            />
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <button
          className="px-6 py-2.5 bg-green-600 text-white font-semibold text-xs rounded shadow-md hover:bg-green-700 mb-3 mt-3 "
          onClick={() => createPelatihan()}
        >
          <If condition={isLoading}>
            <Then>
              <ReactLoading
                type={"bars"}
                color={"white"}
                height={15}
                width={20}
              />
            </Then>
            <Else>Selanjutnya</Else>
          </If>
        </button>
      </div>
    </div>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <div className="app">
      <main style={{ padding: "0px" }}>
        <header className="sticky top-0 z-40">
          <BottomHeader />
        </header>

        <div className="container px-4 md:px-12 mb-10">
          <div className="bg-white rounded-lg ">
            <ul className="stepper pb-3 rounded-lg" data-mdb-stepper="stepper">
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{
                      width: "37px",
                      height: "37px",
                      backgroundColor: "#0DBD7F",
                    }}
                  >
                    01
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Data Pelatihan
                  </span>
                </div>
              </li>
              <li className="stepper-step">
                <a>
                  <div className="stepper-head">
                    <span
                      className="stepper-head-icon font-semibold"
                      style={{ width: "37px", height: "37px" }}
                    >
                      02
                    </span>
                    <span className="stepper-head-text font-semibold">
                      Kelola Konten
                    </span>
                  </div>
                </a>
              </li>
              <li className="stepper-step">
                <div className="stepper-head">
                  <span
                    className="stepper-head-icon font-semibold"
                    style={{ width: "37px", height: "37px" }}
                  >
                    03
                  </span>
                  <span className="stepper-head-text font-semibold">
                    Penilaian
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <Form />
        </div>
      </main>
    </div>
  );
};

const CreateManajemenPelatihan: React.FC = () => {
  return (
    <ManajemenPelatihanCreateController.Provider>
      <SimdiklatController.Provider>
        <MasterJenisPelatihanController.Provider>
          <ToastContainer
            position="top-center"
            style={{ width: "60%", marginTop: "5%" }}
          />
          <SidebarWithHeader title="Manajemen Pelatihan">
            <WrapperMain />
          </SidebarWithHeader>
        </MasterJenisPelatihanController.Provider>
      </SimdiklatController.Provider>
    </ManajemenPelatihanCreateController.Provider>
  );
};

export default CreateManajemenPelatihan;
