import React from "react";
import Carousel from "react-multi-carousel";
import { Box, Image, Skeleton, Link } from "@chakra-ui/react";
import CustomDot from "../CustomDot";
import { useSliderBannerPublicContext } from "../../../controller/public/slider_banner";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

/**
 * Renders a Hero Carousel component for mobile.
 *
 * @returns {React.FC} The HeroCarouselMobile component.
 * @author Bagas Prasetyadi
 */

const HeroCarouselMobile: React.FC = () => {
  const { sliderBannerData: data, loadingData } =
    useSliderBannerPublicContext().state;

  return (
    <>
      {loadingData ? (
        <Box display="flex" justifyContent="center" my={8}>
          <Skeleton height="350px" width="80%" />
        </Box>
      ) : (
        <Box bg="GreenSage" pb={4}>
          <Carousel
            responsive={responsive}
            ssr={false}
            arrows={false}
            customDot={<CustomDot />}
            showDots
            slidesToSlide={1}
            focusOnSelect
            infinite
            autoPlay
            autoPlaySpeed={5000}
            dotListClass="dot-carousel"
          >
            {data.map((item) =>
              item.link ? (
                <Link
                  href={item.link}
                  isExternal
                  key={item.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  my={4}
                  mx="auto"
                  h="20vh"
                  w="85vw"
                >
                  <Image
                    fallback={<Skeleton h="full" w="full" />}
                    borderRadius="lg"
                    src={item.file}
                    alt={`Hero ${item.title}`}
                    w="full"
                    maxW="full"
                    maxH="full"
                    objectFit="cover"
                  />
                </Link>
              ) : (
                <Box
                  key={item.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  my={4}
                  mx="auto"
                  h="20vh"
                  w="85vw"
                >
                  <Image
                    fallback={<Skeleton h="full" w="full" />}
                    borderRadius="lg"
                    src={item.file}
                    alt={`Hero ${item.title}`}
                    w="full"
                    maxW="full"
                    maxH="full"
                    objectFit="cover"
                  />
                </Box>
              )
            )}
          </Carousel>
        </Box>
      )}
    </>
  );
};

export default HeroCarouselMobile;
