import React from "react";
import {
  Box,
  Flex,
  Text,
  Divider,
  Skeleton,
  Container,
  Img,
} from "@chakra-ui/react";
import PintarIcon1 from "../../assets/svg/pintar_1.svg";
import PintarIcon2 from "../../assets/svg/pintar_2.svg";
import TentangPintarImage from "../../assets/webp/img_about_pintar.webp";

const AboutPintar: React.FC = () => {
  return (
    <Box bg="white" bgGradient="linear(white 0%,  green.50 90%)">
      <Container maxW="8xl">
        <Flex
          my={{ base: 8, md: 20 }}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Box
            w={{ base: "100%", md: "49%" }}
            display="flex"
            justifyContent="center"
          >
            <Img
              w={{ base: "100%", md: "auto" }}
              fallback={<Skeleton h="full" w="80%" />}
              h="auto"
              maxH="500px"
              src={TentangPintarImage}
              alt="Gambar Tentang Pintar"
              objectFit="cover"
            />
          </Box>
          <Box w={{ base: "100%", md: "49%" }}>
            <Box mb={4}>
              <Text
                fontSize={{ base: "2xl", md: "5xl" }}
                fontWeight="bold"
                mt={{ base: "1rem", md: "0rem" }}
              >
                Tentang Pintar
              </Text>
              <Text fontSize="md" mt={2} lineHeight="base" fontWeight="medium">
                Pintar (Pusat Informasi Pelatihan & Pembelajaran) hadir sebagai
                wadah untuk belajar bersama secara daring dan terbuka untuk
                semua ASN Kementerian Agama di Indonesia. PINTAR terdiri dari
                dua kategori pembelajaran yakni, pelatihan dan pengetahuan,
                semua tersedia dalam bentuk artikel, video, dan materi untuk
                meningkatkan skill dan kapasitas SDM Kementerian Agama, agar
                semua dapat tumbuh bersama.
              </Text>
            </Box>
            <Divider my={{ base: 4, md: 6 }} />
            <Flex flexDirection={["row"]} justifyContent="space-between">
              <Box w={{ base: "48%", md: "50%" }} mb={[4, 0]}>
                <Img src={PintarIcon1} w={12} h={12} />
                <Text
                  mt={2}
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="semibold"
                >
                  Pusat Pelatihan & Media Pembelajaran Online
                </Text>
              </Box>
              <Box w={{ base: "48%", md: "50%" }}>
                <Img src={PintarIcon2} w={12} h={12} />
                <Text
                  mt={2}
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="semibold"
                >
                  Pusat Pengetahuan & Peningkatan Kualitas SDM
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default AboutPintar;
