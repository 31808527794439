/**
 * MenuItemNavbar Component wraps a menu item in the mobile bottom navigation bar.
 *
 * @param {object} props - The props that are passed to the component.
 * @param {function} props.setMenuSelected - The function to set the selected menu item.
 * @param {string} props.menuSelected - The selected menu item.
 * @param {string} props.title - The title of the menu item.
 * @param {string} props.color - The color of the menu item.
 * @param {IconType} props.icon - The icon component of the menu item.
 */

import React from "react";
import { Flex, GridItem, Text, Icon } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { IconType } from "react-icons";

interface MenuItemNavbarProps {
  setMenuSelected: (value: string) => void;
  menuSelected: string;
  title: string;
  color: string;
  icon: IconType;
}

const MenuItem = ({
  setMenuSelected,
  menuSelected,
  title,
  color,
  icon,
}: MenuItemNavbarProps) => {
  const history = useHistory();

  const handleNavigate = () => {
    setMenuSelected(menuSelected);
    history.push("/" + menuSelected);
    window.scrollTo(0, 0);
  };

  return (
    <GridItem
      onClick={() => handleNavigate()}
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxH="40px"
      colSpan={1}
    >
      <Icon as={icon} boxSize={5} color={color} />
      <Flex flexWrap="wrap" textAlign="center">
        <Text fontWeight="medium" fontSize="xs" lineHeight="1" color={color}>
          {title}
        </Text>
      </Flex>
    </GridItem>
  );
};

export default MenuItem;
