import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";

/**
 * The ModalConfirmationRegister component is a modal dialog that appears when a user
 * wants to follow a speaker. The modal has a confirmation message and two buttons, one
 * to cancel and one to confirm. When the user clicks the confirm button, the
 * registerSpeaker function is called with the argument "mengikuti" to indicate that the
 * user wants to follow the speaker.
 * @returns {JSX.Element} - Returns the ModalConfirmationRegister component.
 * @author Bagas Prasetyadi
 */
const ModalConfirmationRegister = () => {
  const { setPengetahuanDetailState: setState, registerSpeaker } =
    usePengetahuanDetailContext();
  const { isOpenModalConfirmation, isLoadingAction, speakerId } =
    usePengetahuanDetailContext().state;

  const cancelRef = useRef(null);

  const onClose = () => {
    setState("isOpenModalConfirmation", false);
  };
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpenModalConfirmation}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Konfirmasi Mengikuti</AlertDialogHeader>
        <AlertDialogBody>
          Apakah kamu yakin untuk mengikuti pemateri ini?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onClose}>Tidak</Button>
          <Button
            colorScheme="green"
            ml={3}
            isLoading={isLoadingAction}
            onClick={() => registerSpeaker("mengikuti")}
          >
            Ya
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ModalConfirmationRegister;
