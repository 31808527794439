import { LogLatihanInterface } from "../../../data/contracts/LogLatihan";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { LogLatihanDataMapper } from "../../../data/mappers/LogLatihan";
import { LogLatihan } from "../../../entities/LogLatihan";

export class LogLatihanDataApiRepository implements LogLatihanInterface {
  private apiService: ApiService;
  private mapper: LogLatihanDataMapper;
  private endpoints: Endpoints;

  constructor(
    apiService: ApiService,
    mapper: LogLatihanDataMapper,
    endpoints: Endpoints
  ) {
    this.apiService = apiService;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  /**
   * getLogLatihan
   */
  public async getLogLatihan(
    page: number,
    rows: number,
    latihan_id: number
  ): Promise<LogLatihan[]> {
    const headers = new Map<string, string>();

    const dataResponse = await this.apiService.invoke(
      "get",
      this.endpoints.logLatihan(),
      {
        page: page,
        rows: rows,
        latihan_id: latihan_id,
      },
      null,
      headers
    );
    return this.mapper.convertListLogLatihanData(dataResponse);
  }
}
