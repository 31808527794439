/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { HelpdeskBantuanPresenter } from "../../../../../../data/presenters/HelpdeskBantuanPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelpdeskBantuan } from "../../../../../../entities/HelpdeskBantuan";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  keyword: string;
  row: number;
  totalHelpdeskBantuan: number;
  helpdeskBantuan: HelpdeskBantuan[];
  formId: number;
  formDialog: string;
  formTanggapan: string;
  formLoading: boolean;
  showDialog: boolean;
  showDialogDelete: boolean;
  selectedHelpdeskBantuan: HelpdeskBantuan | null;
}

interface InitialState {
  state: IState;
  getHelpdeskBantuan: Function;
  setPage: Function;
  setPageRow: Function;
  searchHelpdeskBantuan: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormTanggapan: Function;
  setShowDialog: Function;
  setShowDialogDelete: Function;
  deleteHelpdeskBantuan: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalHelpdeskBantuan: 1,
    helpdeskBantuan: [],
    formDialog: "",
    formId: 0,
    formTanggapan: "",
    formJawaban: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    selectedHelpdeskBantuan: null,
  },
  getHelpdeskBantuan: () => {},
  searchHelpdeskBantuan: () => {},
  setPage: () => {},
  setPageRow: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormTanggapan: () => {},
  setShowDialog: () => {},
  deleteHelpdeskBantuan: () => {},
  setShowDialogDelete: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: HelpdeskBantuanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    keyword: "",
    row: 10,
    totalHelpdeskBantuan: 0,
    helpdeskBantuan: [],
    formDialog: "",
    formId: 0,
    formTanggapan: "",
    formLoading: false,
    showDialog: false,
    showDialogDelete: false,
    selectedHelpdeskBantuan: null,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      page: value,
    }));
  };

  const setPageRow = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      row: value,
    }));
  };

  const setJumlahPage = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jumlahPage: value,
    }));
  };

  const getHelpdeskBantuan = async (
    pagee: number,
    keyword: string,
    row: number
  ) => {
    setLoading(true);

    try {
      const helpdeskBantuanPresenter = container.resolve(
        HelpdeskBantuanPresenter
      );
      const getAllHelpdeskBantuan =
        await helpdeskBantuanPresenter.getAllHelpdeskBantuan(
          pagee,
          row,
          keyword
        );

      const data = getAllHelpdeskBantuan;

      setJumlahPage(data[0].jumlahData / row);

      setState((prevstate) => ({
        ...prevstate,
        helpdeskBantuan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const searchHelpdeskBantuan = (value: string) => {
    setLoading(true);
    getHelpdeskBantuan(state.page, value, state.row);
    setState((prevstate) => ({
      ...prevstate,
      keyword: value,
    }));
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: HelpdeskBantuan) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formTanggapan: value.tanggapan,
      selectedHelpdeskBantuan: value,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const actionFormDialog = async () => {
    setFormLoading(true);

    if (state.formTanggapan == "") {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const helpdeskBantuanPresenter = container.resolve(
        HelpdeskBantuanPresenter
      );

      const action = await helpdeskBantuanPresenter.updateHelpdeskBantuan(
        state.formId,
        state.formTanggapan
      );
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialog(false);
        getHelpdeskBantuan(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Ubah Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormTanggapan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formTanggapan: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formTanggapan: "",
      formJawaban: "",
    }));
  };

  const deleteHelpdeskBantuan = async () => {
    setFormLoading(true);

    try {
      const helpdeskBantuanPresenter = container.resolve(
        HelpdeskBantuanPresenter
      );
      const action = await helpdeskBantuanPresenter.deleteHelpdeskBantuan(
        state.formId
      );
      const data = action;

      setFormLoading(false);

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDelete(false);
        getHelpdeskBantuan(state.page, state.keyword, state.row);
        clearForm();
      } else {
        toast.error("Ada Kesalahan Saat Hapus Data!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  return (
    <HelpdeskBantuanProvider
      value={{
        state,
        getHelpdeskBantuan,
        setPage,
        setPageRow,
        searchHelpdeskBantuan,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormTanggapan,
        setShowDialog,
        deleteHelpdeskBantuan,
        setShowDialogDelete,
      }}
    >
      {children}
    </HelpdeskBantuanProvider>
  );
};

export const useHelpdeskBantuanContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useHelpdeskBantuanContext,
  Provider,
};
